import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import {getTeams} from './teams/action/actionCreators'
import {can} from '../utils'
import {ModalContext} from 'context/ModalContext'

import Can from 'components/Can'
import EmptyList from 'components/EmptyList'
import Loader from 'components/Loader'
import Pagination from 'components/Pagination'
import AddTeam from './teams/components/AddTeam'
import TeamListItem from './teams/components/TeamListItem'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'

const TicketTeams = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const {showModal, hideModal} = useContext(ModalContext)
  const teamsList = useSelector(({ticket}) => ticket.teams)
  const loggedUser = useSelector(({auth}) => auth.data)

  useEffect(() => {
    dispatch(getTeams(page, size, 'name'))
  }, [dispatch, page, size])

  const createTeam = () =>
    showModal({
      id: 'add-ticket-team-form-modal',
      dialogClassName: 'add-ticket-team-form',
      closeButton: true,
      title: 'Create Team',
      body: <AddTeam />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const getTeamsList = () => {
    if (can.do('show_ticket_team')) {
      return (
        teamsList.data && teamsList.data.map((team) => <TeamListItem key={team.id} team={team} />)
      )
    }

    if (can.do('show_owned_teams')) {
      return (
        teamsList.data &&
        teamsList.data
          .filter((team) => team.leader_id === loggedUser.id)
          .map((team) => <TeamListItem key={team.id} team={team} />)
      )
    }

    return null
  }

  return (
    <div className='card bg-transparent shadow-none mt-10'>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end'>
          <Can do={'create_ticket_team'}>
            <Button
              title={
                <span className='d-flex align-items-center justify-content-center align-items-start'>
                  <i
                    className='bi bi-plus-circle mx-2'
                    style={{color: 'white', fontSize: '1.5rem'}}
                  ></i>
                  Create Team
                </span>
              }
              className='mt-0 w-auto mx-4 p-3 bg-primary'
              onClick={createTeam}
            />
          </Can>
        </div>
      </div>
      <div className='row g-5 g-xl-8 my-5'>
        {teamsList.isFetching && <Skeleton type="block" />}
        {getTeamsList()}
        {teamsList.data && !teamsList.data.length && <EmptyList />}
      </div>
      {!isEmpty(teamsList.data) && teamsList.meta.last_page > 1 && (
        <Pagination
          pageCount={teamsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={teamsList.meta}
          currentPage={page - 1}
        />
      )}
    </div>
  )
}

export default TicketTeams
