import React, { useEffect, useState } from 'react'
import API from 'config/API';
import { Notifier } from 'components/Notifier';
import { withRouter } from 'react-router-dom';
import Loader from 'components/Loader';
import GroupCard from './GroupCard';
import { can } from 'modules/ticket/utils';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit'

const UpdateGroup = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(null);
  const { client_config } = useSelector(({ auth }: any) => auth);
  const groupId = props.match.params.id;

  useEffect(() => {
    async function fetchGroup() {
      try {
        const response = await API.get(`/course/groups/${groupId}`);
        setGroup(response.data.data);
      } catch (error) {
        props.history.push('/404');
      }
    }

    fetchGroup();
  }, []);

  const prepareSave = (values: any) => {
    const group = {
      ...values,
      instructor_id: values.instructor_id.value,
      course_id: values.course_id.value,
      group_status_id: values.group_status_id.value,
      group_round_id: values.group_round_id.value,
      branch_id: values.branch_id.value,
      is_online: values.is_online ? 1 : 0
    };

    return client_config.has_branch ? group : omit(group, 'branch_id');
  };

  const onSubmitHandler = (values: any, setSubmitting: (bool: boolean) => void, setErrors: any) => {
    setLoading(true)
    API.put(`/course/groups/${groupId}`, prepareSave(values))
      .then(() => {
        setLoading(false);
        props.history.push('/groups/list');
        Notifier.successMessage('Group has been updated!');
      })
    .catch((error) => {
      setErrors(error.response.data.errors);
      setLoading(false)
      setSubmitting(false)
    })
  };

  if (can.do('show_card')) {
    props.history.push('/404');
    return null;
  }

  return (
    <>
      {!group && <Loader />}
      {group && <GroupCard group={group} loading={loading} onSubmitHandler={onSubmitHandler} />}
    </>
  )
}

export default withRouter(UpdateGroup);
