import {
  DELETE_LEAD_AD_SUCCESS,
  GET_LEAD_ADS_SUCCESS,
  SET_FETCHING_LEAD_ADS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function leadAdsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_LEAD_ADS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_LEAD_ADS_SUCCESS:
      return {
        ...state,
        ...action.payload.leadAds,
      }
    case DELETE_LEAD_AD_SUCCESS:
      return {
        ...state,
        data: state.data.filter((leadAd) => leadAd.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default leadAdsReducer
