import React, {useEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import Card from '../../../../components/Card';
import Select from 'react-select';
// import AsyncSelect from 'react-select/async';

import {getTicketActions} from '../../teams/TicketActions/action/actionCreators';
import {Tab, Tabs} from 'react-bootstrap';
import {getTeams} from '../../teams/teams/action/actionCreators';
import {getTicketCategories} from '../../teams/TicketCategories/action/actionCreators';
import {getPrioritiesOptions, getTicketModelName} from '../../utils';
import {getBranches} from 'modules/branches/action/actionCreators';

function TicketForm({loading, formik}) {
    const dispatch = useDispatch();
    const [teamId, setTeamId] = useState('');
    const [ticketCategoryId, setTicketCategoryId] = useState('');
    const [step, setStep] = useState('team');
    const {
        teams,
        categories,
        actions
    } = useSelector(({ticket}: any) => ticket, shallowEqual);
    const branches = useSelector(({branches}: any) => branches, shallowEqual);
    const {client_config} = useSelector(({auth}: any) => auth, shallowEqual);
    const fileRef = useRef(null);

    useEffect(() => {
        dispatch(getTeams(1, 100, 'name'));
    }, [dispatch])

    useEffect(() => {
        if (teamId && step === 'category') {
            dispatch(getTicketCategories(1, 100, teamId, ''));
        }
    }, [dispatch, teamId, step])

    useEffect(() => {
        if (ticketCategoryId && step === 'ticket') {
            dispatch(getTicketActions(1, 100, ticketCategoryId, ''));
            if (client_config.has_branch) {
                dispatch(getBranches(1));
            }
        }
    }, [dispatch, ticketCategoryId, step])

    const getActionOptions = () => {
        return actions && actions.data.map((action: any) => ({
            value: action.id,
            label: action.action,
            model_type: action.model_type
        }));
    };

    const getBranchOptions = () => branches && branches.data.map((branch: any) => ({
        value: branch.id,
        label: branch.name
    }));

    return (
        <Card classNames="mt-5 p-7">
            <div className="text-center">
                <h1 className="mb-3">Create Ticket</h1>
            </div>
            <Tabs
                id="controlled-tab-example"
                activeKey={step}
                onSelect={(k) => setStep(k as any)}
                className="mb-3"
            >
                <Tab eventKey="team" title="Choose Team">
                    <div className="row py-7">
                        {teams && teams.data && teams.data.map((team) => (
                            <div className="col-xl-4 cursor-pointer" key={team?.id} onClick={() => {
                                setTeamId(team.id);
                                setStep('category');
                            }}>
                                <div className="card bgi-no-repeat card-xl-stretch mb-xl-8 bg-light">
                                    <div className="card-body">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <span
                                                className="card-title fw-bolder text-muted text-hover-primary fs-4">{team.name}</span>
                                        </div>
                                        <div className="fw-bolder text-primary mt-7">Team leader</div>
                                        <p className="text-dark-75 fw-bold fs-5 m-0">{team.leader?.name}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="category" title="Choose Category" disabled={!teamId || step === 'team'}>
                    <div className="row py-7">
                        {categories && categories.data.map((category) => (
                            <div className="col-xl-4 cursor-pointer" key={category.id} onClick={() => {
                                setTicketCategoryId(category.id);
                                formik.resetForm();
                                setStep('ticket');
                            }}>
                                <div className="card bgi-no-repeat card-xl-stretch mb-xl-8 bg-light">
                                    <div className="card-body">
                                        <div className="d-flex flex-stack flex-grow-1">
                      <span className="card-title fw-bolder text-muted text-hover-primary fs-4">
                        {category.name}
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="ticket" title="Create Ticket" disabled>
                    <form
                        className="form fv-plugins-bootstrap5 fv-plugins-framework py-5"
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <div className="row g-9 mb-8">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-6 fw-bold mb-2">Action</label>
                                <Select
                                    styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({...provided, zIndex: 9999})
                                    }}
                                    options={getActionOptions() as any}
                                    value={formik?.values?.ticket_action_id}
                                    name="ticket_action_id"
                                    classNamePrefix="select-input"
                                    className="select-option"
                                    closeMenuOnSelect
                                    onChange={(option: any) => formik.setFieldValue('ticket_action_id', option)}
                                />
                                {formik.touched.ticket_action_id && formik.errors.ticket_action_id && (
                                    <div className='fv-plugins-message-container invalid-feedback d-block'>
                                        <span role='alert'
                                              className="alert-text">{formik.errors.ticket_action_id}</span>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-bold mb-2">Priority</label>
                                <Select
                                    styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({...provided, zIndex: 9999})
                                    }}
                                    options={getPrioritiesOptions()}
                                    value={formik.values.priority}
                                    name="priority"
                                    classNamePrefix="select-input"
                                    className="select-option"
                                    closeMenuOnSelect
                                    onChange={(option: any) => formik.setFieldValue('priority', option)}
                                />
                                {formik.touched.priority && formik.errors.priority && (
                                    <div className="fv-plugins-message-container invalid-feedback d-block">
                                        <span role='alert' className="alert-text">{formik.errors.priority}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row g-9 mb-8">
                            {formik.values?.ticket_action_id?.value && (
                                <div className="col-md-6 fv-row fv-plugins-icon-container">
                                    <label
                                        className='required fs-6 fw-bold mb-2'>{getTicketModelName(formik.values?.ticket_action_id?.model_type)} ID</label>
                                    <input
                                        placeholder='ID'
                                        {...formik.getFieldProps('ticketable_id')}
                                        className="form-control form-control-lg form-control-solid"
                                        type='text'
                                        name='ticketable_id'
                                        required
                                        autoComplete='off'
                                    />
                                    {formik.touched.ticketable_id && formik.errors.ticketable_id && (
                                        <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                            <span role='alert'
                                                  className="alert-text">{formik.errors.ticketable_id}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {client_config.has_branch && (
                                <div className="col-md-6 fv-row fv-plugins-icon-container">
                                    <label className="required fs-6 fw-bold mb-2">Branch</label>
                                    <Select
                                        styles={{
                                            // Fixes the overlapping problem of the component
                                            menu: provided => ({...provided, zIndex: 9999})
                                        }}
                                        options={getBranchOptions() as any}
                                        value={formik.values.branch_id}
                                        name="branch_id"
                                        classNamePrefix="select-input"
                                        className="select-option"
                                        closeMenuOnSelect
                                        onChange={(option: any) => formik.setFieldValue('branch_id', option)}
                                    />
                                    {formik.touched.branch_id && formik.errors.branch_id && (
                                        <div className="fv-plugins-message-container invalid-feedback d-block">
                                            <span role='alert' className="alert-text">{formik.errors.branch_id}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                            <label className="required fs-6 fw-bold mb-2">Description</label>
                            <textarea
                                className="form-control form-control-solid"
                                {...formik.getFieldProps('description')}
                                rows={4}
                                name="description"
                                placeholder="Type your ticket description">
              </textarea>
                            {formik.touched.description && formik.errors.description && (
                                <div className="fv-plugins-message-container invalid-feedback d-block">
                                    <span role='alert' className="alert-text">{formik.errors.description}</span>
                                </div>
                            )}
                        </div>
                        <div className="fv-row mb-8">
                            <input
                                type="file"
                                accept="image/*,.pdf,.doc"
                                hidden
                                ref={fileRef}
                                {...formik.getFieldProps('attachment')}
                                value={undefined}
                                name="attachment"
                                onChange={(option: any) => formik.setFieldValue('attachment', option.target.files[0])}
                            />
                            <label className="fs-6 fw-bold mb-2">Attachments</label>
                            <div className="dropzone dz-clickable" id="kt_modal_create_ticket_attachments"
                                 onClick={() => (fileRef.current as any).click()}>
                                <div className="dz-message needsclick align-items-center">
                                        <span className="svg-icon svg-icon-3hx svg-icon-primary">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                               viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z"
                                                  fill="black"></path>
                                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                                          </svg>
                                        </span>
                                    <div className="ms-4">
                                        <h3 className="fs-5 fw-bolder text-gray-900 mb-1">{formik.values.attachment ? formik.values.attachment.name : 'click to upload.'}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="reset" className="btn btn-light me-3">Cancel</button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={formik.isSubmitting}
                            >
                                {!loading && <span className="indicator-label">Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                )}
                            </button>
                        </div>
                    </form>
                </Tab>
            </Tabs>
        </Card>
    )
}

export default TicketForm
