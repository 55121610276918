import classnames from 'classnames'
import { capitalizeFirstLetter } from 'config/utils'
import moment from 'moment'
import { can, getTicketModelName, prioritiesColors } from 'modules/ticket/utils'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import {useSelector} from "react-redux";

interface TicketItemProps {
  ticket: any;
}

const TicketItem: FC<TicketItemProps> = ({ ticket }) => {

  // check for valid permssion
  const showTicket = () => {
    if (can.do('show_ticket')) {
      return `/tickets/${ticket.id}`;
    }

    return undefined;
  }

  // @ts-ignore
  const {client_config} = useSelector(({auth}) => auth)
  
  return (
    <div className="mb-3">
      <Link to={showTicket}  className="card border-hover-primary h-200">
        <div className="card-body p-4">
          <div className="d-flex align-items-center justify-content-between">
            {ticket.ticketAction && (
              <div className="card-category-action">
                {ticket.ticketTeam?.name}
                <i className="bi bi-chevron-right mx-1"></i>
                {ticket.ticketAction?.ticketCategory?.name} 
                <i className="bi bi-chevron-right mx-1"></i>
                {ticket.ticketAction?.action}
                {ticket.agent && (
                  <div className="fw-bold text-gray-400 fs-8 mt-2">
                    <div>Assigend To</div>
                    <i className="bi bi-person-circle me-2"></i>
                    {ticket.agent.name}
                  </div>
                )}
              </div>
            )}
            <span className={
              classnames(
                "badge fw-bolder px-4 py-3 w-25",
                [`badge-${prioritiesColors[ticket.priority]}`]
              )}
            >{capitalizeFirstLetter(ticket.priority)}</span>
          </div>
          <div className="d-flex align-items-center fw-bold my-2 justify-content-between">
            <span className="fs-5 text-dark">
              {getTicketModelName(ticket.ticketable_type)}: {ticket.ticketable_id}
            </span>
            <div className="d-flex align-items-center">
              {!!ticket.exceeded_deadline && <span className="exceeded-deadline mx-1"><i className="bi bi-alarm-fill"></i></span>}
              {client_config.has_branch && ticket.branch && <span className="badge bg-warning px-4 py-3 w-100">{ticket.branch?.name}</span>}
            </div>
          </div>
          <p 
            className="fs-6 mt-1 text-dark line-clamp" 
            title={ticket.description}
          >
            {ticket.description}
          </p>
          <div className="d-flex align-items justify-content-between py-3">
            <div className="fw-bold text-gray-400 fs-8">
              <div>Last Action</div>
              <i className="bi bi-clock me-2"></i>
              {moment(ticket.updated_at).format('D MMMM, YYYY h:mm A')}
            </div>
            <div className="fw-bold text-gray-400 fs-8" title={ticket.createdBy.name}>
              <div>Created By</div>
              <i className="bi bi-person-circle me-2"></i>
              {ticket.createdBy.first_name}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default TicketItem
