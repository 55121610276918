import API from 'config/API'
import {
  DELETE_COURSE,
  GET_COURSES_SUCCESS,
  SET_COURSES_FILTER,
  SET_FETCHING_COURSES,
} from './actionTypes'

export const setFetching = (isFetching) => ({
  type: SET_FETCHING_COURSES,
  payload: {isFetching},
})

const getCoursesSuccess = (courses) => ({
  type: GET_COURSES_SUCCESS,
  payload: {courses},
})

export const getCourses =
  (
    page,
    size, // paginate
    sort,
    filters,
    includes = 'groups,leadRequests,items,digitalMaterials,packages,courseTerminationRule,courseApologizingRule,coursePostponeRule,courseRefundRule',
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const url = `/course/courses?include=${includes}&paginate=${size}&sort=${sort}&page=${page}&${filters}`
      const response = await API.get(url)
      dispatch(
        getCoursesSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
    } catch (error) {
      throw new Error(error.response)
    }
  }

export const setCoursesFilter = (filter) => ({
  type: SET_COURSES_FILTER,
  payload: {filter},
})

export const deleteCourse = (id) => ({
  type: DELETE_COURSE,
  payload: {id},
})
