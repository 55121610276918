import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'

import API from 'config/API'
import {ModalContext} from 'context/ModalContext'
import {can} from 'modules/ticket/utils'
import {getGroupStatuses} from './action/actionCreators'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Can from 'components/Can'
import ConfirmationModal from 'components/ConfirmationModal'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import Table from 'components/Table'
import AddGroupStatus from './components/AddGroupStatus'
import UpdateGroupStatus from './components/UpdateGroupStatus'

function GroupStatuses() {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const {showModal, hideModal} = useContext(ModalContext)
  const dispatch = useDispatch()
  const group_statuses = useSelector(({course}) => course.group_status)

  useEffect(() => {
    dispatch(getGroupStatuses(page, size, '-created_at'))
  }, [dispatch, page, size])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleDelete = React.useCallback(
    async (id) => {
      try {
        await API.delete(`/course/group_statuses/${id}`)
        dispatch(getGroupStatuses(page, size, '-created_at'))
        hideModal()
        Notifier.successMessage('Group status has been deleted!')
      } catch (error) {
        // Ignore Error Message
      }
    },
    [dispatch, hideModal, page, size]
  )

  const deleteStatus = React.useCallback(
    (id) => {
      return showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [handleDelete, hideModal, showModal]
  )

  const updateStatus = React.useCallback(
    (status) => {
      return showModal({
        id: 'view-group-status-form-modal',
        dialogClassName: 'view-group-status-form',
        closeButton: true,
        title: 'Update Group Status',
        body: <UpdateGroupStatus page={page} size={size} name={status.name} id={status.id} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal, page, size]
  )

  const addStatus = () =>
    showModal({
      id: 'add-group-status-form-modal',
      dialogClassName: 'add-group-status-form',
      closeButton: true,
      title: 'Add Group Status',
      body: <AddGroupStatus page={page} size={size} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Action',
        accessor: 'action',
        className: 'text-right px-5',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      group_statuses?.data.map((dataItem) => ({
        ...dataItem,
        action: (can.do('show_group_status') || can.do('delete_group_status')) ? (
          <div className='d-flex justify-content-end'>
            <Can do='show_group_status'>
              <div className='rounded-icon' onClick={() => updateStatus(dataItem)}>
                <i className='bi bi-pencil'></i>
              </div>
            </Can>
            <Can do='delete_group_status'>
              <div className='rounded-icon' onClick={() => deleteStatus(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </Can>
          </div>
        ) : null,
      })),
    [group_statuses, deleteStatus, updateStatus]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (group_statuses.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Can do='create_group_status'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Add Group Status
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={addStatus}
              />
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(group_statuses.data) ? (
        <EmptyList message='No statuses Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(group_statuses.data) && group_statuses.meta.last_page > 1 && (
        <Pagination
          pageCount={group_statuses.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={group_statuses.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default GroupStatuses
