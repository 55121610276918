import {
  GET_CHANNELS_SUCCESS,
  UPDATE_CHANNEL_SUCCESS,
  ADD_CHANNEL_SUCCESS,
  DELETE_CHANNEL_SUCCESS,
  SET_FETCHING_CHANNELS,
} from './actionTypes'
import API from 'config/API'

const getChannelsSuccess = (channels) => ({
  type: GET_CHANNELS_SUCCESS,
  payload: {channels},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_CHANNELS,
  payload: {isFetching},
})

export const getChannels =
  (
    page,
    size, // paginate
    sort,
    search
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/crm/channels?paginate=${size}&sort=${sort}&page=${page}&filter[name]=${search}`
      )
      dispatch(
        getChannelsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

export const addChannelSuccess = (channel) => ({
  type: ADD_CHANNEL_SUCCESS,
  payload: {channel},
})

export const updateChannelSuccess = (channel) => ({
  type: UPDATE_CHANNEL_SUCCESS,
  payload: {channel},
})

export const deleteChannelSuccess = (id) => ({
  type: DELETE_CHANNEL_SUCCESS,
  payload: {id},
})
