import { GET_BRANCHES_SUCCESS } from "../action/actionTypes";

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0
  }
};

function branchesReducer(state = initialState, action) {
  switch(action.type) {
    case GET_BRANCHES_SUCCESS:
      return action.payload.branches;
    default: 
      return state;
  }
}

export default branchesReducer;