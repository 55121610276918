import {
  ADD_LEAD_AD_TYPES_SUCCESS,
  DELETE_LEAD_AD_TYPES_SUCCESS,
  GET_LEAD_AD_TYPES_SUCCESS,
  SET_FETCHING_LEAD_AD,
  UPDATE_LEAD_AD_TYPES_SUCCESS,
} from './actionTypes'
import API from 'config/API'

const getLeadAdTypesSuccess = (leads) => ({
  type: GET_LEAD_AD_TYPES_SUCCESS,
  payload: {leads},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_LEAD_AD,
  payload: {isFetching},
})

export const getLeadAdTypes =
  (
    page,
    size, // paginate
    sort,
    filter
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/crm/lead_ad_types?paginate=${size}&page=${page}&sort=${sort}&filter[name]=${filter}`
      )
      dispatch(
        getLeadAdTypesSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore eRROR mESSAGE
    }
  }

export const addLeadAdTypeSuccess = (lead) => ({
  type: ADD_LEAD_AD_TYPES_SUCCESS,
  payload: {lead},
})

export const updateLeadAdTypeSuccess = (lead) => ({
  type: UPDATE_LEAD_AD_TYPES_SUCCESS,
  payload: {lead},
})

export const deleteLeadAdTypeSuccess = (id) => ({
  type: DELETE_LEAD_AD_TYPES_SUCCESS,
  payload: {id},
})
