import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'
import API from 'config/API'
import moment from 'moment'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import ConfirmationModal from 'components/ConfirmationModal'

import {ModalContext} from 'context/ModalContext'
import {deleteLeadAdSuccess, getLeadAds} from './action/actionCreators'
import AddLeadAd from './components/AddLeadAd'
import ViewLeadAd from './components/ViewLeadAd'
import AddLeadBudget from './components/AddLeadBudget'
import TooltipTitle from 'components/TooltipTitle'

const MediaBuying = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const {showModal, hideModal} = React.useContext(ModalContext)
  const leadAdList = useSelector(({crm}) => crm.leadAds)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getLeadAds(page, size, 'name'))
  }, [dispatch, size, page])

  const handleDelete = (id) =>
    API.delete(`/crm/lead_ads/${id}`)
      .then(() => {
        dispatch(deleteLeadAdSuccess(id))
        hideModal()
        Notifier.successMessage('Lead Ad has been deleted!')
      })
      .catch((error) => {
        // Ignore Error Message
      })

  const deleteLeadAd = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const viewLeadAd = (leadAd) =>
    showModal({
      id: 'view-lead-form-modal',
      dialogClassName: 'view-lead-form',
      closeButton: true,
      title: 'View lead',
      body: <ViewLeadAd leadAd={leadAd} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const createLeadAd = () =>
    showModal({
      id: 'add-lead-form-modal',
      dialogClassName: 'add-lead-form',
      closeButton: true,
      title: 'Create lead Ad',
      body: <AddLeadAd />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const addLeadBudget = (leadAd) =>
    showModal({
      id: 'add-lead-budget-form-modal',
      dialogClassName: 'add-lead-budget-form',
      closeButton: true,
      title: 'Add lead Budget',
      body: <AddLeadBudget lead_ad_id={leadAd.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'leadAdType',
        Cell: ({row: {original: row}}) => row.leadAdType?.name,
      },
      {
        Header: 'Project',
        accessor: 'project',
        Cell: ({row: {original: row}}) => row.project?.name,
      },
      {
        Header: 'Total Result',
        accessor: 'total_result',
      },
      {
        Header: 'Total Budget',
        accessor: 'budget',
      },
      {
        Header: 'Price Per Result',
        accessor: 'price_per_result',
      },
      {
        Header: 'short URL',
        accessor: 'short_url',
        Cell: ({row: {original: row}}) => (
          <CopyToClipboard text={row.short_url} onCopy={() => Notifier.infoMessage('Copied!')}>
            <span className='cursor-default'>
              <i className="bi bi-clipboard mx-2"></i>
              {row.short_url}
            </span>
          </CopyToClipboard>
        ),
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({row: {original: row}}) => moment(row.updated_at).format('DD-MM-YYYY, h:mm A'),
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        Cell: ({row: {original: row}}) => row.createdBy?.name,
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      leadAdList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <TooltipTitle title='Edit Lead Ad'>
              <div className='rounded-icon' onClick={() => viewLeadAd(dataItem)}>
                <i className='bi bi-pencil'></i>
              </div>
            </TooltipTitle>
            <TooltipTitle title='Add Budget'>
              <div className='rounded-icon' onClick={() => addLeadBudget(dataItem)}>
                <i className='bi bi-wallet2'></i>
              </div>
            </TooltipTitle>
            <TooltipTitle title='Delete Lead Ad'>
              <div className='rounded-icon' onClick={() => deleteLeadAd(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </TooltipTitle>
          </div>
        ),
      })),
    [leadAdList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (leadAdList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Button
              title={
                <span className='d-flex align-items-center justify-content-center'>
                  <i
                    className='bi bi-plus-circle mx-2'
                    style={{color: 'white', fontSize: '1.5rem'}}
                  ></i>
                  Create Lead Ad
                </span>
              }
              className='mt-0 w-auto mx-4 p-3 bg-primary'
              onClick={createLeadAd}
            />
          </div>
        </div>
      </div>
      {isEmpty(leadAdList.data) ? (
        <EmptyList message='No Lead Ads Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(leadAdList.data) && leadAdList.meta.last_page > 1 && (
        <Pagination
          pageCount={leadAdList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={leadAdList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default MediaBuying
