import React, { useState } from 'react';
import TeamForm from './TeamForm';
import { useHistory } from 'react-router-dom';
import API from 'config/API';
import { Notifier } from 'components/Notifier';

const AddTeam = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [teamId, setTeamId] = useState(null);

  const onSubmitSecondStepHanlder = (values, setError) => {
    setLoading(true)
    API.post(`/team/team_target_and_commissions`, {
      team_id: teamId,
      leader_target: values.leader_target,
      leader_commission: values.leader_commission,
      agent_target: values.agent_target,
      agent_commission: values.agent_commission
    })
      .then(() => {
        setLoading(false);
        history.push('/teams/list');
        Notifier.successMessage('Team has been created!');
      })
    .catch((error) => {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    })
  };

  const onSubmitFirstStepHandler = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.post(`/team/crm_teams`, {
        name: values.name,
        leader_id: values.leader?.value,
        role_id: `${values.role?.value}`
      })
      setTeamId(response.data.data.id);
      setLoading(false);
      return response;
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  };

  return (
    <TeamForm 
      loading={loading} 
      onSubmitFirstStepHandler={onSubmitFirstStepHandler} 
      onSubmitHandler={onSubmitSecondStepHanlder} 
    />
  );
}

export default AddTeam;