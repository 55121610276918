import classNames from "classnames";
import React from "react";
import isEqual from 'lodash/isEqual';

import ErrorInput from "./ErrorInput";

const Input = React.forwardRef(
  (
    {
      containerClassName = "",
      className = "border-transparent bg-transparent h-11 px-3",
      labelClassName = "",
      type = "text",
      placeholder = "",
      id = "",
      error = "",
      required = false,
      leftIcon = null,
      rightIcon = null,
      isTextArea = false,
      value,
      handleBlur,
      ...rest
    },
    ref
  ) => {
    const [isActive, setActive] = React.useState(isEqual(value, 0) && type === 'number' ? true : !!value);

    const setInputFocus = (status) =>
      value ? setActive(true) : setActive(status);

    React.useEffect(() => {
      // fix Google Chrome autofill
      setTimeout(() => {
        // fetch input
        const autoFillInput = document.querySelector("input:-webkit-autofill");
        // detect input change
        if (autoFillInput) {
          setInputFocus(true);
        }
      }, 500);
    }, []);


    React.useEffect(() => {
      setActive(value === 0 && type === 'number' ? true : !!value);
    }, [value, type]);

    const renderInput = () =>
      isTextArea ? (
        <textarea
          type={type}
          id={id}
          ref={ref}
          value={value}
          {...rest}
          className={classNames(
            "input-field",
            {
              "pl-12 pr-5": leftIcon,
              "pr-10": rightIcon,
              "text-inherit": isActive,
            },
            className
          )}
          onFocus={() => setInputFocus(true)}
          onBlur={handleBlur}
        />
      ) : (
        <input
          type={type}
          id={id}
          ref={ref}
          value={value}
          {...rest}
          className={classNames(
            "input-field",
            {
              "pl-12 pr-5": leftIcon,
              "pr-10": rightIcon,
              "text-inherit": isActive,
            },
            className
          )}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
        />
      );

    return (
      <div className={classNames("input-container", containerClassName)}>
        <label
          className={classNames(labelClassName, "input-label", {
            "input-label__focused": isActive,
            "pl-8": !isActive && leftIcon,
            "text-red": isActive && error,
          })}
        >
          {placeholder}
          {required && <span className="text-red text-xs ml-[2px]">*</span>}
        </label>

        <div className="input-relative relative">
          {leftIcon && (
            <span className="input-icon_absolute input-icon_absolute--left">
              {leftIcon}
            </span>
          )}
          {renderInput()}
          <fieldset
            aria-hidden="true"
            className={classNames({ "border-red": error })}
          >
            <legend
              className={classNames({
                "legend--focused": isActive,
                "border-red": error,
              })}
            >
              <span>{placeholder}</span>
            </legend>
          </fieldset>
          {rightIcon && (
            <span className="input-icon_absolute input-icon_absolute--right">
              {rightIcon}
            </span>
          )}
        </div>

        {error && <ErrorInput message={error.message} />}
      </div>
    );
  }
);

export default Input;
