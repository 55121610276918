import React, {useState, useEffect, useContext} from 'react'
import {Notifier} from 'components/Notifier'
import isEmpty from 'lodash/isEmpty'
import {useTable} from 'react-table'

import API from 'config/API'
import {isPastDate, isTodayDate} from 'config/utils'

import {attendanceBadges} from 'modules/course/groupStudents/utils'
import {ModalContext} from 'context/ModalContext'

import EmptyList from 'components/EmptyList'
import TooltipTitle from 'components/TooltipTitle'
import Skeleton from 'components/Skeleton'
import Table from 'components/Table'
import UpdateStudentNotes from './UpdateStudentNotes'
import Can from 'components/Can'

const StudentsAttendanceRequests = ({data: {group, selectedDate, status}}) => {
  const [attendance, setAttendance] = useState({
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      to: 0,
      per_page: 0,
      total: 0,
      last_page: 0,
    },
  })
  const [loading, setLoading] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const {showModal, hideModal} = useContext(ModalContext)

  useEffect(() => {
    async function fetchAttendance() {
      try {
        setLoading(true)
        const response = await API.get(
          `/student/attendance_change_requests?include=studentCourse.student,groupTimeline.group,createdBy,updatedBy&filter[group_timeline_id]=${
            group.groupTimelines && group.groupTimelines[0].id
          }&filter[status]=${status}`
        )
        setAttendance(response.data)
        setLoading(false)
        setRefetch(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (isPastDate(selectedDate) || isTodayDate(selectedDate)) {
      fetchAttendance()
    }
  }, [refetch, selectedDate, group.groupTimelines, status])

  const setAdminAction = React.useCallback(
    async (attendance, status) => {
      try {
        await API.put(`/student/attendance_change_requests/${attendance.id}`, {status})
        setRefetch(true)
        hideModal()
        Notifier.successMessage('Attendance request saved!')
      } catch (error) {
        setLoading(false)
      }
    },
    [hideModal]
  )

  const updateNotes = React.useCallback(
    (attendanceId, notes = '') => {
      return showModal({
        id: 'add-student-notes-form-modal',
        dialogClassName: 'add-student-notes-form',
        closeButton: true,
        title: 'update Notes',
        body: <UpdateStudentNotes attendanceId={attendanceId} notes={notes} setRefetch={setRefetch} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal, setRefetch]
  )

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.name ?? 'N/A',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.mobile ?? 'N/A',
      },
      {
        Header: 'Status',
        accessor: 'attendance_status',
        Cell: ({row: {original: row}}) => (
          <span
            className={`fw-bolder badge text-light fs-8 w-auto badge-${
              attendanceBadges[row?.attendance_status]
            }`}
          >
            {row?.attendance_status}
          </span>
        ),
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({row: {original: row}}) => (
          <div className='d-flex align-items-center gap-1'>
            <span className='line-clamp-1'>{row?.notes ?? 'N/A'}</span>
            <Can do="request_student_attendance_change">
              <TooltipTitle title='Update Notes'>
                <button
                  onClick={() => updateNotes(row.id, row?.notes)}
                  className='rounded-icon'
                >
                  <i className='bi bi-pencil'></i>
                </button>
              </TooltipTitle>
            </Can>
          </div>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({row: {original: row}}) => row.createdBy?.name ?? 'N/A',
      },
      {
        Header: 'Approved by',
        accessor: 'updatedBy',
        Cell: ({row: {original: row}}) => row.updatedBy?.name ?? 'N/A',
      },
      {
        Header: 'Action',
        accessor: 'action',
        className: 'text-right px-5',
      },
    ],
    [updateNotes]
  )

  // table data
  const data = React.useMemo(
    () =>
      attendance?.data.map((dataItem) => ({
        ...dataItem,
        action: dataItem.status === 'pending' ? (
          <div className='d-flex align-items-center justify-content-end'>
            <Can do="request_student_attendance_change">
              <TooltipTitle title="Approve">
                  <button onClick={() => setAdminAction(dataItem, 'approved')} className='rounded-icon bg-success'>
                  <i className='bi bi-check-all text-white color-white'></i>
                  </button>
              </TooltipTitle>
            </Can>
            <Can do="request_student_attendance_change">
              <TooltipTitle title="Reject">
                  <button onClick={() => setAdminAction(dataItem, 'rejected')} className='rounded-icon bg-danger'>
                  <i className='bi bi-x text-white color-white'></i>
                  </button>
              </TooltipTitle>
            </Can>
          </div>
        ) : dataItem.status,
      })),
    [attendance, setAdminAction]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (loading) {
    return <Skeleton />
  }

  if (isEmpty(attendance.data)) {
    return <EmptyList message='No requests Available' />
  }

  return <Table table={table} isFetching={false} />
}

export default StudentsAttendanceRequests
