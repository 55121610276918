/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { can } from 'modules/ticket/utils'
import Can from 'components/Can'

export function AsideMenuMain() {

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title="Dashboard"
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>
       {(can.do('show_channels_menu') || can.do('show_complain_categories_menu') || can.do('show_lead_ad_types_menu')) && (
        <AsideMenuItemWithSub
          to='/crafted/general'
          title='General'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
           <Can do="show_channels_menu">
            <AsideMenuItem to='/channels/list' title='Channels' hasBullet />
           </Can>
           <Can do="show_complain_categories_menu">
            <AsideMenuItem to='/complain_categories/list' title='Complain Categories' hasBullet />
           </Can>
           <Can do="show_lead_ad_types_menu">
            <AsideMenuItem to='/lead_ad_types/list' title='Lead Ad Types' hasBullet />
           </Can>
        </AsideMenuItemWithSub>
       )}
      <Can do="show_users_menu">
        <AsideMenuItemWithSub
          to='/crafted/users'
          title='Users'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem to='/users/list' title='Users' hasBullet />
        </AsideMenuItemWithSub>
      </Can>
        <Can do="show_crm_menu">
      <AsideMenuItemWithSub
        to='/crafted/crm'
        title='CRM'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/teams/list' title='Teams' hasBullet />
        <AsideMenuItem to='/leads/list' title='My leads' hasBullet />
        <AsideMenuItem to='/leads/deleted/list' title='Deleted Leads Requests' hasBullet />
        {/*<AsideMenuItem to='/complaints/list' title='Complaints' hasBullet />*/}
        <AsideMenuItem to='/projects/list' title='Projects' hasBullet />
        <AsideMenuItem to='/lead_ads/list' title='Media Buying' hasBullet />
      </AsideMenuItemWithSub>
        </Can>
      {(can.do('show_tickets_menu') || can.do('show_teams_menu') || can.do('show_tickets_per_agent_menu')) && (
        <AsideMenuItemWithSub
          to='/tickets'
          title='Ticket'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen005.svg'
        >
          <Can do="show_tickets_menu">
            <AsideMenuItem to='/tickets/list' title='My Tickets' hasBullet />
          </Can>
          <Can do="show_all_tickets_menu">
              <AsideMenuItem to='/tickets/all' title='All Tickets' hasBullet />
          </Can>
          <Can do="show_teams_menu">
            <AsideMenuItem to='/tickets/teams' title='Teams' hasBullet />
          </Can>
          <Can do="show_tickets_per_agent_menu">
            <AsideMenuItem to='/tickets_per_agent_report' title='Tickets Per Agent Report' hasBullet />
          </Can>
        </AsideMenuItemWithSub>
      )}
      {(
        can.do('show_courses_menu') || 
        can.do('show_groups_menu') || 
        can.do('show_group_statuses_menu') || 
        can.do('show_student_attendances_menu') ||
        can.do('show_students_menu') ||
        can.do('show_groups_next_session_report_menu')
      ) && (
        <AsideMenuItemWithSub
          to='/course'
          title='Course'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen015.svg'
        >
          <AsideMenuItemWithSub
            to='/groups'
            title='Groups'
            hasBullet
          >
            <Can do="show_groups_menu">
              <AsideMenuItem to='/groups/list' title='List' hasBullet />
            </Can>
            <Can do="show_group_statuses_menu">
              <AsideMenuItem to='/group_statuses/list' title='Group Statuses' hasBullet />
            </Can>
            <Can do="show_student_attendances_menu">
              <AsideMenuItem to='/group_attendance/list' title='Group Attendance' hasBullet />
            </Can>
            <Can do="show_groups_next_session_report_menu">
              <AsideMenuItem to='/next_session_report' title='Next Session Report' hasBullet />
            </Can>
            <Can do="approve_student_attendance_change">
              <AsideMenuItem to="/group_attendance_requests_report" title="Attendance Requests Report" hasBullet />
            </Can>
          </AsideMenuItemWithSub>
          <Can do="show_courses_menu">
            <AsideMenuItem to='/courses/list' title='Courses' hasBullet />
          </Can>
          <Can do="show_students_menu">
            <AsideMenuItem to='/students/list' title='Students' hasBullet />
          </Can>
        </AsideMenuItemWithSub>
      )}
        <Can do="show_crm_menu">
      <AsideMenuItemWithSub
          to='/reports'
          title='Reports'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen017.svg'
        >
          <AsideMenuItem to='/leads_per_day' title='Leads Per Day' hasBullet />
          <AsideMenuItem to='/leads_per_agent' title='Leads Per Agent' hasBullet />
          <AsideMenuItem to='/leads_last_action' title='Leads Last Action' hasBullet />
        </AsideMenuItemWithSub>
        </Can>
    </>
  )
}
