import React, { FC } from 'react'
import classnames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

const Breadcrumb: FC = (props: any) => {

  // breadcrumbs
  const route = {
    "/dashboard": { title: "Dashboard", crumbs: [{ type: 'dark', name: 'dashboard' }] },
    "/channels/list": { title: "Channels List", crumbs: [{ type: 'dark', name: 'Channels' }] },
    "/complain_categories/list": { title: "Complain Categories List", crumbs: [{ type: 'dark', name: 'Complain Categories' }] },
    "/lead_ad_types/list": { title: "Lead Ad Types List", crumbs: [{ type: 'dark', name: 'Lead Ad Types' }] },
    "/users/list": {title: "Users List", crumbs: [{ type: 'dark', name: 'Users' }] },
    "/users/create": { title: "Create User", crumbs: [{ type: 'muted', name: 'Users', to: "/users/list" }, { type: 'dark', name: 'Create' }], hasBranch: true },
    "/users/id": { title: "Update User", crumbs: [{ type: 'muted', name: 'Users', to: "/users/list" }, { type: 'dark', name: 'update' }], hasBranch: true },
    "/teams/list": {title: "Teams List", crumbs: [{ type: 'dark', name: 'Teams' }] },
    "/teams/create": { title: "Create Team", crumbs: [{ type: 'muted', name: 'Teams', to: "/teams/list" }, { type: 'dark', name: 'Create' }], hasBranch: true },
    "/teams/id": { title: "Team Details", crumbs: [{ type: 'muted', name: 'Teams', to: "/teams/list" }, { type: 'dark', name: 'Details' }], hasBranch: true },
    "/tickets/teams": { title: "Teams List", crumbs: [{ type: 'dark', name: 'Teams' }] },
    "/tickets/teams/id": { title: "Team Details", crumbs: [{ type: 'muted', name: 'Teams', to: '/tickets/teams' }, { type: 'dark', name: 'Details' }], hasBranch: true },
    "/category/id": { title: "Team Category Actions List", crumbs: [{ type: 'muted', name: 'Teams', to: '/tickets/teams' }, { type: 'muted', name: 'Details', to: props.location.pathname.split('/category')[0] }, { type: 'dark', name: 'Actions' }], hasBranch: true },
    "/tickets/list": { title: "Tickets List", crumbs: [{ type: 'dark', name: 'Tickets' }] },
    "/tickets/create": { title: "Create Ticket", crumbs: [{ type: 'muted', name: 'Tickets', to: '/tickets/list' }, { type: 'dark', name: 'Create' }], hasBranch: true }, 
    "/tickets/id": { title: "View Ticket", crumbs: [{ type: 'muted', name: 'Tickets', to: '/tickets/list' }, { type: 'dark', name: 'Details' }], hasBranch: true},
    "/courses/list": { title: "Courses List", crumbs: [{ type: 'dark', name: 'Courses' }] },
    "/courses/create": { title: "Create Course", crumbs: [{ type: 'muted', name: 'Courses', to: '/courses/list' }, { type: 'dark', name: 'Create' }], hasBranch: true },
    "/courses/id": { title: "Update Course", crumbs: [{ type: 'muted', name: 'Courses', to: '/courses/list' }, { type: 'dark', name: 'Details' }], hasBranch: true},
    "/group_statuses/list": { title: "Group Statuses List", crumbs: [{ type: 'dark', name: 'Group Statuses' }] },
    "/groups/list": { title: "Groups List", crumbs: [{ type: 'dark', name: 'Groups' }] },
    "/groups/create": { title: "Create Group", crumbs: [{ type: 'muted', name: 'Groups', to: '/groups/list' }, { type: 'dark', name: 'Create' }], hasBranch: true },
    "/groups/id": { title: "Group Details", crumbs: [{ type: 'muted', name: 'Groups', to: '/groups/list' }, { type: 'dark', name: 'Details' }], hasBranch: true},
    "/students/list": { title: "Students List", crumbs: [{ type: 'dark', name: 'Students' }] },
    "/student/id": { title: "Student Profile", crumbs: [{ type: 'muted', name: 'Students', to: '/students/list' }, { type: 'dark', name: 'Profile' }], hasBranch: true},
    "/group_attendance/list": { title: "Group Attendance List", crumbs: [{ type: 'dark', name: 'Group Attendance' }] },
    "/next_session_report": { title: "Next Session Report", crumbs: [{ type: 'dark', name: 'Next Session' }] },
    "/tickets_per_agent_report": { title: "Tickets Per Agent Report", crumbs: [{ type: 'dark', name: 'Tickets Per Agent' }] },
  };

  const getRouteTitle = () => {
    if (route[props.location.pathname]) {
      return route[props.location.pathname];
    }

    if (props.location.pathname.includes('/users/')) {
      return route['/users/id'];
    }

    if (props.location.pathname.includes('/teams/')) {
      return route['/teams/id'];
    }

    if (props.location.pathname.includes('/category/')) {
      return route['/category/id'];
    }

    if (props.location.pathname.includes('/tickets/teams/')) {
      return route['/tickets/teams/id'];
    } 

    if (props.location.pathname.includes('/tickets/')) {
      return route['/tickets/id'];
    } 

    if (props.location.pathname.includes('/courses/')) {
      return route['/courses/id'];
    } 

    if (props.location.pathname.includes('/groups/')) {
      return route['/groups/id'];
    } 

    if (props.location.pathname.includes('/student/')) {
      return route['/student/id'];
    } 

    return undefined;
  };

  return (
    <div className="page-title d-flex align-items-center justify-content-center flex-wrap me-lg-2 pb-2 pb-lg-0"> 
      {/* <div className="mx-4">
        {getRouteTitle() && getRouteTitle().hasBranch && (
          <span className="cursor-pointer" onClick={() => props.history.goBack()}>
            <i className="bi bi-arrow-return-left fs-2"></i>
          </span>
        )}
      </div> */}
      <div>
        {/* <h1 className="text-dark fw-bolder my-0 fs-2">{getRouteTitle() && getRouteTitle().title}</h1> */}
        <ul className="breadcrumb fw-bold fs-base my-1">
          <li className="breadcrumb-item text-muted">
            <Link to="/dashboard" className="text-muted">Home</Link>
          </li>
          {getRouteTitle() && getRouteTitle().crumbs.map((route) => (
            <li 
              key={route.name} 
              className={classnames('breadcrumb-item', `text-${route.type}`)}
            >
              {route.type === 'muted' ? <Link to={route.to}>{route.name}</Link> : route.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(Breadcrumb);
