import React from 'react'
import {Notifier} from 'components/Notifier'
import isEmpty from 'lodash/isEmpty'
import {useTable} from 'react-table'

import API from 'config/API'

import UpdateStudentNotes from '../groupAttendance/components/UpdateStudentNotes'
import {attendanceBadges} from 'modules/course/groupStudents/utils'
import {ModalContext} from 'context/ModalContext'

import EmptyList from 'components/EmptyList'
import TooltipTitle from 'components/TooltipTitle'
import Skeleton from 'components/Skeleton'
import Table from 'components/Table'
import Filters from 'components/Filters'
import Pagination from 'components/Pagination'
import {getBranches} from 'modules/branches/action/actionCreators'
import {useDispatch, useSelector} from 'react-redux'
import {getGroups} from '../groups/action/actionCreators'
import Switcher from 'components/Switcher'
import SelectedFilters from 'components/SelectedFilters'

const GroupAttendanceRequestsReport = () => {
  const [attendance, setAttendance] = React.useState({
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      to: 0,
      per_page: 0,
      total: 0,
      last_page: 0,
    },
  })
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [loading, setLoading] = React.useState(false)
  const [refetch, setRefetch] = React.useState(false)
  const [filters, setFilters] = React.useState({
    branch_id: null,
    group_id: null,
  })
  const [selectedBranch, setSelectedBranch] = React.useState(null)
  const [status, setStatus] = React.useState('pending')

  const {showModal, hideModal} = React.useContext(ModalContext)
  const groups = useSelector(({course}) => course.groups)
  const branches = useSelector(({branches}) => branches)
  const {has_branch} = useSelector(({auth}) => auth.client_config)
  const dispatch = useDispatch()

  React.useEffect(() => {
    async function fetchAttendance() {
      try {
        setLoading(true)
        const response = await API.get(
          `/student/attendance_change_requests?include=studentCourse.student,groupTimeline.group,createdBy,updatedBy&paginate=${size}&page=${page}&filter[groupTimeline.group_id]=${
            filters.group_id?.value ?? ''
          }&filter[status]=${status}`
        )
        setAttendance(response.data)
        setLoading(false)
        setRefetch(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchAttendance()
  }, [refetch, filters, size, page, status])

  React.useEffect(() => {
    if (has_branch) {
      dispatch(getBranches(1))
    }
  }, [dispatch, has_branch])

  const setAdminAction = React.useCallback(
    async (attendance, status) => {
      try {
        await API.put(`/student/attendance_change_requests/${attendance.id}`, {status})
        setRefetch(true)
        hideModal()
        Notifier.successMessage('Attendance request saved!')
      } catch (error) {
        setLoading(false)
      }
    },
    [hideModal]
  )

  const updateNotes = React.useCallback(
    (attendanceId, notes = '') => {
      return showModal({
        id: 'add-student-notes-form-modal',
        dialogClassName: 'add-student-notes-form',
        closeButton: true,
        title: 'update Notes',
        body: (
          <UpdateStudentNotes attendanceId={attendanceId} notes={notes} setRefetch={setRefetch} />
        ),
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal, setRefetch]
  )

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.name ?? 'N/A',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.mobile ?? 'N/A',
      },
      {
        Header: 'Group',
        accessor: 'group',
        Cell: ({row: {original: row}}) => row.groupTimeline?.group?.name ?? 'N/A',
      },
      {
        Header: 'Status',
        accessor: 'attendance_status',
        Cell: ({row: {original: row}}) => (
          <span
            className={`fw-bolder badge text-light fs-8 w-auto badge-${
              attendanceBadges[row?.attendance_status]
            }`}
          >
            {row?.attendance_status}
          </span>
        ),
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({row: {original: row}}) => (
          <div className='d-flex align-items-center gap-1'>
            <span className='line-clamp-1'>{row?.notes ?? 'N/A'}</span>
            <TooltipTitle title='Update Notes'>
              <button onClick={() => updateNotes(row.id, row?.notes)} className='rounded-icon'>
                <i className='bi bi-pencil'></i>
              </button>
            </TooltipTitle>
          </div>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({row: {original: row}}) => row.createdBy?.name ?? 'N/A',
      },
      {
        Header: 'Approved by',
        accessor: 'updatedBy',
        Cell: ({row: {original: row}}) => row.updatedBy?.name ?? 'N/A',
      },
      {
        Header: 'Action',
        accessor: 'action',
        className: 'text-right px-5',
      },
    ],
    [updateNotes]
  )

  // table data
  const data = React.useMemo(
    () =>
      attendance?.data.map((dataItem) => ({
        ...dataItem,
        action:
          dataItem.status === 'pending' ? (
            <div className='d-flex align-items-center justify-content-end'>
              <TooltipTitle title='Approve'>
                <button
                  onClick={() => setAdminAction(dataItem, 'approved')}
                  className='rounded-icon bg-success'
                >
                  <i className='bi bi-check-all text-white color-white'></i>
                </button>
              </TooltipTitle>
              <TooltipTitle title='Reject'>
                <button
                  onClick={() => setAdminAction(dataItem, 'rejected')}
                  className='rounded-icon bg-danger'
                >
                  <i className='bi bi-x text-white color-white'></i>
                </button>
              </TooltipTitle>
            </div>
          ) : (
            dataItem.status
          ),
      })),
    [attendance, setAdminAction]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const handleUpdateFilters = (filters) => setFilters(filters)

  const getBranchesOptions = () => {
    return branches && branches.data.map((item) => ({value: item.id, label: item.name}))
  }

  const getGroupsOptions = () => {
    return groups && groups.data.map((item) => ({value: item.id, label: item.name}))
  }

  const getFilters = () => {
    const pageFilters = [
      {
        name: 'group_id',
        type: 'options',
        label: groups.isFetching ? 'Fetching...' : 'Groups',
        disabled: groups.isFetching,
        options: getGroupsOptions(),
      },
    ]

    if (has_branch) {
      pageFilters.unshift({
        name: 'branch_id',
        type: 'options',
        label: 'Branch',
        options: getBranchesOptions(),
        onChange: (option) =>
          dispatch(getGroups(1, 25, '-created_at', '', `filter[branch_id]=${option.value}`)),
      })
    }

    return pageFilters
  }

  // fetching
  if (loading) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start gap-4'>
            <SelectedFilters filters={filters} setFilters={setFilters} withDispatch={false} />
            <div className='d-flex align-items-center gap-3'>
              <div className='d-flex gap-2'>
                Pending
                <Switcher
                  defaultChecked={status === 'approved,rejected' ? true : false}
                  onChange={(value) => setStatus(value ? 'approved,rejected' : 'pending')}
                />
                Approved
              </div>
              <Filters
                filters={getFilters()}
                handleUpdateFilters={handleUpdateFilters}
                initialValues={filters}
              />
            </div>
          </div>
        </div>
      </div>
      {isEmpty(attendance.data) ? (
        <EmptyList message='No Requests Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(attendance.data) && attendance.meta.last_page > 1 && (
        <Pagination
          pageCount={attendance.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={attendance.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default GroupAttendanceRequestsReport
