import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import {getTimeFormat} from 'config/utils'
import API from 'config/API'
import {getGroupDays} from './action/actionCreators'

import {ModalContext} from 'context/ModalContext'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import AddGroupDay from './components/AddGroupDay'
import ConfirmationModal from 'components/ConfirmationModal'
import Can from 'components/Can'
import {useTable} from 'react-table'
import Table from 'components/Table'
import Skeleton from 'components/Skeleton'
import Button from 'components/Button'

const GroupDays = ({group}) => {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const {showModal, hideModal} = useContext(ModalContext)
  const group_days = useSelector(({course}) => course.group_days)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGroupDays(page, size, `filter[group_id]=${group.id}`))
  }, [dispatch, page, size, group.id])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleDelete = React.useCallback(
    async (id) => {
      try {
        await API.delete(`/course/group_days/${id}`)
        dispatch(getGroupDays(page, size, `filter[group_id]=${group.id}`))
        hideModal()
        Notifier.successMessage('Group day has been deleted!')
      } catch (error) {
        // Ignore Error Message
      }
    },
    [dispatch, hideModal, group.id, page, size]
  )

  const deleteDay = React.useCallback(
    (id) => {
      return showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [handleDelete, hideModal, showModal]
  )

  const insertDays = () => {
    return showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: 'Insert Days',
      body: <AddGroupDay groupId={group.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Weekday',
        accessor: 'day',
        Cell: ({row: {original: row}}) => row.day,
      },
      {
        Header: 'From',
        accessor: 'from',
        Cell: ({row: {original: row}}) => getTimeFormat(row.start_time).format('h:mm a'),
      },
      {
        Header: 'To',
        accessor: 'to',
        Cell: ({row: {original: row}}) =>
          moment(getTimeFormat(row.start_time))
            .add(group.hours_per_session, 'hours')
            .format('h:mm a'),
      },
      {
        Header: 'Action',
        accessor: 'action',
        className: 'text-right px-5',
      },
    ],
    [group.hours_per_session]
  )

  // table data
  const data = React.useMemo(
    () =>
      group_days?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex justify-content-end'>
            <Can do='delete_group_day'>
              <div className='rounded-icon' onClick={() => deleteDay(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </Can>
          </div>
        ),
      })),
    [group_days, deleteDay]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (group_days.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Can do='create_group_day'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Insert Days
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={insertDays}
              />
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(group_days.data) ? (
        <EmptyList message='No group days Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(group_days.data) && group_days.meta.last_page > 1 && (
        <Pagination
          pageCount={group_days.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={group_days.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default GroupDays
