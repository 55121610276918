import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux';
import moment from 'moment';

import API from 'config/API';
import { Notifier } from 'components/Notifier';
import { ModalContext } from 'context/ModalContext';
import GroupRoundForm from './GroupRoundForm';
import { getGroupRounds } from '../action/actionCreators.js';

const AddGroupRound = ({ id }) => {
  const { hideModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/course/group_rounds`, { ...values, start_date: moment(values.start_date).format('YYYY-MM-DD'), course_id: id })
      setLoading(false);
      hideModal();
      dispatch(getGroupRounds(1, 20, `filter[course_id]=${id}`));
      Notifier.successMessage('Group round has been created!');
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  }

  return <GroupRoundForm loading={loading} onSubmitHandler={onSubmit} />;
}

export default AddGroupRound;