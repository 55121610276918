import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {getTeams, resetFilters, setFilters} from './action/actionCreators'
import API from 'config/API'
import {Link, useHistory} from 'react-router-dom'
import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import query from 'query-string'
import {useTable} from 'react-table'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import TooltipTitle from 'components/TooltipTitle'
import { Notifier } from 'components/Notifier'
import ConfirmationModal from 'components/ConfirmationModal'
import { ModalContext } from 'context/ModalContext'
import { normalizeFilters } from 'config/utils'
import SelectedFilters from 'components/SelectedFilters'

const Teams = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [rolesList, setRolesList] = React.useState(null)
  const teamsList = useSelector(({teams}) => teams)
  const dispatch = useDispatch()
  const history = useHistory()
  const { showModal, hideModal } = React.useContext(ModalContext);

  React.useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await API.get(`/user/roles`)
        setRolesList(response.data.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchRoles()
    // reset filters
    return () => {
      dispatch(resetFilters())
    }
  }, [dispatch])

  React.useEffect(() => {
    dispatch(
      getTeams(page, size, '-created_by', query.stringify(normalizeFilters(teamsList.filters)))
    )
  }, [dispatch, size, teamsList.filters, page])

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const handleUpdateFilters = (filters) => dispatch(setFilters(filters))

  const handleDeleteTeam = (id) =>
    API.delete(`/team/crm_teams/${id}`)
      .then(() => {
        dispatch(
          getTeams(page, size, '-created_by', query.stringify(normalizeFilters(teamsList.filters)))
        )
        hideModal()
        Notifier.successMessage('channel has been Deleted!')
      })
      .catch(() => {})

  const deleteTeam = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDeleteTeam(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const getOptions = () => {
    return rolesList && rolesList.map((role) => ({value: role.id, label: role.name}))
  }

  const getFilters = () => [
    {name: 'role_id', type: 'options', label: 'Role', options: getOptions()},
    {name: 'name', type: 'text', label: 'Name'},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => <Link to={`/teams/${row.id}`}>{row.name}</Link>,
      },
      {
        Header: 'Role',
        accessor: 'roles',
        Cell: ({row: {original: row}}) => (
          <span className='badge badge-secondary m-1'>{row.role?.name}</span>
        ),
      },
      {
        Header: 'Leader',
        accessor: 'leader',
        Cell: ({row: {original: row}}) => row.leader?.name,
      },
      {
        Header: 'Fresh Assigned',
        accessor: 'fresh_assigned',
      },
      {
        Header: 'Fresh Unassigned',
        accessor: 'fresh_unassigned',
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      teamsList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <TooltipTitle title="Team Details">
              <div className='rounded-icon' onClick={() => history.push(`/teams/${dataItem.id}`)}>
                <i className='bi bi-eye-fill'></i>
              </div>
            </TooltipTitle>
            <TooltipTitle title="Delete Team">
              <div className='rounded-icon' onClick={() => deleteTeam(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </TooltipTitle>

          </div>
        ),
      })),
    [teamsList, history]
  )

  // table setup
  const table = useTable(
    {
      columns: dataColumns,
      data,
    }
  )

  // fetching
  if (teamsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={teamsList.filters} setFilters={setFilters} />
            <div className='d-flex'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Create Team
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={() => history.push('/teams/create')}
              />
              <Filters
                filters={getFilters()}
                handleUpdateFilters={handleUpdateFilters}
                initialValues={teamsList.filters}
              />
            </div>
          </div>
        </div>
      </div>
      {isEmpty(teamsList.data) ? (
        <EmptyList message='No Teams Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(teamsList.data) && teamsList.meta.last_page > 1 && (
        <Pagination
          pageCount={teamsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={teamsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Teams
