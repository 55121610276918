import { SET_FILTERS_STUDENTS, GET_GROUP_STUDENTS_SUCCESS, SET_FETCHING_STUDENTS } from '../action/actionTypes';

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0
  },
  filters: {
    status: { value: 'waiting', label: 'Waiting' },
    course_id: null,
    level: undefined
  },
  isFetching: false
};

function groupStudentsReducer(state = initialState, action) {
  switch(action.type) {
    case SET_FETCHING_STUDENTS:
      return {
        ...state,
        isFetching: action.payload.isFetching
      }
    case GET_GROUP_STUDENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.students.data,
        meta: action.payload.students.meta,
      }
    case SET_FILTERS_STUDENTS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters }
      }
    default: 
      return state;
  }
}

export default groupStudentsReducer;