import { SET_FETCHING_STATUSES, GET_GROUP_STATUSES_SUCCESS } from "../action/actionTypes";

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0
  },
  isFetching: false
};

function groupStatusReducer(state = initialState, action) {
  switch(action.type) {
    case SET_FETCHING_STATUSES:
      return {
        ...state,
        isFetching: action.payload.isFetching
      }
    case GET_GROUP_STATUSES_SUCCESS:
      return {
        ...state,
        data: action.payload.statuses.data,
        meta: action.payload.statuses.meta
      }
    default: 
      return state;
  }
}

export default groupStatusReducer;