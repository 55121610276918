import API from 'config/API';
import moment from 'moment';
import React, {FC, useEffect, useState, useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import DatePicker from "rsuite/DatePicker";
import {can, getPrioritiesOptions, getStatusOptions, isAdmin, isAgent, isTeamLeader} from '../../utils';
import {Notifier} from 'components/Notifier';
import {ModalContext} from 'context/ModalContext';
import {getTeamMembers} from 'modules/ticket/teams/TeamMembers/action/actionCreators';
import {capitalizeFirstLetter} from 'config/utils';
import Can from 'components/Can';

interface TicketFormActionsProps {
    ticketDetails: any;
    modelDetails: any;
    setTicketDetails: (ticketDetails) => void;
}

const TicketFormActions: FC<TicketFormActionsProps> = ({ticketDetails, setTicketDetails, modelDetails}) => {
    const {showModal, hideModal} = useContext(ModalContext);
    const teamMembers = useSelector(({ticket}: any) => ticket.teamMembers);
    const agent = useSelector(({auth}: any) => auth.data);
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const [assignToAgent, setAssignToAgent] = useState(false);
    const {client_config} = useSelector(({auth}: any) => auth);

    useEffect(() => {
        dispatch(getTeamMembers(1, 100, ticketDetails && ticketDetails.team_id));
    }, [ticketDetails])

    const handleUpdateTicket = async (updates: any) => {
        const ticketDet = {...ticketDetails};
        delete ticketDet.attachment;
        try {
            const response = await API.put(`/ticket/tickets/${ticketDetails.id}`,
                {
                    ...ticketDet,
                    ...updates,
                    deadline: updates.deadline ? updates.deadline : moment(ticketDetails.deadline).format('YYYY-MM-DD HH:mm')
                }
            );
            setTicketDetails({...ticketDetails, ...updates, updated_at: response.data.data.updated_at});
            setEdit(false);
            Notifier.successMessage('Ticket has been updated');
        } catch (error) {
            // Ignore Error Message
        }
    }

    const handleAssignToHimSelf = async () => {
        try {
            const response = await API.post(`/ticket/agent_assign_ticket_to_himself`,
                {ticket_id: ticketDetails.id, agent_id: agent.id}
            );
            setTicketDetails({
                ...ticketDetails,
                agent_id: response.data.data.agent_id,
                agent: {id: agent.name, name: agent.name},
                updated_at: response.data.data.updated_at
            });
            Notifier.infoMessage('Ticket has been assigned to you!');
        } catch (error) {
            // Ignore Error Message
        }
    }

    const handleEscalateTicket = async () => {
        try {
            const response = await API.post(`/ticket/escalate_ticket`,
                {ticket_id: ticketDetails.id}
            );
            setTicketDetails({
                ...ticketDetails, status: response.data.data.status,
                agent_id: response.data.data.agent_id,
                agent: {id: ticketDetails.reporter.name, name: ticketDetails.reporter.name}
            });
            Notifier.infoMessage('Ticket has been escalated!');
            hideModal();
        } catch (error) {
            // Ignore Error Message
        }
    }

    const confirmEscalate = () =>
        showModal({
            id: 'escalate-ticket-form-modal',
            dialogClassName: 'escalate-ticket-form',
            closeButton: true,
            title: 'Escalate Ticket',
            body: <p>Are you sure you want to escalate this ticket?</p>,
            actions: {
                accept: {
                    text: 'Escalate',
                    className: 'btn-warning',
                    onClick: handleEscalateTicket
                },
                cancel: {
                    text: 'Cancel',
                    onClick: hideModal
                }
            },
            closeAction: hideModal,
            width: undefined,
            height: 'auto',
        })

    const getAgentsOptions = () => {
        return teamMembers && teamMembers.data.map((teamMember: any) =>
            ({value: teamMember.member_id, label: teamMember.agent.name}));
    }

    const switchAssignToAgent = () => {
        setAssignToAgent(!assignToAgent);
    }

    const renderTicketActions = () => {
        if (isTeamLeader(ticketDetails.reporter_id, agent.id) || can.do('take_action_on_any_ticket')) {
            return (
                <div className="mb-7">
                    <label className="fs-6 fw-bold mb-2 mt-5">Status</label>
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({...provided, zIndex: 9999})
                        }}
                        options={getStatusOptions()}
                        value={{value: ticketDetails.status, label: ticketDetails.status}}
                        name="ticket_action_id"
                        closeMenuOnSelect
                        isDisabled={!edit}
                        onChange={(option: any) => handleUpdateTicket({status: option.value})}
                    />
                    <label className="fs-6 fw-bold mb-2 mt-5">Priority</label>
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({...provided, zIndex: 9999})
                        }}
                        options={getPrioritiesOptions()}
                        value={{value: ticketDetails.priority, label: ticketDetails.priority}}
                        name="ticket_action_id"
                        closeMenuOnSelect
                        isDisabled={!edit}
                        onChange={(option: any) => handleUpdateTicket({priority: option.value})}
                    />
                    <Can do="edit_ticket_deadline">
                        <label className="fs-6 fw-bold mb-2 mt-5">Deadline</label>
                        <DatePicker
                            format="yyyy-MM-dd HH:mm:ss"
                            value={new Date(ticketDetails.deadline)}
                            onChange={(date: Date) => handleUpdateTicket({deadline: moment(date).format('YYYY-MM-DD HH:mm')})}
                            ranges={[
                                {
                                    label: 'Now',
                                    value: new Date()
                                }
                            ]}
                            style={{width: '100%', color: 'black'}}
                            className="border-secondary"
                        />
                    </Can>
                </div>
            )
        } else if (isAgent(ticketDetails.agent_id, agent.id)) {
            return (
                <div className="mb-7">
                    <label className="fs-6 fw-bold mb-2 mt-5">Status</label>
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({...provided, zIndex: 9999})
                        }}
                        options={getStatusOptions()}
                        value={{value: ticketDetails.status, label: ticketDetails.status}}
                        name="ticket_action_id"
                        closeMenuOnSelect
                        isDisabled={!edit}
                        onChange={(option: any) => handleUpdateTicket({status: option.value})}
                    />
                </div>
            )
        } else {
            //
        }
    };

    return (
        <div className="flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
            {modelDetails && (
                <div className="card card-flush p-7 my-7">
                    <div className="d-flex justify-content-between align-items-center mb-11">
                        <h2 className="text-dark fw-bolder">Model Info</h2>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="d-flex flex-column mw-100 w-100">
                            <div className="d-flex flex-column mw-100 w-100">
                                <div className="mb-7">
                                    <div className="d-flex align-items-center mb-3"><span
                                        className="fw-bolder text-gray-800 text-hover-primary me-2">Name:</span><span
                                        className="fw-bold text-gray-600">{modelDetails.name}</span></div>
                                    {(ticketDetails.ticketable_type != "App\\Domain\\Course\\Entities\\Group") && (
                                        <div className="d-flex align-items-center mb-3"><span
                                            className="fw-bolder text-gray-800 text-hover-primary me-2">Mobile:</span><span
                                            className="fw-bold text-gray-600">{modelDetails.mobile}</span></div>
                                    )}
                                    {(ticketDetails.ticketable_type == "App\\Domain\\Crm\\Entities\\Lead") && (
                                        <div className="d-flex align-items-center mb-3"><span
                                            className="fw-bolder text-gray-800 text-hover-primary me-2">Sales:</span><span
                                            className="fw-bold text-gray-600">{modelDetails.last_updated_lead_course.sale ? modelDetails.last_updated_lead_course.sale.name : 'N/A'}</span>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
            }
            <div className="card card-flush p-7 mb-0">
                <div className="d-flex justify-content-between align-items-center mb-11">
                    <h2 className="text-dark fw-bolder">Ticket Actions</h2>
                    {(ticketDetails.agent_id && !isAdmin(ticketDetails, agent.id) || can.do('take_action_on_any_ticket')) && (
                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                               onClick={() => setEdit(!edit)}>
                            <i className="bi bi-pencil-fill fs-7"></i>
                        </label>
                    )}
                </div>
                <div className="d-flex align-items-center mb-10">
                    <div className="d-flex flex-column mw-100 w-100">
                        <div className="mb-7">
                            <div className="d-flex align-items-center mb-3">
                                <span className="fw-bolder text-gray-800 text-hover-primary me-2">Deadline</span>
                                <span
                                    className="fw-bold text-gray-600">{moment(ticketDetails.deadline).format('D MMMM, YYYY h:mm A')}</span>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <span className="fw-bolder text-gray-800 text-hover-primary me-2">Status</span>
                                <span
                                    className="fw-bold text-gray-600">{capitalizeFirstLetter(ticketDetails.status)}</span>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <span className="fw-bolder text-gray-800 text-hover-primary me-2">Priority</span>
                                <span
                                    className="fw-bold text-gray-600">{capitalizeFirstLetter(ticketDetails.priority)}</span>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <span className="fw-bolder text-gray-800 text-hover-primary me-2">Assigned To</span>
                                <span
                                    className="fw-bold text-gray-600">{ticketDetails.agent_id ? ticketDetails.agent?.name : 'Unassigned'}</span>
                            </div>
                        </div>
                        <div className="separator separator-dashed mb-5"></div>
                        {edit && renderTicketActions()}
                        {isTeamLeader(ticketDetails.reporter_id, agent.id) && can.do('assign_ticket_to_agent') && (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={switchAssignToAgent}
                            >
                                Assign to agent
                            </button>
                        )}
                        {assignToAgent && (
                            <React.Fragment>
                                <label className="fs-6 fw-bold mb-2 mt-5">Assign To</label>
                                <Select
                                    styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({...provided, zIndex: 9999})
                                    }}
                                    options={getAgentsOptions()}
                                    value={ticketDetails.agent ? {
                                        value: ticketDetails.agent.id,
                                        label: ticketDetails.agent.name
                                    } : {}}
                                    name="ticket_action_id"
                                    closeMenuOnSelect
                                    className="mt-2"
                                    onChange={(option: any) => handleUpdateTicket({
                                        agent_id: option.value,
                                        agent: {id: option.value, name: option.label}
                                    })}
                                />
                            </React.Fragment>
                        )}
                        {!ticketDetails.agent_id && can.do('assign_ticket_to_self') && (
                            <button
                                type="button"
                                className="btn btn-primary my-7"
                                onClick={handleAssignToHimSelf}
                            >
                                Assign to me
                            </button>
                        )}
                        {!isAdmin(ticketDetails, agent.id) && can.do('escalate_ticket') && (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={confirmEscalate}
                            >
                                Escalate ticket
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="card card-flush p-7 my-7">
                <div className="d-flex justify-content-between align-items-center mb-11">
                    <h2 className="text-dark fw-bolder">Ticket Info</h2>
                </div>
                <div className="d-flex align-items-center mb-0">
                    <div className="d-flex flex-column mw-100 w-100">
                        {client_config.has_branch && (
                            <div className="d-flex align-items-center mb-3">
                                <span className="fw-bolder text-gray-800 text-hover-primary me-2">Branch</span>
                                <span className="badge badge-light-primary">{ticketDetails.branch.name}</span>
                            </div>
                        )}
                        <div className="mb-7">
                            <div className="d-flex align-items-center mb-3">
                                <span className="fw-bolder text-gray-800 text-hover-primary me-2">Updated By</span>
                                <span className="badge badge-light-success">{ticketDetails.updatedBy.name}</span>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <span className="fw-bolder text-gray-800 text-hover-primary me-2">Last Update</span>
                                <span
                                    className="fw-bold text-gray-600">{moment(ticketDetails.updated_at).format('D MMMM, YYYY h:mm A')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketFormActions
