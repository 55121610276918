import { DELETE_TEAM_MEMBER_SUCCESS, GET_TEAM_MEMBERS_SUCCESS, SET_FETCHING_TEAM_MEMBERS } from "./actionTypes"
import API from 'config/API'

export const setFetching = (isFetching) => ({
  type: SET_FETCHING_TEAM_MEMBERS,
  payload: {isFetching},
})

const getTeamMembersSuccess = (teamMembers) => ({
  type: GET_TEAM_MEMBERS_SUCCESS,
  payload: {teamMembers},
})

export const getTeamMembers = (
  page, 
  size,
  team_id
) => async dispatch => {
  try {
    dispatch(setFetching(true));
    const url = `/team/team_members?include=agent&filter[team_id]=${team_id}&page=${page}&paginate=${size}`;
    const response = await API.get(url)
    dispatch(getTeamMembersSuccess({
      data: response.data.data,
      meta: response.data.meta
    }));
    dispatch(setFetching(false));
  } catch(error) {
    dispatch(setFetching(false));
    throw new Error(error.response);
  }
};

export const deleteTeamMemberSuccess = (id) => ({
  type: DELETE_TEAM_MEMBER_SUCCESS,
  payload: {id},
})
