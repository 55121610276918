import React from 'react'
import {getActiveTabComponent, TeamDetailsTabs} from './utils'
import classNames from 'classnames'
import {useHistory, useParams} from 'react-router-dom'

import API from 'config/API'
import InsightCard from 'components/InsightCard'

const TeamDetails = () => {
  const [activeTab, setActiveTab] = React.useState('basic')
  const [team, setTeam] = React.useState(null)
  const {id: teamId} = useParams()
  const history = useHistory()

  React.useEffect(() => {
    // set a clean up flag
    let isSubscribed = true

    async function fetchTeam() {
      try {
        const response = await API.get(`/team/crm_teams/${teamId}`)
        if (isSubscribed) {
          setTeam(response.data.data)
        }
      } catch (error) {
        if (isSubscribed) {
          history.push('/404')
        }
      }
    }

    fetchTeam()

    // cancel subscription to useEffect
    return () => (isSubscribed = false)
  }, [teamId, history])

  const TeamInsights = () => {
    return (
      <div className='d-flex justify-content-center align-items-center gap-10 mb-5 pb-5'>
        <InsightCard className="w-100" title='Leader Target' insight={team.teamTargetAndCommission?.leader_target} />
        <InsightCard className="w-100"
          title='Leader Commission'
          insight={team.teamTargetAndCommission?.leader_commission}
        />
        <InsightCard className="w-100" title='Agent Target' insight={team.teamTargetAndCommission?.agent_target} />
        <InsightCard className="w-100"
          title='Agent Commission'
          insight={team.teamTargetAndCommission?.agent_commission}
        />
      </div>
    )
  }

  return (
    <div className='mt-5 pt-5'>
      {team && <TeamInsights />}
      <div className='details-tabs bg-white w-full rounded shadow-sm'>
        <ul className='d-flex align-items-center justify-content-start px-0 pt-3'>
          {TeamDetailsTabs.map((detailItem) => (
            <li
              key={detailItem.id}
              className={classNames('d-flex py-4 mx-5 cursor-pointer', {
                'border-b-3 border-primary': activeTab === detailItem.id,
              })}
              onClick={() => setActiveTab(detailItem.id)}
            >
              <detailItem.Icon
                className={classNames('w-5 h-5 p-1 rounded', {
                  'bg-black color-primary': activeTab === detailItem.id,
                  'bg-gray-native': activeTab !== detailItem.id,
                })}
              />
              <span
                className={classNames('mx-3', {
                  'font-bold': activeTab === detailItem.id,
                  'font-medium text-gray-solid': activeTab !== detailItem.id,
                })}
              >
                {detailItem.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {getActiveTabComponent(activeTab, team, setActiveTab)}
    </div>
  )
}

export default TeamDetails
