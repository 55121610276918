import {useContext, useState} from 'react'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import API from 'config/API'
import Card from 'components/Card'
import {Notifier} from 'components/Notifier'
import {getGroupTimeline} from '../action/actionCreators'
import DatePicker from 'rsuite/DatePicker'
import * as Yup from 'yup'
import moment from 'moment'
import {ModalContext} from 'context/ModalContext'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'
import ErrorInput from 'components/ErrorInput'

const GenerateTimeline = ({group, page, size}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {hideModal} = useContext(ModalContext)

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        level: Yup.number().required('Level is required').typeError('Level is required'),
        start_date: Yup.string().required('Start date is required'),
      })
    ),
    defaultValues: {
      level: group?.level,
      start_date: new Date(),
    },
  })

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await API.post('/course/generate_group_timelines', {
        ...values,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        group_id: group.id,
      })
      setLoading(false)
      dispatch(getGroupTimeline(page, size, `filter[group_id]=${group.id}`))
      hideModal()
      Notifier.successMessage('Timeline has been generated!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'start_date',
          render: ({field}) => (
            <DatePicker
              oneTap
              disabledDate={(date) =>
                moment(moment(date).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'))
              }
              defaultValue={new Date()}
              style={{width: '100%', color: 'black'}}
              className='border-secondary z-index-2'
              {...field}
            />
          ),
        }}
      />
      {errors.start_date && <ErrorInput message={errors.start_date.message} />}
      <Controller
        {...{
          control,
          name: 'level',
          render: ({field}) => (
            <Input
              type='number'
              placeholder='Level'
              containerClassName='mt-7'
              id='level'
              error={errors.level}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button
          type='submit'
          title={loading ? <Loader /> : 'Generate'}
          className='bg-primary py-5'
        />
      </div>
    </form>
  )
}

export default GenerateTimeline
