import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {useTable} from 'react-table'
import {Link, useHistory} from 'react-router-dom'

import API from 'config/API'
import {normalizeFilters} from 'config/utils'
import {getCourses, deleteCourse} from './action/actionCreators'
import {ModalContext} from 'context/ModalContext'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import ConfirmationModal from 'components/ConfirmationModal'
import Can from 'components/Can'
import TooltipTitle from 'components/TooltipTitle'

const Courses = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const {showModal, hideModal} = React.useContext(ModalContext)
  const coursesList = useSelector(({course}) => course.list)
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    dispatch(getCourses(page, size, '-created_at', normalizeFilters(coursesList.filters)))
  }, [page, size, coursesList.filters, dispatch])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleDelete = React.useCallback(
    (id) => {
      API.delete(`/course/courses/${id}`)
        .then(() => {
          dispatch(deleteCourse(id))
          hideModal()
          Notifier.successMessage('Course has been deleted!')
        })
        .catch((error) => {
          // Ignore Error Message
        })
    },
    [dispatch, hideModal]
  )

  const deleteCourseItem = React.useCallback(
    (id) => {
      showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [handleDelete, hideModal, showModal]
  )

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => <Link to={`/courses/${row.id}`}>{row.name}</Link>,
      },
      {
        Header: 'Website Name',
        accessor: 'website_name',
        Cell: ({row: {original: row}}) => row.website_name?.en ?? null,
      },
      {
        Header: 'Website Description',
        accessor: 'website_desc',
        Cell: ({row: {original: row}}) => row.website_desc?.en ?? null,
      },
      {
        Header: 'Number of levels',
        accessor: 'num_of_levels',
      },
      {
        Header: 'Hours per level',
        accessor: 'hours_per_level',
      },
      {
        Header: 'Type',
        accessor: 'package',
        Cell: ({row: {original: row}}) => row.package?.type ?? null,
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      coursesList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <Can do='show_course'>
              <TooltipTitle title="course details">
                <div className='rounded-icon' onClick={() => history.push(`/courses/${dataItem.id}`)}>
                  <i className='bi bi-eye-fill'></i>
                </div>
              </TooltipTitle>
            </Can>
            <TooltipTitle title="delete course">
              <div className='rounded-icon' onClick={() => deleteCourseItem(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </TooltipTitle>
          </div>
        ),
      })),
    [coursesList, history, deleteCourseItem]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (coursesList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Can do='create_course'>
              <Link to='/courses/create'>
                <Button
                  title={
                    <span className='d-flex align-items-center justify-content-center'>
                      <i
                        className='bi bi-plus-circle mx-2'
                        style={{color: 'white', fontSize: '1.5rem'}}
                      ></i>
                      Add Course
                    </span>
                  }
                  className='mt-0 w-auto mx-4 p-3 bg-primary'
                  onClick={() => history.push('/users/create')}
                />
              </Link>
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(coursesList.data) ? (
        <EmptyList message='No Courses Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(coursesList.data) && coursesList.meta.last_page > 1 && (
        <Pagination
          pageCount={coursesList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={coursesList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Courses
