import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import API from 'config/API'
import * as Yup from 'yup'

import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'

import {ModalContext} from 'context/ModalContext'
import {fetchVisitCallStatuses} from 'config/utils'
import SelectOptions from 'components/SelectOptions'
import {useSelector} from 'react-redux'

const CallForm = ({onRefetch, lead_request_id}) => {
  const [loading, setLoading] = React.useState(false)
  const [visitStatuses, setVisitStatuses] = React.useState(null)
  const {hideModal} = React.useContext(ModalContext)
  const agent_id = useSelector(({auth}) => auth.data.id)

  const fetchListOptions = async () => {
    const [callStatuses] = await Promise.all([fetchVisitCallStatuses('filter[type]=call_status')])
    // set state
    setVisitStatuses(callStatuses.data)
  }

  React.useEffect(() => {
    fetchListOptions()
  }, [])

  const getOptions = (list) => {
    return list && list.map((item) => ({value: item.id, label: item.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        call_status_id: Yup.object().required('Status is required').nullable(),
        type: Yup.object().required('Type is required').nullable(),
        notes: Yup.string().required('Notes are required'),
      })
    ),
    defaultValues: {
      type: null,
      call_status_id: null,
      notes: '',
    },
  })

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await API.post('/crm/calls', {
        ...values,
        type: values.type?.value,
        call_status_id: values.call_status_id?.value,
        lead_request_id,
        agent_id,
      })
      onRefetch()
      setLoading(false)
      hideModal()
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'type',
          render: ({field}) => (
            <SelectOptions
              placeholder='Type'
              containerClassName='mt-7'
              options={[
                {value: 'call', label: 'Call'},
                {value: 'message', label: 'Message'},
              ]}
              id='type'
              {...field}
              error={errors.type}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'call_status_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Status'
              containerClassName='mt-7'
              options={getOptions(visitStatuses)}
              id='call_status_id'
              {...field}
              error={errors.call_status_id}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'notes',
          render: ({field}) => (
            <Input
              isTextArea
              placeholder='Notes'
              containerClassName='mt-7'
              id='notes'
              error={errors.notes}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default CallForm
