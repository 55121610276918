import moment from 'moment'
import React from 'react'
import {useDispatch} from 'react-redux'

const SelectedFilters = ({filters, setFilters, withDispatch = true}) => {
  const dispatch = useDispatch()

  const formatFilterText = (text) => {
    return (
      text
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // replace _ with space
        .replaceAll('_', ' ')
        .replace('id', ' ')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase()
        })
    )
  }

  const onRemoveFilter = (filter) => {
    if (withDispatch) {
      dispatch(
        setFilters({
          ...filters,
          [filter]: null,
        })
      )
    } else {
      setFilters({
        ...filters,
        [filter]: null,
      })
    }
  }

  return (
    <div className='d-flex justify-content-start align-items-center my-3 gap-2 flex-wrap' style={{ maxWidth: 850 }}>
      {Object.keys(filters).map((filter) => {
        if (filters[filter]) {
          return (
            <div
              className='d-flex badge badge-secondary align-items-center gap-2'
              key={filter}
            >
              <span style={{fontWeight: 400}}>{formatFilterText(filter)} =</span>
              <strong>
                {typeof filters[filter] === 'object'
                  ? filters[filter]?.label ??
                    `${moment(filters[filter][0]).format('YYYY-MM-DD')} ~ ${moment(
                      filters[filter][1]
                    ).format('YYYY-MM-DD')}`
                  : filters[filter]}
              </strong>
              <i className='bi bi-x cursor-pointer fs-2' onClick={() => onRemoveFilter(filter)}></i>
            </div>
          )
        } else return null
      })}
    </div>
  )
}

export default SelectedFilters
