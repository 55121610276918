import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import API from 'config/API'
import isEmpty from 'lodash/isEmpty'
import {useTable} from 'react-table'
import query from 'query-string'

// common components
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import SelectedFilters from 'components/SelectedFilters'
import Skeleton from 'components/Skeleton'

// helpers
import {getUsers} from 'modules/users/action/actionCreators'
import {getTeams} from '../teams/teams/action/actionCreators'
import {getBranches} from 'modules/branches/action/actionCreators'
import {getPrioritiesOptions, getStatusOptions} from '../utils'
import {normalizeFilters} from 'config/utils'
import {getTicketActions} from '../teams/TicketActions/action/actionCreators'
import {getTicketCategories} from '../teams/TicketCategories/action/actionCreators'

const TicketsPerAgentReport = () => {
  const [reportFilters, setReportFilters] = useState({})
  const [tickets, setTickets] = useState({
    data: [],
    isFetching: false,
  })
  const dispatch = useDispatch()
  const {teams, categories, actions} = useSelector(({ticket}) => ticket)
  const branches = useSelector(({branches}) => branches)
  const users = useSelector(({users}) => users)
  const {client_config} = useSelector(({auth}) => auth)
  const selectedRange = useSelector(({filters}) => filters)

  useEffect(() => {
    dispatch(getUsers(1, 25, '-created_at', 'filter[role]=Tickets Agent'))
    dispatch(getTeams(1, 100, 'name'))
    if (client_config.has_branch) {
      dispatch(getBranches(1))
    }
  }, [client_config.has_branch, dispatch])

  useEffect(() => {
    async function fetchTicketsReport() {
      try {
        setTickets((prevTickets) => ({...prevTickets, isFetching: true}))
        const response = await API.get(
          `/ticket/tickets_per_agent_report?pagination=false&${query.stringify(
            normalizeFilters(reportFilters)
          )}`
        )
        setTickets({data: response.data.data, isFetching: false})
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchTicketsReport()
  }, [reportFilters, selectedRange])

  const handleUpdateFilters = (updatedFilters) => setReportFilters(updatedFilters)

  const getOptions = (list) => {
    return list && list.map((item) => ({value: item.id, label: item.name || item.action}))
  }

  const getFilters = () => [
    {
      type: 'options',
      name: 'team_id',
      label: 'Team',
      options: getOptions(teams.data),
      onChange: (option) => dispatch(getTicketCategories(1, 100, option.value, '')),
    },
    {
      type: 'options',
      name: 'ticketAction.ticket_category_id',
      label: categories.isFetching ? 'Fetching...' : 'Category',
      options: getOptions(categories.data),
      onChange: (option) => dispatch(getTicketActions(1, 100, option.value, '')),
    },
    {
      type: 'options',
      name: 'ticket_action_id',
      label: actions.isFetching ? 'Fetching...' : 'Action',
      options: getOptions(actions.data),
    },
    {
      type: 'options',
      name: 'branch_id',
      label: 'Branch',
      options: getOptions(branches.data),
      hidden: !client_config.has_branch,
    },
    {
      type: 'options',
      name: 'agent_id',
      label: 'Agent',
      options: getOptions(users.data),
    },
    {
      type: 'options',
      name: 'status',
      label: 'Status',
      options: getStatusOptions(),
    },
    {
      type: 'options',
      name: 'priority',
      label: 'Priority',
      options: getPrioritiesOptions(),
    },
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Agent name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => row.agent?.name ?? 'N/A',
      },
      {
        Header: 'Exceeded deadline',
        accessor: 'exceeded_deadline',
      },
      {
        Header: 'On time',
        accessor: 'on_time',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      tickets?.data.map((dataItem) => ({
        ...dataItem,
      })),
    [tickets]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (tickets.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters
              filters={reportFilters}
              setFilters={setReportFilters}
              withDispatch={false}
            />
            <Filters
              filters={getFilters()}
              handleUpdateFilters={handleUpdateFilters}
              initialValues={tickets.filters}
            />
          </div>
        </div>
      </div>
      {isEmpty(tickets.data) ? (
        <EmptyList message='No Tickets Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
    </React.Fragment>
  )
}

export default TicketsPerAgentReport
