import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {LayoutProvider, LayoutSplashScreen} from './_metronic/layout/core'
import AuthInit from './modules/auth/components/AuthInit'
import {Routes} from './routing/Routes'
import { ToastContainer } from 'react-toastify'
import WithModal from './components/WithModal'

import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <ToastContainer />
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default WithModal(App);
