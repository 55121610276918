import React from 'react'

import Button from 'components/Button'
import CollapseRow from 'components/Collapser'
import {ModalContext} from 'context/ModalContext'
import API from 'config/API'
import CallForm from './CallForm'

const OverviewFollowup = ({lead_request_id, canTakeActions}) => {
  const {showModal, hideModal} = React.useContext(ModalContext)
  const [calls, setCalls] = React.useState(null)

  const fetchCalls = React.useCallback(async () => {
    try {
      setCalls(null)
      const response = await API.get(
        `/crm/calls?include=callStatus&filter[lead_request_id]=${lead_request_id}`
      )
      setCalls(response.data.data)
    } catch (error) {
      // Ignore Error Message
    }
  }, [lead_request_id])

  React.useEffect(() => {
    fetchCalls()
  }, [lead_request_id, fetchCalls])

  const addNewCall = () => {
    return showModal({
      id: 'add-call-form-modal',
      dialogClassName: 'add-call-form',
      closeButton: true,
      title: 'Add Call',
      body: <CallForm onRefetch={fetchCalls} lead_request_id={lead_request_id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  return (
    <div className='card mb-10'>
      <div className='card-body pb-0 card-body pb-0 d-flex align-items-center justify-content-between'>
        <h5 className='card-title m-0 fs-3'>Followup History</h5>
        {canTakeActions && (
          <Button
            title={
              <span className='d-flex align-items-center justify-content-center'>
                <i
                  className='bi bi-plus-circle mx-2'
                  style={{color: 'white', fontSize: '1.5rem'}}
                ></i>
                Add New Call
              </span>
            }
            onClick={addNewCall}
            className='w-auto m-0 bg-primary hover:bg-slate-800'
          />
        )}
      </div>
      <div className='p-5'>
        {!calls && <div className='font-bold text-center mt-3'>Fetching calls...</div>}
        {calls?.map((call) => (
          <CollapseRow
            key={call.id}
            title={call.callStatus.name}
            type={call.type}
            date={`${call.date} ${call.time}`}
            notes={call.notes}
          />
        ))}
      </div>
    </div>
  )
}

export default OverviewFollowup
