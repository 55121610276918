import React, {useEffect, useState} from 'react'
import {Accordion} from 'react-bootstrap'

import API from 'config/API'
import {capitalizeFirstLetter} from 'config/utils'
import {defaultLeadsStats} from 'modules/ticket/utils'
import Card from 'components/Card'
import InsightCard from 'components/InsightCard'

const LeadsStats = ({filters, defaultActiveKey = '1'}) => {
  const [stats, setStats] = useState(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchLeadsStats() {
      try {
        setLoading(true)
        const response = await API.get(`/crm/count_leads_per_stage_reports?${filters}`)
        setStats(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchLeadsStats()
  }, [filters])

  const formatText = (text) => {
    return (
      text
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // replace _ with space
        .replaceAll('_', ' ')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase()
        })
    )
  }

  return (
    <Card classNames='mt-5'>
      <Accordion defaultActiveKey={defaultActiveKey}>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <div className='card-title m-0 d-flex align-items-center'>
              <h3 className='text-gray-900 text-hover-primary fs-3 fw-bolder me-1 mb-0'>
                Monthly Leads
              </h3>
              {loading && (
                <div className='spinner-border text-primary mx-4' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              )}
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className='flex-grow-1'>
              <div className='d-flex flex-column flex-grow-1'>
                <div className='d-flex align-items-center justify-content-center flex-wrap gap-5'>
                  {stats &&
                    defaultLeadsStats.map((stat) => (
                      <InsightCard
                        key={stat}
                        title={formatText(stat)}
                        insight={stats[0][stat]}
                        style={{width: '30%'}}
                      />
                    ))}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Card>
  )
}

export default LeadsStats
