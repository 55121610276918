import {SET_FETCHING_STATUSES, GET_GROUP_STATUSES_SUCCESS} from './actionTypes'
import API from 'config/API'

const getGroupStatusesSuccess = (statuses) => ({
  type: GET_GROUP_STATUSES_SUCCESS,
  payload: {statuses},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_STATUSES,
  payload: {isFetching},
})

export const getGroupStatuses =
  (
    page,
    size, // paginate
    sort
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const url = `/course/group_statuses?include=groups&paginate=${size}&page=${page}&sort=${sort}`
      const response = await API.get(url)
      dispatch(
        getGroupStatusesSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
    } catch (error) {
      dispatch(setFetching(false))
      throw new Error(error.response)
    }
  }
