import React from 'react'
import {ModalContext} from 'context/ModalContext'
import DetailsForm from './DetailsForm'

const OverviewDetails = ({channel, stage, lead_request_id, onRefetch, canTakeActions}) => {
  const {hideModal, showModal} = React.useContext(ModalContext)

  const updateDetails = () => {
    return showModal({
      id: 'update-details-form-modal',
      dialogClassName: 'update-details-form',
      closeButton: true,
      title: 'Update stage',
      body: (
        <DetailsForm
          lead_request_id={lead_request_id}
          onRefetch={onRefetch}
          lead_stage_id={{value: stage.id, label: stage.name}}
        />
      ),
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  return (
    <div className='card mb-10'>
      <div className='card-body pb-0'>
        <h5 className='card-title fs-3'>Details</h5>
      </div>
      <div className='p-5'>
        <div className='d-flex align-items-center justify-content-between m-4 border-bottom pb-2'>
          <span>Stage</span>
          <span>{stage?.name}</span>
          {canTakeActions && (
            stage?.name !== 'Student' ? (
              <div className='rounded-icon' onClick={updateDetails}>
                <i className='bi bi-pencil'></i>
              </div>
            ): <div></div>
          )}
        </div>
        <div className='d-flex align-items-center justify-content-between m-4 border-bottom pb-2'>
          <span>Channel</span>
          <span>{channel?.name}</span>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default OverviewDetails
