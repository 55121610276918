/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { KTSVG } from '_metronic/helpers';
import GroupDays from 'modules/course/groupDay/GroupDays';
import { getGroupDays } from 'modules/course/groupDay/action/actionCreators';
import GroupTimeline from 'modules/course/groupTimeline/GroupTimeline';

import GroupForm from './GroupForm';
import GroupStudents from './GroupStudents';

interface GroupCardProps {
  group: any;
  loading: boolean;
  onSubmitHandler: (values: any, setSubmitting: (bool: boolean) => void, setErrors: any) => void;
}

const btnClassNames = 'nav-link text-active-primary bg-transparent ms-0 me-10 py-5 cursor-pointer';

const GroupCard: React.FC<GroupCardProps> = ({ group, loading, onSubmitHandler }) => {
  const [active, setActive] = useState(1);
  const dispatch = useDispatch();
  const group_days: any = useSelector<any>(({ course }) => course.group_days);

  useEffect(() => {
    dispatch(getGroupDays(1, 20, `filter[group_id]=${group.id}`));
  }, [])

  const activeTab = {
    1: () => <GroupForm loading={loading} group={group} onSubmitHandler={onSubmitHandler} />,
    2: () => <GroupDays group={group} />,
    3: () => <GroupTimeline group={group} />,
    4: () => <GroupStudents group={group} />
  };

  const renderTooltip = (props, message = '') => (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {group.name}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      />
                      {group.instructor?.name}
                    </span>
                    <span
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      />
                      {group.branch.name}
                    </span>
                    <span
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {group.groupStatus.name}
                    </span>
                    <span
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com012.svg'
                        className='svg-icon-4 me-1'
                      />
                      {group.course?.name}
                    </span>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{group.capacity}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Capacity</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{group.hours_per_session}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Hours Per Sessions</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{group.level}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Level</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{group.activeStudentCoursesCount}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Active Students</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className="nav-item mt-2">
                <span className={classnames(btnClassNames, { active: active === 1 })}
                  onClick={() => setActive(1)}
                >
                  Group Details
                </span>
              </li>
              <li className="nav-item mt-2">
                <span className={classnames(btnClassNames, { active: active === 2 })}
                  onClick={() => setActive(2)}
                >
                  Group Days
                </span>
              </li>
              <li className="nav-item mt-2">
                {group_days.data && (
                  group_days.data.length ? (
                    <button 
                      className={classnames(btnClassNames, { active: active === 3 })}
                      onClick={() => setActive(3)}
                    >
                      Group Timeline
                    </button>
                  ) : (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, 'must have group days to unlock timeline')}
                  > 
                    <button 
                      className={classnames(btnClassNames, { active: active === 3 })}
                      onClick={() => {}}
                    >
                      Group Timeline
                    </button>
                  </OverlayTrigger>
                  )
                )}
              </li>
              <li className="nav-item mt-2">
                <span className={classnames(btnClassNames, { active: active === 4 })}
                  onClick={() => setActive(4)}
                >
                  Group Students
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {activeTab[active]()}
    </>
  )
}

export default GroupCard;
