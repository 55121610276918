import {
  GET_AGENTS_SUCCESS,
  RESET_FILTERS,
  SET_FETCHING_AGENTS,
  SET_FILTERS,
} from './actionTypes'
import API from 'config/API'

const getAgentsSuccess = (agents) => ({
  type: GET_AGENTS_SUCCESS,
  payload: {agents},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_AGENTS,
  payload: {isFetching},
})

export const getAgents =
  (
    page,
    size, // paginate
    sort,
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/team/team_members?include=crmTeam,agent&paginate=${size}&sort=${sort}&page=${page}&${filters}`
      )
      dispatch(
        getAgentsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      throw new Error(error.message)
    }
  }

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: {filters},
})

export const resetFilters = () => ({
  type: RESET_FILTERS,
  payload: {},
})
