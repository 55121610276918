import { SET_FETCHING_GROUP_DAYS, GET_GROUP_DAYS_SUCCESS } from "../action/actionTypes";

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0
  },
  isFetching: false
};

function groupDaysReducer(state = initialState, action) {
  switch(action.type) {
    case SET_FETCHING_GROUP_DAYS: 
      return {
        ...state,
        isFetching: action.payload.isFetching
      }
    case GET_GROUP_DAYS_SUCCESS:
      return {
        ...state,
        data: action.payload.days.data,
        meta: action.payload.days.meta
      }
    default: 
      return state;
  }
}

export default groupDaysReducer;