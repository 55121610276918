import { DELETE_TEAM_MEMBER_SUCCESS, GET_TEAM_MEMBERS_SUCCESS, SET_FETCHING_TEAM_MEMBERS } from "../action/actionTypes"

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function TeamMembersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_TEAM_MEMBERS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        data: action.payload.teamMembers.data,
        meta: action.payload.teamMembers.meta,
      }
    case DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        data: state.data.filter((member) => member.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default TeamMembersReducer
