import React from 'react'
import moment from 'moment'
import {useClickOutside} from 'hooks/useClickOutside'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import API from 'config/API'
import classNames from 'classnames'

import Skeleton from 'components/Skeleton'
import TooltipTitle from 'components/TooltipTitle'
import EmptyList from 'components/EmptyList'
import DatePicker from 'rsuite/esm/DatePicker'
import {ModalContext} from 'context/ModalContext'
import ConfirmationModal from 'components/ConfirmationModal'
import {Notifier} from 'components/Notifier'

const DailyTodos = () => {
  const [showTodos, setShowTodos] = React.useState(false)
  const [todos, setTodos] = React.useState(null)
  const [dateFilter, setDateFilter] = React.useState(new Date())
  const todosRef = React.createRef(null)
  const agent_id = useSelector(({auth}) => auth.data.id)
  const history = useHistory()
  const {hideModal, showModal} = React.useContext(ModalContext)

  useClickOutside(todosRef, () => setShowTodos(!showTodos))

  const fetchTodos = React.useCallback(async () => {
    try {
      setTodos(null)
      const response = await API.get(
        `/crm/todos?include=leadRequest.lead,leadRequest.leadStage&sort=time&filter[agent_id]=${agent_id}&filter[done]=0&filter[date]=${moment(
          dateFilter
        ).format('YYYY-MM-DD')}`
      )
      setTodos(response.data.data)
    } catch (error) {
      // Ignore Error Message
    }
  }, [agent_id, dateFilter])

  React.useEffect(() => {
    fetchTodos()
  }, [fetchTodos])

  const appendTodos = () => {
    setShowTodos(!showTodos)
    !showTodos && fetchTodos()
  }

  const openLeadRequest = (id) => {
    history.push(`/leads/${id}`)
    setShowTodos(false)
  }

  const handleDone = (todo) => {
    return API.put(`/crm/todos/${todo.id}`, {
      ...todo,
      time: todo.time.split(':').splice(0, 2).join(':'),
      done: 1,
    })
      .then(() => {
        hideModal()
        Notifier.infoComponent('Changes Applied', 'Changes applied successfully')
      })
      .catch((error) => {
        // Ignore Error Message
      })
  }

  const onDone = (todo) => {
    return showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: (
        <ConfirmationModal
          title='Are you sure you want to Done this todo?'
          subText=''
          handleAccept={() => handleDone(todo)}
          actionButtonColor='#FDD736'
        />
      ),
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  return (
    <div className='position-relative mx-8'>
      <div className='position-relative'>
        <TooltipTitle title='Todos'>
          <div
            className={classNames('rounded-icon rounded-icon--bigger', {
              'bg-gray-native color-white': showTodos,
            })}
            onClick={appendTodos}
          >
            <i className='bi bi-list-check fs-1'></i>
          </div>
        </TooltipTitle>
        <span className='badge badge-warning position-absolute top-0 right-m-15'>
          {todos && todos.length}
        </span>
      </div>
      {showTodos && (
        <div
          // ref={todosRef}
          className='position-absolute top-10 right-0 bg-white rounded shadow-sm w-300px w-md-325px z-index-1 mt-2 max-h-500 p-5'
        >
          <div className='d-flex justify-content-between align-items-center'>
            <h1 className='fs-3 m-0'>Todos</h1>
            <DatePicker
              format='dd MMM yyyy'
              block
              placement='auto'
              placeholder='Date'
              value={dateFilter}
              onChange={(date) => setDateFilter(date)}
            />
          </div>
          <div className='mt-10'>
            {todos === null && <Skeleton type='notifications' />}
            {todos && todos.length === 0 && (
              <EmptyList
                Icon={
                  <i
                    className='bi bi-list-check'
                    style={{fontSize: '4rem', margin: '50px 0 10px 0'}}
                  ></i>
                }
                message='No todos available'
              />
            )}
            {todos?.map((todo) => (
              <div
                key={todo.id}
                className='border rounded p-5 mb-2 bg-eee cursor-pointer'
                onClick={() => openLeadRequest(todo.lead_request_id)}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <h1 className='fs-6 m-0 mb-0'>{todo.leadRequest.lead.name}</h1>
                    <p className='color-gray mb-5'>{todo.due}</p>
                    <div className='d-flex align-items-center gap-3'>
                      <span className='badge badge-light-primary'>{todo.todo_type}</span>
                      <span className='text-ellipsis'>{todo.notes}</span>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex mb-8'>
                      <TooltipTitle title='Done'>
                        <span className='rounded-icon bg-white' onClick={() => onDone(todo)}>
                          <i className='bi bi-check2-all'></i>
                        </span>
                      </TooltipTitle>
                      <TooltipTitle title='View'>
                        <span
                          className='rounded-icon bg-white'
                          onClick={() => openLeadRequest(todo.lead_request_id)}
                        >
                          <i className='bi bi-eye-fill'></i>
                        </span>
                      </TooltipTitle>
                    </div>
                    <span className='fw-bolder'>{todo.leadRequest.leadStage.name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default DailyTodos
