import React, {useContext} from 'react'
import {ModalContext} from 'context/ModalContext'
import {Controller, useForm} from 'react-hook-form'
import API from 'config/API'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Input from 'components/Input'
import {Notifier} from 'components/Notifier'

const UpdateStudentNotes = ({studentId, attendanceId, notes, setRefetch}) => {
  const [loading, setLoading] = React.useState(false);
  const {hideModal} = useContext(ModalContext)

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      notes,
    },
  })

  const updateStudentRequestNotes = async (values) => {
    try {
      setLoading(true);
      await API.put(`/student/attendance_change_requests/${attendanceId}`, values)
      setRefetch(true)
      hideModal()
      Notifier.successMessage('Notes saved!')
      setLoading(false);
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  }


  const updateStudentActionNotes = async (values) => {
    try {
      setLoading(true);
      await API.put(`/student/student_attendances/${studentId}`, values)
      setRefetch(true)
      hideModal()
      Notifier.successMessage('Notes saved!')
      setLoading(false);
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit(attendanceId ? updateStudentRequestNotes : updateStudentActionNotes)} noValidate>
      <Controller
        {...{
          control,
          name: 'notes',
          render: ({field}) => (
            <Input
              placeholder='Notes'
              containerClassName='mt-7'
              id='notes'
              error={errors.notes}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default UpdateStudentNotes
