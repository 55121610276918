import API from 'config/API'
import { SET_FILTERS_STUDENTS, GET_GROUP_STUDENTS_SUCCESS, SET_FETCHING_STUDENTS } from './actionTypes';

const setFetching = (
  isFetching
) => ({
  type: SET_FETCHING_STUDENTS,
  payload: { isFetching }
})

const getGroupStudentsSuccess = (
  students
) => ({
  type: GET_GROUP_STUDENTS_SUCCESS,
  payload: { students }
});

export const getGroupStudents = (
  page,
  size, // paginate
  include,
  filter
) => async (dispatch) =>  {
  try {
    dispatch(setFetching(true))
    const url = `/student/student_courses?${include}&page=${page}&paginate=${size}&${filter}`;
    const response = await API.get(url);
    dispatch(
      getGroupStudentsSuccess({
        data: response.data.data,
        meta: response.data.meta
      })
    )
    dispatch(setFetching(false))
  } catch (error) {
    // Ignore Error Message
  }
};

export const setGroupStudentsFilters = (
  filters
) => ({
  type: SET_FILTERS_STUDENTS,
  payload: { filters }
}) 
