/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import moment from 'moment'
import {useDispatch} from 'react-redux'

import API from 'config/API'
import {ModalContext} from 'context/ModalContext'
import {Notifier} from 'components/Notifier'
import {getGroupRounds} from '../action/actionCreators'
import GroupRoundForm from './GroupRoundForm'

const UpdateGroupRound = ({round, id}) => {
  const {hideModal} = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.put(`/course/group_rounds/${round.id}`, {
        ...values,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        course_id: id,
      })
      setLoading(false)
      hideModal()
      dispatch(getGroupRounds(1, 20, `filter[course_id]=${id}`))
      Notifier.successMessage('Group round has been saved!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <GroupRoundForm onSubmitHandler={onSubmit} loading={loading} round={round} />
}

export default UpdateGroupRound
