import { OverlayTrigger, Tooltip } from "react-bootstrap";


function TooltipTitle({ title, children }) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-2">{title}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div
          {...triggerHandler}
          ref={ref}
        >
          {children}
        </div>
      )}
    </OverlayTrigger>
  );
}

export default TooltipTitle;