import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import store from './store/configureStore'
import {Chart, registerables} from 'chart.js'

import App from './App'

import 'rsuite/dist/rsuite.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import 'react-phone-number-input/style.css';

Chart.register(...registerables)

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
)
