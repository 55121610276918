import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import query from 'query-string'
import isEmpty from 'lodash/isEmpty'

import {normalizeFilters} from 'config/utils'
import {getPublicTickets} from '../action/actionCreators'
import TicketItem from './TicketItem'

import EmptyList from 'components/EmptyList'
import Pagination from 'components/Pagination'
import ScrollWrapper from 'components/ScrollWrapper'

const PublicTickets = ({team_id}) => {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const dispatch = useDispatch()
  const tickets = useSelector(({ticket}) => ticket.list.public_tickets)
  const selectedRange = useSelector(({filters}) => filters)

  useEffect(() => {
    if (page !== 1 || size !== 10) {
      dispatch(
        getPublicTickets(
          page,
          size,
          query.stringify(
            normalizeFilters({
              team_id,
              date_from: selectedRange.startDate,
              date_to: selectedRange.endDate,
            })
          )
        )
      )
    }
  }, [dispatch, page, selectedRange.endDate, selectedRange.startDate, size, team_id])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  return (
    <div className='my-7'>
      <ScrollWrapper>
        <div className='row g-6 g-xl-9'>
          {tickets.data &&
            tickets.data.length &&
            tickets.data.map((ticket) => (
              <div className='col-4' key={ticket.id}>
                <TicketItem ticket={ticket} />
              </div>
            ))}
          {tickets.data && !tickets.data.length && <EmptyList />}
        </div>
      </ScrollWrapper>
      {!isEmpty(tickets.data) && tickets.meta.last_page > 1 && (
        <Pagination
          pageCount={tickets.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={tickets.meta}
          currentPage={page - 1}
        />
      )}
    </div>
  )
}

export default PublicTickets
