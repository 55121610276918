import React, { useEffect, useState } from 'react'
import moment from 'moment';
import Loader from '../../../../components/Loader';
import { can, getTicketModelName } from '../../utils';
import Comments from './Comments';
import FileCard from 'components/FileCard';
import TicketFormActions from './TicketFormActions';
import API from 'config/API';
import { withRouter } from 'react-router-dom';

function ViewTicket(props: any) {
  const [ticketDetails, setTicketDetails] = useState<any>(null);
  const [modelDetails, setModelDetails] = useState<any>(null);

  useEffect(() => {
    async function fetchTicketDetails() {
      try {
        const response = await API.get(`/ticket/tickets/${props.match.params.id}`);
        setTicketDetails(response.data.data);

        if (response.data.data.ticketable_type == "App\\Domain\\Student\\Entities\\Student"){
          fetch(`https://hrm.rsh.im/api/get-student/${response.data.data.ticketable_id}`).then((response) => response.json())
              .then((data) => {
                setModelDetails(data);
              });
        } else if (response.data.data.ticketable_type == "App\\Domain\\Crm\\Entities\\Lead"){
          fetch(`https://hrm.rsh.im/api/get-lead/${response.data.data.ticketable_id}`).then((response) => response.json())
              .then((data) => {
                setModelDetails(data);
              });
        }
        else if (response.data.data.ticketable_type == "App\\Domain\\Course\\Entities\\Group"){
          fetch(`https://hrm.rsh.im/api/get-group/${response.data.data.ticketable_id}`).then((response) => response.json())
              .then((data) => {
                setModelDetails(data);
              });
        }
      } catch (error) {
        props.history.push('/404');
      }
    }

    fetchTicketDetails();
  }, []);

  if (!can.do('show_ticket')) {
    props.history.push('/404');
    return null;
  }

  return (
    <div className="card bg-transparent">
      {!ticketDetails && <Loader />}
      {ticketDetails && (
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
            <div className="card card-flush p-7 mb-5 mb-lg-10">
              <div className="mb-0">
                <div className="d-flex align-items-center mb-12">
                  <span className="svg-icon svg-icon-4qx svg-icon-success ms-n2 me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16.7 12.7C17.1 12.3 17.1 11.7 16.7 11.3C16.3 10.9 15.7 10.9 15.3 11.3L11 15.6L8.70001 13.3C8.30001 12.9 7.69999 12.9 7.29999 13.3C6.89999 13.7 6.89999 14.3 7.29999 14.7L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z" fill="black"></path>
                      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                    </svg>
                  </span>
                  <div className="d-flex flex-column">
                    <h1 className="text-gray-800 fw-bold">{ticketDetails.ticketAction.action} - {getTicketModelName(ticketDetails.ticketAction.model_type)}: {ticketDetails.ticketable_id}</h1>
                    <div className="">
                      <span className="fw-bold text-muted me-6">Team:
                      <a href="#" className="text-muted text-hover-primary"> {ticketDetails.ticketTeam.name}</a></span>
                      <span className="fw-bold text-muted me-6">By:
                      <a href="#" className="text-muted text-hover-primary"> {ticketDetails.createdBy.name}</a></span>
                      <span className="fw-bold text-muted me-6">Created:
                    ({moment(ticketDetails.created_at).format('D MMMM, YYYY h:mm A')})</span>
                    </div>
                  </div>
                  {!!ticketDetails.exceeded_deadline && <span className="badge bg-danger px-4 py-3 my-2">Exceeded deadline</span>}
                </div>
                <div className="mb-15">
                  <div className="mb-15 fs-5 fw-normal text-gray-800">
                    <div className="mx-18">{ticketDetails.description}</div>
                  </div>
                </div>
              </div>
            </div>
            {
              ticketDetails.attachment && (
                <div className="card card-flush p-7 mb-5 mb-lg-10">
                  <div className="fs-5 fw-normal text-gray-800">
                    <FileCard attachment={ticketDetails.attachment} />
                  </div>
                </div>
              )
            }
            <div className="card card-flush p-7">
              {can.do('show_ticket_comment') && (
                <Comments 
                  comments={ticketDetails.ticketComments} 
                  reporter_id={ticketDetails.reporter_id} 
                  agent_id={ticketDetails.agent_id} 
                  created_by={ticketDetails.created_by} 
                />
              )}
            </div>
          </div>
          <TicketFormActions modelDetails={modelDetails} ticketDetails={ticketDetails} setTicketDetails={setTicketDetails} />
        </div>
      )}
    </div>
  );
}

export default withRouter(ViewTicket);
