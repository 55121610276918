import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {yupResolver} from '@hookform/resolvers/yup'
import {useHistory} from 'react-router-dom'

import {can} from 'modules/ticket/utils'
import {courseValidationRules} from '../yup/courseValidationRules'
import Can from 'components/Can'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Input from 'components/Input'
import SelectOptions from 'components/SelectOptions'
import Switcher from 'components/Switcher'
import FileUpload from 'components/FileUpload'

const CourseForm = ({loading, course, onSubmitHandler}) => {
  const [image, setImage] = React.useState('')
  const history = useHistory()
  const {client_config} = useSelector(({auth}) => auth)

  // cancel submit
  const cancelSubmit = () => history.push('/courses/list')

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
    getValues,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(courseValidationRules),
    defaultValues: {
      name: course ? course.name : '',
      website_name: course ? course.website_name : {en: '', ar: ''},
      website_desc: course ? course.website_desc : {en: '', ar: ''},
      num_of_levels: course ? course.num_of_levels : '',
      hours_per_level: course ? course.hours_per_level : '',
      image: course ? course.image : undefined,
      is_refundable: course ? course.is_refundable : '0',
      type: course ? course.type : undefined,
    },
  })

  console.log(image)

  const onSubmit = (values) => onSubmitHandler({values, image: image.base64}, setError)

  if (course) {
    return (
      <Can do='show_course'>
        <div className='mt-5 pt-5'>
          <form
            className='form w-100 m-auto my-5 pb-5'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className='d-flex'>
              <div className='card d-block flex-2 mx-5' style={{minWidth: '35%'}}>
                <div className='card-body pt-5'>
                  <FileUpload image={image} setImage={setImage} />
                  <div className='mt-7'>
                    <h2>Website Name</h2>
                    <div style={{marginLeft: 30}}>
                      <Controller
                        {...{
                          control,
                          name: 'website_name',
                          render: ({field}) => (
                            <Input
                              placeholder='En'
                              containerClassName='mt-7'
                              id='website_name'
                              error={errors.website_name}
                              {...field}
                              value={getValues('website_name').en}
                              onChange={(event) =>
                                setValue('website_name', {
                                  ...getValues('website_name'),
                                  en: event.target.value,
                                })
                              }
                            />
                          ),
                        }}
                      />
                      <Controller
                        {...{
                          control,
                          name: 'website_name',
                          render: ({field}) => (
                            <Input
                              placeholder='Ar'
                              containerClassName='mt-7'
                              id='website_name'
                              error={errors.website_name}
                              {...field}
                              value={getValues('website_name').ar}
                              onChange={(event) =>
                                setValue('website_name', {
                                  ...getValues('website_name'),
                                  ar: event.target.value,
                                })
                              }
                            />
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className='mt-7'>
                    <h2>Website Description</h2>
                    <div style={{marginLeft: 30}}>
                      <Controller
                        {...{
                          control,
                          name: 'website_desc',
                          render: ({field}) => (
                            <Input
                              placeholder='En'
                              containerClassName='ml-5 mt-7'
                              id='website_desc'
                              error={errors.website_desc}
                              {...field}
                              value={getValues('website_desc').en}
                              onChange={(event) =>
                                setValue('website_desc', {
                                  ...getValues('website_desc'),
                                  en: event.target.value,
                                })
                              }
                            />
                          ),
                        }}
                      />
                      <Controller
                        {...{
                          control,
                          name: 'website_desc',
                          render: ({field}) => (
                            <Input
                              placeholder='Ar'
                              containerClassName='ml-5 mt-7'
                              id='website_desc'
                              error={errors.website_desc_ar}
                              {...field}
                              value={getValues('website_desc').ar}
                              onChange={(event) =>
                                setValue('website_desc', {
                                  ...getValues('website_desc'),
                                  ar: event.target.value,
                                })
                              }
                            />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='card d-block flex-1'>
                <div className='card-body pt-5 mt-5'>
                  <Controller
                    {...{
                      control,
                      name: 'name',
                      render: ({field}) => (
                        <Input
                          placeholder='Course Name'
                          containerClassName='mt-7'
                          id='name'
                          error={errors.name}
                          {...field}
                        />
                      ),
                    }}
                  />

                  <Controller
                    {...{
                      control,
                      name: 'num_of_levels',
                      render: ({field}) => (
                        <Input
                          type='number'
                          placeholder='Number Of Levels'
                          containerClassName='mt-7'
                          id='num_of_levels'
                          error={errors.num_of_levels}
                          {...field}
                        />
                      ),
                    }}
                  />
                  <Controller
                    {...{
                      control,
                      name: 'hours_per_level',
                      render: ({field}) => (
                        <Input
                          type='number'
                          placeholder='Hours Per Level'
                          containerClassName='mt-7'
                          id='hours_per_level'
                          error={errors.hours_per_level}
                          {...field}
                        />
                      ),
                    }}
                  />
                  {!client_config.has_package && (
                    <Controller
                      {...{
                        control,
                        name: 'type',
                        render: ({field}) => (
                          <SelectOptions
                            placeholder='Type'
                            containerClassName='mt-7'
                            options={[
                              {value: 'recorded', label: 'Recorded'},
                              {value: 'online', label: 'Online'},
                              {value: 'offline', label: 'Offline'},
                            ]}
                            id='type'
                            {...field}
                            error={errors.type}
                          />
                        ),
                      }}
                    />
                  )}
                  <Controller
                    {...{
                      control,
                      name: 'is_refundable',
                      render: ({field}) => (
                        <div className='d-flex gap-2 mt-7'>
                          Is Refundable
                          <Switcher numeric offColor='#ddd' id='is_refundable' {...field} />
                        </div>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            {(can.do('create_course') || can.do('update_course')) && (
              <div className='d-flex align-items-start justify-content-center gap-5 p-5 m-5'>
                <Button
                  title='Cancel'
                  className='btn btn-light btn-active-light-primary py-5 mt-0 bg-white'
                  onClick={cancelSubmit}
                />
                <Button
                  type='submit'
                  title={loading ? <Loader /> : 'Save'}
                  className='bg-primary py-5 mt-0'
                />
              </div>
            )}
          </form>
        </div>
      </Can>
    )
  }

  return (
    <Can do='show_course'>
      <div className='mt-5 pt-5'>
        <div className='card'>
          <div className='card-body pt-5 mt-5'>
            <form
              className='form w-100 m-auto max-w-80 my-5 pb-5'
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className='text-center my-5'>
                <h1>Add Course</h1>
              </div>
              <Controller
                {...{
                  control,
                  name: 'name',
                  render: ({field}) => (
                    <Input
                      placeholder='Course Name'
                      containerClassName='mt-7'
                      id='name'
                      error={errors.name}
                      {...field}
                    />
                  ),
                }}
              />
              <Controller
                {...{
                  control,
                  name: 'num_of_levels',
                  render: ({field}) => (
                    <Input
                      type='number'
                      placeholder='Number Of Levels'
                      containerClassName='mt-7'
                      id='num_of_levels'
                      error={errors.num_of_levels}
                      {...field}
                    />
                  ),
                }}
              />
              <Controller
                {...{
                  control,
                  name: 'hours_per_level',
                  render: ({field}) => (
                    <Input
                      type='number'
                      placeholder='Hours Per Level'
                      containerClassName='mt-7'
                      id='hours_per_level'
                      error={errors.hours_per_level}
                      {...field}
                    />
                  ),
                }}
              />
              {!client_config.has_package && (
                <Controller
                  {...{
                    control,
                    name: 'type',
                    render: ({field}) => (
                      <SelectOptions
                        placeholder='Type'
                        containerClassName='mt-7'
                        options={[
                          {value: 'recorded', label: 'Recorded'},
                          {value: 'online', label: 'Online'},
                          {value: 'offline', label: 'Offline'},
                        ]}
                        id='type'
                        {...field}
                        error={errors.type}
                      />
                    ),
                  }}
                />
              )}
              <Controller
                {...{
                  control,
                  name: 'is_refundable',
                  render: ({field}) => (
                    <div className='d-flex gap-2 mt-7'>
                      Is Refundable
                      <Switcher numeric offColor='#ddd' id='is_refundable' {...field} />
                    </div>
                  ),
                }}
              />
              {(can.do('create_course') || can.do('update_course')) && (
                <div className='d-flex align-items-start justify-content-center gap-5'>
                  <Button
                    title='Cancel'
                    className='btn btn-light btn-active-light-primary py-5'
                    onClick={cancelSubmit}
                  />
                  <Button
                    type='submit'
                    title={loading ? <Loader /> : 'Save'}
                    className='bg-primary py-5'
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Can>
  )
}

export default CourseForm
