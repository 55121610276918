
import React from 'react'
import { useDispatch } from 'react-redux';

import API from 'config/API';

import { Notifier } from 'components/Notifier';
import { ModalContext } from 'context/ModalContext';
import { getGroupStatuses } from '../action/actionCreators';
import GroupStatusForm from './GroupStatusForm';

const UpdateGroupStatus = ({ name, id, page, size }) => {
  const [loading, setLoading] = React.useState(false);
  const { hideModal } = React.useContext(ModalContext);
  const dispatch = useDispatch();

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.put(`/course/group_statuses/${id}`, { name: values.name })
      setLoading(false)
      hideModal();
      dispatch(getGroupStatuses(page, size, '-created_at'));
      Notifier.successMessage('Group status has been saved!');
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  } 

  return <GroupStatusForm onSubmitHandler={onSubmit} name={name} loading={loading} />
}

export default UpdateGroupStatus;
