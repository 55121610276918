import React from 'react'
import {getActiveTabComponent, courseDetailsTabs} from './utils'
import {useHistory, useParams} from 'react-router-dom'
import classNames from 'classnames'

import API from 'config/API'

const CourseDetails = () => {
  const [activeTab, setActiveTab] = React.useState('basic')
  const [course, setCourse] = React.useState(null)
  const {id: courseId} = useParams()
  const history = useHistory()

  React.useEffect(() => {
    // set a clean up flag
    let isSubscribed = true

    async function fetchCourse() {
      try {
        const response = await API.get(`/course/courses/${courseId}`)
        if (isSubscribed) {
          setCourse(response.data.data)
        }
      } catch (error) {
        if (isSubscribed) {
          history.push('/404')
        }
      }
    }

    fetchCourse()

    // cancel subscription to useEffect
    return () => (isSubscribed = false)
  }, [courseId, history])

  return (
    <div className='mt-5 pt-5'>
      <div className='details-tabs bg-white w-full rounded shadow-sm'>
        <ul className='d-flex align-items-center justify-content-start px-0 pt-3'>
          {courseDetailsTabs.map((detailItem) => (
            <li
              key={detailItem.id}
              className={classNames('d-flex py-4 mx-5 cursor-pointer', {
                'border-b-3 border-primary': activeTab === detailItem.id,
              })}
              onClick={() => setActiveTab(detailItem.id)}
            >
              <span
                className={classNames('mx-3', {
                  'font-bold': activeTab === detailItem.id,
                  'font-medium text-gray-solid': activeTab !== detailItem.id,
                })}
              >
                {detailItem.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {getActiveTabComponent(activeTab, course)}
    </div>
  )
}

export default CourseDetails
