import React from 'react'
import {useHistory, withRouter} from 'react-router-dom'
import API from 'config/API'
import {ModalContext} from 'context/ModalContext'

// components
import LeadInfo from './components/LeadInfo'
import LeadCourses from './components/LeadCourses'
import OverviewFollowup from './components/OverviewFollowup'
import OverviewDetails from './components/OverviewDetails'
import OverviewTodoList from './components/OverviewTodoList'
import Skeleton from 'components/Skeleton'
import Button from 'components/Button'
import LeadCourseForm from './components/LeadCourseForm'

const LeadDetails = (props) => {
  const [leadDetails, setLeadDetails] = React.useState(null)
  const [leadRequestId, setLeadRequestId] = React.useState(props.match.params.id)
  const {hideModal, showModal} = React.useContext(ModalContext)
  const history = useHistory()

  const canTakeActions = props.location?.canTakeActions ?? true

  // fetch lead by id
  const fetchLeadById = React.useCallback(async () => {
    try {
      setLeadDetails(null)
      const url =
        leadRequestId === 'next_lead_request'
          ? '/crm/next_lead_request'
          : `/crm/lead_requests/${leadRequestId}?include=lead, channel, course, leadStage, agent, visitCallStatus`
      const response = await API.get(url)
      setLeadDetails(response.data.data)
      if (leadRequestId === 'next_lead_request') {
        history.push(`/leads/${response.data.data.id}`)
        setLeadRequestId(response.data.data.id)
      }
    } catch (error) {
      // Ignore Error Message
      history.push('/404')
    }
  }, [leadRequestId, history])

  // request next lead
  const nextLead = async () => setLeadRequestId('next_lead_request')

  // component did mount
  React.useEffect(() => {
    // fetch Lead by id
    fetchLeadById()
    setLeadRequestId(props.match.params.id)
  }, [fetchLeadById, props.match.params.id])

  // refetch callback after any action update
  const onRefetch = () => fetchLeadById()

  // add new lead course
  const addLeadCourse = () => {
    return showModal({
      id: 'add-lead-course-form-modal',
      dialogClassName: 'add-lead-course-form',
      closeButton: true,
      title: 'Add Lead Course',
      body: <LeadCourseForm onRefetch={onRefetch} lead_id={leadDetails.lead.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  if (!leadDetails) {
    return <Skeleton type='block' />
  }

  return (
    <div className='d-flex align-items-start gap-8 mt-10 pt-5'>
      <div className='width-30'>
        <LeadInfo agent={leadDetails.agent} lead={leadDetails.lead} course={leadDetails.course} />
        <LeadCourses
          lead_id={leadDetails.lead_id}
          setLeadRequestId={setLeadRequestId}
          selectedLeadRequest={leadRequestId}
        />
      </div>
      <div className='width-70'>
        <div className='d-flex align-items-center justify-content-between my-5'>
          <h1 className='fs-3 mb-0'>Overview</h1>
          <div>
            {canTakeActions && (
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center gap-3'>
                    <i
                      className='bi bi-plus-circle'
                      style={{color: 'white', fontSize: '1.3rem'}}
                    ></i>
                    Add Lead Course
                  </span>
                }
                className='w-auto bg-primary m-0'
                onClick={addLeadCourse}
              />
            )}
            <Button
              title={
                <span className='d-flex align-items-center justify-content-center gap-3'>
                  Next Lead
                  <i
                    className='bi bi-arrow-right-square'
                    style={{color: 'white', fontSize: '1.3rem'}}
                  ></i>
                </span>
              }
              onClick={nextLead}
              className='w-auto bg-primary m-0'
            />
          </div>
        </div>
        <OverviewFollowup lead_request_id={leadDetails.id} canTakeActions={canTakeActions} />
        <OverviewDetails
          channel={leadDetails.channel}
          stage={leadDetails.leadStage}
          lead_request_id={leadDetails.id}
          canTakeActions={canTakeActions}
        />
        <OverviewTodoList lead_request_id={leadDetails.id} canTakeActions={canTakeActions} />
      </div>
    </div>
  )
}

export default withRouter(LeadDetails)
