import React from 'react'
import { useDispatch } from 'react-redux';

import { updateChannelSuccess } from '../action/actionCreators';
import API from 'config/API';

import { Notifier } from 'components/Notifier';
import { ModalContext } from 'context/ModalContext';
import ChannelForm from './ChannelForm';

const ViewChannel = ({ name, id }) => {
  const [loading, setLoading] = React.useState(false);
  const { hideModal } = React.useContext(ModalContext);
  const dispatch = useDispatch();


  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.put(`/crm/channels/${id}`, { name: values.name });
      setLoading(false)
      dispatch(updateChannelSuccess(response.data.data));
      hideModal();
      Notifier.successMessage('Channel has been saved!');
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  } 

  return <ChannelForm onSubmitHandler={onSubmit} name={name} loading={loading} />
}

export default ViewChannel;
