import EditTeam from '../EditTeam'
import AssignLeads from './AssignLeads'
import classNames from 'classnames'
import Agents from 'modules/agents/Agents'

export const TeamDetailsTabs = [
  {
    id: 'basic',
    label: 'Basic Data',
    Icon: ({className}) => <i className={classNames('bi bi-textarea-resize', className)}></i>,
  },
  {
    id: 'leads',
    label: 'Assign Leads to team',
    Icon: ({className}) => <i className={classNames('bi bi-plus-circle', className)}></i>,
  },
  {
    id: 'agents',
    label: 'Team Agents',
    Icon: ({className}) => <i className={classNames('bi bi-view-list', className)}></i>,
  },
]

export const getActiveTabComponent = (tabId, team, setActiveTab) => {
  const tabComponent = {
    basic: () => <EditTeam team={team} />,
    leads: () => <AssignLeads teamId={team.id} />,
    agents: () => <Agents team={team} />
  }

  return tabComponent[tabId] ? tabComponent[tabId]() : null
}
