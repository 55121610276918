const statuses = [
  'active', 
  'waiting', 
  'postponed', 
  'terminated', 
  'completed', 
  'assigned & waiting', 
  'refunded', 
  'waiting confirmation', 
  'closed', 
  'waiting from postponed'
];

export const getStudentStatusOptions = () => statuses.map(status => ({ value: status, label: status }));

export const getCoursesOptions = (courses) => courses && courses.map((course) => ({ value: course.id, label: course.name }));

export const attendanceBadges = {
  'attend': 'success',
  'absent': 'danger',
  'apologize': 'warning'
}