import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable } from "react-table";
import isEmpty from "lodash/isEmpty";
import API from "config/API";

import { ModalContext } from "context/ModalContext";
import { getGroupStudents } from "modules/course/groupStudents/action/actionCreators";

import Pagination from "components/Pagination";
import EmptyList from "components/EmptyList";
import Table from "components/Table";
import Skeleton from "components/Skeleton";
import TooltipTitle from "components/TooltipTitle";
import ConfirmationModal from "components/ConfirmationModal";
import { Notifier } from "components/Notifier";

const GroupStudents = ({ group }) => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(20);
  const { showModal, hideModal } = React.useContext(ModalContext);
  const students = useSelector(({ course }) => course.group_students);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getGroupStudents(
        page,
        size,
        "include=student",
        group ? `filter[group_id]=${group.id}` : ""
      )
    );
  }, [dispatch, page, size, group]);

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1);

  const handleSizeChange = (event) => setSize(event.target.value);

  const handleDelete = React.useCallback((id) => {
    return API.post(`/student/un_assign_groups`, { student_course_id: id })
      .then(() => {
        hideModal();
        dispatch(
          getGroupStudents(
            page,
            size,
            "include=student",
            group ? `filter[group_id]=${group.id}` : ""
          )
        );
        Notifier.successMessage("Student has been unassigned!");
      })
      .catch((error) => {
        // Ignore Error Message
      });
  }, [dispatch, group, hideModal, page, size]);

  const deleteStudent = React.useCallback((id) => {
    return showModal({
        id: "confirm",
        dialogClassName: "confirm-dialog",
        closeButton: true,
        title: undefined,
        body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: "auto",
      });
  }, [handleDelete, showModal, hideModal]);

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: "Student Name",
        accessor: "student_name",
        Cell: ({ row: { original: row } }) => row.student?.lead?.name ?? null,
      },
      {
        Header: "Mobile",
        accessor: "student_mobile",
        Cell: ({ row: { original: row } }) => row.student?.lead?.mobile ?? null,
      },
      {
        Header: "WhatsApp",
        accessor: "student_whatsApp",
        Cell: ({ row: { original: row } }) => row?.student?.lead?.phone ?? null,
      },
      {
        Header: "Actions",
        accessor: "action",
      },
    ],
    []
  );

  // table data
  const data = React.useMemo(
    () =>
      students?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <TooltipTitle title="delete student">
            <div
              className="rounded-icon"
              onClick={() => deleteStudent(dataItem.id)}
            >
              <i className="bi bi-trash2"></i>
            </div>
          </TooltipTitle>
        ),
      })),
    [students, deleteStudent]
  );

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  });

  // fetching
  if (students.isFetching) {
    return <Skeleton />;
  }

  return (
    <React.Fragment>
      {isEmpty(students.data) ? (
        <EmptyList message="No students Available" />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(students.data) && students.meta.last_page > 1 && (
        <Pagination
          pageCount={students.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={students.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  );
};

export default GroupStudents;
