import {
  ADD_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  GET_TEAMS_SUCCESS,
  RESET_FILTERS,
  SET_FETCHING_TEAMS,
  SET_FILTERS,
  UPDATE_TEAM_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  filters: {
    name: '',
    role_id: null,
  },
  isFetching: false,
}

function teamsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_TEAMS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        data: action.payload.teams.data,
        meta: action.payload.teams.meta,
      }
    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.team],
      }
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.map((team) => {
          if (team.id === action.payload.team.id) {
            return action.payload.team
          }
          return team
        }),
      }
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.filter((team) => team.id !== action.payload.id),
      }
    case SET_FILTERS:
      return {
        ...state,
        filters: {...state.filters, ...action.payload.filters},
      }
    case RESET_FILTERS:
      return {
        ...state,
        filters: {},
      }
    default:
      return initialState
  }
}

export default teamsReducer
