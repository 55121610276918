import ReactPaginate from 'react-paginate';

import "_metronic/assets/sass/layout/_pagination.scss"

const Pagination = ({ pageCount, handlePageClick, handleSizeChange = undefined, meta = undefined, currentPage = 0}) =>  (
  <div className="d-flex justify-content-between align-items-center py-4 bg-white px-3 mt-4">
    <div className="d-flex align-items-center justify-content-center justify-content-md-start">
        <div className="dataTables_length" id="kt_datatable_users_length">
          <label>
            <select className="form-select form-select-sm form-select-solid" value={meta?.per_page} onChange={handleSizeChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </label>
        </div>
      <div className="dataTables_info mx-2">Showing {meta?.from} to {meta?.to} of {meta?.total} records</div>
    </div>
    <div>
      <ReactPaginate
        previousLabel={'<'} // prev
        nextLabel={'>'} // next
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount} // total number of pages
        marginPagesDisplayed={2} // The number of pages to display for margins.
        pageRangeDisplayed={1} // The range of pages displayed.
        onPageChange={(e) => handlePageClick(e.selected)}  
        containerClassName={'pagination'}
        activeClassName={'active'}
        initialPage={currentPage}
        disableInitialCallback={true}
      />
    </div>
  </div>
);

export default Pagination
