import React from 'react'
import classNames from 'classnames';

const InsightCard = ({title = '', icon = null, insight, className = '', ...rest }) => {
  return (
    <div className={classNames(className, 'bg-white border rounded p-5 shadow-sm')} {...rest}>
      <div className='d-flex align-items-center gap-3 mb-3'>
        {icon}
        <h1 className='fs-3 m-0'>{title}</h1>
      </div>
      <span className='fs-3 font-bold text-primary'>{insight ?? 0}</span>
    </div>
  )
}

export default InsightCard
