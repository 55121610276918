import {GET_BRANCHES_SUCCESS} from './actionTypes'
import API from 'config/API'

export const getBranchesSuccess = (branches) => ({
  type: GET_BRANCHES_SUCCESS,
  payload: {branches},
})

export const getBranches = (page) => async (dispatch) => {
  try {
    const response = await API.get(`/branch/branches?page=${page}`)
    dispatch(getBranchesSuccess({
      data: response.data.data,
      meta: response.data.meta,
    }))
    return response.data
  } catch (error) {
    throw new Error(error.message)
  }
}
