import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, Controller} from 'react-hook-form'
import {useSelector, useDispatch} from 'react-redux'

import API from 'config/API'
import {userValidationRules} from '../yup/userValidationRules'
import {getBranches} from 'modules/branches/action/actionCreators'
import Input from 'components/Input'
import SelectOptions from 'components/SelectOptions'
import Button from 'components/Button'
import Loader from 'components/Loader'

const UserForm = ({loading, editMode = false, user = undefined, onSubmitHandler}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [rolesList, setRolesList] = useState([])
  const {client_config} = useSelector(({auth}) => auth)
  const branchesList = useSelector(({branches}) => branches)

  useEffect(() => {
    async function fetchUserRoles() {
      try {
        const response = await API.get(`/user/roles`)
        setRolesList(response.data.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    // fetch user roles
    fetchUserRoles()

    // fetch branches if user has branch
    if (client_config.has_branch) dispatch(getBranches(1))
  }, [dispatch, client_config])

  const cancelSubmit = () => history.push('/users/list')

  const getRolesOptions = () => {
    return rolesList && rolesList.map((role) => ({value: role.id, label: role.name}))
  }

  const getBranchesOptions = () => {
    return (
      branchesList.data &&
      branchesList.data.map((branch) => ({value: branch.id, label: branch.name}))
    )
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
    getValues,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(userValidationRules),
    defaultValues: {
      first_name: user ? user.first_name : '',
      last_name: user ? user.last_name : '',
      username: user ? user.username : '',
      mobile: user ? user.mobile : '',
      password: user ? user.password : '',
      roles: user ? user.roles.map((role) => ({value: role.id, label: role.name})) : undefined,
      branch_id: user ? {value: user.branch?.id, label: user.branch?.name} : undefined,
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <div className='mt-5 pt-5'>
      <div className='card'>
        <div className='card-body pt-5 mt-5'>
          <form
            className='form w-100 m-auto max-w-80 my-5 pb-5'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className='text-center my-5'>
              <h1>{editMode ? 'Update User' : 'Create User'}</h1>
            </div>
            <div className='d-flex align-items-start justify-content-between gap-5'>
              <div className='flex-1'>
                <Controller
                  {...{
                    control,
                    name: 'first_name',
                    render: ({field}) => (
                      <Input
                        placeholder='First Name'
                        containerClassName='mt-7'
                        id='first_name'
                        error={errors.first_name}
                        {...field}
                      />
                    ),
                  }}
                />
                {!editMode && (
                  <Controller
                    {...{
                      control,
                      name: 'username',
                      render: ({field}) => (
                        <Input
                          placeholder='Username'
                          containerClassName='mt-7'
                          id='username'
                          error={errors.username}
                          {...field}
                        />
                      ),
                    }}
                  />
                )}
                <Controller
                  {...{
                    control,
                    name: 'roles',
                    render: ({field}) => (
                      <SelectOptions
                        placeholder='Roles'
                        containerClassName='mt-7'
                        id='roles'
                        {...field}
                        options={getRolesOptions()}
                        value={getValues('roles')}
                        isMulti
                        closeMenuOnSelect
                        onChange={(option) => setValue('roles', option)}
                        error={errors.roles}
                      />
                    ),
                  }}
                />
                {client_config.has_branch && (
                  <Controller
                    {...{
                      control,
                      name: 'branch_id',
                      render: ({field}) => (
                        <SelectOptions
                          placeholder='Branch'
                          containerClassName='mt-7'
                          id='branch_id'
                          {...field}
                          options={getBranchesOptions()}
                          value={getValues('branch_id')}
                          closeMenuOnSelect
                          onChange={(option) => setValue('branch_id', option)}
                          error={errors.branch_id}
                        />
                      ),
                    }}
                  />
                )}
              </div>
              <div className='flex-1'>
                <Controller
                  {...{
                    control,
                    name: 'last_name',
                    render: ({field}) => (
                      <Input
                        placeholder='Last Name'
                        containerClassName='mt-7'
                        id='last_name'
                        error={errors.last_name}
                        {...field}
                      />
                    ),
                  }}
                />
                {!editMode && (
                  <Controller
                    {...{
                      control,
                      name: 'password',
                      render: ({field}) => (
                        <Input
                          type='password'
                          placeholder='Password'
                          containerClassName='mt-7'
                          id='password'
                          error={errors.password}
                          {...field}
                        />
                      ),
                    }}
                  />
                )}
                <Controller
                  {...{
                    control,
                    name: 'mobile',
                    render: ({field}) => (
                      <Input
                        type='tel'
                        placeholder='Mobile Number'
                        containerClassName='mt-7'
                        id='mobile'
                        error={errors.mobile}
                        {...field}
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className='d-flex align-items-start justify-content-center gap-5'>
              <Button title='Cancel' className='btn btn-light btn-active-light-primary py-5' onClick={cancelSubmit} />
              <Button
                type='submit'
                title={loading ? <Loader /> : 'Submit User'}
                className='bg-primary py-5'
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UserForm
