import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import query from 'query-string'
import {useTable} from 'react-table'

import {getLeadsPerDay, setFilters} from './action/actionCreators'
import {fetchCourses, fetchUsers, normalizeFilters} from 'config/utils'

import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Skeleton from 'components/Skeleton'
import SelectedFilters from 'components/SelectedFilters'

const LeadsPerDay = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [coursesList, setCourses] = React.useState(null)
  const [usersList, setUsers] = React.useState(null)
  const leadsPerDayList = useSelector(({crm}) => crm.reports.leadsPerDay)
  const rangeFilters = useSelector(({filters}) => filters)
  const dispatch = useDispatch()

  const fetchLeads = React.useCallback(
    () => {
      const [date_from = '', date_to = ''] = leadsPerDayList.filters?.date ?? [];
      dispatch(
        getLeadsPerDay(
          page,
          size,
          query.stringify(
            normalizeFilters({
              ...leadsPerDayList.filters,
              date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : '',
              date_to: date_to ? moment(date_to).format('YYYY-MM-DD') : '',
            })
          )
        )
      )},
    [dispatch, size, leadsPerDayList.filters, page]
  )

  const fetchFiltersListOptions = async () => {
    const [courses, users] = await Promise.all([
      fetchCourses(),
      fetchUsers(`filter[role]=Social Media Agent&Sales Agent`),
    ])
    // set state
    setCourses(courses.data)
    setUsers(users.data)
  }

  React.useEffect(() => {
    fetchLeads()
    fetchFiltersListOptions()
  }, [fetchLeads])

  React.useEffect(() => {
    dispatch(
      setFilters({
        ...leadsPerDayList.filters,
        date: [new Date(rangeFilters.startDate), new Date(rangeFilters.endDate)],
      })
    )
  }, [])

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const handleUpdateFilters = (filters) => dispatch(setFilters(filters))

  const getOptions = (data) => {
    return data && data?.map((dataItem) => ({value: dataItem.id, label: dataItem.name}))
  }

  const getFilters = () => [
    {name: 'course_id', type: 'options', label: 'Course', options: getOptions(coursesList)},
    {name: 'agent_id', type: 'options', label: 'Agent', options: getOptions(usersList)},
    {name: 'date', type: 'range', label: 'From ~ To'},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Created Date',
        accessor: 'created_date',
        Cell: ({row: {original: row}}) => moment(row.created_date).format('YYYY-MM-DD'),
      },
      {
        Header: 'Leads',
        accessor: 'leads',
        Cell: ({row: {original: row}}) => row.leads,
      },
      {
        Header: 'Fresh',
        accessor: 'fresh',
        Cell: ({row: {original: row}}) => row.Fresh,
      },
      {
        Header: 'Incomplete',
        accessor: 'Incomplete',
        Cell: ({row: {original: row}}) => row.Incomplete,
      },
      {
        Header: 'Not interested',
        accessor: 'Not interested',
        Cell: ({row: {original: row}}) => row['Not interested'],
      },
      {
        Header: 'Interested',
        accessor: 'Interested',
        Cell: ({row: {original: row}}) => row.Interested,
      },
      {
        Header: 'Student',
        accessor: 'Student',
        Cell: ({row: {original: row}}) => row.Student,
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () => leadsPerDayList?.data.map((dataItem) => dataItem),
    [leadsPerDayList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (leadsPerDayList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={leadsPerDayList.filters} setFilters={setFilters} />
            <Filters
              filters={getFilters()}
              handleUpdateFilters={handleUpdateFilters}
              initialValues={leadsPerDayList.filters}
            />
          </div>
        </div>
      </div>
      {isEmpty(leadsPerDayList.data) ? (
        <EmptyList message='No Leads Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(leadsPerDayList.data) && leadsPerDayList.meta.last_page > 1 && (
        <Pagination
          pageCount={leadsPerDayList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={leadsPerDayList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default LeadsPerDay
