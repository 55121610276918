import { GET_GROUP_ROUNDS_SUCCESS, SET_FETCHING_ROUNDS } from "../action/actionTypes";

export const initialState  = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0
  },
  isFetching: false
};

function groupRoundsReducer(state = initialState, action) {
  switch(action.type) {
    case SET_FETCHING_ROUNDS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_GROUP_ROUNDS_SUCCESS:
      return {
        ...state,
        data: action.payload.rounds.data,
        meta: action.payload.rounds.meta
      };
    default: 
      return state;
  }
}

export default groupRoundsReducer;