import {
  SET_FETCHING_GROUPS,
  GET_GROUPS_SUCCESS,
  SET_GROUPS_FILTERS,
  RESET_GROUPS_FILTERS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  filters: {
    levelIsLE: undefined,
    course_id: null,
    group_round_id: null,
    group_status_id: null,
    instructor_id: null
  },
  isFetching: false,
}

function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_GROUPS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.payload.groups.data,
        meta: action.payload.groups.meta,
        isFetching: false,
      }
    case SET_GROUPS_FILTERS:
      return {
        ...state,
        filters: {...state.filters, ...action.payload.filters},
      }
    case RESET_GROUPS_FILTERS:
      return {
        ...state,
        filters: {},
      }
    default:
      return state
  }
}

export default groupsReducer
