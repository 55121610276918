import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, withRouter} from 'react-router-dom'
import query from 'query-string'
import classnames from 'classnames'

import {getTeams} from '../teams/teams/action/actionCreators'
import {getTicketActions} from '../teams/TicketActions/action/actionCreators'
import {getTicketCategories} from '../teams/TicketCategories/action/actionCreators'
import {can, getPrioritiesOptions, getStatusOptions, getModalTypesOptions} from '../utils'

import {getBranches} from 'modules/branches/action/actionCreators'
import {getUsers} from 'modules/users/action/actionCreators'
import {normalizeFilters} from 'config/utils'
import {
  getAllTeamTickets,
  getAssignedTickets,
  getCreatedByTeamMembersTickets,
  getCreatedTickets,
  getPublicTickets,
} from './action/actionCreators'

import Can from 'components/Can'
import Card from 'components/Card'
import Filters from 'components/Filters'
import TicketsStats from 'components/TicketsStats'
import MyAssignedTickets from './components/MyAssignedTickets'
import MyCreatedTickets from './components/MyCreatedTickets'
import PublicTickets from './components/PublicTickets'
import TeamTickets from './components/TeamTickets'
import CreatedByTeamMembersTickets from './components/CreatedByTeamMembersTickets'

const btnClassNames = 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer'

// initail load tickets, stats
// on change filters, on change date refetch tickets, stats
const TicketsLayout = (props) => {
  const [active, setActive] = useState(1)
  const [filters, setFilters] = useState('')
  const dispatch = useDispatch()
  const agentId = useSelector(({auth}) => auth.data.id)
  const {public_tickets, created_tickets, assigned_tickets, team_tickets, created_by_members_tickets} = useSelector(
    ({ticket}) => ticket.list
  )
  const {teams, categories, actions} = useSelector(({ticket}) => ticket)
  const branches = useSelector(({branches}) => branches)
  const users = useSelector(({users}) => users)
  const {client_config} = useSelector(({auth}) => auth)
  const selectedRange = useSelector(({filters}) => filters)

  const modelTypes = useSelector(({auth}) => auth.client_config.ticket_models)

  // get team by Id
  const team_id = props.match.params.id

  // init => loadMasterData
  // show_all_tickets
  useEffect(() => {
    dispatch(getUsers(1, 25, '-created_at', 'filter[role]=Tickets Agent,Tickets Team Leader'))
    if (team_id) {
      dispatch(getTicketCategories(1, 100, team_id, ''))
    } else {
      dispatch(getTeams(1, 100, 'name'))
    }
    if (client_config.has_branch) {
      dispatch(getBranches(1))
    }
    if (can.do('show_public_tickets')) {
      dispatch(
        getPublicTickets(
          1,
          100,
          query.stringify(
            normalizeFilters({
              team_id,
            })
          )
        )
      )
    }
    if (can.do('show_my_created_tickets') && !team_id) {
      dispatch(
        getCreatedTickets(
          query.stringify(
            normalizeFilters({
              team_id,
              created_by: agentId,
            })
          )
        )
      )
    }
    if (can.do('show_my_assigned_tickets') && !team_id) {
      dispatch(
        getAssignedTickets(
          query.stringify(
            normalizeFilters({
              team_id,
              agent_id: agentId,
            })
          )
        )
      )
    }
    if (can.do('show_all_team_tickets') && team_id) {
      dispatch(
        getAllTeamTickets(
          query.stringify(
            normalizeFilters({
              team_id,
            })
          )
        )
      )
      dispatch(
          getCreatedByTeamMembersTickets(
              query.stringify(
                  normalizeFilters({
                    createdByTeamMembers : team_id,
                  })
              )
          )
      )
    }
  }, [])

  // when filter change
  // when date range change
  useEffect(() => {
    // ACTIVE 1
    if (active === 1) {
      dispatch(
        getPublicTickets(
          1,
          100,
          query.stringify(
            normalizeFilters({
              team_id,
            })
          )
        )
      )
    }
    // ACTIVE 2
    if (active === 2) {
      dispatch(
        getCreatedTickets(
          query.stringify(
            normalizeFilters({
              team_id,
              created_by: agentId,
              ...filters,
            })
          )
        )
      )
    }
    // ACTIVE 3
    if (active === 3) {
      dispatch(
        getAssignedTickets(
          query.stringify(
            normalizeFilters({
              team_id,
              agent_id: agentId,
              ...filters,
            })
          )
        )
      )
    }
    // ACTIVE 4
    if (active === 4) {
      dispatch(
        getAllTeamTickets(
          query.stringify(
            normalizeFilters({
              team_id,
              ...filters,
            })
          )
        )
      )
    }
    // ACTIVE 5
    if (active === 5) {
      dispatch(
          getCreatedByTeamMembersTickets(
              query.stringify(
                  normalizeFilters({
                    createdByTeamMembers: team_id,
                    ...filters,
                  })
              )
          )
      )
    }
  }, [filters, active, selectedRange])

  // active component
  const getActiveSectionContent = {
    1: () => <PublicTickets team_id={team_id} />,
    2: () => <MyCreatedTickets />,
    3: () => <MyAssignedTickets />,
    4: () => <TeamTickets />,
    5: () => <CreatedByTeamMembersTickets />,
  }

  const handleUpdateFilters = (filters) => setFilters(filters)

  const getOptions = (list) => {
    return list && list.map((item) => ({value: item.id, label: item.name || item.action}))
  }

  const getFilters = () => [
    {
      type: 'options',
      name: 'team_id',
      label: 'Team',
      options: getOptions(teams.data),
      hidden: team_id,
      onChange: (option) => dispatch(getTicketCategories(1, 100, option.value, '')),
    },
    {
      type: 'options',
      name: 'ticketAction.ticket_category_id',
      label: categories.isFetching ? 'Fetching...' : 'Category',
      disabled: categories.isFetching,
      options: getOptions(categories.data),
      onChange: (option) => dispatch(getTicketActions(1, 100, option.value, '')),
    },
    {
      type: 'options',
      name: 'ticket_action_id',
      label: actions.isFetching ? 'Fetching...' : 'Action',
      disabled: actions.isFetching,
      options: getOptions(actions.data),
    },
    {
      type: 'options',
      name: 'branch_id',
      label: 'Branch',
      options: getOptions(branches.data),
      hidden: !client_config.has_branch,
    },
    {
      type: 'options',
      name: 'agent_id',
      label: 'Agent',
      options: getOptions(users.data),
    },
    {
      type: 'options',
      name: 'created_by',
      label: 'Created By',
      options: getOptions(users.data),
    },
    {
      type: 'options',
      name: 'status',
      label: 'Status',
      options: getStatusOptions(),
    },
    {
      type: 'options',
      name: 'priority',
      label: 'Priority',
      options: getPrioritiesOptions(),
    },
    {
      type: 'options',
      name: 'ticketable_type',
      label: 'Type',
      options: getModalTypesOptions(modelTypes),
    },
    {
      type: 'text',
      name: 'ticketable_id',
      label: 'Model Id',
    },
  ]

  const renderFilters = () => {
    if (active === 4 || active === 5)
      return <Filters filters={getFilters()} handleUpdateFilters={handleUpdateFilters} />
    return (
      <Filters
        filters={getFilters().filter((filterOption) => filterOption.name !== 'agent_id')}
        handleUpdateFilters={handleUpdateFilters}
      />
    )
  }

  const Header = () => (
    <React.Fragment>
      <div className='d-flex overflow-auto h-100px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <Can do='show_public_tickets'>
            <li className='nav-item mt-2'>
              <span
                className={classnames(btnClassNames, {active: active === 1})}
                onClick={() => setActive(1)}
              >
                Public Tickets
                {public_tickets.data && (
                  <span className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 mx-1'>
                    {public_tickets.data && public_tickets.data.length}
                  </span>
                )}
              </span>
            </li>
          </Can>
          {!team_id && (
          <Can do='show_my_created_tickets'>
            <li className='nav-item mt-2'>
              <span
                className={classnames(btnClassNames, {active: active === 2})}
                onClick={() => setActive(2)}
              >
                My Created Tickets
                {created_tickets.data && (
                  <span className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 mx-1'>
                    {created_tickets.data.length}
                  </span>
                )}
              </span>
            </li>
          </Can>
          )}
          {!team_id && (
          <Can do='show_my_assigned_tickets'>
            <li className='nav-item mt-2'>
              <span
                className={classnames(btnClassNames, {active: active === 3})}
                onClick={() => setActive(3)}
              >
                My Assigned Tickets
                {assigned_tickets.data && (
                  <span className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 mx-1'>
                    {assigned_tickets.data.length}
                  </span>
                )}
              </span>
            </li>
          </Can>
          )}
          {team_id && (
            <Can do='show_all_team_tickets'>
              <li className='nav-item mt-2'>
                <span
                  className={classnames(btnClassNames, {active: active === 4})}
                  onClick={() => setActive(4)}
                >
                  All Team Tickets
                  {team_tickets.data && (
                    <span className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 mx-1'>
                      {team_tickets.data && team_tickets.data.length}
                    </span>
                  )}
                </span>
              </li>
              <li className='nav-item mt-2'>
                <span
                    className={classnames(btnClassNames, {active: active === 5})}
                    onClick={() => setActive(5)}
                >
                  Created By Team Members
                  {created_by_members_tickets.data && (
                      <span className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 mx-1'>
                      {created_by_members_tickets.data && created_by_members_tickets.data.length}
                    </span>
                  )}
                </span>
              </li>
            </Can>
          )}
        </ul>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end gap-2'>
          <Can do='create_ticket'>
            <Link
              className='btn btn-sm btn-flex btn-light btn-primary fw-bolder fs-8 fs-lg-base'
              to='/tickets/create'
            >
              Create Ticket
            </Link>
          </Can>
          {renderFilters()}
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Card classNames='mt-10'>
        <div className='card-header pt-3 px-5'>{Header()}</div>
        <React.Fragment>
          {active === 2 && (
            <TicketsStats
              filters={query.stringify(
                normalizeFilters({
                  team_id,
                  created_by: agentId,
                  date_from: selectedRange.startDate,
                  date_to: selectedRange.endDate,
                })
              )}
            />
          )}
          {active === 3 && (
            <TicketsStats
              filters={query.stringify(
                normalizeFilters({
                  team_id,
                  agent_id: agentId,
                  date_from: selectedRange.startDate,
                  date_to: selectedRange.endDate,
                })
              )}
            />
          )}
        </React.Fragment>
      </Card>
      {getActiveSectionContent[active]()}
    </React.Fragment>
  )
}

export default withRouter(TicketsLayout)
