import React from 'react'

import FileBase64 from 'react-file-base64'

const FileUpload = (image = '', setImage) => {
  // Callback
  const getFiles = (file) => {
    console.log(file)
    setImage(file)
  }

  return (
    <div className='m-auto text-center my-3'>
      <div
        style={{
          width: '180px',
          height: '180px',
          maxWidth: '100%',
          borderRadius: '50%',
          border: '3px solid #eee',
          margin: 'auto',
          overflow: 'hidden',
        }}
      >
        <img
          src={image}
          alt=''
          className='image-responsive'
          style={{
            width: '100%',
          }}
        />
      </div>
      <div className='mt-5'>
        <FileBase64 onDone={getFiles} />
      </div>
    </div>
  )
}

export default FileUpload
