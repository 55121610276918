import {
  ADD_COMPLAIN_CATEGORIES_SUCCESS,
  DELETE_COMPLAIN_CATEGORIES_SUCCESS,
  GET_COMPLAIN_CATEGORIES_SUCCESS,
  SET_FETCHING_CATEGORIES,
  UPDATE_COMPLAIN_CATEGORIES_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0
  },
  isFetching: false
};

function complainCategoriesReducer(state = initialState, action) {
  switch(action.type) {
    case SET_FETCHING_CATEGORIES:
      return {
        ...state,
        isFetching: action.payload.isFetching
      }
    case GET_COMPLAIN_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload.categories
      }
    case ADD_COMPLAIN_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.category]
      }
    case UPDATE_COMPLAIN_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: state.data.map((category) => {
        if (category.id === action.payload.category.id) {
          return action.payload.category;
        }
        return category;
      })
    }
    case DELETE_COMPLAIN_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: state.data.filter((category) => category.id !== action.payload.id)
      }
    default: 
      return state;
  }
}

export default complainCategoriesReducer;