import {
  ADD_COMPLAIN_SUCCESS,
  DELETE_COMPLAIN_SUCCESS,
  GET_COMPLAINTS_SUCCESS,
  SET_FETCHING_COMPLAINTS,
  UPDATE_COMPLAIN_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function complaintsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_COMPLAINTS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        ...action.payload.complaints,
      }
    case ADD_COMPLAIN_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.complain],
      }
    case UPDATE_COMPLAIN_SUCCESS:
      return {
        ...state,
        data: state.data.map((complain) => {
          if (complain.id === action.payload.complain.id) {
            return action.payload.complain
          }
          return complain
        }),
      }
    case DELETE_COMPLAIN_SUCCESS:
      return {
        ...state,
        data: state.data.filter((complain) => complain.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default complaintsReducer
