import React from 'react'
import API from 'config/API'
import {Notifier} from 'components/Notifier'
import GroupDayForm from './GroupDayForm'
import {useDispatch} from 'react-redux'
import {getGroupDays} from '../action/actionCreators'
import {ModalContext} from 'context/ModalContext'

const AddGroupDay = ({groupId}) => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)
  const dispatch = useDispatch()

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/course/group_days`, {
        groupDays: [{...values, day: values.day.value}],
        group_id: groupId,
      })
      setLoading(false)
      dispatch(getGroupDays(1, 20, `filter[group_id]=${groupId}`))
      hideModal()
      Notifier.successMessage('Group Day has been inserted!')
    } catch (error) {
      setError('day', {message: 'Week day must be unique.'})
      setLoading(false)
    }
  }

  return <GroupDayForm loading={loading} onSubmitHandler={onSubmit} />
}

export default AddGroupDay
