import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import {channelsValidationRules} from '../yup/channelsValidationRules'

import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'

import { ModalContext } from 'context/ModalContext'

const ChannelForm = ({onSubmitHandler, name = '', loading}) => {
  const {hideModal} = React.useContext(ModalContext)

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(channelsValidationRules),
    defaultValues: {
      name,
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'name',
          render: ({field}) => (
            <Input
              placeholder='Name'
              containerClassName='mt-7'
              id='name'
              error={errors.name}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button
          type='submit'
          title={loading ? <Loader /> : 'Save'}
          className='bg-primary py-5'
        />
      </div>
    </form>
  )
}

export default ChannelForm
