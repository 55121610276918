import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import React from 'react';

const Button = (props) => {
  const { title = undefined, className = 'bg-primary', ...rest } = props;

  return (
    <button
      className={classNames('custom-button', className)}
      type="button"
      {...rest}
    >
      {title}
    </button>
  );
};

export default React.memo(Button, (prevProps, nextProps) => isEqual(prevProps, nextProps));