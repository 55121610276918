import React, {useContext} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'

import API from 'config/API'
import {deleteTeamSuccess} from '../action/actionCreators'
import {ModalContext} from 'context/ModalContext'
import EditTeam from './EditTeam'

import ConfirmationModal from 'components/ConfirmationModal'
import {Notifier} from 'components/Notifier'
import {can} from 'modules/ticket/utils'
import TooltipTitle from 'components/TooltipTitle'
import Can from 'components/Can'

const TeamListItem = ({team}) => {
  const {showModal, hideModal} = useContext(ModalContext)
  const dispatch = useDispatch()

  const handleDelete = (id) =>
    API.delete(`/team/ticket_teams/${id}`)
      .then(() => {
        dispatch(deleteTeamSuccess(id))
        hideModal()
        Notifier.successMessage('Team has been deleted!')
      })
      .catch(() => {
        // Notifier
      })

  const deleteTeam = () => showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(team.id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handleEditTeam = () =>
    showModal({
      id: 'view-edit-team-form-modal',
      dialogClassName: 'view-edit-team-form',
      closeButton: true,
      title: 'Edit Team',
      body: <EditTeam team={team} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  return (
    <div className='col-xl-4'>
      <div className='card bgi-no-repeat card-xl-stretch mb-xl-8'>
        <div className='card-body'>
          <Link
            to={`/tickets/teams/${team.id}`}
            className='card-title fw-bolder text-muted text-hover-primary fs-4'
          >
            {team.name}
          </Link>
          <div className='d-flex flex-stack flex-grow-1'>
            <div>
              <div className='fw-bolder text-primary mt-7'>Team leader</div>
              <p className='text-dark-75 fw-bold fs-5 m-0'>{team.leader?.name}</p>
            </div>
            <div className='d-flex flex-column text-end'>
              <span className='text-muted fw-bolder mt-7'>Members</span>
              <span className='text-dark fw-bold mt-1'>{team.teamMembers_count}</span>
            </div>
          </div>
        </div>
        {(can.do('update_ticket_team') || can.do('delete_ticket_team')) && (
          <div className='position-absolute right-0 m-3 d-flex'>
            <Can do='update_ticket_team'>
              <TooltipTitle title='Update Team'>
                <div className='rounded-icon' onClick={handleEditTeam}>
                  <i className='bi bi-pencil'></i>
                </div>
              </TooltipTitle>
            </Can>
            <Can do='delete_ticket_team'>
              <TooltipTitle title='delete Team'>
                <div className='rounded-icon' onClick={deleteTeam}>
                  <i className='bi bi-trash2'></i>
                </div>
              </TooltipTitle>
            </Can>
          </div>
        )}
      </div>
    </div>
  )
}

export default TeamListItem
