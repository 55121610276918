import {
  DELETE_COURSE,
  GET_COURSES_SUCCESS,
  SET_COURSES_FILTER,
  SET_FETCHING_COURSES,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  filters: {},
  isFetching: false,
}

function coursesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_COURSES:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        data: action.payload.courses.data,
        meta: action.payload.courses.meta
      }
    case DELETE_COURSE:
      return {
        ...state,
        data: state.data.filter((course) => course.id !== action.payload.id),
      }
    case SET_COURSES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filter.name]: action.payload.filter.value,
        },
      }
    default:
      return state
  }
}

export default coursesReducer
