import {GET_LEADS_PER_DAY_SUCCESS, RESET_LEADS_PER_DAY_FILTERS, SET_FETCHING_LEADS_PER_DAY, SET_LEADS_PER_DAY_FILTERS} from '../action/actionTypes'
import API from 'config/API'

const getLeadsPerDaySuccess = (leads) => ({
  type: GET_LEADS_PER_DAY_SUCCESS,
  payload: {leads},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_LEADS_PER_DAY,
  payload: {isFetching},
})

export const getLeadsPerDay =
  (
    page,
    size, // paginate
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/crm/leads_per_day_reports?paginate=${size}&page=${page}&${filters}`
      )
      dispatch(
        getLeadsPerDaySuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

  export const setFilters = (filters) => ({
    type: SET_LEADS_PER_DAY_FILTERS,
    payload: {filters},
  })
  
  export const resetFilters = () => ({
    type: RESET_LEADS_PER_DAY_FILTERS,
    payload: {},
  })
