/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'

import {getTicketCategories} from '../action/actionCreators'
import TicketCategoryForm from './TicketCategoryForm'
import { ModalContext } from 'context/ModalContext'
import API from 'config/API'

import {Notifier} from 'components/Notifier'

const EditTicketCategory = ({category}) => {
  const {hideModal} = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.put(`/ticket/ticket_categories/${category.id}`, {
        ...values,
        ticket_team_id: category.ticket_team_id,
      })
      setLoading(false)
      dispatch(getTicketCategories(1, 10, category.ticket_team_id, ''))
      hideModal()
      Notifier.successMessage('Category has been saved!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <TicketCategoryForm onSubmitHandler={onSubmit} loading={loading} name={category.name} />
}

export default EditTicketCategory
