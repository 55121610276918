import React, {useRef, useState} from 'react'
import classNames from 'classnames'
import {useForm, Controller} from 'react-hook-form'
import {useClickOutside} from 'hooks/useClickOutside'
import Button from './Button'
import Input from './Input'
import SelectOptions from './SelectOptions'
import DateRangePicker from 'rsuite/DateRangePicker'

const Filters = (props) => {
  const {filters = [], handleUpdateFilters, initialValues = {}} = props

  // State for our modal
  const [toggleFilters, setToggleFilters] = useState(false)

  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef()

  // Call hook passing in the ref and a function to call on outside click
  useClickOutside(ref, () => setToggleFilters(false))

  // useForm setup
  const {handleSubmit, control, reset, getValues, setValue} = useForm({
    mode: 'onSubmit',
    defaultValues: {...initialValues},
  })

  const onSubmit = (values) => {
    // dispatch filters to store
    handleUpdateFilters(values)

    setToggleFilters(false)
  }

  const onReset = () => {
    const clearInitialValues = {...initialValues}

    for (const key in clearInitialValues) {
      if (typeof clearInitialValues[key] === 'object') {
        clearInitialValues[key] = null
      } else {
        clearInitialValues[key] = ''
      }
    }

    reset(clearInitialValues)
    // clean filters from store
    handleUpdateFilters(clearInitialValues)
  }

  const hasFilters = Object.values(getValues()).filter((val) => val).length

  return (
    <div className='position-relative'>
      <div className='position-relative'>
        <Button
          title={<i className='bi bi-filter' style={{fontSize: '2rem'}}></i>}
          className='mt-0 w-auto bg-white color-primary p-3'
          onClick={() => setToggleFilters(!toggleFilters)}
        />
        {!!hasFilters && <span className='notify-nums'>{hasFilters}</span>}
      </div>
      {toggleFilters && (
        <div
          className={classNames(
            'filter-options w-300px w-md-325px z-index-1 position-absolute bg-white mt-2 mx-5 right-0'
          )}
          // ref={ref}
          id='filters'
        >
          <div className='d-flex align-items-center justify-content-between px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filters</div>
            {!!hasFilters && (
              <span className='badge badge-primary cursor-pointer' onClick={onReset}>
                Reset
              </span>
            )}
          </div>
          <div className='separator border-gray-200'></div>
          <form className='form filters-form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className='px-7 py-2 mx-1 options-wrapper'>
              {filters?.map((filter) => {
                if (!filter.hidden){
                if (filter.type === 'options') {
                  return (
                    <Controller
                      key={filter.name}
                      {...{
                        control,
                        name: filter.name,
                        render: ({field}) => (
                          <SelectOptions
                            options={filter.options}
                            name={filter.name}
                            placeholder={filter.label}
                            containerClassName='mt-5'
                            {...field}
                            {...filter}
                            onChange={(option) => {
                              setValue(filter.name, option)
                              filter.onChange && filter.onChange(option)
                            }}
                          />
                        ),
                      }}
                    />
                  )
                }

                if (filter.type === 'range') {
                  return (
                    <Controller
                      key={filter.name}
                      {...{
                        control,
                        name: filter.name,
                        render: ({field}) => (
                          <DateRangePicker
                            size='lg'
                            block
                            placement='auto'
                            name={filter.name}
                            placeholder={filter.label}
                            className='mt-5'
                            {...field}
                          />
                        ),
                      }}
                    />
                  )
                }

                return (
                  <Controller
                    key={filter.name}
                    {...{
                      control,
                      name: filter.name,
                      render: ({field}) => (
                        <Input
                          type={filter.type}
                          name={filter.name}
                          placeholder={filter.label}
                          containerClassName='mt-5'
                          {...field}
                        />
                      ),
                    }}
                  />
                )
              }})}
            </div>
            <div className='px-7 py-5'>
              <div className='d-flex justify-content-end gap-3'>
                <Button
                  type='button'
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6 mt-0 mb-4'
                  title='Cancel'
                  onClick={() => setToggleFilters(false)}
                />
                <Button type='submit' className='bg-primary fw-bold px-6 mt-0 mb-4' title='Apply' />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default Filters
