import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import query from 'query-string'
import classnames from 'classnames'
import {withRouter} from 'react-router-dom'

import TicketItem from './TicketItem'
import {capitalizeFirstLetter, normalizeFilters} from 'config/utils'
import {getAllTickets} from '../action/actionCreators'

import {
  getPrioritiesOptions,
  getStatusOptions,
  groupTicketsBy,
  statusColors,
} from 'modules/ticket/utils'
import {getTicketActions} from 'modules/ticket/teams/TicketActions/action/actionCreators'
import {getTicketCategories} from 'modules/ticket/teams/TicketCategories/action/actionCreators'

import Filters from 'components/Filters'
import Card from 'components/Card'
import EmptyList from 'components/EmptyList'
import ScrollWrapper from 'components/ScrollWrapper'

const btnClassNames = 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer'

const AllTickets = (props) => {
  const [filters, setFilters] = useState('')
  const all_tickets = useSelector(({ticket}) => ticket.list.all_tickets, shallowEqual)
  const selectedRange = useSelector(({filters}) => filters, shallowEqual)
  const dispatch = useDispatch()
  const {categories, actions} = useSelector(({ticket}) => ticket, shallowEqual)
  const branches = useSelector(({branches}) => branches, shallowEqual)
  const {client_config} = useSelector(({auth}) => auth, shallowEqual)
  const groupedTickets = groupTicketsBy('status', all_tickets.data)
  // get agent id
  const agent_id = props.match.params.agentId
  // get team by Id
  const team_id = props.match.params.id

  // init => loadMasterData
  // show_all_tickets
  useEffect(() => {
    dispatch(getTicketCategories(1, 100, team_id, ''))
    dispatch(
      getAllTickets(
        query.stringify(
          normalizeFilters({
            team_id,
            agent_id: agent_id,
            date_from: selectedRange.startDate,
            date_to: selectedRange.endDate,
          })
        )
      )
    )
  }, [agent_id, dispatch, selectedRange.endDate, selectedRange.startDate, team_id])

  // when filter change
  // when date range change
  useEffect(() => {
    if (filters) {
      // ACTIVE 5
      dispatch(
        getAllTickets(
          query.stringify(
            normalizeFilters({
              team_id,
              agent_id: agent_id,
              date_from: selectedRange.startDate,
              date_to: selectedRange.endDate,
              ...filters,
            })
          )
        )
      )
    }
  }, [filters, selectedRange])

  const handleUpdateFilters = (filters) => setFilters(filters)

  const getOptions = (list) => {
    return list && list.map((item) => ({value: item.id, label: item.name}))
  }

  const getFilters = () => [
    {
      type: 'select',
      name: 'ticketAction.ticket_category_id',
      label: 'Category',
      options: getOptions(categories.data),
      onChange: (option) => dispatch(getTicketActions(1, 100, option.value, '')),
    },
    {
      type: 'select',
      name: 'ticket_action_id',
      label: actions.isFetching ? 'Fetching...' : 'Action',
      disabled: actions.isFetching,
      options: getOptions(actions.data),
    },
    {
      type: 'select',
      name: 'branch_id',
      label: 'Branch',
      options: getOptions(branches.data),
      hidden: !client_config.has_branch,
    },
    {type: 'select', name: 'status', label: 'Status', options: getStatusOptions()},
    {type: 'select', name: 'priority', label: 'Priority', options: getPrioritiesOptions()},
  ]

  const Header = () => (
    <React.Fragment>
      <div className='d-flex overflow-auto h-100px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item mt-2'>
            <span className={classnames(btnClassNames, 'active')}>
              Agent Tickets
              {all_tickets.data && (
                <span className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 mx-1'>
                  {all_tickets.data && all_tickets.data.length}
                </span>
              )}
            </span>
          </li>
        </ul>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end'>
          <Filters filters={getFilters()} handleUpdateFilters={handleUpdateFilters} />
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Card>
        <div className='card-header pt-3 px-5'>{Header()}</div>
      </Card>
      <div className='my-3 px-3'>
        <ScrollWrapper>
          <div className='row g-6 g-xl-9 flex-nowrap h-100'>
            {all_tickets.data &&
                all_tickets.data.length &&
              Object.keys(groupedTickets).map((group) => (
                <div className='col-md-4 col-lg-12 col-xl-4' key={group}>
                  <div className='mb-3'>
                    <div className='card d-flex p-2'>
                      <div className='fw-bolder fs-4 d-flex'>
                        {capitalizeFirstLetter(group)}
                        <span className='tickets-column-length mx-3'>
                          {groupedTickets[group].length}
                        </span>
                      </div>
                    </div>
                    <div className={classnames('h-3px w-100', [`bg-${statusColors[group]}`])}></div>
                  </div>
                  <div className='tickets-column'>
                    {groupedTickets[group].map((ticket) => (
                      <TicketItem ticket={ticket} key={ticket.id} />
                    ))}
                  </div>
                </div>
              ))}
            {all_tickets.data && !all_tickets.data.length && <EmptyList />}
          </div>
        </ScrollWrapper>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AllTickets)
