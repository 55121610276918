import React from 'react'

import API from 'config/API'
import TodoForm from './TodoForm'

import Button from 'components/Button'
import CollapseRow from 'components/Collapser'
import ConfirmationModal from 'components/ConfirmationModal'
import {Notifier} from 'components/Notifier'
import TooltipTitle from 'components/TooltipTitle'
import {ModalContext} from 'context/ModalContext'

const OverviewTodoList = ({lead_request_id, canTakeActions}) => {
  const {hideModal, showModal} = React.useContext(ModalContext)
  const [todos, setTodos] = React.useState(null)

  const fetchTodos = React.useCallback(async () => {
    try {
      setTodos(null)
      const response = await API.get(
        `/crm/todos?filter[done]=0&filter[lead_request_id]=${lead_request_id}`
      )
      setTodos(response.data.data)
    } catch (error) {
      // Ignore Error Message
    }
  }, [lead_request_id])

  React.useEffect(() => {
    fetchTodos()
  }, [lead_request_id, fetchTodos])

  const addNewTodo = () => {
    return showModal({
      id: 'add-todo-form-modal',
      dialogClassName: 'add-todo-form',
      closeButton: true,
      title: 'New Todo',
      body: <TodoForm onRefetch={fetchTodos} lead_request_id={lead_request_id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  const handleDone = (todo) => {
    return API.put(`/crm/todos/${todo.id}`, {
      ...todo,
      time: todo.time.split(':').splice(0, 2).join(':'),
      done: 1,
    })
      .then(() => {
        hideModal()
        fetchTodos()
        Notifier.infoComponent('Changes Applied', 'Changes applied successfully')
      })
      .catch((error) => {
        // Ignore Error Message
      })
  }

  const onDone = (todo) => {
    return showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: (
        <ConfirmationModal
          title='Are you sure you want to Done this todo?'
          subText=''
          handleAccept={() => handleDone(todo)}
        />
      ),
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  return (
    <div className='card mb-10'>
      <div className='card-body pb-0 d-flex align-items-center justify-content-between'>
        <h5 className='card-title m-0 fs-3'>To Do</h5>
        {canTakeActions && (
          <Button
            title={
              <span className='d-flex align-items-center justify-content-center'>
                <i
                  className='bi bi-plus-circle mx-2'
                  style={{color: 'white', fontSize: '1.5rem'}}
                ></i>
                Add New Todo
              </span>
            }
            onClick={addNewTodo}
            className='w-auto m-0 bg-primary hover:bg-slate-800'
          />
        )}
      </div>
      <div className='p-5'>
        {!todos && <div className='font-bold text-center mt-3'>Fetching Todos...</div>}
        {todos?.map((todo) => (
          <CollapseRow
            key={todo.id}
            title={todo.todo_type}
            due={todo.due}
            date={`${todo.date} ${todo.time}`}
            notes={todo.notes}
            action={
              canTakeActions && (
                <TooltipTitle title='Done'>
                  <span onClick={() => onDone(todo)} className='cursor-pointer'>
                    <i className='bi bi-check2-all fs-2'></i>
                  </span>
                </TooltipTitle>
              )
            }
          />
        ))}
      </div>
    </div>
  )
}

export default OverviewTodoList
