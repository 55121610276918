import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import query from 'query-string'

import {capitalizeFirstLetter, normalizeFilters} from 'config/utils'
import {getModalTypesOptions, getPrioritiesOptions, getStatusOptions, groupTicketsBy, statusColors} from '../utils'
import {getAllTickets} from '../list/action/actionCreators'
import TicketItem from '../list/components/TicketItem'
import {getBranches} from 'modules/branches/action/actionCreators'
import {getUsers} from 'modules/users/action/actionCreators'

import {getTeams} from '../teams/teams/action/actionCreators'
import {getTicketCategories} from '../teams/TicketCategories/action/actionCreators'
import {getTicketActions} from '../teams/TicketActions/action/actionCreators'

import Card from 'components/Card'
import EmptyList from 'components/EmptyList'
import Filters from 'components/Filters'
import ScrollWrapper from 'components/ScrollWrapper'

const AllTickets = () => {
  const [filters, setFilters] = useState('')
  const dispatch = useDispatch()
  const tickets = useSelector(({ticket}) => ticket.list.all_tickets)
  const {teams, categories, actions} = useSelector(({ticket}) => ticket)
  const branches = useSelector(({branches}) => branches)
  const {client_config} = useSelector(({auth}) => auth)
  const selectedRange = useSelector(({filters}) => filters)
  const users = useSelector(({users}) => users)
  const groupedTickets = groupTicketsBy('status', tickets.data)

  const modelTypes = useSelector(({auth}) => auth.client_config.ticket_models)

  // init => loadMasterData
  // show_all_tickets
  useEffect(() => {
    dispatch(getTeams(1, 100, 'name'))
    dispatch(getUsers(1, 25, '-created_at', 'filter[role]=Tickets Agent,Tickets Team Leader'))
    dispatch(
      getAllTickets(
        query.stringify(
          normalizeFilters({
            // date_from: selectedRange.startDate,
            // date_to: selectedRange.endDate,
          })
        )
      )
    )
    if (client_config.has_branch) {
      dispatch(getBranches(1))
    }
  }, [client_config.has_branch, dispatch, selectedRange.endDate, selectedRange.startDate])

  // when filter change
  // when date range change
  useEffect(() => {
    if (filters) {
      dispatch(
        getAllTickets(
          query.stringify(
            normalizeFilters({
              // date_from: selectedRange.startDate,
              // date_to: selectedRange.endDate,
              ...filters,
            })
          )
        )
      )
    }
  }, [dispatch, filters, selectedRange.endDate, selectedRange.startDate])

  const handleUpdateFilters = (filters) => setFilters(filters)

  const getOptions = (list) => {
    return list && list.map((item) => ({value: item.id, label: item.name || item.action}))
  }

  const getFilters = () => [
    {
      type: 'options',
      name: 'team_id',
      label: 'Team',
      options: getOptions(teams.data),
      onChange: (option) => dispatch(getTicketCategories(1, 100, option.value, '')),
    },
    {
      type: 'options',
      name: 'ticketAction.ticket_category_id',
      label: categories.isFetching ? 'Fetching...' : 'Category',
      disabled: categories.isFetching,
      options: getOptions(categories.data),
      onChange: (option) => dispatch(getTicketActions(1, 100, option.value, '')),
    },
    {
      type: 'options',
      name: 'ticket_action_id',
      label: actions.isFetching ? 'Fetching...' : 'Action',
      disabled: actions.isFetching,
      options: getOptions(actions.data),
    },
    {
      type: 'options',
      name: 'branch_id',
      label: 'Branch',
      options: getOptions(branches.data),
      hidden: !client_config.has_branch,
    },
    {
      type: 'options',
      name: 'agent_id',
      label: 'Agent',
      options: getOptions(users.data),
    },
    {
      type: 'options',
      name: 'created_by',
      label: 'Created By',
      options: getOptions(users.data),
    },
    {
      type: 'options',
      name: 'status',
      label: 'Status',
      options: getStatusOptions(),
    },
    {
      type: 'options',
      name: 'priority',
      label: 'Priority',
      options: getPrioritiesOptions(),
    },
    {
      type: 'options',
      name: 'ticketable_type',
      label: 'Type',
      options: getModalTypesOptions(modelTypes),
    },
    {
      type: 'text',
      name: 'ticketable_id',
      label: 'Model Id',
    },
  ]

  const Header = () => (
    <React.Fragment>
      <div className='d-flex overflow-auto h-100px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item mt-2'>
            <span className='nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer active'>
              All Tickets
              {tickets.data && (
                <span className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 mx-1'>
                  {tickets.data && tickets.data.length}
                </span>
              )}
            </span>
          </li>
        </ul>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end'>
          <Filters filters={getFilters()} handleUpdateFilters={handleUpdateFilters} />
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Card>
        <div className='card-header pt-3 px-5'>{Header()}</div>
      </Card>
      <div className='my-3 px-3'>
        <ScrollWrapper>
          <div className='row g-6 g-xl-9 flex-nowrap h-100'>
            {tickets.data &&
              tickets.data.length &&
              Object.keys(groupedTickets).map((group) => (
                <div className='col-md-4 col-lg-12 col-xl-4' key={group}>
                  <div className='mb-3'>
                    <div className='card d-flex p-2'>
                      <div className='fw-bolder fs-4 d-flex'>
                        {capitalizeFirstLetter(group)}
                        <span className='tickets-column-length mx-3'>
                          {groupedTickets[group].length}
                        </span>
                      </div>
                    </div>
                    <div className={classnames('h-3px w-100', [`bg-${statusColors[group]}`])}></div>
                  </div>
                  <div className='tickets-column'>
                    {groupedTickets[group].map((ticket) => (
                      <TicketItem ticket={ticket} key={ticket.id} />
                    ))}
                  </div>
                </div>
              ))}
            {tickets.data && !tickets.data.length && <EmptyList />}
          </div>
        </ScrollWrapper>
      </div>
    </React.Fragment>
  )
}

export default AllTickets
