import classnames from 'classnames';
import React, { FC, ReactElement } from 'react'

interface CardProps {
  classNames?: string;
  headerClassNames?: string;
  bodyClassNames?: string;
  headerElements?: ReactElement;
  bodyElements?: ReactElement;
  children?: ReactElement | any;
};

const Card: FC<CardProps> = ({ 
  classNames = '', 
  headerClassNames = '',
  bodyClassNames = '',
  headerElements, 
  bodyElements, 
  children 
}) => {
  return (  
    <div className={classnames('card', classNames)}>
      {headerElements && (
        <div className={classnames("card-header align-items-center py-3", headerClassNames)}>
          {headerElements}
        </div>
      )}
      {bodyElements && (
        <div className={classnames("card-body pt-0 pb-0", bodyClassNames)}>
          {bodyElements}
        </div>
      )}
      {children}
    </div>
  )
};

export default Card;
