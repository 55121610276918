import {Field, Form, Formik} from 'formik';
import {getBranches} from 'modules/branches/action/actionCreators';
import {getGroupRounds} from 'modules/course/groupRounds/action/actionCreators';
import {getGroupStatuses} from 'modules/course/groupStatus/action/actionCreators';
import {getCourses} from 'modules/course/list/action/actionCreators';
import {getUsers} from 'modules/users/action/actionCreators';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {groupValidationRules} from '../yup/groupValidationRules';
import Select from 'react-select';
import {can} from 'modules/ticket/utils';
import Checkbox from 'components/Checkbox';

function GroupForm({loading, group, onSubmitHandler}: any) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [courseId, setCourseId] = useState(null);
    const {group_status, group_rounds, list: courses}: any = useSelector<any>(({course}) => course);
    const users: any = useSelector<any>(({users}) => users);
    const branches: any = useSelector<any>(({branches}) => branches);
    const {client_config} = useSelector(({auth}: any) => auth);


    const cancelSubmit = () => history.push('/groups/list');

    useEffect(() => {
        dispatch(getUsers(1, 25, '-created_at', 'filter[role]=instructor'));
        dispatch(getGroupStatuses(1, 20, '-created_at'));
        dispatch(getCourses(1, 20, '-created_at', ''));
        dispatch(getBranches(1));
    }, []);

    useEffect(() => {
        if (courseId) {
            dispatch(getGroupRounds(1, 20, `filter[course_id]=${courseId}`));
        }
    }, [courseId])

    const getOptions = (list) => {
        return list && list.map((listItem) => ({value: listItem.id, label: listItem.name}));
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Group Details</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <Formik initialValues={{
                    name: group ? group.name : '',
                    capacity: group ? group.capacity : '',
                    is_online: group ? `${!!group.is_online}` : 'false',
                    hours_per_session: group ? group.hours_per_session : '',
                    level: group ? group.level : '',
                    instructor_id: group && group.instructor ? {
                        value: group.instructor.id,
                        label: group.instructor.name
                    } : [],
                    group_round_id: group && group.groupRound ? {
                        value: group.groupRound.id,
                        label: group.groupRound.name
                    } : [],
                    group_status_id: group ? {value: group.groupStatus.id, label: group.groupStatus.name} : [],
                    course_id: group ? {value: group.course.id, label: group.course.name} : [],
                    branch_id: group ? {value: group.branch.id, label: group.branch.name} : [],
                    notes: group ? group.notes : '',
                }}
                        validationSchema={groupValidationRules}
                        onSubmit={(values, {
                            setSubmitting,
                            setErrors
                        }) => onSubmitHandler(values, setSubmitting, setErrors)}
                >
                    <Form className="form">
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Group Name</label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="name">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              meta
                                          }) => (
                                            <>
                                                <input type="text" placeholder="Group name"
                                                       className='form-control form-control-lg form-control-solid' {...field} />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Capacity</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="capacity">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              meta
                                          }) => (
                                            <>
                                                <input type="number" min={1}
                                                       className='form-control form-control-lg form-control-solid' {...field} />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Level</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="level">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              meta
                                          }) => (
                                            <>
                                                <input type="number" min={1}
                                                       className='form-control form-control-lg form-control-solid' {...field} />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Hours per session</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="hours_per_session">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              meta
                                          }) => (
                                            <>
                                                <input type="number" min={1}
                                                       className='form-control form-control-lg form-control-solid' {...field} />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Course</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="course_id">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              form,
                                              meta
                                          }) => (
                                            <>
                                                <Select
                                                    styles={{
                                                        // Fixes the overlapping problem of the component
                                                        menu: provided => ({...provided, zIndex: 9999})
                                                    }}
                                                    options={getOptions(courses.data)}
                                                    isDisabled={!!group?.activeStudentCoursesCount}
                                                    classNamePrefix="select-input"
                                                    {...field}
                                                    closeMenuOnSelect
                                                    onChange={(option: any) => {
                                                        form.setFieldValue('course_id', option);
                                                        setCourseId(option.value);
                                                    }}
                                                />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Group rounds</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="group_round_id">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              form,
                                              meta
                                          }) => (
                                            <>
                                                <Select
                                                    styles={{
                                                        // Fixes the overlapping problem of the component
                                                        menu: provided => ({...provided, zIndex: 9999})
                                                    }}
                                                    options={courseId ? getOptions(group_rounds.data) : []}
                                                    isDisabled={!courseId || !!group?.activeStudentCoursesCount}
                                                    classNamePrefix="select-input"
                                                    {...field}
                                                    closeMenuOnSelect
                                                    onChange={(option: any) => form.setFieldValue('group_round_id', option)}
                                                />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Group Status</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="group_status_id">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              form,
                                              meta
                                          }) => (
                                            <>
                                                <Select
                                                    styles={{
                                                        // Fixes the overlapping problem of the component
                                                        menu: provided => ({...provided, zIndex: 9999})
                                                    }}
                                                    options={getOptions(group_status.data)}
                                                    classNamePrefix="select-input"
                                                    {...field}
                                                    closeMenuOnSelect
                                                    onChange={(option: any) => form.setFieldValue('group_status_id', option)}
                                                />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>


                            {client_config.has_branch && (
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Branches</span>
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <Field name="branch_id">
                                            {({
                                                  field, // { name, value, onChange, onBlur }
                                                  form,
                                                  meta
                                              }) => (
                                                <>
                                                    <Select
                                                        styles={{
                                                            // Fixes the overlapping problem of the component
                                                            menu: provided => ({...provided, zIndex: 9999})
                                                        }}
                                                        options={getOptions(branches.data)}
                                                        classNamePrefix="select-input"
                                                        {...field}
                                                        closeMenuOnSelect
                                                        onChange={(option: any) => form.setFieldValue('branch_id', option)}
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <div
                                                            className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                            <div className='fv-help-block'>{meta.error}</div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            )}

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span>Instructor</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="instructor_id">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              form,
                                              meta
                                          }) => (
                                            <>
                                                <Select
                                                    styles={{
                                                        // Fixes the overlapping problem of the component
                                                        menu: provided => ({...provided, zIndex: 9999})
                                                    }}
                                                    options={getOptions(users.data)}
                                                    classNamePrefix="select-input"
                                                    {...field}
                                                    closeMenuOnSelect
                                                    onChange={(option: any) => form.setFieldValue('instructor_id', option)}
                                                />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Online</label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="is_online">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              meta
                                          }) => (
                                            <>
                                                <div className='d-flex align-items-center mt-3'>
                                                    <Checkbox defaultChecked={field.value === 'true'} {...field} />
                                                </div>
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Notes</label>

                                <div className='col-lg-8 fv-row'>
                                    <Field name="notes">
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              meta
                                          }) => (
                                            <>
                                                <input type="text" placeholder="Notes.."
                                                       className='form-control form-control-lg form-control-solid' {...field} />
                                                {meta.touched && meta.error && (
                                                    <div className='fv-plugins-message-container alert-danger p-3 mt-2'>
                                                        <div className='fv-help-block'>{meta.error}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                        </div>

                        {(can.do('create_group') || can.do('update_group')) && (
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='reset'
                                    className='btn btn-sm btn-flex btn-light btn-light-primary fw-bolder mx-3'
                                    onClick={cancelSubmit}
                                >
                                    <span className='indicator-label'>Cancel</span>
                                </button>
                                <button type='submit' className='btn btn-sm btn-primary' disabled={loading}>
                                    {!loading && 'Save Changes'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                                    )}
                                </button>
                            </div>
                        )}
                    </Form>
                </Formik>
            </div>
        </div>
    )
};

export default GroupForm
