import { shallowEqual, useSelector } from 'react-redux'
import TicketItem from './TicketItem';
import EmptyList from 'components/EmptyList';
import { capitalizeFirstLetter } from 'config/utils';
import ScrollWrapper from 'components/ScrollWrapper';
import { groupTicketsBy, statusColors } from 'modules/ticket/utils';
import classnames from 'classnames';

const MyAssignedTickets = () => {
  const tickets: any = useSelector(({ ticket }: any) => ticket.list.assigned_tickets, shallowEqual);

  const groupedTickets = groupTicketsBy('status', tickets.data);

  return (
    <div className="my-3">
      <ScrollWrapper>
        <div className="row g-6 g-xl-9 flex-nowrap h-100">
          {tickets.data && tickets.data.length && (
            Object.keys(groupedTickets).map((group) => (
              <div className="col-md-4 col-lg-12 col-xl-4" key={group}>
                <div className="mb-3">
                  <div className="card d-flex p-2">
                    <div className="fw-bolder fs-4 d-flex">{capitalizeFirstLetter(group)}
                      <span className="tickets-column-length mx-3">{groupedTickets[group].length}</span>
                    </div>
                  </div>
                  <div className={classnames("h-3px w-100", [`bg-${statusColors[group]}`])}></div>
                </div>
                <div className="tickets-column">
                  {groupedTickets[group].map((ticket) => (
                    <TicketItem ticket={ticket} key={ticket.id} />
                  ))}
                </div>
              </div>
            ))
          )}
          {tickets.data && !tickets.data.length && <EmptyList />}
        </div>
      </ScrollWrapper>
    </div>
  )
}

export default MyAssignedTickets;
