import { LOGIN, LOGOUT } from "../action/actionTypes";

export const initialAuthState = {
  user: undefined,
  token: '',
  client_config: {},
  permissions: []
}

function authReducer(state = initialAuthState, action) {
  switch(action.type) {
    case LOGIN:
      return action.payload.data;
    case LOGOUT:
      return initialAuthState;
    default: 
      return state;
  }
}

export default authReducer;