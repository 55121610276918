import {
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_USERS_SUCCESS,
  RESET_FILTERS,
  SET_FETCHING_USERS,
  SET_FILTERS,
  UPDATE_USER_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0
  },
  filters: {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    mobile: '',
    role: null
  },
  isFetching: false
};

function usersReducer(state = initialState, action) {
  switch(action.type) {
    case SET_FETCHING_USERS:
      return {
        ...state,
        isFetching: action.payload.isFetching
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.users.data,
        meta: action.payload.users.meta
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.user]
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        data: state.data.map((user) => {
        if (user.id === action.payload.user.id) {
          return action.payload.user;
        }
        return user;
      })
    }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        data: state.data.filter((user) => user.id !== action.payload.id)
      }
    case SET_FILTERS:
      return {
        ...state, 
        filters: { ...state.filters, ...action.payload.filters }
      }
    case RESET_FILTERS:
      return {
        ...state,
        filters: {}
      }
    default: 
      return state;
  }
}

export default usersReducer;