import React from 'react';
import { toast } from 'react-toastify';

type NotifierOptions = any;
type NotifierResult = number | string | boolean;

interface NotifierInterface {
  component(content: React.ReactNode, options?: NotifierOptions): NotifierResult;
  infoComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult;
  successComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult;
  warnComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult;
  errorComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult;

  message(content: string, options?: NotifierOptions):NotifierResult;
  infoMessage(content: string, options?: NotifierOptions):NotifierResult;
  successMessage(content: string, options?: NotifierOptions):NotifierResult;
  warnMessage(content: string, options?: NotifierOptions):NotifierResult;
  errorMessage(content: string, options?: NotifierOptions):NotifierResult;
}

function MessageAlreadyDisplayed(options?: NotifierOptions) {
  if (options && options.toastId && toast.isActive(options.toastId)) {
    return true;
  }
  return false;
}

const toastifyNotifierImpl: NotifierInterface = {
  component(content: React.ReactNode, options?: NotifierOptions): NotifierResult {
    if (MessageAlreadyDisplayed(options)) {
      return;
    }
    toast(content, options);
  },
  infoComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult {
    if (MessageAlreadyDisplayed(options)) {
      return;
    }
    toast.info(
      <div>
        {content}
      </div>,
      options
    )
  },
  successComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult {
    const newOptions = options ? { ...options, autoClose: true } : { autoClose: true };
    if (MessageAlreadyDisplayed(newOptions)) {
      return;
    }
    toast.success(
      <div>
        {content}
      </div>,
      newOptions
    )
  },
  warnComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult {
    if (MessageAlreadyDisplayed(options)) {
      return;
    }
    toast.warn(
      <div>
        {content}
      </div>,
      options
    )
  },
  errorComponent(content: React.ReactNode, options?: NotifierOptions): NotifierResult {
    const newOptions = options ? { ...options, autoClose: true } : { autoClose: true };
    if (MessageAlreadyDisplayed(newOptions)) {
      return;
    }
    toast.error(
      <div>
        {content}
      </div>,
      newOptions
    )
  },


  message(content: string, options?: NotifierOptions): NotifierResult {
    return toast(content, options);
  },
  infoMessage(content: string, options?: NotifierOptions): NotifierResult {
    return this.infoComponent(content, options);
  },
  successMessage(content: string, options?: NotifierOptions): NotifierResult {
    return this.successComponent(content, options);
  },
  warnMessage(content: string, options?: NotifierOptions): NotifierResult {
    return this.warnComponent(content, options);
  },
  errorMessage(content: string, options?: NotifierOptions): NotifierResult {
    return this.errorComponent(content, options);
  },
};

export { toastifyNotifierImpl as Notifier };