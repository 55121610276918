import React from 'react'
import classNames from 'classnames'
import moment from 'moment'

import {KTSVG} from '_metronic/helpers'
import {attendanceDetailsTabs, getActiveTabComponent} from '../utils'
import {fetchGroupTimelineById, isFutureDate} from 'config/utils'
import SelectOptions from 'components/SelectOptions'
import Switcher from 'components/Switcher'

const UpdateAttendance = ({group, selectedDate, setSelectedGroup}) => {
  const [activeTab, setActiveTab] = React.useState('actions')
  const [sessions, setSessions] = React.useState([])
  const [selectedSession, setSelectedSession] = React.useState(null)
  const [status, setStatus] = React.useState('pending')

  React.useEffect(() => {
    async function fetchSessions() {
      try {
        const response = await fetchGroupTimelineById(group.id)
        setSessions(response.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchSessions()
  }, [group.id])

  const getSessionOptions = () => {
    return (
      sessions &&
      sessions.map((session) => ({
        value: session.id,
        label: `${moment(session.date).format('dddd').substring(0, 3)}, ${session.date} - L${
          session.level
        }`,
      }))
    )
  }

  const AttendanceHeaderCard = () => (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='rounded-icon bg-primary' onClick={() => setSelectedGroup(null)}>
                    <i className='bi bi-arrow-left-short text-white color-white'></i>
                  </div>
                  <span className='text-gray-800 text-hover-primary fs-2 fw-bolder mx-1'>
                    {group.name}
                  </span>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    {group.instructor?.name}
                  </span>
                  <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    {group.branch?.name}
                  </span>
                  <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {group.groupStatus?.name}
                  </span>
                  <span className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com012.svg'
                      className='svg-icon-4 me-1'
                    />
                    {group.course?.name}
                  </span>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{group.capacity}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Capacity</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{group.hours_per_session}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Hours Per Sessions</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{group.level}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Level</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{group.activeStudentCoursesCount}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Active Students</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className='mt-10'>
      <AttendanceHeaderCard />
      {isFutureDate(selectedDate) ? (
        <div className='alert alert-warning my-3' role='alert'>
          You selected future date ({moment(selectedDate).format('YYYY-MM-DD')}). no students will
          be listed as timeline not generated yet.
        </div>
      ) : (
        <React.Fragment>
          <div className='details-tabs bg-white w-full rounded shadow-sm d-flex justify-content-between align-items-center'>
            <ul className='d-flex align-items-center justify-content-start px-0 pt-8 m-0'>
              {attendanceDetailsTabs.map((detailItem) => (
                <li
                  key={detailItem.id}
                  className={classNames('d-flex py-4 mx-5 cursor-pointer', {
                    'border-b-3 border-primary': activeTab === detailItem.id,
                  })}
                  onClick={() => setActiveTab(detailItem.id)}
                >
                  <span
                    className={classNames('mx-3', {
                      'font-bold': activeTab === detailItem.id,
                      'font-medium text-gray-solid': activeTab !== detailItem.id,
                    })}
                  >
                    {detailItem.label}
                  </span>
                </li>
              ))}
            </ul>
            <div className='mx-3'>
              {activeTab === 'actions' ? (
                <SelectOptions
                  options={getSessionOptions()}
                  placeholder='select session'
                  containerClassName='w-300'
                  value={selectedSession}
                  onChange={(option) => setSelectedSession(option)}
                />
              ) : (
                <div className='d-flex gap-2'>
                  Pending
                  <Switcher
                    defaultChecked={status === 'approved,rejected' ? true : false}
                    onChange={(value) => setStatus(value ? 'approved,rejected' : 'pending')}
                  />
                  Approved
                </div>
              )}
            </div>
          </div>
          {getActiveTabComponent(activeTab, {
            group,
            selectedDate,
            selectedSession: selectedSession?.value,
            status
          })}
        </React.Fragment>
      )}
    </div>
  )
}

export default UpdateAttendance
