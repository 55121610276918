import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {useTable} from 'react-table'
import moment from 'moment'

import API from 'config/API'
import {ModalContext} from 'context/ModalContext'
import {getGroupRounds} from './action/actionCreators.js'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import ConfirmationModal from 'components/ConfirmationModal'
import Can from 'components/Can'
import AddGroupRound from './components/AddGroupRound'
import UpdateGroupRound from './components/UpdateGroupRound'

const GroupRounds = ({course}) => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(20)
  const {showModal, hideModal} = React.useContext(ModalContext)
  const group_rounds = useSelector(({course}) => course.group_rounds)
  const dispatch = useDispatch()

  const fetchGroupRounds = React.useCallback(() => {
    dispatch(getGroupRounds(page, size, `filter[course_id]=${course.id}`))
  }, [dispatch, course.id, page, size])

  React.useEffect(() => {
    fetchGroupRounds()
  }, [fetchGroupRounds])
  
  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleDelete = React.useCallback(
    (id) => {
      API.delete(`/course/group_rounds/${id}`)
        .then(() => {
          fetchGroupRounds()
          hideModal()
          Notifier.successMessage('Group round has been deleted!')
        })
        .catch((error) => {
          // Ignore Error Message
        })
    },
    [hideModal, fetchGroupRounds]
  )

  const deleteRound = React.useCallback(
    (id) => {
      showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [handleDelete, hideModal, showModal]
  )

  const updateRound = React.useCallback(
    (round) => {
      showModal({
        id: 'view-group-round-form-modal',
        dialogClassName: 'view-group-round-form',
        closeButton: true,
        title: 'Update Group Round',
        body: <UpdateGroupRound round={round} id={course.id} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal, course.id]
  )

  const addRound = () =>
    showModal({
      id: 'add-group-round-form-modal',
      dialogClassName: 'add-group-round-form',
      closeButton: true,
      title: 'Add Group Round',
      body: <AddGroupRound id={course.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => row.name,
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
        Cell: ({row: {original: row}}) => moment(row.start_date).format('DD-MM-YYYY'),
      },
      {
        Header: 'Actions',
        accessor: 'action',
        className: 'text-right px-10'
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      group_rounds?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex justify-content-end'>
            <Can do='show_course'>
              <div className='rounded-icon' onClick={() => updateRound(dataItem)}>
                <i className='bi bi-pencil'></i>
              </div>
            </Can>
            <div className='rounded-icon' onClick={() => deleteRound(dataItem.id)}>
              <i className='bi bi-trash2'></i>
            </div>
          </div>
        ),
      })),
    [group_rounds, updateRound, deleteRound]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (group_rounds.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Can do='create_group_round'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Add Group Round
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={addRound}
              />
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(group_rounds.data) ? (
        <EmptyList message='No Group Rounds Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(group_rounds.data) && group_rounds.meta.last_page > 1 && (
        <Pagination
          pageCount={group_rounds.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={group_rounds.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default GroupRounds
