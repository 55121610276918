import React from 'react'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import {useHistory, Link} from 'react-router-dom'
import query from 'query-string'

import {getGroupStudents, setGroupStudentsFilters} from './action/actionCreators'
import {getCoursesOptions, getStudentStatusOptions} from './utils'
import {getCourses} from 'modules/course/list/action/actionCreators'
import {normalizeFilters} from 'config/utils'
import {ModalContext} from 'context/ModalContext'

import {can} from 'modules/ticket/utils'
import AssignStudentToGroup from './components/AssignStudentToGroup'
import UpdateStudent from './components/UpdateStudent'
import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Skeleton from 'components/Skeleton'
import Can from 'components/Can'
import TooltipTitle from 'components/TooltipTitle'
import SelectedFilters from 'components/SelectedFilters'
import Input from 'components/Input'

const StudentsList = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(20)
  const [searchText, setSearchText] = React.useState('')
  const [inputSearchTerm, setInputSearchTerm] = React.useState('')
  const {showModal, hideModal} = React.useContext(ModalContext)
  const {list: courses, group_students: students} = useSelector(({course}) => course)
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    dispatch(
      getGroupStudents(
        page,
        size,
        'include=student,course,group,createdBy',
        `${query.stringify(
          normalizeFilters(students.filters)
        )}&filter[studentPhoneNumber]=${searchText}`
      )
    )
  }, [dispatch, page, size, students.filters, searchText])

  const handleChangeSearch = (event) => setSearchText(event.target.value)

  const debouncedChangeHandler = React.useMemo(() => debounce(handleChangeSearch, 1000), [])

  React.useEffect(() => {
    dispatch(getCourses(1, 20, '-created_at', ''))

    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [dispatch, debouncedChangeHandler])

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const assignStudentToGroup = React.useCallback(
    (student) => {
      return showModal({
        id: 'assign-student-form-modal',
        dialogClassName: 'assign-student-form',
        closeButton: true,
        title: 'Assign to group',
        body: <AssignStudentToGroup student={student} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal]
  )

  const updateStudent = React.useCallback(
    (student) => {
      return showModal({
        id: 'update-student-form-modal',
        dialogClassName: 'update-student-form',
        closeButton: true,
        title: 'Update student',
        body: <UpdateStudent student={student} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal]
  )

  const getFilters = () => [
    {name: 'level', type: 'number', label: 'Level'},
    {
      name: 'course_id',
      type: 'options',
      label: 'Course',
      options: getCoursesOptions(courses?.data),
    },
    {name: 'status', type: 'options', label: 'Status', options: getStudentStatusOptions()},
  ]

  const handleUpdateFilters = (filters) => dispatch(setGroupStudentsFilters(filters))

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Student Name',
        accessor: 'student_name',
        Cell: ({row: {original: row}}) => (
          <Link
            to={can.do('show_student_profile') ? `/student/${row.student?.id}` : undefined}
            className='cursor-pointer'
          >
            {row.student?.lead?.name ?? null}
          </Link>
        ),
      },
      {
        Header: 'Mobile',
        accessor: 'student_mobile',
        Cell: ({row: {original: row}}) => row.student?.lead?.mobile ?? null,
      },
      {
        Header: 'WhatsApp',
        accessor: 'student_whatsApp',
        Cell: ({row: {original: row}}) => row?.student?.lead?.phone ?? null,
      },
      {
        Header: 'Course',
        accessor: 'package',
        Cell: ({row: {original: row}}) => row?.course?.name ?? null,
      },
      {
        Header: 'Group',
        accessor: 'group',
        Cell: ({row: {original: row}}) => row.group?.name ?? null,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row: {original: row}}) => row.status,
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({row: {original: row}}) => row.level,
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({row: {original: row}}) => moment(row.created_at).format('DD-MM-YYYY'),
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({row: {original: row}}) => row.createdBy?.name ?? null,
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    [history]
  )

  // table data
  const data = React.useMemo(
    () =>
      students?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <Can do="show_student_profile">
              <TooltipTitle title="Student Profile">
                <div className='rounded-icon' onClick={() => history.push(`/student/${dataItem.id}`)}>
                  <i className='bi bi-eye-fill'></i>
                </div>
              </TooltipTitle>
            </Can>
            <TooltipTitle title='Update Student'>
              <div className='rounded-icon' onClick={() => updateStudent(dataItem)}>
                <i className='bi bi-pencil'></i>
              </div>
            </TooltipTitle>
            <Can do='assign_student_to_group'>
              <TooltipTitle title='Assign to group'>
                <div className='rounded-icon' onClick={() => assignStudentToGroup(dataItem)}>
                  <i className='bi bi-plus'></i>
                </div>
              </TooltipTitle>
            </Can>
          </div>
        ),
      })),
    [students, updateStudent, assignStudentToGroup]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (students.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={students.filters} setFilters={setGroupStudentsFilters} />
            <div className='d-flex gap-1'>
              <Input
                type='text'
                containerClassName='bg-white'
                style={{width: 300}}
                placeholder='Search by mobile...'
                onChange={(event) => {
                  setInputSearchTerm(event.target.value)
                  debouncedChangeHandler(event)
                }}
                value={inputSearchTerm}
              />
              <Filters
                filters={getFilters()}
                handleUpdateFilters={handleUpdateFilters}
                initialValues={students.filters}
              />
            </div>
          </div>
        </div>
      </div>
      {isEmpty(students.data) ? (
        <EmptyList message='No students Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(students.data) && students.meta.last_page > 1 && (
        <Pagination
          pageCount={students.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={students.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default StudentsList
