import React, { Component } from 'react'

import ScrollContainer from 'react-indiana-drag-scroll'

class ScrollWrapper extends Component {
  render () {
    return (
      <ScrollContainer className="scroll-container" vertical={false}> 
        {this.props.children}
      </ScrollContainer>
    )
  }
}

export default ScrollWrapper;