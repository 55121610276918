import {
  ADD_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  GET_TEAMS_SUCCESS,
  RESET_FILTERS,
  SET_FETCHING_TEAMS,
  SET_FILTERS,
  UPDATE_TEAM_SUCCESS,
} from './actionTypes'
import API from 'config/API'

const getTeamsSuccess = (teams) => ({
  type: GET_TEAMS_SUCCESS,
  payload: {teams},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_TEAMS,
  payload: {isFetching},
})

export const getTeams =
  (
    page,
    size, // paginate
    sort,
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/team/crm_teams?include=leader,role,teamTargetAndCommission&paginate=${size}&sort=${sort}&page=${page}&${filters}`
      )
      dispatch(
        getTeamsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      throw new Error(error.message)
    }
  }

export const addTeamSuccess = (team) => ({
  type: ADD_TEAM_SUCCESS,
  payload: {team},
})

export const updateTeamSuccess = (team) => ({
  type: UPDATE_TEAM_SUCCESS,
  payload: {team},
})

export const deleteTeamSuccess = (id) => ({
  type: DELETE_TEAM_SUCCESS,
  payload: {id},
})

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: {filters},
})

export const resetFilters = () => ({
  type: RESET_FILTERS,
  payload: {},
})
