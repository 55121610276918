import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import API from 'config/API'
import omit from 'lodash/omit'
import * as Yup from 'yup'
import DatePicker from 'rsuite/DatePicker'

import Button from 'components/Button'
import Loader from 'components/Loader'

import {ModalContext} from 'context/ModalContext'
import {useSelector} from 'react-redux'
import Input from 'components/Input'
import ErrorInput from 'components/ErrorInput'
import moment from 'moment'

const TodoForm = ({onRefetch, lead_request_id}) => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)
  const agent_id = useSelector(({auth}) => auth.data.id)

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        datetime: Yup.string().required('date time is required'),
        notes: Yup.string().required('Notes are required'),
      })
    ),
    defaultValues: {
      datetime: undefined,
      notes: '',
      todo_type: 'call',
    },
  })

  const onSubmit = async (values) => {
    try {
      const formValues = {
        ...values,
        lead_request_id,
        agent_id,
        date: moment(values.datetime).format('YYYY-MM-DD'),
        time: moment(values.datetime).format('HH:MM'),
      };
      setLoading(true)
      await API.post(`/crm/todos`, omit(formValues, ['datetime']))
      onRefetch()
      setLoading(false)
      hideModal()
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'datetime',
          render: ({field}) => (
            <DatePicker
              format='dd MMM yyyy hh:mm aa'
              showMeridian
              size='lg'
              block
              placement='auto'
              id='datetime'
              placeholder='Date time'
              className='mt-5'
              {...field}
            />
          ),
        }}
      />
      {errors.datetime && <ErrorInput message={errors.datetime.message} />}
      <Controller
        {...{
          control,
          name: 'notes',
          render: ({field}) => (
            <Input
              isTextArea
              placeholder='Notes'
              containerClassName='mt-7'
              id='notes'
              error={errors.notes}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default TodoForm
