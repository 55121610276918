// ex: App\Domain\Student\Entities\Student => Student
import {useSelector} from "react-redux";

export const getTicketModels = (ticketModels) => {
  return ticketModels.map((model) => ({
    id: model,
    name: model.split('\\').pop(),
  }))
}

export const getModalTypesOptions = (ticketModels) => {
  return ticketModels.map((model) => ({
    value: model.replaceAll('\\', '\\\\'),
    label: model.split('\\').pop(),
  }))
}

export const getTicketModelName = (ticketModel) => {
  return ticketModel.split('\\').pop()
}

export const isAgent = (ticketAgentId, authorizedUserId) => {
  return ticketAgentId === authorizedUserId
}

export const isTeamLeader = (ticketReporterId, authorizedUserId) => {
  return ticketReporterId === authorizedUserId
}

export const isAdmin = (ticket, authorizedUserId) =>
  !isAgent(ticket.agent_id, authorizedUserId) && !isTeamLeader(ticket.reporter_id, authorizedUserId)

export const getStatusOptions = () =>
  ['Open', 'In Progress', 'Rejected', 'Closed', 'Escalated'].map((status) => ({
    value: status.toLocaleLowerCase(),
    label: status,
  }))

export const getPrioritiesOptions = () =>
  ['Low', 'Medium', 'High', 'Urgent', 'Critical'].map((priority) => ({
    value: priority.toLocaleLowerCase(),
    label: priority,
  }))

// sort descending
export const sortable = (tickets) =>
  Object.entries(tickets)
    .sort(([, a], [, b]) => b.length - a.length)
    .reduce((r, [k, v]) => ({...r, [k]: v}), {})

// group by status
export const groupTicketsBy = (groupBy, tickets) => {
  if (!tickets) {
    return []
  }

  let groupedTickets = {
    open: [],
    'in progress': [],
    rejected: [],
    closed: [],
    escalated: [],
  }

  for (const group in groupedTickets) {
    groupedTickets[group] = tickets.filter((ticket) => ticket[groupBy] === group)
  }

  return sortable(groupedTickets)
}

export const can = {
  do: (permission) => {
    const permissions = localStorage.getItem('auth')
      ? JSON.parse(localStorage.getItem('auth')).permissions
      : []
    return permissions.includes(permission) ? true : false
  },
}

export const statusColors = {
  open: 'dark',
  'in progress': 'primary',
  rejected: 'danger',
  closed: 'success',
  escalated: 'warning',
}

export const prioritiesColors = {
  low: 'secondary',
  medium: 'primary',
  high: 'dark',
  urgent: 'warning',
  critical: 'danger',
}

export const fileImages = {
  pdf: 'pdf.svg',
  doc: 'doc.svg',
}

// default stats keywords
export const defaultTicketsStats = ['closed', 'escalated', 'in progress', 'open', 'rejected']

export const TicketsStatsIcons = {
  closed: 'bi bi-check-all',
  escalated: 'bi bi-arrow-90deg-up',
  'in progress': 'bi bi-hourglass-split',
  open: 'bi bi-file',
  rejected: 'bi bi-x-octagon',
}

// default stats keywords
export const defaultLeadsStats = [
  'Fresh',
  'Incomplete',
  'Interested',
  'Not_interested',
  'Student',
  'Vodafone_Cash',
  'Refollow',
]
