import {
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_USERS_SUCCESS,
  RESET_FILTERS,
  SET_FETCHING_USERS,
  SET_FILTERS,
  UPDATE_USER_SUCCESS,
} from './actionTypes'
import API from 'config/API'

const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: {users},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_USERS,
  payload: {isFetching},
})

export const getUsers =
  (
    page,
    size, // paginate
    sort,
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/user/users?paginate=${size}&sort=${sort}&page=${page}&${filters}`
      )
      dispatch(
        getUsersSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      throw new Error(error.message)
    }
  }

export const addUserSuccess = (user) => ({
  type: ADD_USER_SUCCESS,
  payload: {user},
})

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: {user},
})

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER_SUCCESS,
  payload: {id},
})

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: {filters},
})

export const resetFilters = () => ({
  type: RESET_FILTERS,
  payload: {},
})
