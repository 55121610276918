import * as Yup from 'yup'

export const teamValidationRules = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  leader: Yup.object().required('leader is required').nullable(),
  role: Yup.object().required('role is required').nullable(),
  leader_target: Yup.number().typeError('Amount must be a number'),
  leader_commission: Yup.number().typeError('Amount must be a number'),
  agent_target: Yup.number().typeError('Amount must be a number'),
  agent_commission: Yup.number().typeError('Amount must be a number')
});