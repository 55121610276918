import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useRouteMatch, Link} from 'react-router-dom'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'
import API from 'config/API'

import {can} from 'modules/ticket/utils'
import {ModalContext} from 'context/ModalContext'
import {deleteTicketCategorySuccess, getTicketCategories} from '../action/actionCreators'
import AddTicketCategory from './AddTicketCategory'
import EditTicketCategory from './EditTicketCategory'

import Can from 'components/Can'
import EmptyList from 'components/EmptyList'
import Pagination from 'components/Pagination'
import Skeleton from 'components/Skeleton'
import Button from 'components/Button'
import TooltipTitle from 'components/TooltipTitle'
import ConfirmationModal from 'components/ConfirmationModal'
import {Notifier} from 'components/Notifier'
import Table from 'components/Table'

const TicketCategories = ({team}) => {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const dispatch = useDispatch()
  const {showModal, hideModal} = useContext(ModalContext)
  const ticketCategoriesList = useSelector(({ticket}) => ticket.categories)
  const match = useRouteMatch()

  useEffect(() => {
    dispatch(getTicketCategories(page, size, match.params.id, ''))
  }, [dispatch, size, page, match.params.id])

  const addTicketCategory = () =>
    showModal({
      id: 'add-ticket-category-form-modal',
      dialogClassName: 'add-ticket-category-form',
      closeButton: true,
      title: 'Add Ticket Category',
      body: <AddTicketCategory id={team.id} ticketTeamId={match.params.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleDelete = (id) =>
    API.delete(`/ticket/ticket_categories/${id}`)
      .then(() => {
        dispatch(deleteTicketCategorySuccess(id))
        hideModal()
        Notifier.successMessage('Category has been deleted!')
      })
      .catch(() => {
        // Notifier
      })

  const deleteTicketCategory = (category) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(category.id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handleEditTicketCategory = (category) => {
    showModal({
      id: 'view-edit-ticket-category-form-modal',
      dialogClassName: 'view-edit-ticket-category-form',
      closeButton: true,
      title: 'Edit Ticket Category',
      body: <EditTicketCategory category={category} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })
  }

  const showTicketAction = (category) => {
    if (can.do('show_ticket_action')) {
      return `/tickets/teams/${category.ticket_team_id}/category/${category.id}`
    }

    return undefined
  }

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Category Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => <Link to={() => showTicketAction(row)}>{row.name}</Link>,
      },
      {
        Header: 'Ticket Actions',
        accessor: 'ticketActions_count',
      },
      {
        Header: 'Actions',
        accessor: 'action',
        className: 'text-right'
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      ticketCategoriesList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex justify-content-end'>
            <Can do='update_ticket_category'>
              <TooltipTitle title='Update category'>
                <div className='rounded-icon' onClick={() => handleEditTicketCategory(dataItem)}>
                  <i className='bi bi-pencil'></i>
                </div>
              </TooltipTitle>
            </Can>
            <Can do='delete_ticket_category'>
              <TooltipTitle title='Delete category'>
                <div className='rounded-icon' onClick={() => deleteTicketCategory(dataItem)}>
                  <i className='bi bi-trash2'></i>
                </div>
              </TooltipTitle>
            </Can>
          </div>
        ),
      })),
    [ticketCategoriesList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (ticketCategoriesList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end align-items-center'>
            <Can do='create_ticket_category'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center align-items-start'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Create Ticket Category
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={addTicketCategory}
              />
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(ticketCategoriesList.data) ? (
        <EmptyList message='No Categories Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(ticketCategoriesList.data) && ticketCategoriesList.meta.last_page > 1 && (
        <Pagination
          pageCount={ticketCategoriesList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={ticketCategoriesList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default TicketCategories
