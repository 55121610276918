import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import API from 'config/API'
import { useQuery } from 'config/utils'
import {Notifier} from "../../../components/Notifier";


const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const resetToken = useQuery(useLocation().search).get("token") || '';
  const email = useQuery(useLocation().search).get("email") || '';
  const history = useHistory();

  useEffect(() => {
    if (!resetToken) history.push('/404'); // TODO: create a generic 404 page
  }, [resetToken, history])


  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      API.post(`/user/reset-password`,
        { password: values.password, 
          password_confirmation: values.password_confirmation, 
          token: resetToken ,
          email: email
        })
        .then(({data: {result}}) => {
          setHasErrors(false)
          setLoading(false)
          history.push('/auth/login');
          Notifier.successMessage('Your password updated successfully!');
        })
        .catch((error) => {
          Notifier.successMessage(error.response.data.message);
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password</h1>
          {/* end::Title */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>password reset successfully.</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2 w-100'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block alert-danger p-3 mt-2'>
                <span role='alert' className="alert-text">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2 w-100'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirm Password</label>
              {/* end::Label */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password_confirmation')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation,
              },
              {
                'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
              }
            )}
          />
          {formik.touched.password_confirmation && formik.errors.password_confirmation && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block alert-danger p-3 mt-2'>
                <span role='alert' className="alert-text">{formik.errors.password_confirmation}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder w-100 me-4'
          >
            <span className='indicator-label'>Reset password</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
