/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'
import TeamsForm from './TeamsForm'
import {ModalContext} from '../../../../../context/ModalContext'
import {updateTeamSuccess} from '../action/actionCreators'
import API from 'config/API'
import {Notifier} from 'components/Notifier'

const EditTeam = ({team}) => {
  const {hideModal} = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.put(`/team/ticket_teams/${team.id}`, {
        name: values.name,
        leader_id: values.leader_id.value,
        role_id: values.role_id.value
      })
      setLoading(false)
      dispatch(
        updateTeamSuccess({
          ...response.data.data,
          leader: {id: values.leader_id.value, name: values.leader_id.label},
          role: {id: values.role_id.value, name: values.role_id.label},
        })
      )
      hideModal()
      Notifier.successMessage('Team has been saved!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <TeamsForm onSubmitHandler={onSubmit} loading={loading} team={team} />
}

export default EditTeam
