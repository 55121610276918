import {
  DELETE_TICKET_ACTION_SUCCESS,
  GET_TICKET_ACTIONS_SUCCESS,
  SET_FETCHING_TICKET_ACTION,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function ticketActionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_TICKET_ACTION:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_TICKET_ACTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.actions.data,
        meta: action.payload.actions.meta,
      }
    case DELETE_TICKET_ACTION_SUCCESS:
      return {
        ...state,
        data: state.data.filter((ticketAction) => ticketAction.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default ticketActionsReducer
