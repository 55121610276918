import classNames from 'classnames';
import Modal from 'react-modal';

import Button from './Button';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const CustomModal = ({
  actions,
  body,
  id,
  title,
  closeButton,
  show,
  dialogClassName,
  closeAction,
  centerTitle = true,
  width = '40%',
  height = '95vh'
}) => {
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 999
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#FFFFFF',
      boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.08)',
      borderRadius: '8px',
      width,
      height,
      overflow: 'auto'
    }
  };

  return (
    <Modal
      isOpen={show}
      id={id}
      onRequestClose={closeAction}
      dialogClassName={classNames('custom-modal', dialogClassName)}
      style={customStyles}
    >
      {closeButton && (
        <div className="d-flex align-items-center justify-content-end">
          <span
            className="d-flex align-items-center justify-content-center w-6 h-6 p-2 rounded bg-gray-native cursor-pointer"
            onClick={closeAction}
          >
            <i className="bi bi-x-circle" style={{ fontSize: 18 }}></i>
          </span>
        </div>
      )}
      <div className="px-12">
        {title && (
          <h1 className={classNames('fs-4 font-weight-bold my-3', { 'text-center': centerTitle })}>
            {title}
          </h1>
        )}
        {body && <div className="modal-body">{body}</div>}
        {actions && (
          <div className="d-flex align-items-center justify-content-center">
            {Object.keys(actions).map((action) => (
              <Button
                className={classNames(actions[action].className, 'mx-1 w-40')}
                id={`custom-modal-${action}-button`}
                disabled={actions[action].disabled}
                onClick={actions[action].onClick}
                type="button"
                key={action}
                title={actions[action].text}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CustomModal;
