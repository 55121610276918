import React from 'react'
import isEmpty from 'lodash/isEmpty'
import {ModalContext} from '../../../context/ModalContext'
import AddChannel from './components/AddChannel'
import ViewChannel from './components/ViewChannel'
import {useDispatch, useSelector} from 'react-redux'
import {getChannels, deleteChannelSuccess} from './action/actionCreators'
import API from 'config/API'
import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import moment from 'moment'
import {useTable} from 'react-table'
import Skeleton from 'components/Skeleton'
import Button from 'components/Button'
import Table from 'components/Table'
import ConfirmationModal from 'components/ConfirmationModal'

const Channels = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const channelsList = useSelector(({channels}) => channels)
  const dispatch = useDispatch()
  const {showModal, hideModal} = React.useContext(ModalContext)

  React.useEffect(() => {
    dispatch(getChannels(page, size, '-created_at', ''))
  }, [dispatch, size, page])

  const handleDeleteChannel = (channelId) =>
    API.delete(`/crm/channels/${channelId}`)
      .then(() => {
        dispatch(deleteChannelSuccess(channelId))
        hideModal()
        Notifier.successMessage('channel has been Deleted!')
      })
      .catch(() => {})

  const deleteChannel = (channelId) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDeleteChannel(channelId)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const viewChannel = (channel) =>
    showModal({
      id: 'view-channel-form-modal',
      dialogClassName: 'view-channel-form',
      closeButton: true,
      title: 'View Channel',
      body: <ViewChannel name={channel.name} id={channel.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const addChannel = () =>
    showModal({
      id: 'add-channel-form-modal',
      dialogClassName: 'add-channel-form',
      closeButton: true,
      title: 'Add Channel',
      body: <AddChannel />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({row: {original: row}}) => moment(row.updated_at).format('DD-MM-YYYY, h:mm A'),
      },
      {
        Header: 'Actions',
        accessor: 'action',
        className: 'text-end px-5',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      channelsList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex justify-content-end'>
            <div className='rounded-icon' onClick={() => viewChannel(dataItem)}>
              <i className='bi bi-pencil'></i>
            </div>
            <div className='rounded-icon' onClick={() => deleteChannel(dataItem.id)}>
              <i className='bi bi-trash2'></i>
            </div>
          </div>
        ),
      })),
    [channelsList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (channelsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Button
              title={
                <span className='d-flex align-items-center justify-content-center'>
                  <i
                    className='bi bi-plus-circle mx-2'
                    style={{color: 'white', fontSize: '1.5rem'}}
                  ></i>
                  Create Channel
                </span>
              }
              className='mt-0 w-auto mx-4 p-3 bg-primary'
              onClick={addChannel}
            />
          </div>
        </div>
      </div>
      {isEmpty(channelsList.data) ? (
        <EmptyList message='No Channels Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(channelsList.data) && channelsList.meta.last_page > 1 && (
        <Pagination
          pageCount={channelsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={channelsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Channels
