import moment from 'moment';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateRange } from './action/actionCreators';

const DateRange = () => {
  const [showDate, setShowDate] = useState(false);
  const selectedRange = useSelector(({ filters }: any) => filters, shallowEqual);
  const dateRef = useRef(null);
  const dispatch = useDispatch();

  useClickOutside(dateRef as any, () => setShowDate(false));

  const handleShowDate = () => setShowDate(!showDate);

  const handleSelect = (ranges: any) => {
    dispatch(updateRange({
      startDate: moment(ranges.selection.startDate).format('YYYY-MM-DD'),
      endDate: moment(ranges.selection.endDate).format('YYYY-MM-DD')
    }));
  };

  const ranges = {
    startDate: new Date(selectedRange.startDate), // this month
    endDate: new Date(selectedRange.endDate),
    key: 'selection'
  };

  return (
    <div className="position-relative m-0" ref={dateRef}>
      <div className="col-auto">
        <label className="sr-only" htmlFor="inlineFormInputGroup">Username</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text h-100 rounded-left rounded-top-right-0 rounded-bottom-right-0">
              <i className="bi bi-calendar-fill"></i>
            </div>
          </div>
          <input 
            value={`${moment(selectedRange.startDate).format('MMMM D, YYYY')} : ${moment(selectedRange.endDate).format('MMMM D, YYYY')}`}
            type="text" 
            className="form-control text-center px-1 fs-7" 
            onFocus={handleShowDate} 
          />
        </div>
      </div>
      {showDate && (
        <DateRangePicker
          className="position-absolute top-4 right-0"
          ranges={[ranges]}
          onChange={handleSelect}
        />
      )}
    </div>
  )
}

export default DateRange;