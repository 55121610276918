import React from 'react'
import { useSelector } from 'react-redux'

const LeadInfo = ({agent = null, lead, course = undefined}) => {
  const authId = useSelector(({auth}) => auth.data.id)

  if (!lead) return null;

  return (
    <div className='card mb-10' style={{height: 400}}>
      <div className='p-8'>
        <div className='lead-avatar bg-bisque color-black'>{lead?.name?.charAt(0).toUpperCase()}</div>
        <h5 className='card-title text-center'>{lead.name}</h5>
        <p className='card-text text-center'>
          <span className='badge bg-bisque color-black'>{course?.name}</span>
        </p>
      </div>
      <ul className='list-group list-group-flush'>
        <li className='list-group-item d-flex my-1'>
          <strong>Mobile</strong>
          <a href={`tel:${lead.phone}`} className='flex-1 text-right color-black'>
            {lead.phone}
          </a>
        </li>
        <li className='list-group-item d-flex my-1'>
          <strong>Email</strong>
          <span className='flex-1 text-right'>{lead.email}</span>
        </li>
        <li className='list-group-item d-flex my-1'>
          <strong>WhatsApp</strong>
          <a
            href={`https://wa.me/${lead.mobile}`}
            target='_blank'
            rel='noopener noreferrer'
            className='flex-1 text-right color-black'
          >
            {lead.mobile}
          </a>
        </li>
        {agent?.id !== authId && (
          <li className='list-group-item d-flex my-1'>
            <strong>Agent Name</strong>
            <span className='flex-1 text-right'>{agent?.name}</span>
          </li>
        )}
      </ul>
    </div>
  )
}

export default LeadInfo
