import React from 'react'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash.debounce'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import moment from 'moment'
import {Link} from 'react-router-dom'

import query from 'query-string'

import {getLeadsLastAction, setFilters} from './action/actionCreators'
import {
  fetchChannels,
  fetchCourses,
  fetchLeadStages,
  fetchUsers,
  normalizeFilters,
} from 'config/utils'

import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Skeleton from 'components/Skeleton'
import SelectedFilters from 'components/SelectedFilters'
import Input from 'components/Input'

const LeadsLastAction = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [coursesList, setCourses] = React.useState(null)
  const [usersList, setUsers] = React.useState(null)
  const [channelsList, setChannels] = React.useState(null)
  const [stagesList, setLeadStages] = React.useState(null)
  const [searchText, setSearchText] = React.useState('')
  const [inputSearchTerm, setInputSearchTerm] = React.useState('')
  const leadsLastActionList = useSelector(({crm}) => crm.reports.leadsLastAction)
  const authId = useSelector(({auth}) => auth.data.id)
  const dispatch = useDispatch()

  React.useEffect(
    () =>
      dispatch(
        getLeadsLastAction(
          page,
          size,
          `${query.stringify(
            normalizeFilters(leadsLastActionList.filters)
          )}&filter[lead.mobile]=${searchText}`
        )
      ),
    [dispatch, size, leadsLastActionList.filters, page, searchText]
  )

  const handleChangeSearch = (event) => setSearchText(event.target.value)

  const debouncedChangeHandler = React.useMemo(() => debounce(handleChangeSearch, 1000), [])

  const fetchFiltersListOptions = async () => {
    const [courses, leadStages, users, channels] = await Promise.all([
      fetchCourses(),
      fetchLeadStages(),
      fetchUsers(`filter[role]=Social Media Agent&Sales Agent`),
      fetchChannels(),
    ])
    // set state
    setCourses(courses.data)
    setLeadStages(leadStages.data)
    setUsers(users.data)
    setChannels(channels.data)
  }

  React.useEffect(() => {
    fetchFiltersListOptions()
  }, [])

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const handleUpdateFilters = (filters) => dispatch(setFilters(filters))

  const getOptions = (data) => {
    return data && data?.map((dataItem) => ({value: dataItem.id, label: dataItem.name}))
  }

  const getFilters = () => [
    {name: 'created_at', type: 'date', label: 'Creation Date'},
    {name: 'course_id', type: 'options', label: 'Course', options: getOptions(coursesList)},
    {name: 'agent_id', type: 'options', label: 'Agent', options: getOptions(usersList)},
    {name: 'lead_stage_id', type: 'options', label: 'Stages', options: getOptions(stagesList)},
    {name: 'channel_id', type: 'options', label: 'Channel', options: getOptions(channelsList)},
    {name: 'days_after_last_action', type: 'number', label: 'Days From Last Action'},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Lead',
        accessor: 'lead',
        Cell: ({row: {original: row}}) => (
          <Link to={{pathname: `/leads/${row?.id}`, canTakeActions: row.agent_id === authId}}>
            {row.lead?.name ?? null}
          </Link>
        ),
      },
      {
        Header: 'Lead Mobile',
        accessor: 'mobile',
        Cell: ({row: {original: row}}) => row.lead?.mobile ?? null,
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({row: {original: row}}) => row.agent?.name ?? null,
      },
      {
        Header: 'Course',
        accessor: 'course',
        Cell: ({row: {original: row}}) => row.course?.name ?? null,
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({row: {original: row}}) => moment(row?.created_at).format('YYYY-MM-DD, hh:mm A'),
      },
      {
        Header: 'Last Action',
        accessor: 'visit_call',
        Cell: ({row: {original: row}}) => row.visitCallStatus?.name ?? 'N/A',
      },
      {
        Header: 'Stage',
        accessor: 'leadStage',
        Cell: ({row: {original: row}}) => row?.leadStage?.name ?? null,
      },
      {
        Header: 'Last Call',
        accessor: 'last_call',
        Cell: ({row: {original: row}}) => row?.lastCall?.date ?? 'N/A',
      },
      {
        Header: 'Channel',
        accessor: 'channel',
        Cell: ({row: {original: row}}) => row.channel?.name ?? null,
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () => leadsLastActionList?.data.map((dataItem) => dataItem),
    [leadsLastActionList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (leadsLastActionList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={leadsLastActionList.filters} setFilters={setFilters} />
            <div className='d-flex gap-1'>
              <Input
                type='text'
                containerClassName='bg-white'
                style={{width: 300}}
                placeholder='Search by mobile...'
                onChange={(event) => {
                  setInputSearchTerm(event.target.value)
                  debouncedChangeHandler(event)
                }}
                value={inputSearchTerm}
              />
              <Filters
                filters={getFilters()}
                handleUpdateFilters={handleUpdateFilters}
                initialValues={leadsLastActionList.filters}
              />
            </div>
          </div>
        </div>
      </div>
      {isEmpty(leadsLastActionList.data) ? (
        <EmptyList message='No Leads Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(leadsLastActionList.data) && leadsLastActionList.meta.last_page > 1 && (
        <Pagination
          pageCount={leadsLastActionList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={leadsLastActionList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default LeadsLastAction
