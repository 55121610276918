import {SET_FETCHING_GROUP_DAYS, GET_GROUP_DAYS_SUCCESS} from './actionTypes'
import API from 'config/API'

const getGroupDaysSuccess = (days) => ({
  type: GET_GROUP_DAYS_SUCCESS,
  payload: {days},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_GROUP_DAYS,
  payload: {isFetching},
})

export const getGroupDays =
  (
    page,
    size, // paginate
    filter
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const url = `/course/group_days?include=group&paginate=${size}&page=${page}&${filter}`
      const response = await API.get(url)
      dispatch(
        getGroupDaysSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
    } catch (error) {
      dispatch(setFetching(true))
      throw new Error(error.response)
    }
  }
