/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'

import {getTicketActions} from '../action/actionCreators'
import TicketActionsForm from './TicketActionsForm'
import API from 'config/API'
import {ModalContext} from 'context/ModalContext'

import {Notifier} from 'components/Notifier'

const AddTicketAction = ({action}) => {
  const dispatch = useDispatch()
  const {hideModal} = useContext(ModalContext)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.put(`/ticket/ticket_actions/${action.id}`, {
        ...values,
        duration: action.duration,
        ticket_category_id: action.ticket_category_id,
        model_type: values.model_type?.value,
      })
      setLoading(false)
      dispatch(getTicketActions(1, 10, action.ticket_category_id, ''))
      hideModal()
      Notifier.successMessage('Action has been created!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <TicketActionsForm onSubmitHandler={onSubmit} loading={loading} action={action} />
}

export default AddTicketAction
