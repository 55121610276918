import React, {useState, useEffect, useContext} from 'react'
import {Notifier} from 'components/Notifier'
import isEmpty from 'lodash/isEmpty'
import {useTable} from 'react-table'

import API from 'config/API'
import {isPastDate, isTodayDate} from 'config/utils'

import {attendanceBadges} from 'modules/course/groupStudents/utils'
import {ModalContext} from 'context/ModalContext'
import UpdateStudentNotes from './UpdateStudentNotes'

import EmptyList from 'components/EmptyList'
import TooltipTitle from 'components/TooltipTitle'
import Skeleton from 'components/Skeleton'
import Table from 'components/Table'
import Loader from 'components/Loader'
import StoreAttendanceChangeNotes from './StoreAttendanceChangeNotes'
import Can from 'components/Can'

const StudentsAttendanceActions = ({data: {group, selectedDate, selectedSession}}) => {
  const [attendance, setAttendance] = useState({
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      to: 0,
      per_page: 0,
      total: 0,
      last_page: 0,
    },
  })
  const [loading, setLoading] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [sendingAttendance, setSendingAttendance] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(false)
  const {showModal, hideModal} = useContext(ModalContext)

  useEffect(() => {
    async function fetchAttendance() {
      try {
        setLoading(true)
        const response = await API.get(
          `/student/student_attendances?include=studentCourse.student,updatedBy,groupTimeline&filter[group_timeline_id]=${
            selectedSession ? selectedSession : group.groupTimelines && group.groupTimelines[0].id
          }`
        )
        setAttendance(response.data)
        setLoading(false)
        setRefetch(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (isPastDate(selectedDate) || isTodayDate(selectedDate)) {
      fetchAttendance()
    }
  }, [refetch, selectedDate, group.groupTimelines, selectedSession])

  const storeAttendanceChange = React.useCallback(
    (attendance, status) => {
      return showModal({
        id: 'add-attendance-notes-form-modal',
        dialogClassName: 'add-attendance-notes-form',
        closeButton: true,
        title: 'Add Notes',
        body: (
          <StoreAttendanceChangeNotes
            attendance={attendance}
            status={status}
            setRefetch={setRefetch}
          />
        ),
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal]
  )

  const updateStudentAttendance = async (attendance, status) => {
    try {
      setSelectedStatus(status)
      setSendingAttendance(true)
      await API.put(`/student/student_attendances/${attendance.id}/`, {status})
      setRefetch(true)
      Notifier.successMessage('Attendance updated!')
      setSendingAttendance(false)
    } catch (error) {
      setSendingAttendance(false)
    }
  }

  const applyAction = React.useCallback(
    (attendance, status) => {
      if (isPastDate(attendance.groupTimeline.date)) {
        return storeAttendanceChange(attendance, status)
      }

      return updateStudentAttendance(attendance, status)
    },
    [storeAttendanceChange]
  )

  const updateStudentNotes = React.useCallback(
    (studentId, notes = '') => {
      return showModal({
        id: 'add-student-notes-form-modal',
        dialogClassName: 'add-student-notes-form',
        closeButton: true,
        title: 'Add Notes',
        body: <UpdateStudentNotes studentId={studentId} notes={notes} setRefetch={setRefetch} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [hideModal, showModal, setRefetch]
  )

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.name ?? 'N/A',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.mobile ?? 'N/A',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row: {original: row}}) => (
          <span
            className={`fw-bolder badge text-light fs-8 w-auto badge-${
              attendanceBadges[row?.status]
            }`}
          >
            {row?.status}
          </span>
        ),
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({row: {original: row}}) => (
          <div className='d-flex align-items-center gap-1'>
            <span className='line-clamp-1'>{row?.notes ?? 'N/A'}</span>
            <Can do='approve_student_attendance_change'>
              <TooltipTitle title='Update Notes'>
                <button
                  onClick={() => updateStudentNotes(row.id, row.notes)}
                  className='rounded-icon'
                >
                  <i className='bi bi-pencil'></i>
                </button>
              </TooltipTitle>
            </Can>
          </div>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updatedBy',
        Cell: ({row: {original: row}}) => row.updatedBy?.name ?? 'N/A',
      },
      {
        Header: 'Actions',
        accessor: 'action',
        className: 'text-right px-5',
      },
    ],
    [updateStudentNotes]
  )

  // table data
  const data = React.useMemo(
    () =>
      attendance?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex align-items-center justify-content-end'>
            <Can do='approve_student_attendance_change'>
              <button
                onClick={() => applyAction(dataItem, 'attend')}
                className='btn fs-8 btn-bg-success text-light btn-sm me-1'
              >
                {sendingAttendance && selectedStatus === 'attend' ? <Loader /> : 'Attend'}
              </button>
              <button
                onClick={() => applyAction(dataItem, 'absent')}
                className='btn fs-8 btn-bg-danger text-light btn-active-color-primary btn-sm me-1'
              >
                {sendingAttendance && selectedStatus === 'absent' ? <Loader /> : 'Absent'}
              </button>
              <button
                onClick={() => applyAction(dataItem, 'apologize')}
                className='btn fs-8 btn-bg-warning text-light btn-active-color-primary btn-sm me-1'
              >
                {sendingAttendance && selectedStatus === 'apologize' ? <Loader /> : 'Apologize'}
              </button>
            </Can>
          </div>
        ),
      })),
    [attendance, applyAction, sendingAttendance, selectedStatus]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (loading) {
    return <Skeleton />
  }

  if (isEmpty(attendance.data)) {
    return <EmptyList message='No Attendance Available' />
  }

  return <Table table={table} isFetching={false} />
}

export default StudentsAttendanceActions
