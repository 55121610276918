import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// reducers
import channelsReducer from '../modules/general/channels/reducer/reducer'
import authReducer from '../modules/auth/reducer/reducer'
import complainCategoriesReducer from '../modules/general/complainCategories/reducer/reducer'
import leadAdTypesReducer from '../modules/general/leadAdTypes/reducer/reducer'
import usersReducer from '../modules/users/reducer/reducer'
import teamsReducer from '../modules/teams/reducer/reducer'
import TicketTeamsReducer from '../modules/ticket/teams/teams/reducer/reducer'
import TeamMembersReducer from '../modules/ticket/teams/TeamMembers/reducer/reducer'
import ticketCategoriesReducer from '../modules/ticket/teams/TicketCategories/reducer/reducer'
import ticketActionsReducer from '../modules/ticket/teams/TicketActions/reducer/reducer'
import ticketsReducer from '../modules/ticket/list/reducer/reducer'
import branchesReducer from '../modules/branches/reducer/reducer'
import coursesReducer from 'modules/course/list/reducer/reducer'
import groupStatusReducer from 'modules/course/groupStatus/reducer/reducer'
import groupRoundsReducer from 'modules/course/groupRounds/reducer/reducer'
import groupsReducer from 'modules/course/groups/reducer/reducer'
import groupDaysReducer from 'modules/course/groupDay/reducer/reducer'
import groupTimelineReducer from 'modules/course/groupTimeline/reducer/reducer'
import groupStudentsReducer from 'modules/course/groupStudents/reducer/reducer'
import rangesReducer from 'components/DateRange/reducer/reducer'
import projectsReducer from 'modules/CRM/projects/reducer/reducer'
import complaintsReducer from 'modules/CRM/complaints/reducer/reducer'
import leadAdsReducer from 'modules/CRM/mediaBuying/reducer/reducer'
import agentsReducer from 'modules/agents/reducer/reducer'
import leadsReducer from 'modules/CRM/leads/reducer/reducer'
import leadsPerDayReducer from 'modules/reports/leadsPerDay/reducer/reducer'
import leadsPerAgentReducer from 'modules/reports/leadsPerAgent/reducer/reducer'
import leadsLastActionReducer from 'modules/reports/leadsLastAction/reducer/reducer'

const composeEnhancers = 
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const rootReducer = combineReducers({
  auth: authReducer,
  channels: channelsReducer,
  complain_categories: complainCategoriesReducer,
  lead_ad_types: leadAdTypesReducer,
  users: usersReducer,
  teams: teamsReducer,
  branches: branchesReducer,
  crm: combineReducers({
    projects: projectsReducer,
    complaints: complaintsReducer,
    leadAds: leadAdsReducer,
    agents: agentsReducer,
    leads: leadsReducer,
    reports: combineReducers({
      leadsPerDay: leadsPerDayReducer,
      leadsPerAgent: leadsPerAgentReducer,
      leadsLastAction: leadsLastActionReducer
    })
  }),
  ticket: combineReducers({
    teams: TicketTeamsReducer,
    teamMembers: TeamMembersReducer,
    categories: ticketCategoriesReducer,
    actions: ticketActionsReducer,
    list: ticketsReducer
  }),
  course: combineReducers({
    list: coursesReducer,
    group_status: groupStatusReducer,
    group_rounds: groupRoundsReducer,
    group_days: groupDaysReducer,
    group_timeline: groupTimelineReducer,
    group_students: groupStudentsReducer,
    groups: groupsReducer
  }),
  filters: rangesReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
)

export default store;
