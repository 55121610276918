import React from 'react';

const Checkbox = ({ checked, onChange, ...rest }) => (
  <input
    type="checkbox"
    checked={checked}
    onChange={onChange}
    // className="w-4 h-4 border-slate-200 border-[1px] rounded-none"
    {...rest}
  />
);

export default Checkbox;
