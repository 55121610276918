import {
  DELETE_TICKET_ACTION_SUCCESS,
  GET_TICKET_ACTIONS_SUCCESS,
  SET_FETCHING_TICKET_ACTION,
} from './actionTypes'
import API from 'config/API'

export const setFetching = (isFetching) => ({
  type: SET_FETCHING_TICKET_ACTION,
  payload: {isFetching},
})

const getTicketActionsSuccess = (actions) => ({
  type: GET_TICKET_ACTIONS_SUCCESS,
  payload: {actions},
})

export const getTicketActions = (page, size, ticket_category_id, filter) => async (dispatch) => {
  try {
    dispatch(setFetching(true))
    const url = `/ticket/ticket_actions?include=ticketCategory&page=${page}&paginate=${size}&filter[ticket_category_id]=${ticket_category_id}&filter[action]=${filter}`
    const response = await API.get(url)
    dispatch(
      getTicketActionsSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
    dispatch(setFetching(false))
  } catch (error) {
    dispatch(setFetching(false))
    throw new Error(error.response)
  }
}

export const deleteTicketActionSuccess = (id) => ({
  type: DELETE_TICKET_ACTION_SUCCESS,
  payload: {id},
})
