import API from 'config/API'
import {GET_GROUP_ROUNDS_SUCCESS, SET_FETCHING_ROUNDS} from './actionTypes'

const setFetching = (isFetching) => ({
  type: SET_FETCHING_ROUNDS,
  payload: {isFetching},
})

const getGroupRoundsSuccess = (rounds) => ({
  type: GET_GROUP_ROUNDS_SUCCESS,
  payload: {rounds},
})

export const getGroupRounds =
  (
    page,
    size, // paginate
    filter
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const url = `/course/group_rounds?include=course&paginate=${size}&page=${page}&${filter}`
      const response = await API.get(url)
      dispatch(
        getGroupRoundsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
    } catch (error) {
      // Ignore Error  Message
      throw new Error(error.response)
    }
  }
