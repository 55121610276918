/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { Route, Switch } from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import AuthPage from '../modules/auth/AuthPage'
import { NotFound } from 'components/errors/NotFound'

const Routes = () => {
  const isAuthorized = useSelector<any>(({ auth }) => auth.token, shallowEqual)
  return (
    !isAuthorized ? <AuthPage /> : (
      <Switch>
        <Route path="/404" exact render={() => <NotFound />} />
        <Route path="/" component={PrivateRoutes} />
      </Switch>
    )
  )
}

export {Routes}
