import {
  GET_LEADS_PER_AGENT_SUCCESS,
  RESET_LEADS_PER_AGENT_FILTERS,
  SET_FETCHING_LEADS_PER_AGENT,
  SET_LEADS_PER_AGENT_FILTERS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  filters: {
    course_id: null,
    date_from: '',
    date_to: ''
  },
  isFetching: false,
}

function leadsPerAgentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_LEADS_PER_AGENT:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_LEADS_PER_AGENT_SUCCESS:
      return {
        ...state,
        data: action.payload.leads.data,
        meta: action.payload.leads.meta
      }
    case SET_LEADS_PER_AGENT_FILTERS:
      return {
        ...state,
        filters: {...state.filters, ...action.payload.filters},
      }
    case RESET_LEADS_PER_AGENT_FILTERS:
      return {
        ...state,
        filters: {},
      }
    default:
      return state
  }
}

export default leadsPerAgentReducer
