import moment from "moment";
import { AuthTypeTypes } from "../action/actionCreators";
import { RangesActionTypes } from "../action/actionTypes";
import { RangesDto } from "../model/RangesDto";

export const initialState: RangesDto = {
  startDate: moment(new Date(moment().startOf('month').toDate())).format('YYYY-MM-DD'),
  endDate: moment(new Date(moment().endOf('month').toDate())).format('YYYY-MM-DD'),
}

function rangesReducer(state = initialState, action: AuthTypeTypes) {
  switch(action.type) {
    case RangesActionTypes.UPDATE_RANGE:
      return action.payload.ranges;
    default: 
      return state;
  }
}

export default rangesReducer;