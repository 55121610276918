import React from 'react'
import query from 'query-string'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash.debounce'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import {Link, useLocation, useHistory} from 'react-router-dom'

// helpers
import API from 'config/API'
import {ModalContext} from 'context/ModalContext'
import {getUsers} from 'modules/users/action/actionCreators'
import {normalizeFilters, useQuery} from 'config/utils'
import {getGroupStatuses} from '../groupStatus/action/actionCreators'
import {getCourses} from '../list/action/actionCreators'
import {getGroupRounds} from '../groupRounds/action/actionCreators.js'
import {getGroups, setGroupsFilters, resetGroupsFilters} from './action/actionCreators'

// components
import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/ConfirmationModal'
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import Skeleton from 'components/Skeleton'
import Button from 'components/Button'
import Table from 'components/Table'
import Can from 'components/Can'
import SelectedFilters from 'components/SelectedFilters'
import TooltipTitle from 'components/TooltipTitle'

const Groups = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(20)
  const [searchText, setSearchText] = React.useState('')
  const {showModal, hideModal} = React.useContext(ModalContext)
  const users = useSelector(({users}) => users.data)
  const {groups, group_status, list: courses, group_rounds} = useSelector(({course}) => course)
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const group_round_id = useQuery(location.search).get('group_round_id')

  const handleChangeSearch = (event) => setSearchText(event.target.value)
  const debouncedChangeHandler = React.useMemo(() => debounce(handleChangeSearch, 1000), [])

  const fetchGroups = React.useCallback(() => {
    dispatch(
      getGroups(
        page,
        size,
        '-created_at',
        'include=course,groupStatus,activeStudentCoursesCount,branch,instructor',
        `${query.stringify(normalizeFilters(groups.filters))}&filter[name]=${searchText}`
      )
    )
  }, [dispatch, page, size, groups.filters, searchText])

  React.useEffect(() => {
    // fetch groups
    fetchGroups()

    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler, fetchGroups])

  React.useEffect(() => {
    if (group_round_id) {
      const round =
        group_rounds.data && group_rounds.data.find((round) => round.id === group_round_id)
      dispatch(
        setGroupsFilters({
          group_round_id: {
            value: round?.id,
            label: 'Round',
            name: round?.name,
            key: 'group_round_id',
          },
        })
      )
    }
    dispatch(getUsers(1, 25, '-created_at', 'filter[role]=instructor'))
    dispatch(getGroupStatuses(page, size, '-created_at'))
    dispatch(getCourses(1, 25, '-created_at', ''))

    return () => {
      dispatch(resetGroupsFilters())
    }
  }, [group_round_id])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleDelete = React.useCallback(
    (id) => {
      API.delete(`/course/groups/${id}`)
        .then(() => {
          fetchGroups()
          hideModal()
          Notifier.successMessage('Group has been deleted!')
        })
        .catch((error) => {
          // Ignore Error Message
        })
    },
    [hideModal, fetchGroups]
  )

  const deleteGroupItem = React.useCallback(
    (id) => {
      showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [handleDelete, hideModal, showModal]
  )

  const getOptions = (list) => {
    return list && list.map((item) => ({value: item.id, label: item.name}))
  }

  const handleUpdateFilters = (filters) => dispatch(setGroupsFilters(filters))

  const getFilters = () => [
    {name: 'levelIsLE', type: 'number', label: 'Level'},
    {
      name: 'course_id',
      label: 'Course',
      type: 'options',
      options: getOptions(courses.data),
      onChange: (option) => dispatch(getGroupRounds(page, size, `filter[course_id]=${option.value}`)),
    },
    {
      name: 'group_round_id',
      label: group_rounds.isFetching ? 'Fetching...' : 'Round',
      disabled: group_rounds.isFetching,
      type: 'options',
      options: getOptions(group_rounds.data),
    },
    {
      name: 'group_status_id',
      label: 'Status',
      type: 'options',
      options: getOptions(group_status.data),
    },
    {name: 'instructor_id', label: 'Instructor', type: 'options', options: getOptions(users)},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => <Link to={`/groups/${row.id}`}>{row.name}</Link>,
      },
      {
        Header: 'Course',
        accessor: 'course',
        Cell: ({row: {original: row}}) => row.course?.name ?? null,
      },
      {
        Header: 'Instructor',
        accessor: 'instructor',
        Cell: ({row: {original: row}}) => row.instructor?.name ?? null,
      },
      {
        Header: 'Status',
        accessor: 'groupStatus',
        Cell: ({row: {original: row}}) => row.groupStatus?.name ?? null,
      },
      {
        Header: 'Capacity',
        accessor: 'capacity',
        Cell: ({row: {original: row}}) => row?.capacity ?? null,
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({row: {original: row}}) => row?.level ?? null,
      },
      {
        Header: 'Remaining',
        accessor: 'remaining',
        Cell: ({row: {original: row}}) =>
          Number(row.capacity) - Number(row.activeStudentCoursesCount) ?? 0,
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      groups?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <TooltipTitle title='Group Details'>
              <Can do='show_group'>
                <div
                  className='rounded-icon'
                  onClick={() => history.push(`/groups/${dataItem.id}`)}
                >
                  <i className='bi bi-eye-fill'></i>
                </div>
              </Can>
            </TooltipTitle>
            <div className='rounded-icon' onClick={() => deleteGroupItem(dataItem.id)}>
              <i className='bi bi-trash2'></i>
            </div>
          </div>
        ),
      })),
    [groups, history, deleteGroupItem]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (groups.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={groups.filters} setFilters={setGroupsFilters} />
            <div className='d-flex'>
              <Can do='create_group'>
                <Link to='/groups/create'>
                  <Button
                    title={
                      <span className='d-flex align-items-center justify-content-center'>
                        <i
                          className='bi bi-plus-circle mx-2'
                          style={{color: 'white', fontSize: '1.5rem'}}
                        ></i>
                        Add Group
                      </span>
                    }
                    className='mt-0 w-auto mx-4 p-3 bg-primary'
                  />
                </Link>
              </Can>
              <Filters
                filters={getFilters()}
                handleUpdateFilters={handleUpdateFilters}
                initialValues={groups.filters}
              />
            </div>
          </div>
        </div>
      </div>
      {isEmpty(groups.data) ? (
        <EmptyList message='No Groups Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(groups.data) && groups.meta.last_page > 1 && (
        <Pagination
          pageCount={groups.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={groups.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Groups
