import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import query from 'query-string'

import {getLeadsPerAgent, setFilters} from './action/actionCreators'
import {fetchCourses, normalizeFilters} from 'config/utils'

import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Skeleton from 'components/Skeleton'
import SelectedFilters from 'components/SelectedFilters'

const LeadsPerAgent = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [coursesList, setCourses] = React.useState(null)
  const leadsPerAgentList = useSelector(({crm}) => crm.reports.leadsPerAgent)
  const rangeFilters = useSelector(({filters}) => filters)

  const dispatch = useDispatch()

  const fetchLeads = React.useCallback(() => {
    const [date_from = '', date_to = ''] = leadsPerAgentList.filters?.date ?? []
    dispatch(
      getLeadsPerAgent(
        page,
        size,
        query.stringify(
          normalizeFilters({
            ...leadsPerAgentList.filters,
            date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : '',
            date_to: date_to ? moment(date_to).format('YYYY-MM-DD') : '',
          })
        )
      )
    )
  }, [dispatch, size, leadsPerAgentList.filters, page])

  const fetchFiltersListOptions = async () => {
    const [courses] = await Promise.all([fetchCourses()])
    // set state
    setCourses(courses.data)
  }

  React.useEffect(() => {
    fetchLeads()
    fetchFiltersListOptions()
  }, [fetchLeads])

  React.useEffect(() => {
    dispatch(
      setFilters({
        ...leadsPerAgentList.filters,
        date: [new Date(rangeFilters.startDate), new Date(rangeFilters.endDate)],
      })
    )
  }, [])

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const handleUpdateFilters = (filters) => dispatch(setFilters(filters))

  const getOptions = (data) => {
    return data && data?.map((dataItem) => ({value: dataItem.id, label: dataItem.name}))
  }

  const getFilters = () => [
    {name: 'course_id', type: 'options', label: 'Course', options: getOptions(coursesList)},
    {name: 'date', type: 'range', label: 'From ~ To'},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({row: {original: row}}) => row.agent_name,
      },
      {
        Header: 'Total Leads',
        accessor: 'leads',
        Cell: ({row: {original: row}}) => row.leads,
      },
      {
        Header: 'Created Leads',
        accessor: 'created_leads',
        Cell: ({row: {original: row}}) => row.created_leads,
      },
      {
        Header: 'Interested',
        accessor: 'Interested',
        Cell: ({row: {original: row}}) => row.Interested,
      },
      {
        Header: 'Not interested',
        accessor: 'Not_Interested',
        Cell: ({row: {original: row}}) => row.Not_Interested,
      },
      {
        Header: 'Student',
        accessor: 'Student',
        Cell: ({row: {original: row}}) => row.Student,
      },
      {
        Header: 'Vodafone Cash',
        accessor: 'Vodafone_Cash',
        Cell: ({row: {original: row}}) => row.Vodafone_Cash,
      },
      {
        Header: 'Re follow',
        accessor: 'Refollow',
        Cell: ({row: {original: row}}) => row.Refollow,
      },
      {
        Header: 'Incomplete',
        accessor: 'Incomplete',
        Cell: ({row: {original: row}}) => row.Incomplete,
      },
      {
        Header: 'Conversion',
        accessor: 'percentage_quality',
        Cell: ({row: {original: row}}) => row.percentage_quality,
      },
      {
        Header: 'Achievement',
        accessor: 'achieved_target',
        Cell: ({row: {original: row}}) => row.achieved_target,
      },
      {
        Header: 'Target',
        accessor: 'target',
        Cell: ({row: {original: row}}) => row.should_achieved_target,
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () => leadsPerAgentList?.data.map((dataItem) => dataItem),
    [leadsPerAgentList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (leadsPerAgentList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={leadsPerAgentList.filters} setFilters={setFilters} />
            <Filters
              filters={getFilters()}
              handleUpdateFilters={handleUpdateFilters}
              initialValues={leadsPerAgentList.filters}
            />
          </div>
        </div>
      </div>
      {isEmpty(leadsPerAgentList.data) ? (
        <EmptyList message='No Leads Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(leadsPerAgentList.data) && leadsPerAgentList.meta.last_page > 1 && (
        <Pagination
          pageCount={leadsPerAgentList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={leadsPerAgentList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default LeadsPerAgent
