import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import API from 'config/API'

import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'

import {ModalContext} from 'context/ModalContext'
import {budgetValidationRules} from '../yup/budgetValidationRules'
import {getLeadAds} from '../action/actionCreators'
import {useDispatch} from 'react-redux'
import {Notifier} from 'components/Notifier'

const AddLeadBudget = ({lead_ad_id}) => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)
  const dispatch = useDispatch()

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(budgetValidationRules),
    defaultValues: {
      date_from: '',
      date_to: '',
      budget: undefined,
      lead_ad_id,
    },
  })

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await API.post(`/crm/lead_ad_budgets`, values)
      setLoading(false)
      dispatch(getLeadAds(1, 10, 'name'))
      hideModal()
      Notifier.successMessage('Lead Ad Budget has been updated!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'date_from',
          render: ({field}) => (
            <Input
              type='date'
              placeholder='Start Date'
              containerClassName='mt-7'
              id='date_from'
              error={errors.date_from}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'date_to',
          render: ({field}) => (
            <Input
              type='date'
              placeholder='End Date'
              containerClassName='mt-7'
              id='date_to'
              error={errors.date_to}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'budget',
          render: ({field}) => (
            <Input
              type='number'
              placeholder='Budget'
              containerClassName='mt-7'
              id='budget'
              error={errors.budget}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default AddLeadBudget
