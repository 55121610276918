import React, {useEffect, useContext} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {yupResolver} from '@hookform/resolvers/yup'
import moment from 'moment'
import DatePicker from 'rsuite/DatePicker'

import {ModalContext} from 'context/ModalContext'
import {getUsers} from 'modules/users/action/actionCreators'
import {allValidationRules} from '../yup/allValidationRules'
import {notesValidationRules} from '../yup/notesValidationRules'
import {isFutureDate} from 'config/utils'

import SelectOptions from 'components/SelectOptions'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Input from 'components/Input'

const GroupTimelineForm = ({loading, onSubmitHandler, timeline}) => {
  const users = useSelector(({users}) => users)
  const dispatch = useDispatch()
  const {hideModal} = useContext(ModalContext)
  const isFuture = timeline ? isFutureDate(timeline.date) : true

  useEffect(() => {
    dispatch(getUsers(1, 25, '-created_at', 'filter[role]=instructor'))
  }, [dispatch])

  const getOptions = (list) => {
    return list && list.map((listItem) => ({value: listItem.id, label: listItem.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(isFuture ? allValidationRules : notesValidationRules),
    defaultValues: {
      instructor_id: timeline?.instructor
        ? {value: timeline.instructor.id, label: timeline.instructor.name}
        : [],
      level: timeline ? timeline.level : '',
      date: timeline ? new Date(timeline.date) : moment().add(1, 'days').toDate(),
      from: timeline ? timeline.from : '',
      to: timeline ? timeline.to : '',
      notes: timeline ? timeline.notes : '',
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  const getFormFields = () => (
    <React.Fragment>
      <Controller
        {...{
          control,
          name: 'date',
          render: ({field}) => (
            <DatePicker
              format='yyyy-MM-dd'
              disabledDate={(date) =>
                moment(moment(date).format('YYYY-MM-DD')).isBefore(
                  moment().add(1, 'days').format('YYYY-MM-DD')
                )
              }
              oneTap
              defaultValue={timeline ? new Date(timeline.date) : moment().add(1, 'days').toDate()}
              style={{width: '100%', color: 'black'}}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'instructor_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Instructor'
              containerClassName='mt-7'
              options={getOptions(users.data)}
              id='instructor_id'
              {...field}
              error={errors.instructor_id}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'from',
          render: ({field}) => (
            <Input
              type='time'
              placeholder='From'
              containerClassName='mt-7'
              id='from'
              error={errors.from}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'to',
          render: ({field}) => (
            <Input
              type='time'
              placeholder='To'
              containerClassName='mt-7'
              id='to'
              error={errors.to}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'level',
          render: ({field}) => (
            <Input
              type='number'
              placeholder='Level'
              containerClassName='mt-7'
              id='level'
              error={errors.level}
              {...field}
            />
          ),
        }}
      />
    </React.Fragment>
  )

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      {isFuture && getFormFields()}
      <Controller
        {...{
          control,
          name: 'notes',
          render: ({field}) => (
            <Input
              placeholder='Notes'
              containerClassName='mt-7'
              id='notes'
              error={errors.notes}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default GroupTimelineForm
