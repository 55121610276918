import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {deleteUserSuccess, getUsers, resetFilters, setFilters} from './action/actionCreators'
import {ModalContext} from '../../context/ModalContext'
import API from 'config/API'
import {Link, useHistory} from 'react-router-dom'
import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import EmptyList from 'components/EmptyList'
import query from 'query-string'
import {useRowSelect, useTable} from 'react-table'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import Checkbox from 'components/Checkbox'
import moment from 'moment'
import ConfirmationModal from 'components/ConfirmationModal'
import { normalizeFilters } from 'config/utils'
import SelectedFilters from 'components/SelectedFilters'
import TooltipTitle from 'components/TooltipTitle'

const Users = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [rolesList, setRolesList] = React.useState(null)
  const {showModal, hideModal} = React.useContext(ModalContext)
  const usersList = useSelector(({users}) => users)
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    async function fetchUserRoles() {
      try {
        const response = await API.get(`/user/roles`)
        setRolesList(response.data.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchUserRoles()
    // reset filters
    return () => {
      dispatch(resetFilters())
    }
  }, [dispatch])

  React.useEffect(() => {
    dispatch(
      getUsers(page, size, '-created_at', query.stringify(normalizeFilters(usersList.filters)))
    )
  }, [dispatch, size, usersList.filters, page])

  const handleDelete = (id) =>
    API.delete(`/user/users/${id}`)
      .then(() => {
        dispatch(deleteUserSuccess(id))
        hideModal()
        Notifier.successMessage('User has been deleted!')
      })
      .catch((error) => {
        // Ignore Error Message
      })

  const deleteUser = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  // filters
  const handleUpdateFilters = (filters) => dispatch(setFilters(filters))

  const getOptions = () => {
    return rolesList && rolesList.map((role) => ({value: role.id, label: role.name}))
  }

  const getFilters = () => [
    {name: 'role', type: 'options', label: 'Role', options: getOptions()},
    {name: 'first_name', type: 'text', label: 'First name'},
    {name: 'last_name', type: 'text', label: 'Last name'},
    {name: 'username', type: 'text', label: 'Username'},
    {name: 'email', type: 'email', label: 'Email'},
    {name: 'mobile', type: 'number', label: 'Mobile'},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Full Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => <Link to={`/users/${row.id}`}>{row.name}</Link>,
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'roles',
        Cell: ({row: {original: row}}) => (
          <td className='white-space-none'>
            {row.roles && row.roles.map(({name}) => (
              <span className='badge badge-secondary m-1'>{name}</span>
            ))}
          </td>
        ),
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({row: {original: row}}) => moment(row.updated_at).format('DD-MM-YYYY, h:mm A'),
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      usersList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <TooltipTitle title='View User'>
              <div className='rounded-icon' onClick={() => history.push(`/users/${dataItem.id}`)}>
                <i className='bi bi-eye-fill'></i>
              </div>
            </TooltipTitle>
            <TooltipTitle title='delete User'>
              <div className='rounded-icon' onClick={() => deleteUser(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </TooltipTitle>
          </div>
        ),
      })),
    [usersList, history]
  )

  // table setup
  const table = useTable(
    {
      columns: dataColumns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({getToggleAllRowsSelectedProps}) => {
            const {checked, onChange} = getToggleAllRowsSelectedProps()

            return <Checkbox checked={checked} onChange={onChange} className='select-all' />
          },
          Cell: ({row}) => {
            const {checked, onChange} = row.getToggleRowSelectedProps()

            return <Checkbox checked={checked} onChange={onChange} />
          },
        },
        ...columns,
      ])
    }
  )

  // fetching
  if (usersList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={usersList.filters} setFilters={setFilters} />
            <div className='d-flex'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center align-items-start'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Create User
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={() => history.push('/users/create')}
              />
              <Filters
                filters={getFilters()}
                handleUpdateFilters={handleUpdateFilters}
                initialValues={usersList.filters}
              />
            </div>
          </div>
        </div>
      </div>
      {isEmpty(usersList.data) ? (
        <EmptyList message='No Users Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(usersList.data) && usersList.meta.last_page > 1 && (
        <Pagination
          pageCount={usersList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={usersList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Users
