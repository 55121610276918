import React from 'react'
import API from 'config/API'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {getStudentStatusOptions} from '../utils'
import * as Yup from 'yup'

import {ModalContext} from 'context/ModalContext'
import {Notifier} from 'components/Notifier'
import Input from 'components/Input'
import Loader from 'components/Loader'
import Button from 'components/Button'
import SelectOptions from 'components/SelectOptions'

const UpdateStudent = ({student}) => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)

  const updateHandler = async (values, setError) => {
    try {
      setLoading(true)
      await API.put(`/student/student_courses/${student.id}`, {
        ...values,
        status: values.status.value,
      })
      hideModal()
      Notifier.successMessage('Student has been updated!')
      setLoading(false)
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        level: Yup.number().required('Number is required').typeError('Number is required'),
        status: Yup.object().required('Status is required').nullable(),
      })
    ),
    defaultValues: {
      level: student.level,
      status: {value: student.status, label: student.status},
    },
  })

  const onSubmit = (values) => updateHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'level',
          render: ({field}) => (
            <Input
              type='number'
              placeholder='Level'
              containerClassName='mt-7'
              id='level'
              error={errors.level}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'status',
          render: ({field}) => (
            <SelectOptions
              placeholder='Status'
              containerClassName='mt-7'
              id='status'
              options={getStudentStatusOptions()}
              {...field}
              error={errors.status}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default UpdateStudent
