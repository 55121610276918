import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import Stepper from 'react-stepper-horizontal'
import PhoneInput, {isValidPhoneNumber, formatPhoneNumber} from 'react-phone-number-input'
import classNames from 'classnames'

import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'

import {ModalContext} from 'context/ModalContext'
import SelectOptions from 'components/SelectOptions'
import {useDispatch, useSelector} from 'react-redux'
import {getCourses} from 'modules/course/list/action/actionCreators'
import {getChannels} from 'modules/general/channels/action/actionCreators'
import {leadsValidationRules} from '../yup/leadsValidationRules'
import {getBranches} from 'modules/branches/action/actionCreators'
import ErrorInput from 'components/ErrorInput'

const FIRST_STEP = 0

const LeadRequestForm = ({onAddNewLead, onSubmitLeadRequest, initialValues, loading}) => {
  const [activeStep, setActiveStep] = React.useState(FIRST_STEP)
  const {hideModal} = React.useContext(ModalContext)
  const dispatch = useDispatch()
  const courses = useSelector(({course}) => course.list)
  const channels = useSelector(({channels}) => channels)
  const branches = useSelector(({branches}) => branches)
  const {has_branch} = useSelector(({auth}) => auth.client_config)

  React.useEffect(() => {
    dispatch(getChannels(1, 25, '-created_at', ''))
    dispatch(getCourses(1, 25, '-created_at', '', ''))
    has_branch && dispatch(getBranches(1))
  }, [dispatch, has_branch])

  const getCoursesOptions = () => {
    return courses && courses.data?.map((data) => ({value: data.id, label: data.name}))
  }

  const getChannelsOptions = () => {
    return channels && channels.data?.map((data) => ({value: data.id, label: data.name}))
  }

  const getBranchesOptions = () => {
    return branches && branches.data?.map((data) => ({value: data.id, label: data.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    getValues,
    setError,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(leadsValidationRules),
    defaultValues: {...initialValues},
  })

  const onSubmit = (values) => onSubmitLeadRequest(values, setError)

  const LeadInfoForm = () => (
    <div className='d-flex align-items-start gap-5'>
      <div className='flex-1'>
        <Controller
          {...{
            control,
            name: 'name',
            render: ({field}) => (
              <Input
                placeholder='Lead Name'
                containerClassName='mt-7'
                id='name'
                error={errors.name}
                {...field}
              />
            ),
          }}
        />
        <Controller
          {...{
            control,
            name: 'mobile',
            render: ({field}) => (
              <div className='mt-7'>
                <PhoneInput
                  international
                  placeholder='Lead Mobile'
                  defaultCountry='EG'
                  className={classNames('phone', {'error-input': errors.mobile})}
                  value={field.value}
                  onChange={field.onChange}
                />
                {field.value.length && !isValidPhoneNumber(field.value) ? (
                  <ErrorInput message='Invalid WhatsApp number' />
                ) : undefined}
                {errors.mobile && <ErrorInput message={errors.mobile.message} />}
              </div>
            ),
          }}
        />
        <Controller
          {...{
            control,
            name: 'email',
            render: ({field}) => (
              <Input
                type='email'
                placeholder='Lead Email'
                containerClassName='mt-7'
                id='email'
                error={errors.email}
                {...field}
              />
            ),
          }}
        />
      </div>
      <div className='flex-1'>
        {has_branch && (
          <Controller
            {...{
              control,
              name: 'branch_id',
              render: ({field}) => (
                <SelectOptions
                  placeholder='Branch'
                  containerClassName='mt-7'
                  options={getBranchesOptions()}
                  id='branch_id'
                  {...field}
                  error={errors.branch_id}
                />
              ),
            }}
          />
        )}
        <Controller
          {...{
            control,
            name: 'phone',
            render: ({field}) => (
              <div className='mt-7'>
                <PhoneInput
                  international
                  placeholder='Lead WhatsApp'
                  defaultCountry='EG'
                  className={classNames('phone', {'error-input': errors.phone})}
                  value={field.value}
                  onChange={field.onChange}
                />
                {field.value.length && !isValidPhoneNumber(field.value) ? (
                  <ErrorInput message='Invalid WhatsApp number' />
                ) : undefined}
                {errors.phone && <ErrorInput message={errors.phone.message} />}
              </div>
            ),
          }}
        />
      </div>
    </div>
  )

  const LeadRequestForm = () => (
    <div className='d-flex align-items-start gap-5'>
      <Controller
        {...{
          control,
          name: 'course_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Course'
              containerClassName='mt-7 flex-1'
              options={getCoursesOptions()}
              id='course_id'
              {...field}
              error={errors.course_id}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'channel_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Channel'
              containerClassName='mt-7 flex-1'
              options={getChannelsOptions()}
              id='channel_id'
              {...field}
              error={errors.channel_id}
            />
          ),
        }}
      />
    </div>
  )

  const getCurrentStep = (step) => {
    const steps = {
      0: () => <LeadInfoForm />,
      1: () => <LeadRequestForm />,
    }

    return steps[step]()
  }

  const nextStep = async () => {
    try {
      await onAddNewLead(getValues(), setError)
      setActiveStep(activeStep + 1)
    } catch (error) {}
  }

  const previousStep = () => {
    if (activeStep === FIRST_STEP) return hideModal()
    setActiveStep(activeStep - 1)
  }

  // change title based on
  const getButtonTitle = (title) => (loading ? <Loader /> : title)

  const getActions = () => {
    const createButton = (
      <Button title={getButtonTitle('Save Request')} type='submit' className='bg-primary py-5' />
    )

    const continueButton = (
      <Button
        title={getButtonTitle('Save Lead')}
        type='button'
        className='font-medium text-white text-base ml-5 bg-primary hover:bg-slate-800'
        onClick={nextStep}
      />
    )

    return (
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={previousStep}
        />
        {activeStep === 0 && continueButton}
        {activeStep === 1 && createButton}
      </div>
    )
  }

  return (
    <React.Fragment>
      <Stepper
        steps={[{title: 'New Lead'}, {title: 'Lead Request'}]}
        activeStep={activeStep}
        activeColor='#000000'
        completeColor='#000000'
        defaultColor='#F4F4F4'
        activeTitleColor='#010101'
        completeTitleColor='#010101'
        defaultTitleColor='#979797'
        circleFontSize={12}
        titleFontSize={12}
        circleTop={20}
        barStyle='dashed'
      />
      <form className='form w-100 m-auto my-5 pb-5' onSubmit={handleSubmit(onSubmit)} noValidate>
        {getCurrentStep(activeStep)}
        <div className='flex items-center justify-center'>{getActions()}</div>
      </form>
    </React.Fragment>
  )
}

export default LeadRequestForm
