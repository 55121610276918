import {GET_LEADS_SUCCESS, SET_FETCHING_LEADS, SET_LEADS_FILTERS, RESET_LEADS_FILTERS} from '../action/actionTypes'
import API from 'config/API'

const getLeadsSuccess = (leads) => ({
  type: GET_LEADS_SUCCESS,
  payload: {leads},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_LEADS,
  payload: {isFetching},
})

export const getLeads =
  (
    page,
    size, // paginate
    sort,
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/crm/lead_requests?include=lead,course,channel,team,agent,leadStage,visitCallStatus,lastCall&paginate=${size}&sort=${sort}&page=${page}&${filters}`
      )
      dispatch(
        getLeadsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

  export const setFilters = (filters) => ({
    type: SET_LEADS_FILTERS,
    payload: {filters},
  })
  
  export const resetFilters = () => ({
    type: RESET_LEADS_FILTERS,
    payload: {},
  })
  
