import GroupRounds from 'modules/course/groupRounds/GroupRounds';
import UpdateCourse from './UpdateCourse';

export const courseDetailsTabs = [
  {
    id: 'basic',
    label: 'Course Details',
  },
  {
    id: 'rounds',
    label: 'Ground Rounds',
  },
]

export const getActiveTabComponent = (tabId, course) => {
  const tabComponent = {
    basic: () => <UpdateCourse course={course} />,
    rounds: () => <GroupRounds course={course} />
  }

  return tabComponent[tabId] ? tabComponent[tabId]() : null
}
