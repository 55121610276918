import React from 'react'
import ChannelForm from './ChannelForm';
import { useDispatch } from 'react-redux';
import { addChannelSuccess } from '../action/actionCreators';
import API from 'config/API';
import { Notifier } from 'components/Notifier';
import { ModalContext } from 'context/ModalContext';

const AddChannel = () => {
  const [loading, setLoading] = React.useState(false);
  const { hideModal } = React.useContext(ModalContext);
  const dispatch = useDispatch();

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.post(`/crm/channels`, { name: values.name })
      setLoading(false)
      dispatch(addChannelSuccess(response.data.data));
      hideModal();
      Notifier.successMessage('Channel has been created!');
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  }

  return <ChannelForm onSubmitHandler={onSubmit} loading={loading} />
}

export default AddChannel