import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import API from 'config/API';
import { Notifier } from 'components/Notifier';
import GroupForm from './GroupForm';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit'

const AddGroup = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { client_config } = useSelector(({ auth }: any) => auth);

  const prepareSave = (values: any) => {
    const group = {
      ...values,
      instructor_id: values.instructor_id.value,
      course_id: values.course_id.value,
      group_status_id: values.group_status_id.value,
      group_round_id: values.group_round_id.value,
      branch_id: values.branch_id.value,
      is_online: values.is_online ? 1 : 0
    };

    return client_config.has_branch ? group : omit(group, 'branch_id');
  };

  const onSubmitHandler = (values: any, setSubmitting: (bool: boolean) => void, setErrors: any) => {
    setLoading(true)
    API.post(`/course/groups`, prepareSave(values))
      .then(() => {
        setLoading(false)
        history.push('/groups/list');
        Notifier.successMessage('Group has been created!');
      })
    .catch((error) => {
      setErrors(error.response.data.errors);
      setLoading(false)
      setSubmitting(false)
    })
  };

  return <GroupForm loading={loading} onSubmitHandler={onSubmitHandler} />;
}

export default AddGroup;