import * as Yup from 'yup'

export const userValidationRules =  Yup.object().shape({
  first_name: Yup.string().required('first name is required'),
  last_name: Yup.string(),
    //temporary
  // last_name: Yup.string().required('last name is required'),
  username: Yup.string(),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    // .matches( /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].**$/, 'Need one special character')
  ,
    mobile: Yup.string().nullable(),
    //temporary
  // mobile: Yup.string().length(11).test(
  //     'no-leading-zero',
  //     'mobile number must start with 0',
  //     (value) => {
  //       return value && value.startsWith('0');
  //     }),
  //     .required('mobile is required'),
  roles: Yup.array().required('roles are required'),
  branch_id: Yup.object()
});