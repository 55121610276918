import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import {groupDaysValidationRules} from '../yup/groupDaysValidationRules'

import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'

import {ModalContext} from 'context/ModalContext'
import SelectOptions from 'components/SelectOptions'

const GroupDayForm = ({onSubmitHandler, loading}) => {
  const {hideModal} = React.useContext(ModalContext)

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(groupDaysValidationRules),
    defaultValues: {
      day: null,
      start_time: '',
    },
  })

  const getOptions = () => [
    {value: 'SUN', label: 'Sunday'},
    {value: 'MON', label: 'Monday'},
    {value: 'TUE', label: 'Tuesday'},
    {value: 'WED', label: 'Wednesday'},
    {value: 'THU', label: 'Thursday'},
    {value: 'FRI', label: 'Friday'},
    {value: 'SAT', label: 'Saturday'},
  ]

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'day',
          render: ({field}) => (
            <SelectOptions
              placeholder='Week day'
              containerClassName='mt-7'
              options={getOptions()}
              id='day'
              {...field}
              error={errors.day}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'start_time',
          render: ({field}) => (
            <Input
              type='time'
              placeholder='Start Time'
              containerClassName='mt-7'
              id='start_time'
              error={errors.start_time}
              {...field}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Insert'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default GroupDayForm