import {
  ADD_TICKET_TEAM_SUCCESS,
  DELETE_TICKET_TEAM_SUCCESS,
  GET_TICKET_TEAMS_SUCCESS,
  SET_FETCHING_TICKET_TEAM,
  UPDATE_TICKET_TEAM_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: null,
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function TeamsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_TICKET_TEAM:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_TICKET_TEAMS_SUCCESS:
      return {
        ...state,
        data: action.payload.teams.data,
        meta: action.payload.teams.meta,
      }
    case ADD_TICKET_TEAM_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.team],
      }
    case UPDATE_TICKET_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.map((team) => {
          if (team.id === action.payload.team.id) {
            return action.payload.team
          }
          return team
        }),
      }
    case DELETE_TICKET_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.filter((team) => team.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default TeamsReducer
