import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'

import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'
import SelectOptions from 'components/SelectOptions'

import {ModalContext} from 'context/ModalContext'
import {getProjects} from 'modules/CRM/projects/action/actionCreators'
import {leadAdValidationRules} from '../yup/leadAdValidationRules'
import { getLeadAdTypes } from 'modules/general/leadAdTypes/action/actionCreators'

const LeadAdForm = ({onSubmitHandler, initialValues, loading}) => {
  const {hideModal} = React.useContext(ModalContext)
  const dispatch = useDispatch()
  const projectsList = useSelector(({crm}) => crm.projects)
  const leadsList = useSelector(({lead_ad_types}) => lead_ad_types)

  React.useEffect(() => {
    dispatch(getProjects(1, 25, 'name', `filter[before_end_date]=${moment().format('YYYY-MM-DD')}`))
    dispatch(getLeadAdTypes(1, 25, 'name', ''))
  }, [dispatch])

  const getProjectsOptions = () => {
    return projectsList && projectsList.data.map((data) => ({value: data.id, label: data.name}))
  }

  const getTypeOptions = () => {
    return leadsList && leadsList.data.map((data) => ({value: data.id, label: data.name}))
  }
  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(leadAdValidationRules),
    defaultValues: {...initialValues},
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'name',
          render: ({field}) => (
            <Input
              placeholder='Name'
              containerClassName='mt-7'
              id='name'
              error={errors.name}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'lead_ad_type_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Type'
              containerClassName='mt-7'
              options={getTypeOptions()}
              id='lead_ad_type_id'
              {...field}
              error={errors.lead_ad_type_id}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'project_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Project'
              containerClassName='mt-7'
              options={getProjectsOptions()}
              id='project_id'
              {...field}
              error={errors.project_id}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default LeadAdForm
