import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import API from 'config/API'
import query from 'query-string'

import {getLeads} from '../action/actionCreators'
import {Notifier} from 'components/Notifier'
import {ModalContext} from 'context/ModalContext'
import LeadRequestForm from './LeadRequestForm'
import {normalizeFilters} from 'config/utils'

const AddLeadRequest = () => {
  const [loading, setLoading] = React.useState(false)
  const [lead_id, setLeadId] = React.useState(null)
  const {hideModal} = React.useContext(ModalContext)
  const leadsList = useSelector(({crm}) => crm.leads)
  const agent_id = useSelector(({auth}) => auth.data.id)
  const dispatch = useDispatch()

  const onSubmitLeadRequest = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/crm/lead_requests`, {
        lead_id,
        course_id: values.course_id.value,
        channel_id: values.channel_id.value,
      })
      setLoading(false)
      dispatch(
        getLeads(
          1,
          10,
          '-updated_by',
          query.stringify(normalizeFilters({...leadsList.filters, agent_id}))
        )
      )
      hideModal()
      Notifier.successMessage('Lead has been created!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  const onAddNewLead = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.post(`/crm/leads`, {
        ...values,
        phone: values.phone.slice(1,),
        mobile: values.mobile.slice(1,),
        branch_id: values.branch_id?.value,
      })
      setLoading(false)
      setLeadId(response.data.data.id)
      return response
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
      throw new Error('')
    }
  }

  return (
    <LeadRequestForm
      onSubmitLeadRequest={onSubmitLeadRequest}
      onAddNewLead={onAddNewLead}
      initialValues={{
        name: '',
        mobile: '',
        phone: '',
        email: '',
        course_id: null,
        channel_id: null,
        branch_id: null,
      }}
      loading={loading}
    />
  )
}

export default AddLeadRequest
