import {
  DELETE_TICKET_CATEGORY_SUCCESS,
  GET_TICKET_CATEGORIES_SUCCESS,
  SET_FETCHING_TICKET_CATEGORIES,
} from './actionTypes'
import API from 'config/API'

const getTicketCategoriesSuccess = (categories) => ({
  type: GET_TICKET_CATEGORIES_SUCCESS,
  payload: {categories},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_TICKET_CATEGORIES,
  payload: {isFetching},
})

export const getTicketCategories = (page, size, ticket_team_id, filter) => async (dispatch) => {
  try {
    dispatch(setFetching(true))
    const url = `/ticket/ticket_categories?include=ticketActionsCount&page=${page}&paginate=${size}&filter[ticket_team_id]=${ticket_team_id}&filter[name]=${filter}`
    const response = await API.get(url)
    dispatch(
      getTicketCategoriesSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
    dispatch(setFetching(false))
  } catch (error) {
    dispatch(setFetching(false))
    throw new Error(error.response)
  }
}

export const deleteTicketCategorySuccess = (id) => ({
  type: DELETE_TICKET_CATEGORY_SUCCESS,
  payload: {id},
})
