import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import API from 'config/API'
import * as Yup from 'yup'
import DatePicker from 'rsuite/DatePicker'

import Button from 'components/Button'
import Loader from 'components/Loader'

import {ModalContext} from 'context/ModalContext'
import {fetchLeadStages} from 'config/utils'
import SelectOptions from 'components/SelectOptions'
import Input from 'components/Input'
import moment from 'moment'
import ErrorInput from 'components/ErrorInput'

const DetailsForm = ({onRefetch, lead_request_id, lead_stage_id}) => {
  const [loading, setLoading] = React.useState(false)
  const [leadStages, setLeadStages] = React.useState(null)
  const [selectedLeadStage, setSelectedLeadStage] = React.useState('')
  const {hideModal} = React.useContext(ModalContext)

  const fetchListOptions = async () => {
    const [stages] = await Promise.all([fetchLeadStages()])
    // set state
    setLeadStages(stages.data)
  }

  React.useEffect(() => {
    fetchListOptions()
  }, [])

  const getOptions = () => {
    return leadStages ? leadStages.map((item) => ({value: item.id, label: item.name})) : []
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        lead_stage_id: Yup.object().required('Stage is required').nullable(),
        todo_notes: Yup.string().when('lead_stage_id', {
          is: (val) => val.label === 'Interested' && lead_stage_id.label !== 'Interested',
          then: Yup.string().required('Notes are required'),
          otherwise: Yup.string(),
        }),
        todo_datetime: Yup.string().when('lead_stage_id', {
          is: (val) => val.label === 'Interested' && lead_stage_id.label !== 'Interested',
          then: Yup.string().required('date time is required'),
          otherwise: Yup.string(),
        }),
      })
    ),
    defaultValues: {
      lead_stage_id,
      todo_notes: '',
      todo_datetime: undefined,
    },
  })

  const onSubmit = async (values) => {
    try {
      let formValues = {...values, lead_stage_id: values.lead_stage_id.value}
      if (values.todo_datetime) {
        formValues = {
          ...values,
          lead_stage_id: values.lead_stage_id.value,
          todo_date: moment(values.todo_datetime).format('YYYY-MM-DD'),
          todo_time: moment(values.todo_datetime).format('HH:MM'),
          todo_type: 'call',
        }
      }
      setLoading(true)
      await API.put(`/crm/lead_requests/update_stage/${lead_request_id}`, formValues)
      setLoading(false)
      onRefetch()
      hideModal()
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'lead_stage_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Status'
              containerClassName='mt-7'
              options={getOptions()}
              id='lead_stage_id'
              {...field}
              onChange={(value) => {
                setValue('lead_stage_id', value)
                setSelectedLeadStage(value.label)
              }}
              error={errors.lead_stage_id}
            />
          ),
        }}
      />
      {selectedLeadStage === 'Interested' && (
        <React.Fragment>
          <Controller
            {...{
              control,
              name: 'todo_datetime',
              render: ({field}) => (
                <DatePicker
                  format='dd MMM yyyy hh:mm aa'
                  showMeridian
                  size='lg'
                  block
                  placement='auto'
                  id='todo_datetime'
                  placeholder='Date time'
                  className='mt-5'
                  {...field}
                />
              ),
            }}
          />
          {errors.todo_datetime && <ErrorInput message={errors.todo_datetime.message} />}
          <Controller
            {...{
              control,
              name: 'todo_notes',
              render: ({field}) => (
                <Input
                  placeholder='Notes'
                  containerClassName='mt-7'
                  id='todo_notes'
                  {...field}
                  error={errors.todo_notes}
                />
              ),
            }}
          />
        </React.Fragment>
      )}
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default DetailsForm
