import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {schema} from '../yup/schema'
import {ModalContext} from 'context/ModalContext'
import {getTicketModels} from 'modules/ticket/utils'
import Input from 'components/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'
import SelectOptions from 'components/SelectOptions'

const TicketActionsForm = ({onSubmitHandler, loading, action = null}) => {
  const {hideModal} = useContext(ModalContext)
  const modelTypes = useSelector(({auth}) => auth.client_config.ticket_models)

  const getModalTypesOptions = () => {
    return getTicketModels(modelTypes).map((type) => ({value: type.id, label: type.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      action: action ? action.action : '',
      model_type: action ? {value: action.model_type, label: action.model_type} : null,
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'action',
          render: ({field}) => (
            <Input
              placeholder='Name'
              containerClassName='mt-7'
              id='action'
              error={errors.action}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'model_type',
          render: ({field}) => (
            <SelectOptions
              placeholder='Model Type'
              containerClassName='mt-7'
              id='model_type'
              options={getModalTypesOptions()}
              {...field}
              error={errors.model_type}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default TicketActionsForm
