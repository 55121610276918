import React from 'react'
import {useDispatch} from 'react-redux'

import {updateProjectSuccess} from '../action/actionCreators'
import API from 'config/API'

import {Notifier} from 'components/Notifier'
import {ModalContext} from 'context/ModalContext'
import ProjectForm from './ProjectForm'

const ViewProject = ({ project }) => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)
  const dispatch = useDispatch()

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.put(`/crm/projects/${project.id}`, {
        name: values.name,
        start_date: values.start_date,
        end_date: values.end_date,
      })
      setLoading(false)
      dispatch(updateProjectSuccess(response.data.data))
      hideModal()
      Notifier.successMessage('Project has been saved!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <ProjectForm onSubmitHandler={onSubmit} initialValues={project} loading={loading} />
}

export default ViewProject
