import {
  ADD_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  GET_PROJECTS_SUCCESS,
  SET_FETCHING_PROJECTS,
  UPDATE_PROJECT_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false
}

function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_PROJECTS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        ...action.payload.projects,
      }
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.project],
      }
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        data: state.data.map((project) => {
          if (project.id === action.payload.project.id) {
            return action.payload.project
          }
          return project
        }),
      }
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        data: state.data.filter((project) => project.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default projectsReducer
