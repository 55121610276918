import {shallowEqual, useSelector} from 'react-redux'
import query from 'query-string'

import {normalizeFilters} from 'config/utils'
import LeadsStats from './LeadsStats'

import TicketsStats from 'components/TicketsStats'
import Can from 'components/Can'

const Dashboard = () => {
  const selectedRange = useSelector(({filters}) => filters, shallowEqual)

  return (
    <div>
      <Can do='show_dashboard_tickets_stats'>
        <TicketsStats
          defaultActiveKey='0'
          filters={query.stringify(
            normalizeFilters({
              date_from: selectedRange.startDate,
              date_to: selectedRange.endDate,
            })
          )}
        />
      </Can>
        <Can do='show_dashboard_leads_stats'>
      <LeadsStats
        defaultActiveKey='0'
        filters={query.stringify(
          normalizeFilters({
            date_from: selectedRange.startDate,
            date_to: selectedRange.endDate,
          })
        )}
      />
        </Can>
    </div>
  )
}

export default Dashboard
