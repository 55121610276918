import {
  GET_AGENTS_SUCCESS,
  RESET_FILTERS,
  SET_FETCHING_AGENTS,
  SET_FILTERS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  filters: {
    name: '',
    role_id: null,
  },
  isFetching: false,
}

function agentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_AGENTS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.agents.data,
        meta: action.payload.agents.meta,
      }
    case SET_FILTERS:
      return {
        ...state,
        filters: {...state.filters, ...action.payload.filters},
      }
    case RESET_FILTERS:
      return {
        ...state,
        filters: {},
      }
    default:
      return initialState
  }
}

export default agentsReducer
