import React, {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'
import API from 'config/API'
import moment from 'moment'

import GroupTimelineForm from './GroupTimelineForm'
import {Notifier} from 'components/Notifier'
import {ModalContext} from 'context/ModalContext'
import {getGroupTimeline} from '../action/actionCreators'
import {isFutureDate} from 'config/utils'

const UpdateGroupTimeline = ({groupId, timeline, page, size}) => {
  const dispatch = useDispatch()
  const {hideModal} = useContext(ModalContext)
  const [loading, setLoading] = useState(false)

  const prepareSave = (values) => {
    if (isFutureDate(timeline.date)) {
      return {
        ...values,
        instructor_id: values.instructor_id.value,
        date: moment(values.date).format('YYYY-MM-DD'),
        to: values.to.split(':').splice(0, 2).join(':'),
        from: values.from.split(':').splice(0, 2).join(':'),
        group_id: groupId,
      }
    }

    return {notes: values.notes}
  }

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      const url = isFutureDate(timeline.date)
        ? `/course/group_timelines/${timeline.id}`
        : `/course/update_group_timeline_notes/${timeline.id}`

      await API.put(url, prepareSave(values))
      setLoading(false)
      dispatch(getGroupTimeline(page, size, `filter[group_id]=${groupId}&filter[trashed]=with`))
      hideModal()
      Notifier.successMessage('Timeline has been updated!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <GroupTimelineForm loading={loading} timeline={timeline} onSubmitHandler={onSubmit} />
}

export default UpdateGroupTimeline
