import {
  ADD_TICKET_TEAM_SUCCESS,
  DELETE_TICKET_TEAM_SUCCESS,
  GET_TICKET_TEAMS_SUCCESS,
  SET_FETCHING_TICKET_TEAM,
  UPDATE_TICKET_TEAM_SUCCESS,
} from './actionTypes'
import API from 'config/API'

const getTeamsSuccess = (teams) => ({
  type: GET_TICKET_TEAMS_SUCCESS,
  payload: {teams},
})

export const setFetching = (isFetching) => ({
  type: SET_FETCHING_TICKET_TEAM,
  payload: {isFetching},
})

export const getTeams = (page, size, sort) => async (dispatch) => {
  try {
    dispatch(setFetching(true))
    const url = `/team/ticket_teams?include=leader,teamMembersCount&page=${page}&sort=${sort}&paginate=${size}`
    const response = await API.get(url)
    dispatch(
      getTeamsSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
    dispatch(setFetching(false))
  } catch (error) {
    dispatch(setFetching(false))
    throw new Error(error.response)
  }
}

export const addTeamSuccess = (team) => ({
  type: ADD_TICKET_TEAM_SUCCESS,
  payload: {team},
})

export const updateTeamSuccess = (team) => ({
  type: UPDATE_TICKET_TEAM_SUCCESS,
  payload: {team},
})

export const deleteTeamSuccess = (id) => ({
  type: DELETE_TICKET_TEAM_SUCCESS,
  payload: {id},
})
