import moment from 'moment'
import React from 'react'

const CollapseRow = ({ title, type = null, date = '', notes = '', due = '', action = null }) => {
  const [collapse, setCollapse] = React.useState(false)

  return (
    <div className='py-0 border-bottom' data-kt-lead-request-call='row'>
      <div className='py-3 d-flex flex-stack flex-wrap'>
        <div
          className={`d-flex align-items-center collapsible ${collapse ? '' : 'collapsed'}`}
          data-bs-toggle={`collapse ${collapse ? 'show' : ''}`}
          href='#kt_lead_request_call_0'
          role='button'
          aria-expanded='false'
          aria-controls='kt_lead_request_call_0'
          onClick={() => setCollapse(!collapse)}
        >
          <div className='me-3 rotate-90'>
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </div>
          <div className='me-3'>
            <div className='d-flex align-items-center'>
              <div className='text-gray-800 fw-bolder'>{title}</div>
              {type && <div className='badge badge-light-primary ms-5'>{type}</div>}
              {due && <div className='badge badge-light-primary ms-5'>due in {due}</div>}
            </div>
            <div className='text-muted mt-2'>{moment(date).format('DD-MM-YYYY, hh:mm A')}</div>
          </div>
        </div>
        <div className='d-flex my-3 ms-9'>
          {action && action}
        </div>
      </div>
      <div
        id='kt_lead_request_call_0'
        className={`fs-6 ps-10 collapse ${collapse ? 'show' : ''}`}
        data-bs-parent='#kt_lead_request_call'
      >
        <div className='d-flex flex-wrap py-5'>
          <div className='flex-equal me-5'>
            <table className='table table-flush fw-bold gy-1'>
              <tbody>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>Notes</td>
                  <td className='text-gray-800'>
                    <pre>{notes}</pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollapseRow
