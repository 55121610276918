import { Action } from 'redux';
import { RangesDto } from '../model/RangesDto';
import { RangesActionTypes } from './actionTypes';

export interface UpdateRangesAction extends Action<RangesActionTypes.UPDATE_RANGE> {
  payload: {
    ranges: RangesDto;
  };
}

export type AuthTypeTypes = UpdateRangesAction;

export const updateRange = (
  ranges: RangesDto
): UpdateRangesAction => ({
  type: RangesActionTypes.UPDATE_RANGE,
  payload: { ranges }
});

