import * as Yup from 'yup'

export const assignLeadsValidationRules = Yup.object().shape({
  date_from: Yup.string().required('Start date is required'),
  date_to: Yup.string().required('End date is required'),
  course_id: Yup.object().required('course is required').nullable(),
  operator: Yup.object().required('operator is required').nullable(),
  order: Yup.object().required('order is required').nullable(),
  num_of_leads: Yup.number().typeError('Amount must be a number'),
})
