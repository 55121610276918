import React from 'react'
import DatePicker from 'rsuite/DatePicker'
import moment from 'moment'
import {Controller, useForm} from 'react-hook-form'

import {can} from 'modules/ticket/utils'
import {yupResolver} from '@hookform/resolvers/yup'
import {roundsValidationRules} from '../yup/roundsValidationRules'
import {ModalContext} from 'context/ModalContext'

import Input from 'components/Input'
import Loader from 'components/Loader'
import Button from 'components/Button'

const GroupRoundForm = ({onSubmitHandler, loading, round = undefined}) => {
  const {hideModal} = React.useContext(ModalContext)

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
    getValues
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(roundsValidationRules),
    defaultValues: {
      name: round ? round.name : '',
      start_date: round ? moment(round.start_date).toDate() : new Date(),
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'name',
          render: ({field}) => (
            <Input
              placeholder='Name'
              containerClassName='mt-7'
              id='name'
              error={errors.name}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'start_date',
          render: ({field}) => (
            <DatePicker
              defaultValue={getValues('start_date')}
              disabledDate={(date) =>
                moment(moment(date).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'))
              }
              id='start_date'
              oneTap
              style={{width: '100%', color: 'black'}}
              className='border-secondary z-index-2 mt-7'
              {...field}
            />
          ),
        }}
      />
      {(can.do('create_group_round') || can.do('update_group_round')) && (
        <div className='d-flex align-items-start justify-content-center gap-5'>
          <Button
            title='Cancel'
            className='btn btn-light btn-active-light-primary py-5'
            onClick={hideModal}
          />
          <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
        </div>
      )}
    </form>
  )
}

export default GroupRoundForm
