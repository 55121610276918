/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import TeamForm from './TeamForm'
import API from 'config/API'
import {Notifier} from 'components/Notifier'
import Skeleton from 'components/Skeleton'
import { useHistory } from 'react-router-dom'

const EditTeam = ({ team }) => {
  const [loading, setLoading] = React.useState(false)
  const history = useHistory();

  const onSubmitSecondStepHandler = (values, setError) => {
    setLoading(true)
    API.put(`/team/crm_teams/${team.id}`, {
      name: values.name,
      leader_id: values.leader?.value,
      role_id: `${values.role?.value}`,
      leader_target: values.leader_target,
      leader_commission: values.leader_commission,
      agent_target: values.agent_target,
      agent_commission: values.agent_commission,
    })
      .then(() => {
        setLoading(false)
        history.push('/teams/list')
        Notifier.successMessage('Team has been saved!')
      })
      .catch((error) => {
        for (let err in error.response.data.errors) {
          setError(err, {message: error.response.data.errors[err][0]})
        }
        setLoading(false)
      })
  }

  const onSubmitFirstStepHandler = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.put(`/team/crm_teams/${team.id}`, {
        name: values.name,
        leader_id: values.leader?.value,
        role_id: `${values.role?.value}`,
      })
      setLoading(false)
      return response
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  if (!team) {
    return <Skeleton type='block' />
  }

  return (
    <TeamForm
      onSubmitFirstStepHandler={onSubmitFirstStepHandler}
      onSubmitHandler={onSubmitSecondStepHandler}
      team={team}
      loading={loading}
    />
  )
}

export default EditTeam
