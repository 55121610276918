import { LOGIN, LOGOUT } from "./actionTypes";

export const authenticate = (
  data
) => ({
  type: LOGIN,
  payload: { data }
});


export const logout = () => ({
  type: LOGOUT,
  payload: {}
});