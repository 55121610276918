import React from 'react'
import API from 'config/API'
import * as Yup from 'yup'

import {Notifier} from 'components/Notifier'
import {Controller, useForm} from 'react-hook-form'
import Button from 'components/Button'
import Loader from 'components/Loader'
import {ModalContext} from 'context/ModalContext'
import {yupResolver} from '@hookform/resolvers/yup'
import Input from 'components/Input'
import {attendanceBadges} from 'modules/course/groupStudents/utils'

const StoreAttendanceChangeNotes = ({attendance, setRefetch, status}) => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        notes: Yup.string().required('Notes are required'),
      })
    ),
    defaultValues: {
      notes: '',
    },
  })

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await API.post(`/student/attendance_change_requests`, {
        group_timeline_id: attendance.group_timeline_id,
        attendance_status: status,
        student_course_id: attendance.student_course_id,
        notes: values.notes,
      })
      setLoading(false)
      setRefetch(true)
      hideModal()
      Notifier.successMessage('Attendance updated!')
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div>
      <p className='text-center'>
        You're changing attendance with due date, you'll have to insert a notes to specify the
        reason and wait for admin approval.
        <br />
      </p>
      <p className='text-center'>
        Status
        <span
          className={`fw-bolder badge text-light fs-8 w-auto mx-2 badge-${attendanceBadges[status]}`}
        >
          {status}
        </span>
      </p>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          {...{
            control,
            name: 'notes',
            render: ({field}) => (
              <Input
                placeholder='Notes'
                containerClassName='mt-7'
                id='notes'
                error={errors.notes}
                {...field}
              />
            ),
          }}
        />
        <div className='d-flex align-items-start justify-content-center gap-5'>
          <Button
            title='Cancel'
            className='btn btn-light btn-active-light-primary py-5'
            onClick={hideModal}
          />
          <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
        </div>
      </form>
    </div>
  )
}

export default StoreAttendanceChangeNotes
