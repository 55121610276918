import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addUserSuccess } from '../action/actionCreators';
import omit from 'lodash/omit';
import UserForm from './UserForm';
import { useHistory } from 'react-router-dom';
import API from 'config/API';
import { Notifier } from 'components/Notifier';

const AddUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const { client_config } = useSelector(({ auth }) => auth);

  const prepareSave = (values) => {
    const user = { 
      ...values, 
      roles: values.roles.map(({ value }) => ( value )),
      branch_id: client_config.has_branch ? values.branch_id.value : null,
    };

    return client_config.has_branch ? user : omit(user, 'branch_id');
  }

  const onSubmitHandler = (values, setError) => {
    setLoading(true);
    API.post(`/user/users`, prepareSave(values))
      .then((response) => {
        dispatch(addUserSuccess(response.data.data));
        setLoading(false);
        history.push('/users/list');
        Notifier.successMessage('User has been created!');
      })
    .catch((error) => {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    })
  };

  return <UserForm loading={loading} onSubmitHandler={onSubmitHandler} />;
}

export default AddUser;