import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import API from 'config/API'
import {useHistory} from 'react-router-dom'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import {useTable} from 'react-table'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import TooltipTitle from 'components/TooltipTitle'
import {Notifier} from 'components/Notifier'
import ConfirmationModal from 'components/ConfirmationModal'
import {ModalContext} from 'context/ModalContext'
import {getAgents} from './action/actionCreators'
import AddAgent from './components/AddAgent'
import AssignLeads from 'modules/teams/components/teamDetails/AssignLeads'

const Agents = ({team, setActiveTab = undefined}) => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const agentsList = useSelector(({crm}) => crm.agents)

  const dispatch = useDispatch()
  const history = useHistory()
  const {showModal, hideModal} = React.useContext(ModalContext)

  React.useEffect(() => {
    dispatch(getAgents(page, size, '', `filter[team_id]=${team.id}`))
  }, [dispatch, size, page, history, team.id])

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const handleDeleteTeamAgent = (id) =>
    API.delete(`/team/team_members/${id}`)
      .then(() => {
        dispatch(getAgents(1, 10, '', `filter[team_id]=${team.id}`))
        hideModal()
        Notifier.successMessage('Team Agent has been Deleted!')
      })
      .catch(() => {})

  const deleteTeamAgent = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDeleteTeamAgent(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const assignLeadsToAgent = (agent_id) =>
    showModal({
      id: 'assign-leads-to-agents-form-modal',
      dialogClassName: 'assign-leads-to-agents-',
      closeButton: true,
      title: 'Assign Leads to Agent',
      body: <AssignLeads agent_id={agent_id} teamId={team.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const addTeamAgent = () => {
    if (
      isEmpty(team.teamTargetAndCommission) ||
      team.teamTargetAndCommission?.agent_target === 0 ||
      team.teamTargetAndCommission?.agent_commission === 0
    ) {
      Notifier.infoMessage('You should add agent target and agent commission first.')
    } else {
      showModal({
        id: 'add-agent-form-modal',
        dialogClassName: 'add-agent-form',
        closeButton: true,
        title: 'Add Agent',
        body: <AddAgent team_id={team.id} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    }
  }

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Team',
        accessor: 'crmTeam',
        Cell: ({row: {original: row}}) => row.crmTeam?.name,
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({row: {original: row}}) => row.agent?.name,
      },
      {
        Header: 'Number of fresh leads',
        accessor: 'num_of_fresh_leads',
      },
      {
        Header: 'Number of created leads',
        accessor: 'num_of_created_leads',
      },
      {
        Header: 'Target',
        accessor: 'target',
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      agentsList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <TooltipTitle title='Assign Leads'>
              <div className='rounded-icon' onClick={() => assignLeadsToAgent(dataItem.id)}>
                <i className='bi bi-pencil'></i>
              </div>
            </TooltipTitle>
            <TooltipTitle title='Delete Agent'>
              <div className='rounded-icon' onClick={() => deleteTeamAgent(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </TooltipTitle>
          </div>
        ),
      })),
    [agentsList, history]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (agentsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Button
              title={
                <span className='d-flex align-items-center justify-content-center'>
                  <i
                    className='bi bi-plus-circle mx-2'
                    style={{color: 'white', fontSize: '1.5rem'}}
                  ></i>
                  Add Agent
                </span>
              }
              className='mt-0 w-auto mx-4 p-3 bg-primary'
              onClick={addTeamAgent}
            />
          </div>
        </div>
      </div>
      {isEmpty(agentsList.data) ? (
        <EmptyList message='No Teams Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(agentsList.data) && agentsList.meta.last_page > 1 && (
        <Pagination
          pageCount={agentsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={agentsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Agents
