import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import {deleteTeamMemberSuccess, getTeamMembers} from '../action/actionCreators'
import {can} from 'modules/ticket/utils'
import API from 'config/API'
import AddTeamMembers from './AddTeamMembers'
import ConfirmationModal from 'components/ConfirmationModal'
import {ModalContext} from 'context/ModalContext'

import {Notifier} from 'components/Notifier'
import Can from 'components/Can'
import EmptyList from 'components/EmptyList'
import Pagination from 'components/Pagination'
import Skeleton from 'components/Skeleton'
import {useTable} from 'react-table'
import Button from 'components/Button'
import Table from 'components/Table'
import TooltipTitle from 'components/TooltipTitle'

function TeamMembers({team}) {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const {showModal, hideModal} = useContext(ModalContext)
  const teamMembersList = useSelector(({ticket}) => ticket.teamMembers)
  const match = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getTeamMembers(page, size, match.params.id))
  }, [dispatch, match.params.id, page, size])

  const handleDelete = (id) =>
    API.delete(`/team/team_members/${id}`)
      .then(() => {
        dispatch(deleteTeamMemberSuccess(id))
        hideModal()
        Notifier.successMessage('Team member has been removed!')
      })
      .catch(() => {})

  const deleteTeamMember = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const addTeamMember = () =>
    showModal({
      id: 'add-team-member-form-modal',
      dialogClassName: 'add-team-member-form',
      closeButton: true,
      title: `Add Team Members - ${team.name}`,
      body: <AddTeamMembers id={team.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleShowAllTickets = React.useCallback((agentId) => {
    if (can.do('show_team_member_tickets')) {
      return history.push(`/tickets/teams/${team.id}/${agentId}`)
    }

    return undefined
  }, [history, team?.id])

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => (
          <span to={() => handleShowAllTickets(row.id)}>{row.agent?.name ?? 'N/A'}</span>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'action',
        className: "text-right"
      },
    ],
    [handleShowAllTickets]
  )

  // table data
  const data = React.useMemo(
    () =>
      teamMembersList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex justify-content-end'>
            <Can do='delete_ticket_team_member'>
              <TooltipTitle title='Delete team member'>
                <div className='rounded-icon' onClick={() => deleteTeamMember(dataItem.id)}>
                  <i className='bi bi-trash2'></i>
                </div>
              </TooltipTitle>
            </Can>
          </div>
        ),
      })),
    [teamMembersList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (teamMembersList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end align-items-center'>
            <Can do='create_ticket_team_member'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center align-items-start'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Add Team member
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={addTeamMember}
              />
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(teamMembersList.data) ? (
        <EmptyList message='No Teams Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(teamMembersList.data) && teamMembersList.meta.last_page > 1 && (
        <Pagination
          pageCount={teamMembersList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={teamMembersList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default TeamMembers
