import {
  GET_LEADS_LAST_ACTION_SUCCESS,
  RESET_LEADS_LAST_ACTION_FILTERS,
  SET_FETCHING_LEADS_LAST_ACTION,
  SET_LEADS_LAST_ACTION_FILTERS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  filters: {
    course_id: null,
    created_at: '',
    lead_stage_id: null,
    channel_id: null,
    agent_id: null,
    days_after_last_action: ''
  },
  isFetching: false,
}

function leadsLastActionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_LEADS_LAST_ACTION:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_LEADS_LAST_ACTION_SUCCESS:
      return {
        ...state,
        data: action.payload.leads.data,
        meta: action.payload.leads.meta
      }
    case SET_LEADS_LAST_ACTION_FILTERS:
      return {
        ...state,
        filters: {...state.filters, ...action.payload.filters},
      }
    case RESET_LEADS_LAST_ACTION_FILTERS:
      return {
        ...state,
        filters: {},
      }
    default:
      return state
  }
}

export default leadsLastActionReducer
