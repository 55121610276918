import {SET_FETCHING_GROUP_TIMELINE, GET_GROUP_TIMELINE_SUCCESS} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function groupTimelineReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_GROUP_TIMELINE:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_GROUP_TIMELINE_SUCCESS:
      return {
        ...state,
        data: action.payload.timeline.data,
        meta: action.payload.timeline.meta,
      }
    default:
      return state
  }
}

export default groupTimelineReducer
