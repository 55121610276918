
import API from 'config/API'
import {FC, useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import { authenticate, logout } from '../action/actionCreators'

const AuthInit: FC = (props) => {
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const checkUserStatus = async () => {
    try {
      const response = await API.get('/user/validate_token');
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    async function initAuth() {
      try {
        const cred = JSON.parse(localStorage.getItem('auth'));
        await checkUserStatus();
        dispatch(authenticate(cred));
        setShowSplashScreen(false)
      } catch (error) {
        dispatch(logout());
        localStorage.removeItem('auth');
        setShowSplashScreen(false);
      }
    }

    initAuth()
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}
export default AuthInit
