import React from 'react'
import {useDispatch} from 'react-redux'
import {getLeadAds} from '../action/actionCreators'
import API from 'config/API'
import {Notifier} from 'components/Notifier'
import {ModalContext} from 'context/ModalContext'
import LeadAdForm from './LeadAdForm'

const AddLeadAd = () => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)
  const dispatch = useDispatch()

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/crm/lead_ads`, {
        ...values,
        lead_ad_type_id: values.lead_ad_type_id.value,
        project_id: values.project_id.value,
      })
      setLoading(false)
      dispatch(getLeadAds(1, 10, 'name'))
      hideModal()
      Notifier.successMessage('Lead Ad has been created!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <LeadAdForm
      onSubmitHandler={onSubmit}
      initialValues={{
        name: '',
        lead_ad_type_id: null,
        project_id: null,
      }}
      loading={loading}
    />
  )
}

export default AddLeadAd
