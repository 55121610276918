import React from 'react'
import {useHistory} from 'react-router-dom'
import classNames from 'classnames'
import API from 'config/API'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import Input from 'components/Input'
import SelectOptions from 'components/SelectOptions'
import Loader from 'components/Loader'
import Button from 'components/Button'
import {Notifier} from 'components/Notifier'
import {assignLeadsValidationRules} from 'modules/teams/yup/assignLeadsValidationRules'
import moment from 'moment'
import {ModalContext} from 'context/ModalContext'

const AssignLeads = ({teamId, agent_id = null}) => {
  const [courseList, setCourseList] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const history = useHistory()
  const {hideModal} = React.useContext(ModalContext)

  React.useEffect(() => {
    async function fetchCourses() {
      try {
        const response = await API.get(`/course/courses`)
        setCourseList(response.data.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchCourses()
  }, [])

  const getCoursesOptions = () => {
    return courseList && courseList.map((course) => ({value: course.id, label: course.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(assignLeadsValidationRules),
    defaultValues: {
      date_from: moment().format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      course_id: null,
      operator: null,
      order: null,
      num_of_leads: '',
    },
  })

  const cancelSubmit = () => (agent_id ? hideModal() : reset({}))

  const onSubmit = async (values) => {
    const savedValues = {
      ...values,
      operator: values.operator?.value,
      order: values.order?.value,
      course_id: values.course_id?.value,
      team_id: teamId,
    }
    try {
      setLoading(true)
      await API.post(
        'crm/assign_leads_to_team',
        agent_id ? {...savedValues, agent_id} : savedValues
      )
      setLoading(false)
      // check
      agent_id ? hideModal() : history.push('/teams/list')
      Notifier.successMessage('Leads has been assigned successfully')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  const getForm = () => (
    <form
      className={classNames('form w-100', {'m-auto max-w-80 my-5 pb-5': !agent_id})}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className='d-flex align-items-start justify-content-between gap-5'>
        <div className='flex-1'>
          <Controller
            {...{
              control,
              name: 'date_from',
              render: ({field}) => (
                <Input
                  type='date'
                  placeholder='Start Date'
                  containerClassName='mt-7'
                  id='date_from'
                  error={errors.date_from}
                  {...field}
                />
              ),
            }}
          />
          <Controller
            {...{
              control,
              name: 'course_id',
              render: ({field}) => (
                <SelectOptions
                  placeholder='Course'
                  containerClassName='mt-7'
                  id='course_id'
                  options={getCoursesOptions()}
                  error={errors.course_id}
                  {...field}
                />
              ),
            }}
          />
          <Controller
            {...{
              control,
              name: 'order',
              render: ({field}) => (
                <SelectOptions
                  placeholder='Order'
                  containerClassName='mt-7'
                  id='order'
                  options={[
                    {label: 'desc', value: 'desc'},
                    {label: 'asc', value: 'asc'},
                  ]}
                  error={errors.order}
                  {...field}
                />
              ),
            }}
          />
        </div>
        <div className='flex-1'>
          <Controller
            {...{
              control,
              name: 'date_to',
              render: ({field}) => (
                <Input
                  type='date'
                  placeholder='End Date'
                  containerClassName='mt-7'
                  id='date_to'
                  error={errors.date_to}
                  {...field}
                />
              ),
            }}
          />
          <Controller
            {...{
              control,
              name: 'operator',
              render: ({field}) => (
                <SelectOptions
                  placeholder='Operator'
                  containerClassName='mt-7'
                  id='operator'
                  options={[
                    {label: '010', value: '010'},
                    {label: '011', value: '011'},
                    {label: '012', value: '012'},
                  ]}
                  error={errors.operator}
                  {...field}
                />
              ),
            }}
          />
          <Controller
            {...{
              control,
              name: 'num_of_leads',
              render: ({field}) => (
                <Input
                  type='number'
                  placeholder='Leads numbers'
                  containerClassName='mt-7'
                  id='num_of_leads'
                  error={errors.num_of_leads}
                  {...field}
                />
              ),
            }}
          />
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={cancelSubmit}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Submit'} className='bg-primary py-5' />
      </div>
    </form>
  )

  if (agent_id) {
    return getForm()
  }

  return (
    <div className='mt-5 pt-5'>
      <div className='card'>
        <div className='card-body pt-5 mt-5'>{getForm()}</div>
      </div>
    </div>
  )
}

export default AssignLeads
