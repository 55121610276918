import API from 'config/API';
import { capitalizeFirstLetter } from 'config/utils';
import { defaultTicketsStats, TicketsStatsIcons } from 'modules/ticket/utils';
import React, { FC, useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap';
import Card from './Card';
import classnames from 'classnames';
import InsightCard from './InsightCard';

interface TicketsStatsProps {
  filters: string;
  defaultActiveKey?: string;
}

const TicketsStats: FC<TicketsStatsProps> = ({ filters, defaultActiveKey = "1" }) => {
  const [stats, setStats] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchTicketStats() {
      try {
        setLoading(true);
        const response = await API.get(`/ticket/ticket_stats?${filters}`);
        setStats(response.data.data);
        setLoading(false);
      } catch(error) {
        setLoading(false);
      }
    }

    fetchTicketStats();
  }, [filters])

  return (
    <Card classNames='mt-5'>
      <Accordion defaultActiveKey={defaultActiveKey}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="card-title m-0 d-flex align-items-center">
              <h3 className="text-gray-900 text-hover-primary fs-3 fw-bolder me-1 mb-0">Tickets Statistics</h3>
              <span className="fw-bold text-gray-400 fs-4">{stats && `(${stats[0]['all tickets']})`}</span>
              {loading && (
                <div className="spinner-border text-primary mx-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="flex-grow-1">
              <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-5">
                  {stats && defaultTicketsStats.map((stat) => (
                    <InsightCard 
                      key={stat}
                      title={capitalizeFirstLetter(stat)} 
                      icon={(
                        <i className={classnames('fs-2 text-primary', TicketsStatsIcons[stat])}></i>
                      )} 
                      insight={stats[0][stat]} 
                      style={{ width: '30%' }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Card>
  )
};

export default TicketsStats;
