import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import API from 'config/API'
import isEmpty from 'lodash/isEmpty'

import Can from 'components/Can'
import EmptyList from 'components/EmptyList'
import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'

import {deleteTicketActionSuccess, getTicketActions} from '../action/actionCreators'
import AddTicketAction from './AddTicketAction'
import EditTicketAction from './EditTicketAction'
import ConfirmationModal from 'components/ConfirmationModal'
import {ModalContext} from 'context/ModalContext'
import {can, getTicketModelName} from 'modules/ticket/utils'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import {useTable} from 'react-table'
import Table from 'components/Table'
import TooltipTitle from 'components/TooltipTitle'

function TicketActions(props) {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const dispatch = useDispatch()
  const {showModal, hideModal} = useContext(ModalContext)
  const ticketActionsList = useSelector(({ticket}) => ticket.actions)

  useEffect(() => {
    dispatch(getTicketActions(page, size, props.match.params.ticket_category_id, ''))
  }, [dispatch, size, page, props.match.params.ticket_category_id])

  const handleDelete = (id) =>
    API.delete(`/ticket/ticket_actions/${id}`)
      .then(() => {
        dispatch(deleteTicketActionSuccess(id))
        hideModal()
        Notifier.successMessage('Action has been deleted!')
      })
      .catch(() => {})

  const deleteTicketAction = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const createTicketAction = () =>
    showModal({
      id: 'add-ticket-action-form-modal',
      dialogClassName: 'add-ticket-action-form',
      closeButton: true,
      title: `Create ticket action`,
      body: <AddTicketAction ticketCategory={props.match.params.ticket_category_id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const viewTicketAction = (action) =>
    showModal({
      id: 'update-ticket-action-form-modal',
      dialogClassName: 'update-ticket-action-form',
      closeButton: true,
      title: `Update ticket action`,
      body: <EditTicketAction action={action} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.values)

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Action Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => (
          <span to={() => viewTicketAction(row)}>{row.action}</span>
        ),
      },
      {
        Header: 'Category',
        accessor: 'ticketCategory',
        Cell: ({row: {original: row}}) => row.ticketCategory?.name ?? 'N/A',
      },
      {
        Header: 'Model type',
        accessor: 'model_type',
        Cell: ({row: {original: row}}) => getTicketModelName(row.model_type) ?? 'N/A',
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        Cell: ({row: {original: row}}) => row.duration,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        className: 'text-right',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      ticketActionsList?.data.map((dataItem) => ({
        ...dataItem,
        actions: (
          <div className='d-flex justify-content-end'>
            <Can do='update_ticket_action'>
              <TooltipTitle title='Update category'>
                <div className='rounded-icon' onClick={() => viewTicketAction(dataItem)}>
                  <i className='bi bi-pencil'></i>
                </div>
              </TooltipTitle>
            </Can>
            <Can do='delete_ticket_action'>
              <TooltipTitle title='Delete category'>
                <div className='rounded-icon' onClick={() => deleteTicketAction(dataItem.id)}>
                  <i className='bi bi-trash2'></i>
                </div>
              </TooltipTitle>
            </Can>
          </div>
        ),
      })),
    [ticketActionsList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // check for valid permission
  if (!can.do('show_ticket_action')) {
    props.history.push('/404')
    return null
  }

  // fetching
  if (ticketActionsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end align-items-center'>
            <Can do='create_ticket_action'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center align-items-start'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Create Ticket Action
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={createTicketAction}
              />
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(ticketActionsList.data) ? (
        <EmptyList message='No Categories Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(ticketActionsList.data) && ticketActionsList.meta.last_page > 1 && (
        <Pagination
          pageCount={ticketActionsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={ticketActionsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default withRouter(TicketActions)
