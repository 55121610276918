import React from 'react'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import API from 'config/API'

import AddLeadAdType from './components/AddLeadAdType'
import ViewLeadAdType from './components/ViewLeadAdType'
import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Skeleton from 'components/Skeleton'
import Button from 'components/Button'

import {deleteLeadAdTypeSuccess, getLeadAdTypes} from './action/actionCreators'
import {ModalContext} from 'context/ModalContext'
import Table from 'components/Table'
import ConfirmationModal from 'components/ConfirmationModal'

const Channels = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const leadsList = useSelector(({lead_ad_types}) => lead_ad_types)
  const dispatch = useDispatch()
  const {showModal, hideModal} = React.useContext(ModalContext)

  React.useEffect(() => {
    dispatch(getLeadAdTypes(page, size, '-name', ''))
  }, [dispatch, size, page])

  const handleDelete = (id) =>
    API.delete(`/crm/lead_ad_types/${id}`)
      .then(() => {
        dispatch(deleteLeadAdTypeSuccess(id))
        hideModal()
        Notifier.successMessage('Lead Ad has been deleted!')
      })
      .catch(() => {})

  const deleteLeadAdType = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const viewLeadAdType = (lead) =>
    showModal({
      id: 'view-lead-ad-type-form-modal',
      dialogClassName: 'view-lead-ad-type-form',
      closeButton: true,
      title: 'View Lead Ad Type',
      body: <ViewLeadAdType name={lead.name} id={lead.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const addLeadAdType = () =>
    showModal({
      id: 'add-lead-ad-type-form-modal',
      dialogClassName: 'add-lead-ad-type-form',
      closeButton: true,
      title: 'Add Lead Ad Type',
      body: <AddLeadAdType />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({row: {original: row}}) => moment(row.updated_at).format('DD-MM-YYYY, h:mm A'),
      },
      {
        Header: 'Actions',
        accessor: 'action',
        className: 'text-end px-5',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      leadsList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex justify-content-end'>
            <div className='rounded-icon' onClick={() => viewLeadAdType(dataItem)}>
              <i className='bi bi-pencil'></i>
            </div>
            <div className='rounded-icon' onClick={() => deleteLeadAdType(dataItem.id)}>
              <i className='bi bi-trash2'></i>
            </div>
          </div>
        ),
      })),
    [leadsList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (leadsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end'>
            <Button
              title={
                <span className='d-flex align-items-center justify-content-center'>
                  <i
                    className='bi bi-plus-circle mx-2'
                    style={{color: 'white', fontSize: '1.5rem'}}
                  ></i>
                  Create Lead Ad Type
                </span>
              }
              className='mt-0 w-auto mx-4 p-3 bg-primary'
              onClick={addLeadAdType}
            />
          </div>
        </div>
      </div>
      {isEmpty(leadsList.data) ? (
        <EmptyList message='No Lead Ad Types Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(leadsList.data) && leadsList.meta.last_page > 1 && (
        <Pagination
          pageCount={leadsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={leadsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Channels
