import {
  ADD_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  GET_PROJECTS_SUCCESS,
  SET_FETCHING_PROJECTS,
  UPDATE_PROJECT_SUCCESS,
} from '../action/actionTypes'
import API from 'config/API'

const getProjectsSuccess = (projects) => ({
  type: GET_PROJECTS_SUCCESS,
  payload: {projects},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_PROJECTS,
  payload: {isFetching},
})

export const getProjects =
  (
    page,
    size, // paginate
    sort,
    filter = ''
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/crm/projects?paginate=${size}&sort=${sort}&page=${page}&${filter}`
      )
      dispatch(
        getProjectsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

export const addProjectSuccess = (project) => ({
  type: ADD_PROJECT_SUCCESS,
  payload: {project},
})

export const updateProjectSuccess = (project) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: {project},
})

export const deleteProjectSuccess = (id) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: {id},
})