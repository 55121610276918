import {
  ADD_LEAD_AD_TYPES_SUCCESS,
  DELETE_LEAD_AD_TYPES_SUCCESS,
  GET_LEAD_AD_TYPES_SUCCESS,
  SET_FETCHING_LEAD_AD,
  UPDATE_LEAD_AD_TYPES_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function leadAdTypesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_LEAD_AD:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_LEAD_AD_TYPES_SUCCESS:
      return {
        ...state,
        ...action.payload.leads,
      }
    case ADD_LEAD_AD_TYPES_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.lead],
      }
    case UPDATE_LEAD_AD_TYPES_SUCCESS:
      return {
        ...state,
        data: state.data.map((lead) => {
          if (lead.id === action.payload.lead.id) {
            return action.payload.lead
          }
          return lead
        }),
      }
    case DELETE_LEAD_AD_TYPES_SUCCESS:
      return {
        ...state,
        data: state.data.filter((lead) => lead.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default leadAdTypesReducer
