import React, { FC } from 'react'
import { toAbsoluteUrl } from '_metronic/helpers';
import { fileImages } from 'modules/ticket/utils';
import { getFileNameFromUrl, getUrlExtension } from 'config/utils';

interface FileCardProps {
  attachment: string;
}

const FileCard: FC<FileCardProps> = ({ attachment }) => {

  const getImageSource = () => {
    if (!fileImages[getUrlExtension(attachment)]) {
      return attachment;
    }

    return toAbsoluteUrl(`/media/${fileImages[getUrlExtension(attachment)]}`);
  }

  return (
    <div className="card">
      <a href={attachment} target="_blank" className="text-gray-800 text-hover-primary d-flex align-items-center">
        <div className="symbol symbol-60px mb-5 ms-n2 me-3">
          <img src={getImageSource()} alt="" />
        </div>
        <div className="fs-6 fw-bolder mb-2">{getFileNameFromUrl(attachment)}</div>
      </a>
    </div>
  )
}

export default FileCard
