import {
  GET_LEADS_SUCCESS,
  RESET_LEADS_FILTERS,
  SET_FETCHING_LEADS,
  SET_LEADS_FILTERS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  filters: {
    course_id: null,
    lead_stage_id: { value: '7a250a2c-28cb-4df2-9324-779f0789b228', label: 'Fresh' },
    start_dat: '',
    end_date: '',
    visit_call_status_id: null,
  },
  isFetching: false,
}

function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_LEADS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        ...action.payload.leads,
      }
    case SET_LEADS_FILTERS:
      return {
        ...state,
        filters: {...state.filters, ...action.payload.filters},
      }
    case RESET_LEADS_FILTERS:
      return {
        ...state,
        filters: {},
      }
    default:
      return state
  }
}

export default leadsReducer
