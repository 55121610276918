import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import query from 'query-string'
import * as Yup from 'yup'

import API from 'config/API'
import {ModalContext} from 'context/ModalContext'
import {getGroups} from 'modules/course/groups/action/actionCreators'
import {Notifier} from 'components/Notifier'
import {normalizeFilters} from 'config/utils'
import {getGroupStudents} from '../action/actionCreators'
import {yupResolver} from '@hookform/resolvers/yup'
import SelectOptions from 'components/SelectOptions'
import Button from 'components/Button'
import Loader from 'components/Loader'

const AssignStudentToGroup = ({student}) => {
  const [loading, setLoading] = useState(false)
  const {hideModal} = useContext(ModalContext)
  const dispatch = useDispatch()
  const groups = useSelector(({course}) => course.groups)
  const students = useSelector(({course}) => course.group_students)

  useEffect(() => {
    dispatch(
      getGroups(
        1,
        25,
        '-created_at',
        '',
        `filter[course_id]=${student.course?.id}&filter[levelIsLE]=${student.level}&filter[withRemaining]=true`
      )
    )
  }, [dispatch, student.course.id, student.level])

  const getOptions = ({data}) => {
    return data && data.map((group) => ({value: group.id, label: `${group.name} - ${group.level}`}))
  }

  const assignToGroup = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/student/assign_groups`, {
        group_id: values.group_id.value,
        student_course_id: student.id,
      })
      hideModal()
      Notifier.successMessage('Student has been assigned!')
      dispatch(
        getGroupStudents(
          1,
          25,
          'include=student,course,group,createdBy',
          query.stringify(normalizeFilters(students.filters))
        )
      )
      setLoading(false)
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        group_id: Yup.object().required('Group is required').nullable(),
      })
    ),
    defaultValues: {
      group_id: {value: student.group?.id, label: student.group?.name},
    },
  })

  const onSubmit = (values) => assignToGroup(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'group_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Group'
              containerClassName='mt-7'
              id='group_id'
              options={getOptions(groups)}
              {...field}
              error={errors.group_id}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default AssignStudentToGroup
