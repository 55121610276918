/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'
import {ModalContext} from 'context/ModalContext'
import API from 'config/API'

import {addTeamSuccess} from '../action/actionCreators'
import TeamsForm from './TeamsForm'
import {Notifier} from 'components/Notifier'

const AddTeam = () => {
  const dispatch = useDispatch()
  const {hideModal} = useContext(ModalContext)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.post(`/team/ticket_teams`, {
        ...values,
        leader_id: values.leader_id?.value,
        role_id: values.role_id?.value,
      })
      setLoading(false)
      dispatch(
        addTeamSuccess({
          ...response.data.data,
          leader: {id: values.leader_id?.value, name: values.leader_id?.label},
          role: {id: values.role_id?.value, name: values.role_id?.label},
        })
      )
      hideModal()
      Notifier.successMessage('Team has been created!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <TeamsForm onSubmitHandler={onSubmit} loading={loading} />
}

export default AddTeam
