import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import Select from 'react-select'

import API from 'config/API'
import {can} from 'modules/ticket/utils'

import Loader from 'components/Loader'
import Card from 'components/Card'
import EmptyList from 'components/EmptyList'
import Pagination from 'components/Pagination'
import Skeleton from 'components/Skeleton'
import {Notifier} from 'components/Notifier'

const NextSessionReport = () => {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [sessions, setSessions] = useState({
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      to: 0,
      per_page: 0,
      total: 0,
      last_page: 0,
    },
    filters: {},
    isFetching: false,
  })
  const [selectedGroup, setSelectedGroup] = useState(null)
  const loggedUser = useSelector(({auth}) => auth.data)

  const groupOptions = () => [
    {value: 'level', label: 'Level'},
    {value: 'date', label: 'Weekday'},
    {value: 'course', label: 'Course'},
    {value: 'instructor', label: 'Instructor'},
  ]

  useEffect(() => {
    async function fetchSessions() {
      try {
        setSessions((prevSessions) => ({...prevSessions, isFetching: true}))
        const instructorFilter = can.do('show_all_groups_next_session')
          ? ''
          : `&filter[instructor_id]=${loggedUser.id}`
        const response = await API.get(
          `/course/groups?include=nextSession.instructor,course,activeStudentCoursesCount${instructorFilter}&paginate=${size}&page=${page}`
        )
        setSessions((prevSessions) => ({
          ...prevSessions,
          data: response.data.data,
          meta: response.data.meta,
          isFetching: false,
        }))
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchSessions()
  }, [loggedUser.id, page, size])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const groupBy = (data, group) => {
    const grouped = {}
    const groupWith = group?.value || undefined

    if (!groupWith) {
      return data.sort((a, b) => (a.nextSession.date > b.nextSession.date ? 1 : -1))
    }

    data
      .sort((a, b) => (a.nextSession.date > b.nextSession.date ? 1 : -1))
      .forEach(function (curr) {
        if (groupWith === 'course') {
          const key = curr[groupWith].name
          return grouped[key] ? grouped[key].push(curr) : (grouped[key] = [curr])
        } else if (groupWith === 'instructor') {
          const key = curr.nextSession[groupWith]?.name || 'Anonymous'
          return grouped[key] ? grouped[key].push(curr) : (grouped[key] = [curr])
        } else if (groupWith === 'date') {
          const key = curr.nextSession[groupWith]
          return grouped[moment(key).format('dddd')]
            ? grouped[moment(key).format('dddd')].push(curr)
            : (grouped[moment(key).format('dddd')] = [curr])
        } else {
          const key = curr.nextSession[groupWith]
          return grouped[key] ? grouped[key].push(curr) : (grouped[key] = [curr])
        }
      })

    return grouped
  }

  const Header = () => (
    <React.Fragment>
      <div className='card-toolbar position-relative'>
        <div className='d-flex justify-content-start align-items-center'>
          <label className='fw-bolder fs-6 me-2'>Group by</label>
          <Select
            options={groupOptions()}
            value={selectedGroup}
            isClearable
            onChange={(option) => setSelectedGroup(option)}
            className='w-200'
          />
        </div>
      </div>
    </React.Fragment>
  )

  const Body = () => (
    <React.Fragment>
      <div className='table-responsive p-3'>
        {/* begin::Table */}
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bolder text-muted text-left'>
              <th className='min-w-150px'>Name</th>
              <th className='min-w-120px'>Weekday</th>
              <th className='min-w-120px'>Date</th>
              <th className='min-w-150px'>Course</th>
              <th className='min-w-150px'>Instructor</th>
              <th className='min-w-120px'>Level</th>
              <th className='min-w-120px'>Students</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {Array.isArray(groupBy(sessions && sessions.data, selectedGroup))
              ? groupBy(sessions && sessions.data, selectedGroup).map((group) => (
                  <tr key={group.id}>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-7'>
                        {group.name}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-7'>
                        {moment(group.nextSession?.date).format('dddd')}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-7'>
                        {group.nextSession?.date}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-7'>
                        {group.course?.name}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-7'>
                        {group.nextSession?.instructor?.name}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-7'>
                        {group.nextSession && group.nextSession.level}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-7'>
                        {group.activeStudentCoursesCount}
                      </span>
                    </td>
                  </tr>
                ))
              : Object.keys(groupBy(sessions && sessions.data, selectedGroup)).map((groupHead) => (
                  <React.Fragment key={groupHead}>
                    <tr className='group fs-6 fw-bolder bg-secondary-light'>
                      <td colSpan={7} className='px-2'>
                        {groupHead}
                      </td>
                    </tr>
                    {groupBy(sessions && sessions.data, selectedGroup)[groupHead].map((group) => (
                      <tr key={group.id}>
                        <td>
                          <span className='cursor-pointer text-dark fw-bolder text-hover-primary fs-7'>
                            {group.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary fs-7'>
                            {moment(group.nextSession?.date).format('dddd')}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary fs-7'>
                            {group.nextSession?.date}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary fs-7'>
                            {group.course?.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary fs-7'>
                            {group.nextSession?.instructor?.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary fs-7'>
                            {group.nextSession && group.nextSession.level}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder text-hover-primary fs-7'>
                            {group.activeStudentCoursesCount}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
            {sessions.data && !sessions.data.length && <EmptyList />}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {sessions.data && sessions.data.length ? (
        <div className='d-flex justify-content-between align-items-center p-3'>
          <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
            <div className='dataTables_length' id='kt_datatable_users_length'>
              <label>
                <select
                  className='form-select form-select-sm form-select-solid'
                  value={sessions.meta.per_page}
                  onChange={handleChangeSize}
                >
                  <option value='20'>20</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </label>
            </div>
            <div className='dataTables_info mx-2'>
              Showing {sessions.meta.from} to {sessions.meta.to} of {sessions.meta.total} records
            </div>
          </div>
          <div>
            <Pagination pageCount={sessions.meta.last_page} handlePageClick={handlePageChange} />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )

  if (sessions.isFetching) {
    return <Skeleton />
  }

  return (
    <Card
      classNames='mt-5 mb-xl-8'
      bodyClassNames='px-0'
      bodyElements={Body()}
      headerElements={Header()}
    />
  )
}

export default NextSessionReport
