import ContentLoader from 'react-content-loader'

const Skeleton = (props) => {
  const {type = 'table', width = 1200, height = 700} = props

  if (type === 'block') {
    return (
      <ContentLoader height='500' width='1000' viewBox='0 0 265 230' {...props}>
        <rect x='15' y='15' rx='4' ry='4' width='350' height='25' />
        <rect x='15' y='50' rx='2' ry='2' width='350' height='150' />
        <rect x='15' y='230' rx='2' ry='2' width='170' height='20' />
        <rect x='60' y='230' rx='2' ry='2' width='170' height='20' />
      </ContentLoader>
    )
  }

  if (type === 'notifications') {
    return (
      <ContentLoader
        width={300}
        height={500}
        viewBox='0 0 350 600'
        backgroundColor='#f5f5f5'
        foregroundColor='#dbdbdb'
        {...props}
      >
        <rect x='4' y='8' rx='3' ry='3' width='8' height='570' />
        <rect x='5' y='573' rx='3' ry='3' width='331' height='7' />
        <rect x='329' y='9' rx='3' ry='3' width='8' height='570' />
        <rect x='102' y='69' rx='3' ry='3' width='102' height='7' />
        <rect x='92' y='47' rx='3' ry='3' width='178' height='6' />
        <circle cx='48' cy='63' r='18' />
        <rect x='95' y='95' rx='3' ry='3' width='178' height='6' />
        <rect x='105' y='169' rx='3' ry='3' width='102' height='7' />
        <rect x='95' y='147' rx='3' ry='3' width='178' height='6' />
        <circle cx='51' cy='163' r='18' />
        <rect x='98' y='195' rx='3' ry='3' width='178' height='6' />
        <rect x='107' y='265' rx='3' ry='3' width='102' height='7' />
        <rect x='97' y='243' rx='3' ry='3' width='178' height='6' />
        <circle cx='53' cy='259' r='18' />
        <rect x='100' y='291' rx='3' ry='3' width='178' height='6' />
        <rect x='108' y='365' rx='3' ry='3' width='102' height='7' />
        <rect x='98' y='343' rx='3' ry='3' width='178' height='6' />
        <circle cx='54' cy='359' r='18' />
        <rect x='101' y='391' rx='3' ry='3' width='178' height='6' />
        <rect x='110' y='458' rx='3' ry='3' width='102' height='7' />
        <rect x='100' y='436' rx='3' ry='3' width='178' height='6' />
        <circle cx='56' cy='452' r='18' />
        <rect x='103' y='484' rx='3' ry='3' width='178' height='6' />
        <rect x='114' y='507' rx='3' ry='3' width='102' height='7' />
        <rect x='103' y='534' rx='3' ry='3' width='178' height='6' />
        <rect x='5' y='8' rx='3' ry='3' width='331' height='7' />
      </ContentLoader>
    )
  }

  if (type === 'table') {
    return (
      <ContentLoader
        width={width}
        height={height}
        viewBox='0 0 1200 700'
        backgroundColor='#eaeced'
        foregroundColor='#ffffff'
        {...props}
      >
        <rect x='51' y='45' rx='3' ry='3' width='1106' height='17' />
        <circle cx='1079' cy='123' r='11' />
        <circle cx='1114' cy='123' r='11' />
        <rect x='104' y='115' rx='3' ry='3' width='141' height='15' />
        <rect x='305' y='114' rx='3' ry='3' width='299' height='15' />
        <rect x='661' y='114' rx='3' ry='3' width='141' height='15' />
        <rect x='861' y='114' rx='3' ry='3' width='141' height='15' />
        <rect x='52' y='155' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='184' r='11' />
        <circle cx='1114' cy='184' r='11' />
        <rect x='105' y='176' rx='3' ry='3' width='141' height='15' />
        <rect x='306' y='175' rx='3' ry='3' width='299' height='15' />
        <rect x='662' y='175' rx='3' ry='3' width='141' height='15' />
        <rect x='862' y='175' rx='3' ry='3' width='141' height='15' />
        <rect x='53' y='216' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='242' r='11' />
        <circle cx='1114' cy='242' r='11' />
        <rect x='106' y='234' rx='3' ry='3' width='141' height='15' />
        <rect x='307' y='233' rx='3' ry='3' width='299' height='15' />
        <rect x='663' y='233' rx='3' ry='3' width='141' height='15' />
        <rect x='863' y='233' rx='3' ry='3' width='141' height='15' />
        <rect x='54' y='274' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='303' r='11' />
        <circle cx='1114' cy='303' r='11' />
        <rect x='107' y='295' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='294' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='294' rx='3' ry='3' width='141' height='15' />
        <rect x='864' y='294' rx='3' ry='3' width='141' height='15' />
        <rect x='52' y='335' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='363' r='11' />
        <circle cx='1114' cy='363' r='11' />
        <rect x='106' y='355' rx='3' ry='3' width='141' height='15' />
        <rect x='307' y='354' rx='3' ry='3' width='299' height='15' />
        <rect x='663' y='354' rx='3' ry='3' width='141' height='15' />
        <rect x='863' y='354' rx='3' ry='3' width='141' height='15' />
        <rect x='51' y='395' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='424' r='11' />
        <circle cx='1114' cy='424' r='11' />
        <rect x='107' y='416' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='415' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='415' rx='3' ry='3' width='141' height='15' />
        <rect x='864' y='415' rx='3' ry='3' width='141' height='15' />
        <rect x='52' y='453' rx='3' ry='3' width='1103' height='2' />
        <rect x='51' y='49' rx='3' ry='3' width='2' height='645' />
        <rect x='1155' y='49' rx='3' ry='3' width='2' height='645' />
        <circle cx='1079' cy='484' r='11' />
        <circle cx='1114' cy='484' r='11' />
        <rect x='107' y='476' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='475' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='475' rx='3' ry='3' width='141' height='15' />
        <rect x='864' y='475' rx='3' ry='3' width='141' height='15' />
        <rect x='52' y='513' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='544' r='11' />
        <circle cx='1114' cy='544' r='11' />
        <rect x='107' y='536' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='536' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='536' rx='3' ry='3' width='141' height='15' />
        <rect x='864' y='536' rx='3' ry='3' width='141' height='15' />
        <rect x='52' y='573' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='604' r='11' />
        <circle cx='1114' cy='604' r='11' />
        <rect x='107' y='596' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='596' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='596' rx='3' ry='3' width='141' height='15' />
        <rect x='864' y='596' rx='3' ry='3' width='141' height='15' />
        <rect x='52' y='633' rx='3' ry='3' width='1103' height='2' />
        <circle cx='1079' cy='664' r='11' />
        <circle cx='1114' cy='664' r='11' />
        <rect x='107' y='656' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='656' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='656' rx='3' ry='3' width='141' height='15' />
        <rect x='864' y='656' rx='3' ry='3' width='141' height='15' />
        <rect x='52' y='693' rx='3' ry='3' width='1103' height='2' />
        <rect x='52' y='80' rx='3' ry='3' width='1103' height='17' />
        <rect x='53' y='57' rx='3' ry='3' width='68' height='33' />
        <rect x='222' y='54' rx='3' ry='3' width='149' height='33' />
        <rect x='544' y='55' rx='3' ry='3' width='137' height='33' />
        <rect x='782' y='56' rx='3' ry='3' width='80' height='33' />
        <rect x='980' y='54' rx='3' ry='3' width='90' height='33' />
        <rect x='1120' y='57' rx='3' ry='3' width='38' height='33' />
      </ContentLoader>
    )
  }
}

export default Skeleton
