import React from 'react'
import {useHistory} from 'react-router-dom'

import API from 'config/API'
import CourseForm from './CourseForm'
import {can} from 'modules/ticket/utils'
import {Notifier} from 'components/Notifier'

const AddCourse = () => {
  const [loading, setLoading] = React.useState(false)
  const history = useHistory()

  const prepareSave = (values) => {
    // Create an object of formData
    const formData = new FormData()

    for (const value in values) {

      // Update the formData object
      formData.append(
        value,
        values[value]?.value ?? values[value]
      )
    }

    return formData
  }

  const onSubmitHandler = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/course/courses`, prepareSave(values))
      setLoading(false)
      history.push('/courses/list')
      Notifier.successMessage('Course has been created!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  if (!can.do('create_course')) {
    history.push('/404')
    return null
  }

  return <CourseForm loading={loading} onSubmitHandler={onSubmitHandler} />
}

export default AddCourse
