import * as Yup from 'yup'

export const leadsValidationRules = Yup.object().shape({
  name: Yup.string().required('Lead Name is required'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string(),
  mobile: Yup.string().required('Mobile is required'),
  course_id: Yup.object().required('Course is required').nullable(),
  channel_id: Yup.object().required('Channel is required').nullable(),
  branch_id:  Yup.object().nullable(),
});