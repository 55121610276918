import * as Yup from 'yup'

export const groupValidationRules = Yup.object().shape({
  name: Yup.string().required('name is required'),
  capacity: Yup.number().positive().integer().required('Capacity is required'),
  hours_per_session: Yup.number().positive().integer().required('Hours per session are required'),
  level: Yup.number().positive().integer().required('Level is required'),
  instructor_id: Yup.object().nullable(),
  group_round_id: Yup.object().required('Round is required').nullable(),
  group_status_id: Yup.object().required('Status is required').nullable(),
  course_id: Yup.object().required('Course is required').nullable(),
  branch_id: Yup.object().nullable(),
  is_online: Yup.boolean().required('Is Online required'),
  notes: Yup.string().required('Notes are required')
});