import {
  GET_ALL_TICKETS_SUCCESS,
  GET_ALL_TEAM_TICKETS_SUCCESS,
  GET_CREATED_BY_TEAM_MEMBERS_TICKETS_SUCCESS,
  GET_ASSIGNED_TICKETS_SUCCESS,
  GET_CREATED_TICKETS_SUCCESS,
  GET_PUBLIC_TICKETS_SUCCESS,
} from './actionTypes'
import API from 'config/API'

const getPublicTicketsSuccess = (tickets) => ({
  type: GET_PUBLIC_TICKETS_SUCCESS,
  payload: {tickets},
})

export const getPublicTickets = (page, size, filters) => async (dispatch) => {
  try {
    const url = `/ticket/public_tickets?page=${page}&pagination=${size}&include=reporter,ticketTeam,branch,ticketAction.ticketCategory,createdBy&${filters}`
    const response = await API.get(url)
    dispatch(
      getPublicTicketsSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
  } catch (error) {
    throw new Error(error.response)
  }
}

const getCreatedTicketsSuccess = (tickets) => ({
  type: GET_CREATED_TICKETS_SUCCESS,
  payload: {tickets},
})

// My created tickets
export const getCreatedTickets = (filters) => async (dispatch) => {
  try {
    const url = `/ticket/tickets?include=reporter,ticketTeam,agent,branch,ticketAction.ticketCategory,createdBy&pagination=false&${filters}`
    const response = await API.get(url)
    dispatch(
      getCreatedTicketsSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
  } catch (error) {
    throw new Error(error.response)
  }
}

const getAssignedTicketsSuccess = (tickets) => ({
  type: GET_ASSIGNED_TICKETS_SUCCESS,
  payload: {tickets},
})

// My created tickets
export const getAssignedTickets = (filters) => async (dispatch) => {
  try {
    const url = `/ticket/tickets?include=reporter,ticketTeam,branch,ticketAction.ticketCategory,createdBy&pagination=false&${filters}`
    const response = await API.get(url)
    dispatch(
      getAssignedTicketsSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
  } catch (error) {
    throw new Error(error.response)
  }
}

const getAllTeamTicketsSuccess = (tickets) => ({
  type: GET_ALL_TEAM_TICKETS_SUCCESS,
  payload: {tickets},
})

const getCreatedByTeamMembersTicketsSuccess = (tickets) => ({
  type: GET_CREATED_BY_TEAM_MEMBERS_TICKETS_SUCCESS,
  payload: {tickets},
})
// Team tickets
export const getCreatedByTeamMembersTickets = (filters) => async (dispatch) => {
  try {
    const url = `/ticket/tickets?include=reporter,ticketTeam,agent,branch,ticketAction.ticketCategory,createdBy&pagination=false&${filters}`
    const response = await API.get(url)
    dispatch(
        getCreatedByTeamMembersTicketsSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
  } catch (error) {
    throw new Error(error.response)
  }
}

export const getAllTeamTickets = (filters) => async (dispatch) => {
  try {
    const url = `/ticket/tickets?include=reporter,ticketTeam,agent,branch,ticketAction.ticketCategory,createdBy&pagination=false&${filters}`
    const response = await API.get(url)
    dispatch(
        getAllTeamTicketsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
    )
  } catch (error) {
    throw new Error(error.response)
  }
}

const getAllTicketsSuccess = (tickets) => ({
  type: GET_ALL_TICKETS_SUCCESS,
  payload: {tickets},
})

// Agent tickets
export const getAllTickets = (filters) => async (dispatch) => {
  try {
    const url = `/ticket/tickets?include=reporter,ticketTeam,agent,branch,ticketAction.ticketCategory,createdBy&pagination=false&${filters}`
    const response = await API.get(url)
    dispatch(
      getAllTicketsSuccess({
        data: response.data.data,
        meta: response.data.meta,
      })
    )
  } catch (error) {
    throw new Error(error.response)
  }
}
