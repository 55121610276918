
import API from 'config/API'
import { useFormik } from 'formik'
import { Notifier } from 'components/Notifier'
import { can } from 'modules/ticket/utils'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { schema } from '../yup/schema'
import TicketForm from './TicketForm'
import omit from 'lodash/omit'
import { useSelector } from 'react-redux'

function CreateTicket() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { client_config } = useSelector(({ auth }: any) => auth);

  const prepareSave = (values: any) => {
    // Create an object of formData
    const formData = new FormData();

    const newValues = {
      ...values,
      ticket_action_id: values.ticket_action_id.value,
      priority: values.priority.value,
      branch_id: values.branch_id.value,
    }

    if (!client_config.has_branch) {
      omit(newValues, 'branch_id');
    }

    for (const value in newValues) {
      // Update the formData object
      formData.append(
        value,
        newValues[value],
      );
    }

    return formData;
  };

  const formik = useFormik({
    initialValues: {
      ticket_action_id: '',
      priority: '',
      branch_id: '',
      description: '',
      attachment: '',
      ticketable_id: ''
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const transformedValues = prepareSave(values);
      setLoading(true)
      API.post(`/ticket/tickets`, transformedValues)
        .then(() => {
          setLoading(false)
          history.push('/tickets/list')
          Notifier.successMessage('Ticket has been created!');
        })
        .catch((error) => {
          Notifier.successMessage(error.response.data.message);
          setErrors(error.response.data.errors);
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  // check for valid permssion
  if (!can.do('create_ticket')) {
    history.push('/404');
    return null;
  }

  return <TicketForm formik={formik} loading={loading}  />;
}

export default CreateTicket;
