import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '_metronic/partials'

import Dashboard from 'modules/dashboard/Dashboard'

// channels
import Channels from 'modules/general/channels/Channels'

// complain categories
import ComplainCategories from 'modules/general/complainCategories/ComplainCategories'

// lead ad types
import LeadAdTypes from 'modules/general/leadAdTypes/LeadAdTypes'

// users
import Users from 'modules/users/Users'
import AddUser from 'modules/users/components/AddUser'
import EditUser from 'modules/users/components/EditUser'

// users
import Teams from 'modules/teams/Teams'
import AddTeam from 'modules/teams/components/AddTeam'

// ticket teams
import TicketTeams from 'modules/ticket/teams/TicketTeams'
import TicketTeamDetails from 'modules/ticket/teams/TicketTeamDetails'

// ticket list
import TicketsLayout from 'modules/ticket/list/TicketsLayout'
import TicketActions from 'modules/ticket/teams/TicketActions/components/TicketActions'
import CreateTicket from 'modules/ticket/list/components/CreateTicket'
import ViewTicket from 'modules/ticket/list/components/ViewTicket'
import AgentTickets from 'modules/ticket/list/components/AllTickets'
import AllTickets from 'modules/ticket/allTickets/AllTickets'
import TicketsPerAgentReport from 'modules/ticket/TicketsPerAgent/TicketsPerAgentReport'

// course management 
import Courses from 'modules/course/list/Courses'
import AddCourse from 'modules/course/list/components/AddCourse'
import CourseDetails from 'modules/course/list/components/CourseDetails'
import GroupStatuses from 'modules/course/groupStatus/groupStatuses'
import Groups from 'modules/course/groups/Groups'
import AddGroup from 'modules/course/groups/components/AddGroup'
import UpdateGroup from 'modules/course/groups/components/UpdateGroup'
import StudentsList from 'modules/course/groupStudents/StudentsList'
import GroupAttendance from 'modules/course/groupAttendance/GroupAttendance'
import NextSessionReport from 'modules/course/nextSession/NextSessionReport'

// generic
import { can } from 'modules/ticket/utils'
import { MasterLayout } from '_metronic/layout/MasterLayout'
import StudentProfile from 'modules/course/groupStudents/components/StudentProfile'
import TeamDetails from 'modules/teams/components/teamDetails/TeamDetails'
import Projects from 'modules/CRM/projects/Projects'
import Complaints from 'modules/CRM/complaints/Complaints'
import MediaBuying from 'modules/CRM/mediaBuying/MediaBuying'
import Leads from 'modules/CRM/leads/Leads'
import DeletedLeadsRequests from 'modules/CRM/deletedLeadsRequests/DeletedLeadsRequests'
import LeadDetails from 'modules/CRM/leads/components/leadDetails/LeadDetails'
import LeadsPerDay from 'modules/reports/leadsPerDay/LeadsPerDay'
import LeadsPerAgent from 'modules/reports/leadsPerAgent/LeadsPerAgent'
import LeadsLastAction from 'modules/reports/leadsLastAction/LeadsLastAction'
import GroupAttendanceRequestsReport from 'modules/course/groupAttendanceRequests/GroupAttendanceRequestsReport'

export function PrivateRoutes() {
  return (
    <MasterLayout>
      <React.Suspense fallback={<FallbackView />}>
        <Switch>
          {/* dashboard */}
          <Route exact path="/dashboard" render={() => <Dashboard />} />
          <Redirect exact from="/" to='/dashboard' />
          <Route exact path="/" render={() => <Dashboard />} />
          {/* general */}
          <Route exact path="/channels/list" render={() => <Channels />} />
          {/*<Route exact path="/complain_categories/list" render={() => <ComplainCategories />} />*/}
          <Route exact path="/lead_ad_types/list" render={() => <LeadAdTypes />} />
          {/* users */}
          {can.do("show_users_menu") && <Route exact path="/users/list" render={() => <Users />} />}
          {can.do("show_users_menu") && <Route exact path="/users/create" render={() => <AddUser />} />}
          {can.do("show_users_menu") && <Route exact path="/users/:id" render={() => <EditUser />} />}
          <Route exact path="/teams/list" render={() => <Teams />} />
          <Route exact path="/teams/create" render={() => <AddTeam />} />
          <Route exact path="/teams/:id" render={() => <TeamDetails />} />
          {/* CRM */}
          <Route exact path="/leads/list" render={() => <Leads />} />
          <Route exact path="/leads/:id" render={() => <LeadDetails />} />
          <Route exact path="/projects/list" render={() => <Projects />} />
          {/*<Route exact path="/complaints/list" render={() => <Complaints />} />*/}
          <Route exact path="/lead_ads/list" render={() => <MediaBuying />} />
          <Route exact path="/leads/deleted/list" render={() => <DeletedLeadsRequests />} />
          {/* tickets */}
          {can.do("show_teams_menu") && <Route exact path="/tickets/teams" render={() => <TicketTeams />} />}
          {can.do("show_teams_menu") && <Route exact path="/tickets/teams/:id" render={() => <TicketTeamDetails />} />}
          {can.do("show_team_member_tickets") && <Route exact path="/tickets/teams/:id/:agentId" render={() => <AgentTickets />} />}
          {can.do("show_teams_menu") && <Route exact path="/tickets/teams/:ticket_team_id/category/:ticket_category_id" render={() => <TicketActions />} />}
          {can.do("show_tickets_menu") && <Route exact path="/tickets/list" render={() => <TicketsLayout />} />}
          {can.do("show_all_tickets_menu") && <Route exact path="/tickets/all" render={() => <AllTickets />} />}
          {can.do("show_tickets_menu") && <Route exact path="/tickets/create" render={() => <CreateTicket />} />}
          <Route exact path="/tickets/:id" render={() => <ViewTicket />} />
          <Route exact path="/tickets/list/team/:id" render={() => <TicketsLayout />} />
          {can.do("show_tickets_per_agent_menu") && <Route exact path="/tickets_per_agent_report" render={() => <TicketsPerAgentReport />} />}
          {/* courses */}
          {can.do('show_courses_menu') && <Route exact path="/courses/list" render={() => <Courses />} />}
          {can.do('show_courses_menu') && <Route exact path="/courses/create" render={() => <AddCourse />} />}
          {can.do('show_courses_menu') && <Route exact path="/courses/:id" render={() => <CourseDetails />} />}
          {can.do('show_group_statuses_menu') && <Route exact path="/group_statuses/list" render={() => <GroupStatuses />} />}
          {can.do('show_groups_menu') && <Route exact path="/groups/list" render={() => <Groups />} />}
          {can.do('show_groups_menu') && <Route exact path="/groups/create" render={() => <AddGroup />} />}
          {can.do('show_groups_menu') && <Route exact path="/groups/:id" render={() => <UpdateGroup />} />}
          {can.do('show_groups_next_session_report_menu') && <Route exact path="/next_session_report" render={() => <NextSessionReport />} />}
          {can.do('approve_student_attendance_change') && <Route exact path="/group_attendance_requests_report" render={() => <GroupAttendanceRequestsReport />} />}
          {can.do('show_students_menu') && <Route exact path="/students/list" render={() => <StudentsList />} />}
          {can.do('show_students_menu') && <Route exact path="/student/:id" render={() => <StudentProfile />} />}
          {can.do('show_student_attendances_menu') && <Route exact path="/group_attendance/list" render={() => <GroupAttendance />} />}
          {/* reports */}
          <Route exact path="/leads_per_day" render={() => <LeadsPerDay />} />
          <Route exact path="/leads_per_agent" render={() => <LeadsPerAgent />} />
          <Route exact path="/leads_last_action" render={() => <LeadsLastAction />} />
          {/* dashboard */}
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='/404' />
        </Switch>
      </React.Suspense>
    </MasterLayout>
  )
}
