import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'
import API from 'config/API'
import query from 'query-string'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Skeleton from 'components/Skeleton'
import ConfirmationModal from 'components/ConfirmationModal'

import {ModalContext} from 'context/ModalContext'
import Filters from 'components/Filters'
import {fetchCourses, fetchLeadStages, fetchVisitCallStatuses, normalizeFilters} from 'config/utils'
import {getLeads, setFilters} from '../leads/action/actionCreators'
import TooltipTitle from 'components/TooltipTitle'
import SelectedFilters from 'components/SelectedFilters'

const DeletedLeadsRequests = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [leadStages, setLeadStages] = React.useState(null)
  const [visitStatuses, setVisitStatuses] = React.useState(null)
  const [courses, setCourses] = React.useState(null)
  const {showModal, hideModal} = React.useContext(ModalContext)
  const leadsList = useSelector(({crm}) => crm.leads)
  const agent_id = useSelector(({auth}) => auth.data.id)
  const dispatch = useDispatch()

  const fetchLeads = React.useCallback(
    () =>
      dispatch(
        getLeads(
          page,
          size,
          '-updated_by',
          `&only_trashed=1&${query.stringify(normalizeFilters({...leadsList.filters, agent_id}))}`
        )
      ),
    [dispatch, size, leadsList.filters, page, agent_id]
  )

  const fetchFiltersListOptions = async () => {
    const [leadStages, callStatuses, courses] = await Promise.all([
      fetchLeadStages(),
      fetchVisitCallStatuses(),
      fetchCourses(),
    ])
    // set state
    setLeadStages(leadStages.data)
    setVisitStatuses(callStatuses.data)
    setCourses(courses.data)
  }

  React.useEffect(() => {
    fetchLeads()
    fetchFiltersListOptions()
  }, [fetchLeads])

  const handleApprove = React.useCallback(
    (id) =>
      API.delete(`/crm/hard_delete_lead_requests/${id}`)
        .then(() => {
          fetchLeads()
          hideModal()
          Notifier.infoComponent('Changes Applied', 'Changes applied successfully')
        })
        .catch((error) => {
          // Ignore Error Message
        }),
    [hideModal, fetchLeads]
  )

  const onApproveDelete = React.useCallback(
    (id) =>
      showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: (
          <ConfirmationModal
            subText='By doing this action you won’t be able to retrieve later'
            handleAccept={() => handleApprove(id)}
          />
        ),
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      }),
    [handleApprove, hideModal, showModal]
  )

  const handleRestore = React.useCallback(
    (id) =>
      API.post(`/crm/lead_requests/${id}/restore`)
        .then(() => {
          fetchLeads()
          hideModal()
          Notifier.infoComponent('Changes Applied', 'Changes applied successfully')
        })
        .catch((error) => {
          // Ignore Error Message
        }),
    [hideModal, fetchLeads]
  )

  const onRestore = React.useCallback(
    (id) =>
      showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: (
          <ConfirmationModal
            subText='By doing this action you are going to restore this lead'
            handleAccept={() => handleRestore(id)}
          />
        ),
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      }),
    [handleRestore, hideModal, showModal]
  )

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  // filters
  const handleUpdateFilters = (filters) => dispatch(setFilters(filters))

  const getOptions = (data) => {
    return data && data.map((dataItem) => ({value: dataItem.id, label: dataItem.name}))
  }

  const getFilters = () => [
    {name: 'course_id', type: 'options', label: 'Course', options: getOptions(courses)},
    {name: 'lead_stage_id', type: 'options', label: 'Lead Stage', options: getOptions(leadStages)},
    {
      name: 'visit_call_status_id',
      type: 'options',
      label: 'Visit Or Call Status',
      options: getOptions(visitStatuses),
    },
    {name: 'start_date', type: 'date', label: 'Start Date'},
    {name: 'end_date', type: 'date', label: 'End Date'},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Lead',
        accessor: 'lead',
        Cell: ({row: {original: row}}) => row.lead?.name ?? 'N/A',
      },
      {
        Header: 'Lead Mobile',
        accessor: 'mobile',
        Cell: ({row: {original: row}}) => row.lead?.mobile ?? 'N/A',
      },
      {
        Header: 'Course',
        accessor: 'course',
        Cell: ({row: {original: row}}) => row.course?.name ?? 'N/A',
      },
      {
        Header: 'Channel',
        accessor: 'channel',
        Cell: ({row: {original: row}}) => row.channel?.name ?? 'N/A',
      },
      {
        Header: 'Team',
        accessor: 'team',
        Cell: ({row: {original: row}}) => row.team?.name ?? 'N/A',
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({row: {original: row}}) => row.agent?.name ?? 'N/A',
      },
      {
        Header: 'Lead Stage',
        accessor: 'leadStage',
        Cell: ({row: {original: row}}) => row.leadStage?.name ?? 'N/A',
      },
      {
        Header: 'Visit Call Status',
        accessor: 'visitCallStatus',
        Cell: ({row: {original: row}}) => row.visitCallStatus?.name ?? 'N/A',
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      leadsList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <TooltipTitle title='Approve Delete'>
              <div className='rounded-icon' onClick={() => onApproveDelete(dataItem.id)}>
                <i className="bi bi-check-all"></i>
              </div>
            </TooltipTitle>
            <TooltipTitle title='Restore'>
              <div className='rounded-icon' onClick={() => onRestore(dataItem.id)}>
                <i className="bi bi-arrow-clockwise"></i>
              </div>
            </TooltipTitle>
          </div>
        ),
      })),
    [leadsList, onApproveDelete, onRestore]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (leadsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-start'>
            <SelectedFilters filters={leadsList.filters} setFilters={setFilters} />
            <Filters
              filters={getFilters()}
              handleUpdateFilters={handleUpdateFilters}
              initialValues={leadsList.filters}
            />
          </div>
        </div>
      </div>
      {isEmpty(leadsList.data) ? (
        <EmptyList message='No Deleted Leads Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(leadsList.data) && leadsList.meta.last_page > 1 && (
        <Pagination
          pageCount={leadsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={leadsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default DeletedLeadsRequests
