import {
  DELETE_LEAD_AD_SUCCESS,
  GET_LEAD_ADS_SUCCESS,
  SET_FETCHING_LEAD_ADS,
} from '../action/actionTypes'
import API from 'config/API'

const getLeadAdsSuccess = (leadAds) => ({
  type: GET_LEAD_ADS_SUCCESS,
  payload: {leadAds},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_LEAD_ADS,
  payload: {isFetching},
})

export const getLeadAds =
  (
    page,
    size, // paginate
    sort,
    filters = ''
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/crm/lead_ads?include=leadAdType,project,createdBy&paginate=${size}&sort=${sort}&page=${page}&${filters}`
      )
      dispatch(
        getLeadAdsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

export const deleteLeadAdSuccess = (id) => ({
  type: DELETE_LEAD_AD_SUCCESS,
  payload: {id},
})
