import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux';

import API from 'config/API';
import { getTeamMembers } from '../action/actionCreators';
import { ModalContext } from 'context/ModalContext';
import { Notifier } from 'components/Notifier';
import TeamMembersForm from './TeamMembersForm';

const AddTeamMembers = (props) => {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/team/team_members`, { 
        ...values, 
        members: values.members.map(({ value }) => ( value )),
        team_id: props.id
      })
        setLoading(false)
        dispatch(getTeamMembers(1, 10, props.id));
        hideModal();
        Notifier.successMessage('Team member has been added!');
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  }

  return <TeamMembersForm onSubmitHandler={onSubmit} loading={loading} />
}

export default AddTeamMembers;