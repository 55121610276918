import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {schema} from '../yup/schema'
import Loader from 'components/Loader'
import Button from 'components/Button'
import SelectOptions from 'components/SelectOptions'
import { ModalContext } from 'context/ModalContext'
import { getUsers } from 'modules/users/action/actionCreators'

const TeamMembersForm = ({onSubmitHandler, loading}) => {
  const {hideModal} = useContext(ModalContext)
  const dispatch = useDispatch()
  const users = useSelector(({users}) => users.data)

  useEffect(() => {
    // filter[without_team]=true
    dispatch(getUsers(1, 25, '-created_at', 'filter[role]=Tickets Agent&filter[noRelation]=ticketTeam'))
  }, [dispatch])

  const getOptions = () => {
    return users && users.map((user) => ({value: user.id, label: user.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      members: [],
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'members',
          render: ({field}) => (
            <SelectOptions
              placeholder='Team Members'
              containerClassName='mt-7'
              options={getOptions()}
              id='members'
              isMulti
              {...field}
              error={errors.members}
            />
          ),
        }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default TeamMembersForm
