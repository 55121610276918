import React, {useState} from 'react'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import LeadInfo from 'modules/CRM/leads/components/leadDetails/components/LeadInfo'
import LeadCourses from 'modules/CRM/leads/components/leadDetails/components/LeadCourses'
import API from 'config/API'
import {getGroupStudents} from '../action/actionCreators'
import {attendanceBadges} from '../utils'

import {Notifier} from 'components/Notifier'
import Table from 'components/Table'
import EmptyList from 'components/EmptyList'
import Skeleton from 'components/Skeleton'

const StudentProfile = (props) => {
  const [student, setStudent] = useState(null)
  const [studentCourseId, setStudentCourseId] = useState(null)
  const [attendance, setAttendance] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const studentId = props.match.params.id
  const {group_students: students} = useSelector(({course}) => course)

  const fetchStudentProfile = React.useCallback(async () => {
    try {
      const response = await API.get(`/student/students/${studentId}?include=lead,offers,packages,studentCalls`)
      setStudent(response.data.data)
    } catch (error) {
      props.history.push('/404')
    }
  }, [studentId, props.history])

  const fetchAll = React.useCallback(async () => {
    await Promise.all([
      fetchStudentProfile(),
      dispatch(getGroupStudents(1, 25, 'include=course', `filter[student_id]=${studentId}`)),
    ])
  }, [dispatch, fetchStudentProfile, studentId])

  React.useEffect(() => {
    fetchAll()
  }, [fetchAll])

  const fetchAttendance = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await API.get(
        `/student/student_attendances?include=studentCourse.student&filter[student_course_id]=${studentCourseId}`
      )
      setAttendance(response.data.data)
      setLoading(false)
    } catch (error) {
      // Ignore Error Message
      setLoading(false)
    }
  }, [studentCourseId])

  React.useEffect(() => {
    studentCourseId && fetchAttendance();
  }, [studentCourseId, fetchAttendance])

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.name ?? null,
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        Cell: ({row: {original: row}}) => row.studentCourse?.student?.lead?.mobile ?? null,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row: {original: row}}) => (
          <span
            className={`fw-bolder badge text-light fs-8 w-80 badge-${
              attendanceBadges[row?.status]
            }`}
          >
            {row?.status}
          </span>
        ),
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({row: {original: row}}) => row.notes ?? 'N/A',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      attendance.map((dataItem) => ({
        ...dataItem,
      })),
    [attendance]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  if (!student) {
    return <Skeleton type='block' />
  }


  const getDOMElement = () => {
    if (loading) {
      return <Skeleton width={800} height={500} />
    }

    if (isEmpty(attendance)) {
      return <EmptyList message='No reports Available' />
    }

    return <Table table={table} isFetching={false} />;
  }

  return (
    <div className='d-flex align-items-start justify-content-center gap-5 mt-10 pt-5'>
      <div className="width-30">
        <LeadInfo lead={student?.lead} />
        <LeadCourses
          leadRequests={students.data}
          setLeadRequestId={setStudentCourseId}
          selectedLeadRequest={studentCourseId}
        />
      </div>
      <div className="width-70">
        <div className='d-flex align-items-center justify-content-between pb-5'>
          <h1 className='fs-3 mb-0'>Attendance Report</h1>
        </div>
        {getDOMElement()}
      </div>
    </div>
  )
}

export default withRouter(StudentProfile)
