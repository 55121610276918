import React from 'react'
import {useHistory} from 'react-router-dom'
import { teamValidationRules } from '../yup/teamValidationRules'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import API from 'config/API'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import Input from 'components/Input'
import SelectOptions from 'components/SelectOptions'
import Stepper from 'react-stepper-horizontal'
import Loader from 'components/Loader'
import Button from 'components/Button'

const FIRST_STEP = 0

const TeamForm = ({loading, team = null, onSubmitFirstStepHandler, onSubmitHandler}) => {
  const [leadersList, setLeadersList] = React.useState([])
  const [rolesList, setRolesList] = React.useState([])
  const [activeStep, setActiveStep] = React.useState(FIRST_STEP)

  const history = useHistory()

  React.useEffect(() => {
    async function fetchLeaders() {
      try {
        const response = await API.get(`/user/users?filter[role]=Team Leader`)
        setLeadersList(response.data.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchLeaders()
  }, [])

  React.useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await API.get(`/user/roles`)
        setRolesList(response.data.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchRoles()
  }, [])

  const getRolesOptions = () => {
    return rolesList && rolesList.map((role) => ({value: role.id, label: role.name}))
  }

  const getLeadersOptions = () => {
    return leadersList && leadersList.map((leader) => ({value: leader.id, label: leader.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors, isValid},
    getValues,
    setError,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(teamValidationRules),
    defaultValues: {
      name: team ? team.name : '',
      leader: team ? {value: team.leader?.id, label: team.leader?.name} : null,
      role: team ? {value: team.role?.id, label: team.role?.name} : null,
      leader_target: team ? team.teamTargetAndCommission?.leader_target : 1,
      leader_commission: team ? team.teamTargetAndCommission?.leader_commission : 1,
      agent_target: team ? team.teamTargetAndCommission?.agent_target : 1,
      agent_commission: team ? team.teamTargetAndCommission?.agent_commission : 1,
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  const BasicInfo = () => (
    <div className='d-flex align-items-start justify-content-between gap-5'>
      <div className='flex-1'>
        <Controller
          {...{
            control,
            name: 'name',
            render: ({field}) => (
              <Input
                placeholder='Name'
                containerClassName='mt-7'
                id='name'
                error={errors.name}
                {...field}
              />
            ),
          }}
        />
        <Controller
          {...{
            control,
            name: 'role',
            render: ({field}) => (
              <SelectOptions
                placeholder='Role'
                containerClassName='mt-7'
                id='role'
                options={getRolesOptions()}
                error={errors.role}
                {...field}
              />
            ),
          }}
        />
      </div>
      <div className='flex-1'>
        <Controller
          {...{
            control,
            name: 'leader',
            render: ({field}) => (
              <SelectOptions
                placeholder='Leader'
                containerClassName='mt-7'
                id='leader'
                options={getLeadersOptions()}
                error={errors.leader}
                {...field}
              />
            ),
          }}
        />
      </div>
    </div>
  )

  const InsightsInfo = () => (
    <div className='d-flex align-items-start justify-content-between gap-5'>
      <div className='flex-1'>
        <Controller
          {...{
            control,
            name: 'leader_target',
            render: ({field}) => (
              <Input
                type='number'
                placeholder='Leader Target'
                containerClassName='mt-7'
                id='leader_target'
                error={errors.leader_target}
                {...field}
              />
            ),
          }}
        />
        <Controller
          {...{
            control,
            name: 'leader_commission',
            render: ({field}) => (
              <Input
                type='number'
                placeholder='Leader Commission'
                containerClassName='mt-7'
                id='leader_commission'
                error={errors.leader_commission}
                {...field}
              />
            ),
          }}
        />
      </div>
      <div className='flex-1'>
        <Controller
          {...{
            control,
            name: 'agent_target',
            render: ({field}) => (
              <Input
                type='number'
                placeholder='Agent Target'
                containerClassName='mt-7'
                id='agent_target'
                error={errors.agent_target}
                {...field}
              />
            ),
          }}
        />
        <Controller
          {...{
            control,
            name: 'agent_commission',
            render: ({field}) => (
              <Input
                type='number'
                placeholder='Agent Commission'
                containerClassName='mt-7'
                id='agent_commission'
                error={errors.agent_commission}
                {...field}
              />
            ),
          }}
        />
      </div>
    </div>
  )

  const getCurrentStep = (step) => {
    const steps = {
      0: () => <BasicInfo />,
      1: () => <InsightsInfo />,
    }

    return steps[step]()
  }

  const nextStep = async () => {
    // validate required form inputs on first step before go next
    if (isEqual(activeStep, FIRST_STEP) && !isValid) {
      return trigger()
    }

    await onSubmitFirstStepHandler(getValues(), setError);
    setActiveStep(activeStep + 1)
  }

  const cancelSubmit = () => history.push('/teams/list')

  const previousStep = () => {
    if (activeStep === FIRST_STEP) return cancelSubmit()
    setActiveStep(activeStep - 1)
  }

  // change title based on
  const getButtonTitle = (title) => (loading ? <Loader /> : title)

  const getActions = () => {
    const createButton = (
      <Button title={getButtonTitle('Submit Team')} type='submit' className='bg-primary py-5' />
    )

    const continueButton = (
      <Button
        title={getButtonTitle('Continue')}
        type='button'
        className='font-medium text-white text-base ml-5 bg-primary hover:bg-slate-800'
        onClick={nextStep}
      />
    )

    return (
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={previousStep}
        />
        {activeStep === 0 && continueButton}
        {activeStep === 1 && createButton}
      </div>
    )
  }

  return (
    <div className='mt-5 pt-5'>
      <div className='card'>
        <div className='card-body pt-5 mt-5'>
          <div className='text-center my-5'>
              <h1>{isEmpty(team) ? 'Create Team' : ''}</h1>
            </div>
          <Stepper
            steps={[{title: 'Basic Info'}, {title: 'Insights'}]}
            activeStep={activeStep}
            activeColor='#000000'
            completeColor='#000000'
            defaultColor='#F4F4F4'
            activeTitleColor='#010101'
            completeTitleColor='#010101'
            defaultTitleColor='#979797'
            circleFontSize={12}
            titleFontSize={12}
            circleTop={20}
            barStyle='dashed'
          />
          <form
            className='form w-100 m-auto max-w-80 my-5 pb-5'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {getCurrentStep(activeStep)}
            <div className='flex items-center justify-center'>{getActions()}</div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default TeamForm
