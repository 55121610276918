import React, {useEffect, useState} from 'react'
import {Accordion} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import {Link, withRouter} from 'react-router-dom'
import query from 'query-string'

import TeamMembers from './TeamMembers/components/TeamMembers'
import TicketCategories from './TicketCategories/components/TicketCategories'

import TicketsStats from 'components/TicketsStats'
import API from 'config/API'
import Card from 'components/Card'
import Can from 'components/Can'

import {can} from '../utils'
import {normalizeFilters} from 'config/utils'

function TicketTeamDetails(props) {
  const [team, setTeam] = useState(null)
  const {teamMembers, categories} = useSelector(({ticket}) => ticket)
  const selectedRange = useSelector(({filters}) => filters)

  const teamId = props.match.params.id

  useEffect(() => {
    async function fetchTeam() {
      try {
        const response = await API.get(`/team/ticket_teams/${teamId}?include=leader`)
        setTeam(response.data.data)
      } catch (error) {
        // Ignore Error Message
        props.history.push('/ticket/teams')
      }
    }

    fetchTeam()
  }, [])

  return (
    <React.Fragment>
      <TicketsStats
        filters={query.stringify(
          normalizeFilters({
            team_id: teamId,
            date_from: selectedRange.startDate,
            date_to: selectedRange.endDate,
          })
        )}
      />
      <div className='card my-5'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-900 text-hover-primary fs-2 fw-bolder me-1'>
                      {team?.name}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                      <span className='svg-icon svg-icon-4 me-1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            opacity='0.3'
                            d='M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z'
                            fill='black'
                          ></path>
                          <path
                            d='M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z'
                            fill='black'
                          ></path>
                        </svg>
                      </span>
                      {team?.leader?.name}
                    </span>
                  </div>
                </div>
                <Link to={`/tickets/list/team/${teamId}`} className='btn btn-sm btn-primary me-3'>
                  View Tickets
                </Link>
              </div>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <Can do='show_ticket_team_member'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder counted' data-kt-countup='true'>
                          {teamMembers && teamMembers.data.length}
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Team Members</div>
                    </div>
                  </Can>
                  <Can do='show_ticket_category'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder counted' data-kt-countup='true'>
                          {categories && categories.data.length}
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Ticket Categories</div>
                    </div>
                  </Can>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {can.do('show_ticket_category') && (
        <Card classNames='mb-5'>
          <Accordion>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Ticket Categories</h3>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <TicketCategories team={team} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      )}
      {can.do('show_ticket_team_member') && (
        <Card classNames='mb-5'>
          <Accordion>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Team Members</h3>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <TeamMembers team={team} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      )}
    </React.Fragment>
  )
}

export default withRouter(TicketTeamDetails)
