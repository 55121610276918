import React from 'react'

import {ModalContext} from 'context/ModalContext'

import {Notifier} from './Notifier'
import Loader from './Loader'
import Button from './Button'

const ConfirmationModal = ({
  title = 'Are you sure you want to save this action?',
  subText = 'By Deleting this field you won’t be able to retrieve later',
  handleAccept = undefined,
  actionButtonColor = '#F1416C',
}) => {
  // status
  const [loading, setLoading] = React.useState(false)

  // context
  const {hideModal} = React.useContext(ModalContext)

  const onAccept = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      hideModal()
      // if we have a custom handler
      if (handleAccept) {
        return handleAccept()
      }
      Notifier.infoComponent('Changes Applied', 'Changes applied successfully')
    }, 1500)
  }

  return (
    <div className='d-flex align-items-center justify-content-center text-center flex-column py-10'>
      <i className='bi bi-shield-x' style={{color: actionButtonColor, fontSize: 60}}></i>
      <h1 className='font-weight-bold fs-2 mt-10 mb-3'>{title}</h1>
      <p className='fs-6 max-w-sm'>{subText}</p>
      <div className='d-flex align-items-center justify-content-center'>
        <Button title='No' className='bg-white w-40' onClick={hideModal} />
        <Button
          title={loading ? <Loader /> : 'Yes'}
          className='color-white mx-5 w-40'
          style={{backgroundColor: actionButtonColor}}
          onClick={onAccept}
        />
      </div>
    </div>
  )
}

export default ConfirmationModal
