import React from 'react'
import Switch from 'react-switch'

const Switcher = ({
  defaultChecked = false,
  onChange,
  onColor = '#3FC493',
  offColor = '#ffc700',
  numeric = false,
}) => {
  // active
  const [active, setActive] = React.useState(defaultChecked)

  const onToggleActive = (value) => {
    setActive(value)
    onChange(numeric ? Number(value) : value)
  }

  const SwitchComponent = () => (
    <Switch
      checked={active}
      onChange={onToggleActive}
      onColor={onColor}
      offColor={offColor}
      uncheckedIcon={null}
      checkedIcon={null}
      width={28}
      height={20}
    />
  )

  return <SwitchComponent />
}

export default Switcher
