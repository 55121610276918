import React, {useState} from 'react'
import API from 'config/API'

import {useHistory} from 'react-router-dom'

import CourseForm from './CourseForm'
import {Notifier} from 'components/Notifier'
import Skeleton from 'components/Skeleton'

const UpdateCourse = ({course}) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const onSubmitHandler = async (values, setError) => {
    try {
      setLoading(true)
      await API.put(`/course/courses/${course.id}`, {
        ...values,
        type: values['type'].value
      })
      setLoading(false)
      history.push('/courses/list')
      Notifier.successMessage('Course has been updated!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  };

  if (!course) {
    return <Skeleton type='block' />
  }

  return <CourseForm loading={loading} course={course} onSubmitHandler={onSubmitHandler} />
}

export default UpdateCourse
