import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'
import API from 'config/API'
import moment from 'moment'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import ConfirmationModal from 'components/ConfirmationModal'

import {ModalContext} from 'context/ModalContext'
import {deleteProjectSuccess, getProjects} from './action/actionCreators'
import ViewProject from './components/ViewProject'
import AddProject from './components/AddProject'
import Input from 'components/Input'

const Projects = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [searchText, setSearchText] = React.useState('')
  const [searchFilter, setSearchFilter] = React.useState('')
  const {showModal, hideModal} = React.useContext(ModalContext)
  const projectsList = useSelector(({crm}) => crm.projects)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getProjects(page, size, 'name', `filter[name]=${searchFilter}`))
  }, [dispatch, size, searchFilter, page])

  const handleDelete = (id) =>
    API.delete(`/crm/projects/${id}`)
      .then(() => {
        dispatch(deleteProjectSuccess(id))
        hideModal()
        Notifier.successMessage('Project has been deleted!')
      })
      .catch((error) => {
        // Ignore Error Message
      })

  const deleteProject = (id) =>
    showModal({
      id: 'confirm',
      dialogClassName: 'confirm-dialog',
      closeButton: true,
      title: undefined,
      body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const viewProject = (project) =>
    showModal({
      id: 'view-project-form-modal',
      dialogClassName: 'view-project-form',
      closeButton: true,
      title: 'View Project',
      body: <ViewProject project={project} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const addProject = () =>
    showModal({
      id: 'add-project-form-modal',
      dialogClassName: 'add-project-form',
      closeButton: true,
      title: 'Add Project',
      body: <AddProject />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  const handleChangeSearch = (event) => setSearchText(event.target.value)

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({row: {original: row}}) => moment(row.updated_at).format('DD-MM-YYYY, h:mm A'),
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      projectsList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <div className='rounded-icon' onClick={() => viewProject(dataItem)}>
              <i className='bi bi-pencil'></i>
            </div>
            <div className='rounded-icon' onClick={() => deleteProject(dataItem.id)}>
              <i className='bi bi-trash2'></i>
            </div>
          </div>
        ),
      })),
    [projectsList]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (projectsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-center'>
            <form
              onSubmit={(event) => {
                event.preventDefault()
                setSearchFilter(searchText)
              }}
            >
              <Input
                type='text'
                containerClassName='bg-white'
                style={{width: 400}}
                placeholder='Project Name'
                value={searchText}
                onChange={handleChangeSearch}
                rightIcon={
                  <i
                    className='bi bi-arrow-right-circle text-black color-black fs-4 cursor-pointer'
                    onClick={() => setSearchFilter(searchText)}
                  ></i>
                }
              />
            </form>
            <Button
              title={
                <span className='d-flex align-items-center justify-content-center'>
                  <i
                    className='bi bi-plus-circle mx-2'
                    style={{color: 'white', fontSize: '1.5rem'}}
                  ></i>
                  Create Project
                </span>
              }
              className='mt-0 w-auto mx-4 p-3 bg-primary'
              onClick={addProject}
            />
          </div>
        </div>
      </div>
      {isEmpty(projectsList.data) ? (
        <EmptyList message='No Projects Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(projectsList.data) && projectsList.meta.last_page > 1 && (
        <Pagination
          pageCount={projectsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={projectsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Projects
