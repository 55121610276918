import { getFileNameFromUrl } from 'config/utils';
import moment from 'moment';
import Can from 'components/Can';
import React, { useRef, useState } from 'react'

function CommentListItem({ comment, sendComment }: any) {
  const [newComment, setNewComment] = useState('');
  const [file, setFile] = useState('');
  const [reply, showReply] = useState(false);
  const fileRef: any = useRef();

  const onFileChange = (event: any) => setFile(event.target.files[0]); 

  return (
              <React.Fragment>
                <div className="mb-9" key={comment.id}>
                  <div className="card card-bordered w-100">
                    <div className="card-body">
                      <div className="w-100 d-flex flex-stack mb-8">
                        <div className="d-flex align-items-center f">
                          <div className="symbol symbol-50px me-5">
                            <div className="symbol-label fs-1 fw-bolder bg-light-info text-info">{comment.createdBy.name[0].toUpperCase()}</div>
                          </div>
                          <div className="d-flex flex-column fw-bold fs-5 text-gray-600 text-dark">
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bolder text-hover-primary fs-5 me-3">{comment.createdBy.name}</span>
                            </div>
                            <span className="text-muted fw-bold fs-6">{moment(comment.created_at).format('D MMMM, YYYY h:mm A')}</span>
                          </div>
                        </div>
                          {!comment.reply && (
                            <div className="m-0">
                              <Can do="create_ticket_comment">
                                <button 
                                  className="btn btn-color-gray-400 btn-active-color-primary p-0 fw-bolder"
                                  onClick={() => showReply(!reply)}
                                  >Reply
                                  </button>
                              </Can>
                            </div>
                          )}
                      </div>
                      <p className="fw-normal fs-5 text-gray-700 m-0 mx-20">{comment.comment}</p>
                      {comment.attachment && (
                        <div className="d-flex align-items-center my-5 mx-20 text-gray-700">
                          <i className="bi bi-paperclip fs-4"></i>
                          <a href={comment.attachment} target="_blanK" className="mx-2">{getFileNameFromUrl(comment.attachment)}</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!comment.reply && reply && (
                  <div className="mb-9 ms-9">
                    <textarea value={newComment} onChange={(event) => setNewComment(event.target.value)} className="form-control form-control-solid placeholder-gray-600 fw-bolder fs-4 ps-9 pt-7" rows={6} name="message" placeholder="Comment..."></textarea>
                    <button type="button" onClick={() => sendComment(newComment, comment.id, file)} 
                    className="btn btn-primary mt-n20 mb-20 position-relative float-end me-7">Comment</button>
                    <input 
                      type="file" 
                      accept="image/*,.pdf,.doc"
                      ref={fileRef}  
                      onChange={onFileChange} 
                      hidden 
                    />
                    <button className="btn btn-secondary mt-n20 mb-20 position-relative float-end me-2" onClick={() => (fileRef.current as any).click()}><i className="bi bi-paperclip fs-4"></i></button>
                    {file && <span className="mt-n15 mb-20 position-relative float-end me-2">{(file as any).name}</span>}
                  </div>
                )}
                {comment.reply && (
                  <div className="mb-9 ms-9 position-relative" key={comment.reply.id}>
                    <div className="card card-bordered w-100">
                      <div className="card-body">
                        <div className="w-100 d-flex flex-stack mb-8">
                          <div className="d-flex align-items-center f">
                            <div className="symbol symbol-50px me-5">
                              <div className="symbol-label fs-1 fw-bolder bg-light-info text-info">{comment.reply.createdBy.name[0].toUpperCase()}</div>
                            </div>
                            <div className="d-flex flex-column fw-bold fs-5 text-gray-600 text-dark">
                              <div className="d-flex align-items-center">
                                <span className="text-gray-800 fw-bolder text-hover-primary fs-5 me-3">{comment.reply.createdBy.name}</span>
                              </div>
                              <span className="text-muted fw-bold fs-6">{moment(comment.reply.created_at).format('D MMMM, YYYY h:mm A')}</span>
                            </div>
                          </div>
                        </div>
                        <p className="fw-normal fs-5 text-gray-700 m-0 mx-20">{comment.reply.comment}</p>
                        {comment.reply.attachment && (
                        <div className="d-flex align-items-center my-5 mx-20 text-gray-700">
                          <i className="bi bi-paperclip fs-4"></i>
                          <a href={comment.reply.attachment} target="_blanK" className="mx-2">{getFileNameFromUrl(comment.reply.attachment)}</a>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
  )
}

export default CommentListItem
