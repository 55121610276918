import * as Yup from 'yup'

export const courseValidationRules = Yup.object().shape({
  name: Yup.string().required('name is required'),
  website_desc:  Yup.object().shape({
    en: Yup.string().required('English description is required'),
    ar: Yup.string().required('Arabic description is required')
  }),
  website_name: Yup.object().shape({
    en: Yup.string().required('English name is required'),
    ar: Yup.string().required('Arabic name is required')
  }),
  image: Yup.object().nullable().shape({
    file: Yup.object().shape({
        name: Yup.string()
    }).label('File'),
  }),
  is_refundable: Yup.string().required('Is Refundable required'),
  num_of_levels: Yup.number().required('Number of levels is required').typeError('Number of levels is required'),
  hours_per_level: Yup.number().required('Number of hours is required').typeError('Number of hours is required'),
  type: Yup.object().required('Type is required').nullable(),
});