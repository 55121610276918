import React from 'react'
import {useDispatch} from 'react-redux'
import API from 'config/API'

import {getLeadAds, updateLeadAdSuccess} from '../action/actionCreators'
import {Notifier} from 'components/Notifier'
import {ModalContext} from 'context/ModalContext'
import LeadAdForm from './LeadAdForm'

const ViewLeadAd = ({leadAd}) => {
  const [loading, setLoading] = React.useState(false)
  const {hideModal} = React.useContext(ModalContext)
  const dispatch = useDispatch()

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.put(`/crm/lead_ads/${leadAd.id}`, {
        name: values.name,
        lead_ad_type_id: values.lead_ad_type_id.value,
        project_id: values.project_id.value,
      })
      setLoading(false)
      dispatch(getLeadAds(1, 10, 'name'));
      hideModal();
      Notifier.successMessage('Lead Ad has been saved!')
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <LeadAdForm
      onSubmitHandler={onSubmit}
      initialValues={{
        name: leadAd.name,
        lead_ad_type_id: leadAd.leadAdType
          ? {label: leadAd.leadAdType.name, value: leadAd.leadAdType.id}
          : null,
        project_id: leadAd.project ? {label: leadAd.project.name, value: leadAd.project.id} : null,
      }}
      loading={loading}
    />
  )
}

export default ViewLeadAd
