import { ModalContext } from 'context/ModalContext';
import React from 'react';
import CustomModal from './CustomModal';

export default function withModal(WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  function ComponentWithModal(props) {
    const [modal, setModal] = React.useState(undefined);

    const hideModal = () => {
      setModal(undefined);
    };

    const showModal = ({
      id,
      dialogClassName,
      closeButton,
      title,
      body,
      actions,
      closeAction,
      centerTitle,
      width,
      height
    }) => {
      setModal({
        id,
        dialogClassName,
        closeButton,
        title,
        body,
        actions,
        show: true,
        closeAction,
        centerTitle,
        width,
        height
      });
    };

    const providerValue = React.useMemo(() => ({ hideModal, showModal, modal }), [modal]);

    return (
      <ModalContext.Provider value={providerValue}>
        {modal && (
          <CustomModal
            id={modal.id}
            dialogClassName={modal.dialogClassName}
            closeButton={modal.closeButton}
            title={modal.title}
            body={modal.body}
            actions={modal.actions}
            show={modal.show}
            closeAction={modal.closeAction}
            centerTitle={modal.centerTitle}
            width={modal.width}
            height={modal.height}
          />
        )}
        <WrappedComponent {...{ props }} />
      </ModalContext.Provider>
    );
  }

  ComponentWithModal.displayName = `withModal(${displayName})`;

  return ComponentWithModal;
}
