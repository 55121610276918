import classNames from "classnames";
import React from "react";
import isEmpty from 'lodash/isEmpty';
import Select, { components } from "react-select";
import ErrorInput from "./ErrorInput";

const SelectOptions = React.forwardRef(
  (
    {
      name = "",
      placeholder = "",
      containerClassName = "",
      customOption = null,
      options = [],
      isClearable = false,
      value,
      onChange,
      defaultValue,
      error,
      disabled,
      ...rest
    },
    ref
  ) => {
    const [isActive, setActive] = React.useState(!isEmpty(value));
    const setInputFocus = (status) =>
      value ? setActive(true) : setActive(status);

    // default option DOM
    const defaultCustomOption = (props) => (
      <components.Option {...props}>{props?.label}</components.Option>
    );

    const getPlaceholder = () => (
      <div className="d-flex align-items-center">
        <i className="bi bi-search"></i>
        <span className="mx-2">Search...</span>
      </div>
    )

    return (
      <div className={classNames("input-relative", containerClassName)}>
        <label
          className={classNames("input-label", {
            "input-label__focused": isActive,
            "text-red": isActive && error,
          })}
        >
          {placeholder}
        </label>

        <div className="input-relative relative">
          <Select
            className="select-overlay"
            classNamePrefix="select"
            styles={{
              indicatorSeparator: () => ({ display: "none" }),
              control: (base) => ({
                ...base,
                border: "none",
                borderRadius: "4px",
                minHeight: "44px",
                fontSize: "14px",
                paddingLeft: "6px",
              }),
              menuPortal: (base,) => ({ ...base, zIndex: 9999 }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected && "#F4F4F4",
                borderBottom: '1px solid #f4f4f4',
                display: "flex",
                alignItems: "center",
                color: "#333",
                "&:hover": {
                  backgroundColor: "#F4F4F4"
                }
              }),
            }}
            menuPortalTarget={document.body}
            components={{ Option: customOption || defaultCustomOption }} // if null use defaultCustomOption
            name={name}
            options={options}
            placeholder={isActive ? getPlaceholder() : ""}
            value={value || defaultValue}
            onChange={onChange}
            indicator
            ref={ref}
            isClearable={isClearable}
            isDisabled={disabled}
            {...rest}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
          <fieldset
            aria-hidden="true"
            className={classNames({ "border-red": error })}
          >
            <legend
              className={classNames({
                "legend--focused": isActive,
                "border-red": error,
              })}
            >
              <span>{placeholder}</span>
            </legend>
          </fieldset>
        </div>

        {error && <ErrorInput message={error.message} />}
      </div>
    );
  }
);

export default SelectOptions;
