import {
  GET_ALL_TICKETS_SUCCESS,
  GET_ALL_TEAM_TICKETS_SUCCESS,
  GET_ASSIGNED_TICKETS_SUCCESS,
  GET_CREATED_TICKETS_SUCCESS,
  GET_PUBLIC_TICKETS_SUCCESS,
  GET_CREATED_BY_TEAM_MEMBERS_TICKETS_SUCCESS,
} from '../action/actionTypes'

const initialList = {
  data: null,
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
}

export const initialState = {
  public_tickets: initialList,
  created_tickets: initialList,
  assigned_tickets: initialList,
  team_tickets: initialList,
  created_by_members_tickets: initialList,
  all_tickets: initialList,
}

function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PUBLIC_TICKETS_SUCCESS:
      return {
        ...state,
        public_tickets: action.payload.tickets,
      }
    case GET_CREATED_TICKETS_SUCCESS:
      return {
        ...state,
        created_tickets: action.payload.tickets,
      }
    case GET_ASSIGNED_TICKETS_SUCCESS:
      return {
        ...state,
        assigned_tickets: action.payload.tickets,
      }
    case GET_ALL_TEAM_TICKETS_SUCCESS:
      return {
        ...state,
        team_tickets: action.payload.tickets,
      }
    case GET_CREATED_BY_TEAM_MEMBERS_TICKETS_SUCCESS:
      return {
        ...state,
        created_by_members_tickets: action.payload.tickets,
      }
    case GET_ALL_TICKETS_SUCCESS:
      return {
        ...state,
        all_tickets: action.payload.tickets,
      }
    default:
      return state
  }
}

export default ticketsReducer
