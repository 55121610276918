import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {getUsers} from 'modules/users/action/actionCreators'
import {ModalContext} from 'context/ModalContext'
import {schema} from '../yup/schema'

import SelectOptions from 'components/SelectOptions'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Input from 'components/Input'
import {resetFilters} from "../../../../teams/action/actionCreators";
import API from 'config/API';

const TeamsForm = ({onSubmitHandler, loading, team = null}) => {
  const {hideModal} = useContext(ModalContext)
  const dispatch = useDispatch()
  const users = useSelector(({users}) => users.data)
  const [rolesList, setRolesList] = React.useState(null)

  useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await API.get(`/user/roles`)
        setRolesList(response.data.data)
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchRoles()
    // reset filters
    return () => {
      dispatch(resetFilters())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getUsers(1, 25, '-created_at', 'filter[role]=Tickets Team Leader'))
  }, [dispatch])

  const getLeadersOptions = () => {
    return users && users.map((user) => ({value: user.id, label: user.name}))
  }

  const getRolesOptions = () => {
    return rolesList && rolesList.map((role) => ({value: role.id, label: role.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: team ? team.name : '',
      leader_id: team ? {value: team.leader.id, label: team.leader.name} : null,
      role_id: team ? {value: team.role.id, label: team.role.name} : null,
    },
  })

  const onSubmit = (values) => onSubmitHandler(values, setError)

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'name',
          render: ({field}) => (
            <Input
              placeholder='Team name'
              containerClassName='mt-7'
              id='name'
              error={errors.name}
              {...field}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'leader_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Leader'
              containerClassName='mt-7'
              options={getLeadersOptions()}
              id='leader_id'
              {...field}
              error={errors.leader_id}
            />
          ),
        }}
      />
      <Controller
          {...{
            control,
            name: 'role_id',
            render: ({field}) => (
                <SelectOptions
                    placeholder='Role'
                    containerClassName='mt-7'
                    options={getRolesOptions()}
                    id='role_id'
                    {...field}
                    error={errors.role_id}
                />
            ),
          }}
      />
      <div className='d-flex align-items-start justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button type='submit' title={loading ? <Loader /> : 'Save'} className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default TeamsForm
