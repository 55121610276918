import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import API from 'config/API'
import {ModalContext} from 'context/ModalContext'
import {getTimeFormat, isFutureDate, isTodayDate} from 'config/utils'
import {getGroupTimeline} from './action/actionCreators'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import ConfirmationModal from 'components/ConfirmationModal'
import Skeleton from 'components/Skeleton'
import Can from 'components/Can'
import Table from 'components/Table'
import AddGroupTimeline from './components/AddGroupTimeline'
import UpdateGroupTimeline from './components/UpdateGroupTimeline'
import Button from 'components/Button'
import TooltipTitle from 'components/TooltipTitle'
import GenerateTimeline from './components/GenerateTimeline'

const GroupTimeline = ({group}) => {
  const dispatch = useDispatch()
  const {showModal, hideModal} = useContext(ModalContext)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const timeline = useSelector(({course}) => course.group_timeline)

  useEffect(() => {
    dispatch(getGroupTimeline(page, size, `filter[group_id]=${group.id}&filter[trashed]=with`))
  }, [dispatch, page, size, group.id])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  const handleAction = React.useCallback(
    async (id, url, action) => {
      try {
        await API.delete(`${url}/${id}`)
        dispatch(getGroupTimeline(page, size, `filter[group_id]=${group.id}&filter[trashed]=with`))
        Notifier.successMessage(`Timeline has been ${action}!`)
        hideModal()
      } catch (error) {
        // Ignore Error Message
      }
    },
    [dispatch, group.id, hideModal, page, size]
  )

  const deleteGroupTimelineItem = React.useCallback(
    (id) => {
      return showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: (
          <ConfirmationModal
            handleAccept={() => handleAction(id, '/course/hard_delete_group_timeline', 'deleted')}
          />
        ),
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [handleAction, hideModal, showModal]
  )

  const cancelGroupTimelineItem = React.useCallback(
    (id) => {
      return showModal({
        id: 'cancel-group-timeline-session-form-modal',
        dialogClassName: 'cancel-group-timeline-session-form',
        closeButton: true,
        title: 'Cancel Timeline Session',
        body: (
          <ConfirmationModal
            title='Are you sure you want to cancel this session?'
            subText=''
            handleAccept={() => handleAction(id, '/course/group_timelines', 'canceled')}
            actionButtonColor='#FDD736'
          />
        ),
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [handleAction, hideModal, showModal]
  )

  const updateTimeline = React.useCallback(
    (timeline) => {
      return showModal({
        id: 'view-group-timeline-form-modal',
        dialogClassName: 'view-group-timeline-form',
        closeButton: true,
        title: 'Update Timeline',
        body: (
          <UpdateGroupTimeline page={page} size={size} groupId={group.id} timeline={timeline} />
        ),
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      })
    },
    [group.id, hideModal, showModal, page, size]
  )

  const addTimeline = () =>
    showModal({
      id: 'add-group-timeline-form-modal',
      dialogClassName: 'add-group-timeline-form',
      closeButton: true,
      title: 'Add Timeline',
      body: <AddGroupTimeline page={page} size={size} groupId={group.id} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  const generateTimeline = () =>
    showModal({
      id: 'generate-group-timeline-form-modal',
      dialogClassName: 'generate-group-timeline-form',
      closeButton: true,
      title: 'Generate Timeline',
      body: <GenerateTimeline page={page} size={size} group={group} />,
      actions: null,
      closeAction: hideModal,
      width: undefined,
      height: 'auto',
    })

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Instructor',
        accessor: 'instructor',
        Cell: ({row: {original: row}}) => (
          <span className='cursor-pointer text-dark fw-bolder text-hover-primary fs-7'>
            {row.instructor?.name}
            {row.deleted_by && <span className='badge badge-warning ms-2'>Canceled</span>}
          </span>
        ),
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({row: {original: row}}) => moment(row.date).format('DD-MM-YYYY'),
      },
      {
        Header: 'From',
        accessor: 'from',
        Cell: ({row: {original: row}}) => getTimeFormat(row.from).format('h:mm a'),
      },
      {
        Header: 'To',
        accessor: 'to',
        Cell: ({row: {original: row}}) => getTimeFormat(row.to).format('h:mm a'),
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({row: {original: row}}) => row?.level ?? 'N/A',
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({row: {original: row}}) => row?.notes ?? 'N/A',
      },
      {
        Header: 'Actions',
        accessor: 'action',
        className: 'text-right px-10',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      timeline?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex justify-content-end'>
            <Can do='show_group_timeline'>
              {!dataItem.deleted_by && (
                <TooltipTitle title='Update timeline'>
                  <div className='rounded-icon' onClick={() => updateTimeline(dataItem)}>
                    <i className='bi bi-pencil'></i>
                  </div>
                </TooltipTitle>
              )}
            </Can>
            {isFutureDate(dataItem.date) && (
              <React.Fragment>
                <Can do='cancel_group_timeline'>
                  {!dataItem.deleted_by && (
                    <TooltipTitle title='Cancel session'>
                      <div
                        className='rounded-icon'
                        onClick={() => cancelGroupTimelineItem(dataItem.id)}
                      >
                        <i className='bi bi-x-circle'></i>
                      </div>
                    </TooltipTitle>
                  )}
                </Can>
                <Can do='delete_group_timeline'>
                  <TooltipTitle title='Delete timeline'>
                    <div
                      className='rounded-icon'
                      onClick={() => deleteGroupTimelineItem(dataItem.id)}
                    >
                      <i className='bi bi-trash2'></i>
                    </div>
                  </TooltipTitle>
                </Can>
              </React.Fragment>
            )}
            {isTodayDate(dataItem.date) && (
              <React.Fragment>
                <Can do='cancel_group_timeline'>
                  {!dataItem.deleted_by && (
                    <TooltipTitle title='Cancel session'>
                      <div
                        className='rounded-icon'
                        onClick={() => cancelGroupTimelineItem(dataItem.id)}
                      >
                        <i className='bi bi-x-circle'></i>
                      </div>
                    </TooltipTitle>
                  )}
                </Can>
                <Can do='delete_group_timeline'>
                  <TooltipTitle title='Delete timeline'>
                    <div
                      className='rounded-icon'
                      onClick={() => deleteGroupTimelineItem(dataItem.id)}
                    >
                      <i className='bi bi-trash2'></i>
                    </div>
                  </TooltipTitle>
                </Can>
              </React.Fragment>
            )}
          </div>
        ),
      })),
    [cancelGroupTimelineItem, deleteGroupTimelineItem, timeline?.data, updateTimeline]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (timeline.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end gap-2'>
            <Can do='generate_group_timeline'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center'>
                    <i
                      className='bi bi-arrow-repeat mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Generate Timeline
                  </span>
                }
                className='mt-0 w-auto p-3 bg-primary'
                onClick={generateTimeline}
                disabled={!group.instructor_id}
              />
            </Can>
            <Can do='create_group_timeline'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Add Timeline
                  </span>
                }
                className='mt-0 w-auto p-3 bg-primary'
                onClick={addTimeline}
              />
            </Can>
          </div>
        </div>
      </div>
      {isEmpty(timeline.data) ? (
        <EmptyList message='No timeline available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(timeline.data) && timeline.meta.last_page > 1 && (
        <Pagination
          pageCount={timeline.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleChangeSize}
          meta={timeline.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default GroupTimeline
