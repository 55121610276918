import axios from "axios";
import { Notifier } from "components/Notifier";


const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

request.interceptors.request.use(config => {
  const token = JSON.parse(localStorage.getItem("auth")) && JSON.parse(localStorage.getItem("auth")).token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

request.interceptors.response.use(response => response, error =>  {
  if (error.response.status === 422) {
    for (let err in error.response.data.errors) {
      Notifier.errorMessage(error.response.data.errors[err].join('\n'));
    }
  } else {
    Notifier.errorMessage(error.response.data.message || error.message)
  }
  return Promise.reject(error);
});

export default request;
