import React from 'react';
import { CheckboxGroup } from '@createnl/grouped-checkboxes';

import Loader from './Loader';

const Table = ({
  table = undefined,
  filterComponent = null,
  filterable = false,
  isFetching = false,
  groupedCheckbox = false,
  getRowProps = undefined,
  headClassName = '',
  tableClassName = ''
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = table;

  const renderRows = () =>
    rows.map((row) => {
      prepareRow(row);
      const { key: rowKey, role: rowRole } = row.getRowProps(getRowProps && getRowProps(row));

      const Tr = () => (
        <tr key={rowKey} role={rowRole}>
          {row.cells.map((cell) => {
            const {
              key: cellKey,
              role: cellRole,
              className: cellClassName
            } = cell.getCellProps({
              className: cell.column.className
            });
            return (
              <td key={cellKey} role={cellRole} className={cellClassName}>
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );

      return groupedCheckbox ? <CheckboxGroup key={rowKey}>{Tr()}</CheckboxGroup> : Tr();
    });

  return (
    <div className='table-responsive mt-5'>
      <table role={getTableProps().role} className={tableClassName}>
        <thead className={headClassName}>
          {headerGroups.map((headerGroup) => {
            const { key: rowKey, role: rowRole } = headerGroup.getHeaderGroupProps();
            return (
              <React.Fragment key={rowKey}>
                <tr key={`${rowKey}-head`} role={rowRole}>
                  {headerGroup.headers.map((column) => {
                    const {
                      key: headerKey,
                      role: headerRole,
                      className: colClassName
                    } = column.getHeaderProps({
                      className: column.className
                    });
                    return (
                      <th key={headerKey} role={headerRole} className={colClassName}>
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
                {filterable && (
                  <tr key={`${rowKey}-filter`} role={rowRole} className="filters--row">
                    {headerGroup.headers.map((column) => {
                      const { key: headerKey, className: filterClassName } = column.getHeaderProps({
                        className: column.className
                      });
                      return (
                        <td key={`${headerKey}-td`} className={filterClassName}>
                          <div> {column.render('Filter')} </div>
                        </td>
                      );
                    })}
                  </tr>
                )}
              </React.Fragment>
            );
          })}
          {filterComponent}
        </thead>
        <tbody role={getTableBodyProps().role}>{isFetching ? <Loader /> : renderRows()}</tbody>
      </table>
    </div>
  );
};

export default Table;