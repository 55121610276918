/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import omit from 'lodash/omit'
import {updateUserSuccess} from '../action/actionCreators'
import UserForm from './UserForm'
import API from 'config/API'
import {Notifier} from 'components/Notifier'
import Skeleton from 'components/Skeleton'

const EditUser = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [user, setUser] = React.useState(null)
  const dispatch = useDispatch()
  const {client_config} = useSelector(({auth}) => auth)
  const userId = props.match.params.id

  React.useEffect(() => {
    async function fetchUser() {
      try {
        const response = await API.get(`/user/users/${userId}`)
        setUser(response.data.data)
      } catch (error) {
        props.history.push('/404')
      }
    }

    fetchUser()
  }, [props.history, userId])

  const prepareSave = (values) => {
    const userData = {
      ...values,
      roles: values.roles.map(({value}) => value),
      branch_id: values.branch_id.value,
    }

    return client_config.has_branch ? userData : omit(userData, 'branch_id')
  }

  const onSubmitHandler = (values, setError) => {
    setLoading(true)
    API.put(`/user/users/${userId}`, prepareSave(values))
      .then((response) => {
        dispatch(updateUserSuccess(response.data.data))
        setLoading(false)
        props.history.push('/users/list')
        Notifier.successMessage('User has been updated!')
      })
      .catch((error) => {
        for (let err in error.response.data.errors) {
          setError(err, {message: error.response.data.errors[err][0]})
        }
        setLoading(false)
      })
  }

  if (!user) {
    return <Skeleton type="block" />
  }

  return <UserForm onSubmitHandler={onSubmitHandler} user={user} loading={loading} editMode />
}

export default withRouter(EditUser)
