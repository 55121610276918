import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTable} from 'react-table'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash/isEmpty'
import API from 'config/API'
import query from 'query-string'

import {Notifier} from 'components/Notifier'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Table from 'components/Table'
import Button from 'components/Button'
import Skeleton from 'components/Skeleton'
import ConfirmationModal from 'components/ConfirmationModal'

import {ModalContext} from 'context/ModalContext'
import {getLeads, setFilters} from './action/actionCreators'
import AddLeadRequest from './components/AddLeadRequest'
import Filters from 'components/Filters'
import {fetchCourses, fetchLeadStages, fetchVisitCallStatuses, normalizeFilters} from 'config/utils'
import TooltipTitle from 'components/TooltipTitle'
import {Link, useHistory} from 'react-router-dom'
import SelectedFilters from 'components/SelectedFilters'
import Input from 'components/Input'

const Leads = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(10)
  const [leadStages, setLeadStages] = React.useState(null)
  const [visitStatuses, setVisitStatuses] = React.useState(null)
  const [courses, setCourses] = React.useState(null)
  const [searchText, setSearchText] = React.useState('')
  const [inputSearchTerm, setInputSearchTerm] = React.useState('')
  const {showModal, hideModal} = React.useContext(ModalContext)
  const leadsList = useSelector(({crm}) => crm.leads)
  const agent_id = useSelector(({auth}) => auth.data.id)
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(
    () =>
      dispatch(
        getLeads(
          page,
          size,
          '-updated_by',
          `${query.stringify(
            normalizeFilters({...leadsList.filters, agent_id})
          )}&filter[lead.mobile]=${searchText}`
        )
      ),
    [dispatch, size, leadsList.filters, page, agent_id, searchText]
  )

  const handleChangeSearch = (event) => setSearchText(event.target.value)

  const debouncedChangeHandler = React.useMemo(() => debounce(handleChangeSearch, 1000), [])

  const fetchFiltersListOptions = async () => {
    const [leadStages, callStatuses, courses] = await Promise.all([
      fetchLeadStages(),
      fetchVisitCallStatuses(),
      fetchCourses(),
    ])
    // set state
    setLeadStages(leadStages.data)
    setVisitStatuses(callStatuses.data)
    setCourses(courses.data)
  }

  React.useEffect(() => {
    fetchFiltersListOptions()
  }, [])

  const handleDelete = React.useCallback(
    (id) =>
      API.delete(`/crm/lead_requests/${id}`)
        .then(() => {
          dispatch(
            getLeads(
              page,
              size,
              '-updated_by',
              `${query.stringify(
                normalizeFilters({...leadsList.filters, agent_id})
              )}&filter[lead.mobile]=${searchText}`
            )
          )
          hideModal()
          Notifier.infoComponent('Changes Applied', 'Changes applied successfully')
        })
        .catch((error) => {
          // Ignore Error Message
        }),
    [hideModal]
  )

  const deleteLead = React.useCallback(
    (id) =>
      showModal({
        id: 'confirm',
        dialogClassName: 'confirm-dialog',
        closeButton: true,
        title: undefined,
        body: <ConfirmationModal handleAccept={() => handleDelete(id)} />,
        actions: null,
        closeAction: hideModal,
        width: undefined,
        height: 'auto',
      }),
    [handleDelete, hideModal, showModal]
  )

  const addLeadRequest = () =>
    showModal({
      id: 'add-lead-request-form-modal',
      dialogClassName: 'add-lead-request-form',
      closeButton: true,
      title: 'Add Lead Request',
      body: <AddLeadRequest />,
      actions: null,
      closeAction: hideModal,
      width: 800,
      height: 'auto',
    })

  const handlePageChange = (pageNumber) => setPage(pageNumber + 1)

  const handleSizeChange = (event) => setSize(event.target.value)

  // filters
  const handleUpdateFilters = (filters) => dispatch(setFilters(filters))

  const getOptions = (data) => {
    return data && data.map((dataItem) => ({value: dataItem.id, label: dataItem.name}))
  }

  const getFilters = () => [
    {name: 'course_id', type: 'options', label: 'Course', options: getOptions(courses)},
    {name: 'lead_stage_id', type: 'options', label: 'Lead Stage', options: getOptions(leadStages)},
    {
      name: 'visit_call_status_id',
      type: 'options',
      label: 'Visit Or Call Status',
      options: getOptions(visitStatuses),
    },
    {name: 'start_date', type: 'date', label: 'Start Date'},
    {name: 'end_date', type: 'date', label: 'End Date'},
  ]

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Lead',
        accessor: 'lead',
        Cell: ({row: {original: row}}) => (
          <Link to={`/leads/${row.id}`}>{row.lead?.name ?? 'N/A'}</Link>
        ),
      },
      {
        Header: 'Lead Mobile',
        accessor: 'mobile',
        Cell: ({row: {original: row}}) => row.lead?.mobile ?? 'N/A',
      },
      {
        Header: 'Course',
        accessor: 'course',
        Cell: ({row: {original: row}}) => row.course?.name ?? 'N/A',
      },
      {
        Header: 'Channel',
        accessor: 'channel',
        Cell: ({row: {original: row}}) => row.channel?.name ?? 'N/A',
      },
      {
        Header: 'Team',
        accessor: 'team',
        Cell: ({row: {original: row}}) => row.team?.name ?? 'N/A',
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({row: {original: row}}) => row.agent?.name ?? 'N/A',
      },
      {
        Header: 'Lead Stage',
        accessor: 'leadStage',
        Cell: ({row: {original: row}}) => row.leadStage?.name ?? 'N/A',
      },
      {
        Header: 'Visit Call Status',
        accessor: 'visitCallStatus',
        Cell: ({row: {original: row}}) => row.visitCallStatus?.name ?? 'N/A',
      },
      {
        Header: 'Actions',
        accessor: 'action',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(
    () =>
      leadsList?.data.map((dataItem) => ({
        ...dataItem,
        action: (
          <div className='d-flex'>
            <TooltipTitle title='View Lead'>
              <div className='rounded-icon' onClick={() => history.push(`/leads/${dataItem.id}`)}>
                <i className='bi bi-eye-fill'></i>
              </div>
            </TooltipTitle>
            <TooltipTitle title='Delete Lead'>
              <div className='rounded-icon' onClick={() => deleteLead(dataItem.id)}>
                <i className='bi bi-trash2'></i>
              </div>
            </TooltipTitle>
          </div>
        ),
      })),
    [leadsList, deleteLead]
  )

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  // fetching
  if (leadsList.isFetching) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div className='my-5'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-between'>
            <div>
              <Input
                type='text'
                containerClassName='bg-white'
                style={{width: 300}}
                placeholder='Search by mobile...'
                onChange={(event) => {
                  setInputSearchTerm(event.target.value)
                  debouncedChangeHandler(event)
                }}
                value={inputSearchTerm}
              />
            </div>
            <div className='d-flex gap-1'>
              <Button
                title={
                  <span className='d-flex align-items-center justify-content-center'>
                    <i
                      className='bi bi-plus-circle mx-2'
                      style={{color: 'white', fontSize: '1.5rem'}}
                    ></i>
                    Add Lead Request
                  </span>
                }
                className='mt-0 w-auto mx-4 p-3 bg-primary'
                onClick={addLeadRequest}
              />
              <Filters
                filters={getFilters()}
                handleUpdateFilters={handleUpdateFilters}
                initialValues={leadsList.filters}
              />
            </div>
          </div>
          <SelectedFilters filters={leadsList.filters} setFilters={setFilters} />
        </div>
      </div>
      {isEmpty(leadsList.data) ? (
        <EmptyList message='No Leads Available' />
      ) : (
        <Table table={table} isFetching={false} />
      )}
      {!isEmpty(leadsList.data) && leadsList.meta.last_page > 1 && (
        <Pagination
          pageCount={leadsList.meta.last_page}
          handlePageClick={handlePageChange}
          handleSizeChange={handleSizeChange}
          meta={leadsList.meta}
          currentPage={page - 1}
        />
      )}
    </React.Fragment>
  )
}

export default Leads
