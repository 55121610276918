import {
  DELETE_TICKET_CATEGORY_SUCCESS,
  GET_TICKET_CATEGORIES_SUCCESS,
  SET_FETCHING_TICKET_CATEGORIES,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function ticketCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_TICKET_CATEGORIES:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_TICKET_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload.categories.data,
        meta: action.payload.categories.meta,
      }
    case DELETE_TICKET_CATEGORY_SUCCESS:
      return {
        ...state,
        data: state.data.filter((category) => category.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default ticketCategoriesReducer
