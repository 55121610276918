import * as Yup from 'yup'

export const schema = Yup.object().shape({
  ticket_action_id: Yup.object().required('action is required'),
  priority: Yup.object().required('priority is required'),
  branch_id: Yup.object(),
  description: Yup.string().required('description is required'),
  attachment: Yup.object().nullable().shape({
    file: Yup.object().shape({
        name: Yup.string()
    }).label('File'),
  }),
  ticketable_id: Yup.string().required('id is required')
});