import {
  GET_LEADS_LAST_ACTION_SUCCESS,
  RESET_LEADS_LAST_ACTION_FILTERS,
  SET_FETCHING_LEADS_LAST_ACTION,
  SET_LEADS_LAST_ACTION_FILTERS,
} from '../action/actionTypes'
import API from 'config/API'

const getLeadsPerAgentSuccess = (leads) => ({
  type: GET_LEADS_LAST_ACTION_SUCCESS,
  payload: {leads},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_LEADS_LAST_ACTION,
  payload: {isFetching},
})

export const getLeadsLastAction =
  (
    page,
    size, // paginate
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(`/crm/leads_reports?paginate=${size}&page=${page}&${filters}`)
      dispatch(
        getLeadsPerAgentSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

export const setFilters = (filters) => ({
  type: SET_LEADS_LAST_ACTION_FILTERS,
  payload: {filters},
})

export const resetFilters = () => ({
  type: RESET_LEADS_LAST_ACTION_FILTERS,
  payload: {},
})
