import API from 'config/API';
import Can from 'components/Can';
import { Notifier } from 'components/Notifier';
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import CommentListItem from './CommentListItem';

export default function Comments({ comments, reporter_id, agent_id, created_by }: any) {
  const [ticketComments, setTicketComments] = useState(comments);
  const [newComment, setNewComment] = useState('');
  const loggedUser = useSelector(({ auth }: any) => auth.data);
  const match: any = useRouteMatch();
  const [file, setFile] = useState('');
  const fileRef: any = useRef();

  const onFileChange = (event: any) => setFile(event.target.files[0]); 

  const sendComment = async (comment: string, parent_id = '', attachment = '') => {
    let formData = new FormData();

    const values: any = {
      comment, 
      parent_id, 
      attachment, 
      ticket_id: match.params.id
    }

    for (const value in values) {
      // Update the formData object
      formData.append(
        value,
        values[value],
      );
    }

    try {
        const response = await API.post(
          `/ticket/ticket_comments`, formData
        );
        setNewComment('');
        setFile('');
        setTicketComments([...ticketComments, {...response.data.data, createdBy: loggedUser  }]);
        Notifier.successMessage('Comment has been saved!');
    } catch(error) {
      // Ignore Error Message
    }
  }

  // 
  const childCommentsIds = ticketComments.filter((comment: any) => comment.parent_id).map(({ parent_id }: any) => (parent_id));
  const transformedComments = ticketComments.filter((comment: any) => !comment.parent_id).map((comment: any) => {
    if (childCommentsIds.includes(comment.id)) {
      return {
        ...comment,
        reply: ticketComments.find(({ parent_id }: any) => parent_id === comment.id)
      }
    } else {
      return comment;
    };
  });

  return (
    <div>
      <Can do={"create_ticket_comment"}>
        {[reporter_id, agent_id, created_by].includes(loggedUser.id) && (
      <div className="mb-0">
        <textarea 
          value={newComment} 
          onChange={(event) => setNewComment(event.target.value)} 
          className="form-control form-control-solid placeholder-gray-600 fw-bolder fs-4 ps-9 pt-7" 
          rows={6} 
          name="message" 
          placeholder="Comment..."
        ></textarea>
        <button
          type="button" 
          onClick={() => sendComment(newComment, '', file)} 
          className="btn btn-primary mt-n20 mb-20 position-relative float-end me-7"
        >
          Comment
        </button>
        <input 
          type="file" 
          accept="image/*,.pdf,.doc"
          ref={fileRef}  
          onChange={onFileChange} 
          hidden 
        />
        <button 
          className="btn btn-secondary mt-n20 mb-20 position-relative float-end me-2" 
          onClick={() => (fileRef.current as any).click()}
        >
          <i className="bi bi-paperclip fs-4"></i>
        </button>
        {file && <span className="mt-n15 mb-20 position-relative float-end me-2">{(file as any).name}</span>}
      </div>
        )}
      </Can>
      <div className="mb-15">
        {transformedComments && transformedComments.map((comment: any) => (
          <CommentListItem key={comment.id} comment={comment} sendComment={sendComment} />
        ))}
      </div>
    </div>
  )
}
