import React from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import * as Yup from 'yup'
import API from 'config/API'

import Button from 'components/Button'
import Loader from 'components/Loader'

import {ModalContext} from 'context/ModalContext'
import SelectOptions from 'components/SelectOptions'
import {useDispatch, useSelector} from 'react-redux'
import {getCourses} from 'modules/course/list/action/actionCreators'
import {getChannels} from 'modules/general/channels/action/actionCreators'

const LeadCourseForm = ({ onRefetch, lead_id }) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const courses = useSelector(({course}) => course.list)
  const channels = useSelector(({channels}) => channels)
  const {hideModal} = React.useContext(ModalContext)

  React.useEffect(() => {
    dispatch(getChannels(1, 25, '-created_at', ''))
    dispatch(getCourses(1, 25, '-created_at', ''))
  }, [dispatch])

  const getOptions = (list) => {
    return list && list.data?.map((data) => ({value: data.id, label: data.name}))
  }

  // useForm setup
  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        channel_id: Yup.object().required('Channel is required').nullable(),
        course_id: Yup.object().required('Course is required').nullable(),
      })
    ),
    defaultValues: {
      channel_id: null,
      course_id: null,
    },
  })

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await API.post('/crm/lead_requests', {
        ...values,
        channel_id: values.channel_id?.value,
        course_id: values.course_id?.value,
        lead_id,
      })
      onRefetch()
      setLoading(false)
      hideModal()
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return (
    <form className='form w-100 m-auto my-5 pb-5' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        {...{
          control,
          name: 'course_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Course'
              containerClassName='mt-7 flex-1'
              options={getOptions(courses)}
              id='course_id'
              {...field}
              error={errors.course_id}
            />
          ),
        }}
      />
      <Controller
        {...{
          control,
          name: 'channel_id',
          render: ({field}) => (
            <SelectOptions
              placeholder='Channel'
              containerClassName='mt-7 flex-1'
              options={getOptions(channels)}
              id='channel_id'
              {...field}
              error={errors.channel_id}
            />
          ),
        }}
      />
      <div className='d-flex align-items-center justify-content-center gap-5'>
        <Button
          title='Cancel'
          className='btn btn-light btn-active-light-primary py-5'
          onClick={hideModal}
        />
        <Button title={loading ? <Loader /> : 'Save'} type='submit' className='bg-primary py-5' />
      </div>
    </form>
  )
}

export default LeadCourseForm
