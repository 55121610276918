import React, {useState} from 'react'
import API from 'config/API'
import {Notifier} from 'components/Notifier'
import {ModalContext} from 'context/ModalContext'
import AgentForm from './AgentForm'
import {getAgents} from '../action/actionCreators'
import {useDispatch} from 'react-redux'

const AddAgent = ({team_id}) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {hideModal} = React.useContext(ModalContext)

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      const response = await API.post(`/team/team_members`, {
        members: values.members.map((member) => member.value),
        team_id,
      })
      hideModal()
      dispatch(getAgents(1, 10, '', `filter[team_id]=${team_id}`))
      setLoading(false)
      Notifier.successMessage('Agent has been assigned!')
      return response
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError('members', {message: error.response.data.errors[err][0]})
      }
      setLoading(false)
    }
  }

  return <AgentForm loading={loading} initialValues={{members: null}} onSubmitHandler={onSubmit} />
}

export default AddAgent
