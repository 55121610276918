import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux';
import API from 'config/API';
import moment from 'moment';

import { Notifier } from 'components/Notifier';
import { ModalContext } from 'context/ModalContext';
import { getGroupTimeline } from '../action/actionCreators';
import GroupTimelineForm from './GroupTimelineForm';

const AddGroupTimeline = ({ groupId, page, size }) => {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values, setError) => {
    try {
      setLoading(true)
      await API.post(`/course/group_timelines`, { 
        ...values, 
        instructor_id: values.instructor_id.value, 
        date: moment(values.date).format('YYYY-MM-DD'), group_id: groupId 
      })
      setLoading(false);
      dispatch(getGroupTimeline(page, size, `filter[group_id]=${groupId}`))
      hideModal();
      Notifier.successMessage('Timeline has been created!');
    } catch (error) {
      for (let err in error.response.data.errors) {
        setError(err, { message: error.response.data.errors[err][0] });
      }
      setLoading(false)
    }
  }

  return <GroupTimelineForm loading={loading} onSubmitHandler={onSubmit} />;
}

export default AddGroupTimeline;