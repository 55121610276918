import {
  SET_GROUPS_FILTERS,
  RESET_GROUPS_FILTERS,
  SET_FETCHING_GROUPS,
  GET_GROUPS_SUCCESS,
} from './actionTypes'
import API from 'config/API'

export const setFetching = (isFetching) => ({
  type: SET_FETCHING_GROUPS,
  payload: {isFetching},
})

const getGroupsSuccess = (groups) => ({
  type: GET_GROUPS_SUCCESS,
  payload: {groups},
})

export const getGroups =
  (
    page,
    size, // paginate
    sort,
    include,
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const url = `/course/groups?${include}&paginate=${size}&page=${page}&${filters}`
      const response = await API.get(url)
      dispatch(
        getGroupsSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

export const setGroupsFilters = (filters) => ({
  type: SET_GROUPS_FILTERS,
  payload: {filters},
})

export const resetGroupsFilters = () => ({
  type: RESET_GROUPS_FILTERS,
  payload: {},
})
