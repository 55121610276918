import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import API from 'config/API'

// capitalize each letter from sentence  
// with example => new world => New World
export const capitalizeFirstLetter = (str: string) => {
  return str
    .split(' ')
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ');
};

// extract extension (pdf, png, jpeg, jpg) from url
export const getUrlExtension = (url: string) => {
  return url
    .split(/[#?]/)[0]
    .split('.')
    .pop()
    .trim();
};

// extract file name from url
export const getFileNameFromUrl = (url: string) => {
  return  url.substring(url.lastIndexOf('/') + 1);
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const  useQuery = (search: string) => {
  return new URLSearchParams(search);
}

export const getTimeFormat = (time) => {
  const [hours, minutes, seconds] = time.split(":");
  return moment(moment().set({ hours, minutes, seconds }).toDate());
}

const today = moment().format('YYYY-MM-DD');

export const isFutureDate = (date: Date) => moment(date).isAfter(today);

export const isPastDate = (date: Date) => moment(date).isBefore(today);

export const isTodayDate = (date: Date) => moment(date).isSame(today);

// normalize filters object Hook
export const normalizeFilters = (userFilters) => {
  let queryObject = {};

  // check if key has value
  const hasValue = (filter) => !isEmpty(userFilters[filter]);

  // loop through filters object
  for (const filter in userFilters) {
    // check if filter has value
    if (hasValue(filter)) {
      // check for type if 'object', which means reselect value { value: '', label: '' }
      if (typeof userFilters[filter] === 'object') {
        // use value from that 'object'
        queryObject = { ...queryObject, [`filter[${filter}]`]: userFilters[filter].value };
      } else {
        // if not, set the filter value directly
        queryObject = { ...queryObject, [`filter[${filter}]`]: userFilters[filter] };
      }
    }
  }

  return queryObject;
}

export const fetchLeadStages = async () => {
  try {
    const response = await API.get(`/crm/lead_stages`);
    return response.data;
  } catch (error) {
    // Ignore Error Message
  }
}

export const fetchVisitCallStatuses = async (filters = '') => {
  try {
    const response = await API.get(`/crm/visit_call_statuses?${filters}`);
    return response.data;
  } catch (error) {
    // Ignore Error Message
  }
}

export const fetchCourses = async () => {
  try {
    const response = await API.get(`/course/courses`);
    return response.data;
  } catch (error) {
    // Ignore Error Message
  }
}

export const fetchChannels = async () => {
  try {
    const response = await API.get(`/crm/channels`);
    return response.data;
  } catch (error) {
    // Ignore Error Message
  }
}

export const fetchUsers = async (filter = null) => {
  try {
    const response = await API.get(`/user/users?${filter}`);
    return response.data;
  } catch (error) {
    // Ignore Error Message
  }
}

export const fetchGroupTimelineById = async (id) => {
  try {
    const response = await API.get(`course/group_timelines?sort=-date&paginate=100&filter[group_id]=${id}`);
    return response.data;
  } catch (error) {
    // Ignore Error Message
  }
}