import {
  ADD_CHANNEL_SUCCESS,
  DELETE_CHANNEL_SUCCESS,
  GET_CHANNELS_SUCCESS,
  SET_FETCHING_CHANNELS,
  UPDATE_CHANNEL_SUCCESS,
} from '../action/actionTypes'

export const initialState = {
  data: [],
  meta: {
    current_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  isFetching: false,
}

function channelsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHING_CHANNELS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      }
    case GET_CHANNELS_SUCCESS:
      return {
        ...state,
        ...action.payload.channels,
      }
    case ADD_CHANNEL_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.channel],
      }
    case UPDATE_CHANNEL_SUCCESS:
      return {
        ...state,
        data: state.data.map((channel) => {
          if (channel.id === action.payload.channel.id) {
            return action.payload.channel
          }
          return channel
        }),
      }
    case DELETE_CHANNEL_SUCCESS:
      return {
        ...state,
        data: state.data.filter((channel) => channel.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default channelsReducer
