import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import {useHistory} from 'react-router-dom'

import {getGroups} from '../groups/action/actionCreators'
import {can} from 'modules/ticket/utils'

import UpdateAttendance from './components/UpdateAttendance'
import Pagination from 'components/Pagination'
import EmptyList from 'components/EmptyList'
import Skeleton from 'components/Skeleton'
import {useTable} from 'react-table'
import Table from 'components/Table'
import Input from 'components/Input'

const GroupAttendance = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'))
  const [selectedGroup, setSelectedGroup] = useState(null)
  const groups = useSelector(({course}) => course.groups)
  const loggedInUser = useSelector(({auth}) => auth.data)
  const instructorFilter = can.do('list_student_attendances')
    ? ''
    : `&filter[instructor_id]=${loggedInUser.id}`
  const history = useHistory()

  useEffect(() => {
    dispatch(
      getGroups(
        page,
        size,
        '-created_at',
        'include=course,groupStatus,activeStudentCoursesCount,branch,instructor',
        `filter[hasSessionOnDate]=${moment(selectedDate).format('YYYY-MM-DD')}${instructorFilter}`
      )
    )
  }, [dispatch, page, size, selectedDate, instructorFilter])

  const handlePageChange = (page) => setPage(page + 1)

  const handleChangeSize = (event) => setSize(event.target.value)

  // table columns
  const dataColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row: {original: row}}) => (
          <span className="cursor-pointer" onClick={() => setSelectedGroup(row)}>{row.name}</span>
        ),
      },
      {
        Header: 'Course',
        accessor: 'course',
        Cell: ({row: {original: row}}) => row.course?.name ?? null,
      },
      {
        Header: 'Instructor',
        accessor: 'instructor',
        Cell: ({row: {original: row}}) => row.instructor?.name ?? null,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row: {original: row}}) => row.groupStatus?.name ?? null,
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({row: {original: row}}) => row?.level ?? 'N/A',
      },
      {
        Header: 'Students',
        accessor: 'activeStudentCoursesCount',
      },
    ],
    []
  )

  // table data
  const data = React.useMemo(() => groups?.data.map((dataItem) => dataItem), [groups])

  // table setup
  const table = useTable({
    columns: dataColumns,
    data,
  })

  if (!(can.do('list_student_attendances') || can.do('list_owned_only_student_attendances'))) {
    history.push('/404')
    return null
  }

  // fetching
  if (groups.isFetching) {
    return <Skeleton />
  }

  if (selectedGroup) {
    return (
      <UpdateAttendance
        group={selectedGroup}
        selectedDate={selectedDate}
        setSelectedGroup={setSelectedGroup}
      />
    )
  }

  return (
    <div className='mt-5 py-10'>
      <div className='bg-white rounded p-5 mb-3 d-flex align-items-center justify-content-between'>
        <h2 className='m-0'>Group Attendance</h2>
        <Input
          type='date'
          placeholder='Choose date'
          value={selectedDate}
          onChange={(event) => setSelectedDate(moment(event.target.value).format('YYYY-MM-DD'))}
        />
      </div>
      <div>
        {isEmpty(groups.data) ? (
          <EmptyList message={`No Groups Available on ${selectedDate}`} />
        ) : (
          <Table table={table} isFetching={false} />
        )}
        {!isEmpty(groups.data) && groups.meta.last_page > 1 && (
          <Pagination
            pageCount={groups.meta.last_page}
            handlePageClick={handlePageChange}
            handleSizeChange={handleChangeSize}
            meta={groups.meta}
            currentPage={page - 1}
          />
        )}
      </div>
    </div>
  )
}

export default GroupAttendance
