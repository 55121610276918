import React from 'react'
import {toAbsoluteUrl} from '_metronic/helpers'

const EmptyList = ({Icon = undefined, message = 'No Data Available'}) => {
  return (
    <div className='d-flex flex-column flex-center'>
      {Icon ? Icon : <img src={toAbsoluteUrl('/media/empty.png')} className='mw-400px' alt='' />}
      <div className='fw-bolder text-dark mb-4' style={{fontSize: '1.5rem'}}>
        {message}
      </div>
    </div>
  )
}

export default EmptyList
