import React from 'react'
import moment from 'moment'
import API from 'config/API'
import classNames from 'classnames'

const LeadCourses = ({lead_id, setLeadRequestId, selectedLeadRequest}) => {
  const [leadRequests, setLeadRequests] = React.useState(null)

  const fetchCourses = React.useCallback(async () => {
    try {
      setLeadRequests(null)
      const response = await API.get(
        `/crm/lead_requests?include=course,leadStage&filter[lead_id]=${lead_id}`
      )
      setLeadRequests(response.data.data)
    } catch (error) {
      // Ignore Error Message
    }
  }, [lead_id])

  React.useEffect(() => {
    fetchCourses()
  }, [lead_id, fetchCourses])

  return (
    <div className='card mb-10'>
      <div className='card-body pb-0'>
        <h5 className='card-title'>Courses</h5>
      </div>
      <div className='p-5'>
        {!leadRequests && <div className='font-bold text-center'>Fetching courses....</div>}
        {leadRequests?.map((leadRequest) => (
          <CourseListItem
            key={leadRequest.id}
            leadRequest={leadRequest}
            setLeadRequestId={setLeadRequestId}
            selectedLeadRequest={selectedLeadRequest}
          />
        ))}
      </div>
    </div>
  )
}

const CourseListItem = ({leadRequest, setLeadRequestId, selectedLeadRequest}) => (
  <div
    className={classNames('border rounded p-5 shadow-sm mb-2', {
      'bg-bisque': leadRequest.id === selectedLeadRequest,
      'bg-white': leadRequest.id !== selectedLeadRequest,
    })}
  >
    <div className='d-flex align-items-center justify-content-between'>
      <div>
        <h1
          className='fs-6 m-0 mb-2 cursor-pointer'
          onClick={() => setLeadRequestId(leadRequest.id)}
        >
          {leadRequest.course.name}
        </h1>
        <span className='mt-2 color-gray'>
          {moment(leadRequest.assigned_at).format('DD-MM-YYYY, hh:mm A')}
        </span>
      </div>
      <div>
        <span className='fw-bolder'>{leadRequest.leadStage?.name}</span>
      </div>
    </div>
  </div>
)

export default LeadCourses
