import {
  GET_LEADS_PER_AGENT_SUCCESS,
  RESET_LEADS_PER_AGENT_FILTERS,
  SET_FETCHING_LEADS_PER_AGENT,
  SET_LEADS_PER_AGENT_FILTERS,
} from '../action/actionTypes'
import API from 'config/API'

const getLeadsPerAgentSuccess = (leads) => ({
  type: GET_LEADS_PER_AGENT_SUCCESS,
  payload: {leads},
})

const setFetching = (isFetching) => ({
  type: SET_FETCHING_LEADS_PER_AGENT,
  payload: {isFetching},
})

export const getLeadsPerAgent =
  (
    page,
    size, // paginate
    filters
  ) =>
  async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await API.get(
        `/crm/leads_per_agent_reports?paginate=${size}&page=${page}&${filters}`
      )
      dispatch(
        getLeadsPerAgentSuccess({
          data: response.data.data,
          meta: response.data.meta,
        })
      )
      dispatch(setFetching(false))
      return response.data
    } catch (error) {
      dispatch(setFetching(false))
      // Ignore Error Message
    }
  }

export const setFilters = (filters) => ({
  type: SET_LEADS_PER_AGENT_FILTERS,
  payload: {filters},
})

export const resetFilters = () => ({
  type: RESET_LEADS_PER_AGENT_FILTERS,
  payload: {},
})
