import StudentsAttendanceActions from './components/StudentsAttendanceActions'
import StudentsAttendanceRequests from './components/StudentsAttendanceRequests'

export const attendanceDetailsTabs = [
  {
    id: 'actions',
    label: 'Attendance Actions',
  },
  {
    id: 'requests',
    label: 'Attendance Requests',
  },
]

export const getActiveTabComponent = (tabId, data) => {
  const tabComponent = {
    actions: () => <StudentsAttendanceActions data={data} />,
    requests: () => <StudentsAttendanceRequests data={data} />,
  }

  return tabComponent[tabId] ? tabComponent[tabId]() : null
}
