import React from 'react';

const ErrorInput = ({ message }) => (
  <span className="error-input">
    <i className="bi bi-info-circle" style={{ margin: '0 5px 0 5px', color: 'red' }}></i>
    {message}
  </span>
);

export default ErrorInput;
